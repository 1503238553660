import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import React from 'react'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

interface AlertMainProps {
  open: boolean
  onClose: any
  type: any
  text: string
  autoHideDuration?: number
}

export const RenderAlert: React.FC<AlertMainProps> = ({
  open = false,
  onClose,
  type='success',
  text,
  autoHideDuration = 6000,
}) => {

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={type}>
        {text}
      </Alert>
    </Snackbar>
  )
}
