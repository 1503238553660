import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../../api'
import { handleError } from '../../../libs/helpers'
import { InvitedUser, VerificationStatus } from './type'

const isLoggedIn = Boolean(localStorage.getItem('id_token'))

interface InviteRegistrationSlice {
  isLoading: boolean
  invitedUser: InvitedUser
  verificationStatus: VerificationStatus
  loggedIn: boolean
  linkRetry: number
}
const initialStore: InviteRegistrationSlice = {
  isLoading: false,
  invitedUser: {
    email: '',
    firstName: '',
    lastName: '',
    tenantId: '',
    accountId: '',
    accessCode: '',
    permission: 5,
  },
  verificationStatus: VerificationStatus.UNKNOWN,
  linkRetry: 0,
  loggedIn: isLoggedIn,
}

export const inviteRegistrationSlice = createSlice({
  name: 'inviteRegistration',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setUser(store, { payload }: { payload: InvitedUser }) {
      store.invitedUser = payload
    },
    setUserData(store, { payload }: { payload: InvitedUser }) {
      store.invitedUser = payload
    },
    setVerificationStatus(store, { payload }: { payload: VerificationStatus }) {
      store.verificationStatus = payload
    },
    clearMemberState(store) {
      store.invitedUser = initialStore.invitedUser
    },
    setLinkRetry(store, { payload }: { payload: number }) {
      store.linkRetry = payload
    },
    resetInviteStore: () => initialStore,
  },
})
export const {
  setLoading,
  setUser,
  setUserData,
  setVerificationStatus,
  resetInviteStore,
  clearMemberState,
} = inviteRegistrationSlice.actions

const { setLinkRetry } = inviteRegistrationSlice.actions

export const submitEmailVerify =
  (params: any) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const { data, errorMessage, error } = await api.submitEmailVerification(
      params
    )

    if (error) {
      if (error in VerificationStatus) {
        sessionStorage.removeItem('registerComplete')
        sessionStorage.removeItem('codeSent')
        dispatch(setVerificationStatus(error))
      } else {
        dispatch(setLoading(false))
        dispatch(setVerificationStatus(VerificationStatus.ERROR))
      }
      dispatch(setLoading(false))
    }

    if (data && data in VerificationStatus) {
      sessionStorage.removeItem('registerComplete')
      sessionStorage.removeItem('codeSent')
      dispatch(setLoading(false))
      dispatch(setVerificationStatus(data))
    }
    dispatch(setLoading(false))
  }

export const requestNewEmailLink =
  (params: any, onSuccess?: () => void) => async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))
    const { linkRetry } = getStore().inviteRegistration
    const { data, error } = await api.sendNewInviteEmail(params)

    if (error) {
      dispatch(setLoading(false))
      if (error === 'PREVIOUSLY_VERIFIED_COMPLETE') {
        dispatch(setVerificationStatus(error))
      } else if (linkRetry > 0) {
        dispatch(setVerificationStatus(VerificationStatus.ERROR))
      } else {
        dispatch(setLinkRetry(linkRetry + 1))
        dispatch(handleError({ message: error }))
      }
    } else if (data === true) {
      dispatch(setLoading(false))
      if (onSuccess) onSuccess()
      else dispatch(setVerificationStatus(VerificationStatus.NEW_LINK_SENT))
    } else {
      dispatch(handleError({ message: error }))
      dispatch(setLoading(false))
    }
  }

export const sendAppDownloadEmail = () => async (dispatch: Dispatch) => {
  const { error } = await api.sendAppDownloadEmail()
  if (error) {
    console.log('ERROR')
  }
}

export const inviteRegistrationReducer = inviteRegistrationSlice.reducer
export const inviteRegistrationReducerName = inviteRegistrationSlice.name
interface RootStore {
  [inviteRegistrationReducerName]: typeof initialStore
}

export const selectIsLoading = ({ inviteRegistration }: RootStore) =>
  inviteRegistration.isLoading

export const selectUser = ({ inviteRegistration }: RootStore) =>
  inviteRegistration.invitedUser

export const selectStatus = ({ inviteRegistration }: RootStore) =>
  inviteRegistration.verificationStatus

export const selectLoggedIn = ({ inviteRegistration }: RootStore) =>
  inviteRegistration.loggedIn
