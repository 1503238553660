export const paths = {
  login: () => '/',
  mfAuth: () => '/mfa',
  setupMFA: () => '/setup-mfa',
  setupMFAType: (type = ':type') => `/setup-mfa/${type}`,
  setupTOTP: () => '/setup-totp',
  accessCode: () => '/register-access-code',

  registerTab: (type = ':type') => `/register/${type}`,
  inviteEmailVerification: () => `/email-verification`,
  inviteMessaging: (type = ':type') => `/sign-up/${type}`,
  confirmEmail: () => '/email-confirmation',
  forgotPassword: () => '/password-reset-request',
  resetConfirmation: (email = ':email') =>
    `/password-reset-request/confirm/${email}`,
  resetSuccessful: () => `/password-reset-request/reset-successful`,
  authLogin: () => '/auth',
  scan: () => '/scan',
  resources: () => '/resources',
  resourcesById: (id = ':id') => `/resources/${id}`,
  resourceDetails: (type = ':type', id = ':resourceId') =>
    `/resource/${type}/${id}`,
  symptomCheckerIntro: () => `/virtual-consult-intro`,
  symptomCheckerIntroById: (id = ':id') => `/virtual-consult-intro/${id}`,

  symptomCheckerV2: () => '/symptom-checker',
  symptomCheckerById: (id = ':id') => `/symptom-checker/${id}`,
  symptomCheckerMoreInfo: () => '/virtual-consult-info',
  telehealthIntro: () => '/telehealth-introduction',
  telehealthIntake: (type = ':type') => `/telehealth-intake/${type}`,
  beInTouch: () => '/well-be-in-touch',
  landing: () => '/landing',
  faqs: () => '/faqs',
  faqsDetails: (faqId = ':faqId') => `/faqs/${faqId}`,
  flowList: () => '/list-health-services',
  guardianNotification: () => '/guardian-required',
  guardianInfo: () => '/guardian-info',
  guardianAgreement: () => '/guardian-agreement',
  guardianComplete: () => '/guardian-registration-complete',
  welcome: () => '/welcome',

  facility: (type = ':type', id = ':id') => `/${type}/${id}/facility`,
  dropOff: (type = ':type', id = ':id') => `/${type}/${id}/drop-off`,
  schedule: () => '/schedule',
  scheduleAppointment: (type = ':type', id = ':id', siteCode = ':siteCode') =>
    `/${type}/${id}/${siteCode}/schedule-appointment`,

  insuranceVerifyingProcess: (type = ':type', id = ':id') =>
    `/${type}/${id}/insurance-verifying`,
  insuranceProblem: (type = ':type', id = ':id') =>
    `/${type}/${id}/insurance-problem`,
  addInsuranceProvider: (type = ':type', id = ':id') =>
    `/${type}/${id}/add-insurance-provider`,
  insuranceProviderAdded: (type = ':type', id = ':id') =>
    `/${type}/${id}/insurance-provider-added`,
  policyHolderInfo: (type = ':type', id = ':id') =>
    `/${type}/${id}/policy-holder-info`,
  confirmInsurance: (type = ':type', id = ':id', page = ':page') =>
    `/${type}/${id}/confirm-insurance/${page}`,
  addInsurance: (type = ':type', id = ':id') => `/${type}/${id}/add-insurance`,
  memberAccount: (type = ':type', id = ':id') =>
    `/${type}/${id}/member-account`,
  editAccount: (type = ':type', id = ':id') => `/${type}/${id}/edit-account`,
  checkout: (type = ':type', id = ':id') => `/${type}/${id}/checkout`,
  paymentMethods: (type = ':type', id = ':id') =>
    `/${type}/${id}/payment-methods`,
  shippingAddress: (type = ':type', id = ':id') =>
    `/${type}/${id}/shipping-address`,
  billingAddress: (type = ':type', id = ':id') =>
    `/${type}/${id}/billing-address`,
  addPaymentCard: (type = ':type', id = ':id') =>
    `/${type}/${id}/add-payment-card`,
  informedConsent: (type = ':type', id = ':id') => `/${type}/${id}/informed`,
  hippaConsnet: (type = ':type', id = ':id') => `/${type}/${id}/hippa`,
  addCard: (type = ':type', id = ':id') => `/${type}/${id}/add-card`,
  orderPlaced: (type = ':type') => `/${type}/order-placed`,
  registerTest: (type = ':type') => `/register-test/${type}`,
  getTested: () => '/get-tested',

  invalidQRCode: () => '/invalid-qr-code',

  scanKitIntro: (id = ':id') => `/scan-kit-intro/${id}`,
  scanIntroduction: (id = ':id') => `/scan-qr-introduction/${id}`,
  productAssetDetails: () => '/product-asset-details',
  testResults: () => '/test-history',
  testResultDetails: (resultId = ':resultId') =>
    `/test-result-details/${resultId}`,
  testOrderDetails: (resultId = ':resultId') =>
    `/test-order-details/${resultId}`,
  orderTestPreview: (type = ':type', id = ':id') =>
    `/${type}/${id}/order-preview`,
  shopifyStore: (type = ':type') => `/store/${type}`,
  shopifyProductDetail: (type = ':type', id = ':id') =>
    `/store/${type}?id=${id}`,

  testDetails: () => `/test-details`,
  instructions: () => '/instructions',
  scanKit: () => '/scan-kit',
  uploadProcessing: () => '/upload-processing',
  uploadFailed: () => '/upload-alert',
  uploadSuccessful: () => '/upload-successful',

  shippingOptions: (orderId = ':orderId') => `/shipping-options/${orderId}`,
  confirmAddress: (type = ':type', orderId = ':orderId') =>
    `/${type}/${orderId}/confirm-pickup-address`,
  schedulePickup: (type = ':type', orderId = ':orderId') =>
    `/${type}/${orderId}/schedule-pickup`,
  settingsTab: (tab = ':tab', type = ':type') => `/settings/${tab}/${type}`,
  settingsTabOption: (tab = ':tab', type = ':type', selection = ':selection') =>
    `/settings/${tab}/${type}/${selection}`,
  termsAndConds: () => '/terms-and-conditions',
  termsAndCondsDetails: (termsId = ':termsId') =>
    `/terms-and-conditions/${termsId}`,
  treatmentPlan: (prescriptionId = ':prescriptionId') =>
    `/treatment-plan/${prescriptionId}`,
  waitScreen: () => '/wait-screen',
  confirmation: () => '/confirmation',
  selectTestOrder: (type = ':type') => `/${type}/select-test`,
  consent: () => '/consent',
  childCart: (childId = ':childId') => `/child-cart/${childId}`,
  systemMessages: () => `/system-messages`,
  systemMessageItem: (id = ':id') => `/system-messages/${id}`,
  contactSupport: () => '/contact-support',
  supportMessageSent: () => '/support-message-sent',
  dropOffVerified: (type = ':type', id = ':id') =>
    `/${type}/${id}/drop-off-verified`,

  // todo, add path generator. object entries?
  app: {
    prefix: () => '/app',
    getTested: () => getAppRoute('/get-tested'),
    productAssetDetails: () => getAppRoute('/product-asset-details'),
    instructions: () => getAppRoute('/instructions'),
    outcome: () => getAppRoute('/outcome'),
    testRegistered: () => getAppRoute('/test-registered'),
    dashboard: () => getAppRoute('/dashboard'),
    dependents: () => getAppRoute('/dependents'),
    myPassport: () => getAppRoute('/passport'),
    hsCanceled: () => getAppRoute('/health-service-canceled'),
    telehealth: (type = ':type') =>
      getAppRoute(`/telehealth-consultation/${type}`),
    systemMessages: () => getAppRoute('/system-messages'),
    insurance: (type = ':type', id = ':id') =>
      getAppRoute(`/${type}/${id}/use-insurance`),
    contactSupport: () => getAppRoute('/contact-support'),
    settings: () => getAppRoute('/settings'),
    addCreditCard: () => getAppRoute(`/settings-new/add-card`),
    addDependent: () => getAppRoute(`/settings-new/add-dependent`),
    editDependent: (dependentId = ':dependentId') =>
      getAppRoute(`/settings/dependents/edit/${dependentId}`),
    settingsPaymentMethods: () => getAppRoute('/settings/payment-methods'),
    scanOrderCode: () => getAppRoute('/scan-order-code'),
    scanQrCode: () => getAppRoute('/scan-qr'),
    myProfile: () => getAppRoute('/my-profile'),
    empty: () => '/',
  },
}

const getAppRoute = (route: string) => `/app${route}`
