import { Box } from '@material-ui/core'
import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux'
import WhiteCheckMark from '../../../assets/animations/WhiteCheckMark.json'
import { selectLanguageContent } from '../../../features/translation'
import { lsClient } from '../../../ls-client'
import { Button, Typography } from '../../../ui'
import { Wrapper } from '../../../ui/templates/app-layout'
import { telehealthStyles } from '../components/styles'

export const CompletePage = (props: any) => {
  const { handleNext, matches } = props
  const classes = telehealthStyles()
  const i18n = useSelector(selectLanguageContent)
  lsClient.removeUserKeyLS('memberCancelled')
  return (
    <Wrapper>
      <Box
        className={classes.pageWrapper}
        style={{ display: 'flex', justifyContent: 'center', padding: '75px 0px 175px' }}
      >
        <Box
          className={classes.animationContainer}
          style={{ maxWidth: '205px' }}
        >
          <Lottie animationData={WhiteCheckMark} loop={true} />
        </Box>
        <Box className={classes.textContentContainer}>
          <Typography className={`${classes.title} ${classes.whiteText}`}>
            {i18n.telehealth_consult_complete_title}
          </Typography>
          <Typography className={`${classes.body} ${classes.whiteText}`}>
            {i18n.telehealth_safe_consult_complete_message}
          </Typography>
        </Box>

        <div className={`${classes.buttonWrapper} ${classes.altBG}`}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.actionBtn}
            onClick={handleNext}
          >
            {i18n.telehealth_return_home_title}
          </Button>
        </div>
      </Box>
    </Wrapper>
  )
}
