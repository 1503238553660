import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LocalMallSharp } from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import QRCode from 'react-qr-code'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { VerificationCode } from '../../../../../features/code-verification/code-verification'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../../../../features/cookies/cookie-helper'
import { showNotification } from '../../../../../features/notifications/model'
import { selectLanguageContent } from '../../../../../features/translation'
import {
  forgetMFADevice,
  getMFASetupCode,
  resetError,
  selectToken,
  selectTOTPSetupCode,
  selectUserError,
  selectUserLoading,
  verifyTOTP,
} from '../../../../../features/user/model/userTenantSlice'
import { addSpaceEvery4 } from '../../../../../libs/utils'
import { Button, ContentLayout, Typography } from '../../../../../ui'
import { paths } from '../../../../paths'
import { selectProfileData } from '../../../../profile/model'
import { SecuritySettingsType, SettingsTabType } from '../../../type'

export const SetupTOTP = (props: {
  handleBack?: () => void
  rememberDevice?: () => void
  handleFirstSetup?: () => void
}) => {
  const { handleBack, handleFirstSetup } = props
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(600))
  const loading = useSelector(selectUserLoading)
  const error = useSelector(selectUserError)
  const [cookies, setCookie, removeCookie] = useCookies()
  const appSetupCode = useSelector(selectTOTPSetupCode)
  const [submitReady, setSubmitReady] = useState(false)
  const [mfaCode, setMfaCode] = useState('')
  const profile = useSelector(selectProfileData)
  const [showCopy, setShowCopy] = useState(false)
  const rememberedDevice = userHasDeviceCookie(profile.email)
  const i18n = useSelector(selectLanguageContent)
  const tokens = useSelector(selectToken)
  let timeout: NodeJS.Timeout
  const appScreenCode = matches ? addSpaceEvery4(appSetupCode) : appSetupCode
  const accessToken = localStorage.getItem('access_token')
  const email = profile?.email || localStorage.getItem('email')

  if (!tokens.accessToken && !tokens.idToken && !accessToken)
    history.push(paths.login())

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    dispatch(getMFASetupCode(tokens.accessToken ? tokens : undefined))
  }, [])

  const forgetDevice = () => {
    const callback = () => {
      removeCookie(`${btoa(profile.email)}_safe_device`, { path: '/' })
      history.push(
        paths.settingsTab(SettingsTabType.SECURITY, SecuritySettingsType.MFA)
      )
    }

    const deviceParams = getSavedDeviceValues(profile.email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: profile.email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  const showMessage = () => {
    setShowCopy(true)
    timeout = setTimeout(() => {
      dispatch(setShowCopy(false))
    }, 3000)
  }

  const activateTOTP = () => {
    dispatch(
      verifyTOTP(
        mfaCode,
        email || '',
        rememberedDevice ? forgetDevice : undefined,
        handleFirstSetup ? handleFirstSetup : undefined,
        handleFirstSetup ? tokens : undefined,
      )
    )
  }

  const handleCopy = (code: string) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        showMessage()
      })
      .catch((err) => {
        dispatch(showNotification(err.message, 'error'))
      })
  }

  const qrEncoded =
    'otpauth://totp/' +
    email +
    '?secret=' +
    appSetupCode +
    '&issuer=HealthCheck'

  return (
    <Box className={classes.pageWrapper}>
      <ContentLayout className={classes.form}>
        {handleBack && (
          <div onClick={handleBack}>
            <ArrowBackIcon color="primary" fontSize="large" />
          </div>
        )}
        <Typography className={classes.title} variant="h2" align="center">
          {i18n.authenticator_setup_header}
        </Typography>
        <div>
          <Typography className={classes.description} align="center">
            {i18n.authenticator_setup_description}
          </Typography>
        </div>
        <div className={classes.instructionWrapper}>
          <Typography
            className={classes.instruction}
            variant="body1"
            align="center"
          >
            {i18n.authenticator_setup_scan_qr}
          </Typography>
          <div className={classes.qrWrapper}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '120px', width: '100%' }}
              value={qrEncoded}
              viewBox={`0 0 256 256`}
            />
          </div>
          <Typography
            className={classes.instruction}
            variant="body1"
            align="center"
            style={{ maxWidth: 440 }}
          >
            {i18n.authenticator_setup_copy_instruction}
          </Typography>
          <div
            className={classes.setupKeyWrapper}
            onClick={() => handleCopy(appSetupCode)}
          >
            <Typography className={classes.setupKey} align="center">
              {appScreenCode}
            </Typography>
            {showCopy && (
              <div className={classes.copiedWrapper}>
                <Typography className={classes.copiedText} align="center">
                  {i18n.copied_snackbar}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={classes.codeWrapper}>
          <Typography
            className={classes.instruction}
            variant="body1"
            align="center"
          >
            {i18n.authenticator_setup_pairing_instruction}
          </Typography>
          <VerificationCode
            setVerificationCode={setMfaCode}
            setSubmitReady={setSubmitReady}
            verifyError={error}
            clearError={() => dispatch(resetError())}
            submitting={false}
            type="Integer"
            simpleErrorView={true}
          />
        </div>
      </ContentLayout>
      <div className={classes.buttonWrapper}>
        <Button
          color="primary"
          className={classes.actionBtn}
          disabled={!submitReady || loading}
          onClick={activateTOTP}
        >
          {i18n.continue_button}
        </Button>
      </div>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFilledInput-root': {
      background: '#FFF',
    },
    '& .MuiFilledInput-underline:before': { borderBottom: 'none' },
    '& .MuiFilledInput-underline:after': { borderBottom: 'none' },
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: '#505358',
    fontSize: '64px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  pageWrapper: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
  },
  processText: {
    fontSize: 26,
    color: '#FFF',
    marginTop: 10,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  header: {
    padding: 0,
    borderBottom: '1px solid #D7D7D7',
  },
  title: {
    fontWeight: 500,
    marginBottom: '24px',
  },
  description: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '24px',
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
    },
  },
  instructionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px auto',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    padding: 32,
    marginBottom: '16px',
    borderRadius: '8px',
    maxWidth: '590px',
    backgroundColor: '#E7E7E7',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
  instruction: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
    },
  },
  setupKeyWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    borderRadius: '8px',
    backgroundColor: '#FFF',
    position: 'relative',
    cursor: 'pointer',
  },
  setupKey: {
    fontSize: 14,
    fontWeight: 500,
  },
  copiedWrapper: {
    backgroundColor: theme.palette.success.main,
    borderRadius: 8,
    padding: '4px 16px',
    position: 'absolute',
    top: '26px',
  },
  copiedText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#FFF',
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
    paddingBottom: '32px',
    display: 'flex',
    justifyContent: 'center',
  },
  qrWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    borderRadius: '4px',
    backgroundColor: '#FFF',
    marginBottom: '16px',
  },
  codeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '700px',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
  actionBtn: {
    '&:hover': {
      borderColor: '#003C72',
      color: '#003C72',
    },
    'fontSize': '16px',
    'textTransform': 'none',
    'letterSpacing': '2.25px',
    'maxWidth': '550px',
    'width': '100%',
    'justifySelf': 'center',
    'maxHeight': 48,
    'paddingTop': 10,
    'paddingBottom': 10,
    'marginTop': 10,
    'marginBottom': 0,
    '@media (max-width: 300px)': {
      minWidth: 'unset',
    },
  },
}))
