import { Box, Link as MUILink, Tooltip } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import App_Store from '../../../../assets/img/app-store.png'
import Goggle_Play from '../../../../assets/img/goggle-play.png'
import { selectLanguageContent } from '../../../../features/translation'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { NetworkSettings } from '../../../network/type'

export const StoreLinks = (props: {
  currentNetwork: NetworkSettings | null
}) => {
  const classes = onboardingStyles()
  const { currentNetwork } = props
  const appStoreLink = currentNetwork?.appleAppDownloadUrl
  const playStoreLink = currentNetwork?.androidAppDownloadUrl
  const i18n = useSelector(selectLanguageContent)

  return (
    <Box className={classes.linkContainer}>
      {appStoreLink && (
        <Tooltip title={i18n.tooltip_apple_store} placement="top-end" arrow>
          <MUILink
            role="button"
            onClick={() => (window.location.href = appStoreLink)}
          >
            <img
              src={App_Store}
              alt={i18n.accesibility_apple_store}
              style={{ height: '45px' }}
            />
          </MUILink>
        </Tooltip>
      )}
      {playStoreLink && (
        <Tooltip title={i18n.tooltip_play_store} placement="top-start" arrow>
          <MUILink
            role="button"
            onClick={() => (window.location.href = playStoreLink)}
          >
            <img
              src={Goggle_Play}
              alt={i18n.accesibility_play_store}
              style={{ height: '45px' }}
            />
          </MUILink>
        </Tooltip>
      )}
    </Box>
  )
}
