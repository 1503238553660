import { Button, styled } from '@material-ui/core'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import { lsClient } from '../../ls-client'
import { paths } from '../../pages/paths'
import { Loading } from '../../ui'
import { FEATURES } from './feature-map'
import { FlowApi } from './flow-api'
import {
  FlowActions,
  flowActions,
  flowInitialState,
  flowReducer,
  FlowState,
} from './flow-reducer'

export interface FlowContextActions {
  next: (params: { out: string }) => void
  startFlow: (id: string) => void
  endFLow: () => void
  setOutputs: (outputs: { [key: string]: string }) => void
  completeModule: () => void
  setLoading: (loading: boolean) => void
  resumeFlow: () => void
}
export interface FlowInterface {
  state: FlowState
  actions: FlowContextActions
}

const FlowContext = createContext({})

export interface FlowProviderProps {
  apiUrl: string
  apiUrlBuilder: string
  children: ReactNode
}

export const FlowProvider = (props: FlowProviderProps) => {
  const history = useHistory()

  const flowApi = useRef<FlowApi>()

  const [flowState, flowDispatch] = useReducer(flowReducer, flowInitialState)

  const startFlow = async (id: string) => {
    setLoading(true)
    flowDispatch(flowActions[FlowActions.START_FLOW]())

    if (flowApi.current) {
      const { start_module, flowId, flowExecutionId } =
        await flowApi.current?.startFlow(id)
      setFlowStorage({ flowId, flowExecutionId, module: start_module })
      flowDispatch(
        flowActions[FlowActions.SET_FLOW_IDS]({ flowId, flowExecutionId })
      )
      flowDispatch(flowActions[FlowActions.SET_MODULE](start_module))
      history.push(FEATURES[start_module.module_type].path(start_module.config))
      setLoading(false)
    }
  }

  const resumeFlow = async () => {
    setLoading(true)
    flowDispatch(flowActions[FlowActions.START_FLOW]())

    if (flowApi.current) {
      const { flowId, flowExecutionId, module } = getFlowStorage()
      flowDispatch(
        flowActions[FlowActions.SET_FLOW_IDS]({ flowId, flowExecutionId })
      )
      flowDispatch(flowActions[FlowActions.SET_MODULE](module))
      history.push(FEATURES[module.module_type].path(module.config))
      setLoading(false)
    }
  }

  const endFlow = () => {
    flowDispatch(flowActions[FlowActions.END_FLOW]())
  }

  const getFlowStorage = () => {
    let flowStorage = lsClient.getUserLSByKey('flowExecution')
    try {
      flowStorage = JSON.parse(flowStorage)
    } catch (e) {
      console.log('error loading previously running flow')
    }
    return {
      flowId: flowStorage?.flowId,
      flowExecutionId: flowStorage?.flowExecutionId,
      module: flowStorage?.module,
    }
  }
  const setFlowStorage = ({
    flowId,
    flowExecutionId,
    module,
  }: { flowId?: string; flowExecutionId?: string; module?: any } = {}) => {
    const flowStorage = {
      flowId: flowId || flowState.flowId,
      flowExecutionId: flowExecutionId || flowState.flowExecutionId,
      module: module || flowState.currentModule,
    }
    lsClient.setUserLS('flowExecution', JSON.stringify(flowStorage))
  }

  const next = async (params?: { out: string }) => {
    setLoading(true)
    const response: any = await flowApi.current?.next(params?.out)
    if (response.module_type) {
      setFlowStorage({ module: response })
      history.push(FEATURES[response.module_type].path(response.config))
      setLoading(false)
    }
  }

  const setOutputs = (outputs?: { out: string; [key: string]: string }) => {
    flowDispatch(flowActions[FlowActions.SET_OUTPUT](outputs))
  }

  const completeModule = () => {
    next(flowState.currentModule.outputs)
  }

  const setLoading = (loading: boolean) => {
    flowDispatch(flowActions[FlowActions.SET_LOADING](loading))
  }

  useEffect(() => {
    flowApi.current = new FlowApi(props.apiUrl, props.apiUrlBuilder)
    flowApi.current.getFlows().then((data)=>{
      flowDispatch(flowActions[FlowActions.SET_FLOWS_LIST](data))
    })
  }, [])

  const value = useMemo(
    () => ({
      state: flowState,
      actions: {
        startFlow,
        next,
        endFlow,
        setOutputs,
        completeModule,
        setLoading,
        resumeFlow,
      },
    }),
    [flowState]
  )

  return (
    <FlowContext.Provider value={value}>
      {props.children}
      {flowState.isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : null}
      {/* <DevControls>
        <Button onClick={() => startFlow('646bdb2e3233517f7fabda06')}>
          Start Flow
        </Button>
        <Button onClick={() => resumeFlow()}>
          Resume Flow
        </Button>
        <Button onClick={() => next()}>Next</Button>
        <Button onClick={() => setLoading(!flowState.isLoading)}>loading</Button>
      </DevControls> */}
    </FlowContext.Provider>
  )
}

export const useFlowControl = (): FlowInterface => {
  return useContext(FlowContext) as FlowInterface
}

const LoadingContainer = styled('div')({
  position: 'fixed',
  height: '100%',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 9999,
  paddingTop: '200px',
})

const DevControls = styled('div')({
  position: 'fixed',
  bottom: '5px',
  right: '5px',
  backgroundColor: 'white',
  zIndex: 99999,
})
