import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../../network/model'
import { DashboardCard } from '../../../network/type'

export const BackgroundImageCard = (props: {
  activeHealthService: boolean
  button: DashboardCard
  linkPath: string
  xl?: boolean
}) => {
  const { button, xl = false, activeHealthService, linkPath } = props
  const history = useHistory()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = dashboardCardStyles({ radius })

  return (
    <>
      <Box
        key={button.cardTitle}
        className={
          xl
            ? `${classes.bgImageButtonContainer} ${classes.xlCard}`
            : classes.bgImageButtonContainer
        }
        style={{
          backgroundImage: `url(${button.cardImageUrl})`,
          borderRadius: radius,
        }}
        onClick={() => linkPath && history.push(linkPath)}
      >
        <Box className={`${classes.bgImageCard} ${classes.contrastImage}`}>
          <Typography className={classes.dashboardButtonTitle}>
            <HTMLTextComponent HTMLString={button.cardTitle} />
          </Typography>
          {button.cardBody && (
            <Typography className={classes.dashboardButtonBody}>
              <HTMLTextComponent HTMLString={button.cardBody} />
            </Typography>
          )}
        </Box>
      </Box>
    </>
  )
}
