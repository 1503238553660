import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { showNotification } from '../../features/notifications/model'
import { handleError } from '../../libs/helpers'
import { history } from '../../libs/history'
import { paths } from '../paths'

import { NOTIFICATION_TYPES } from '../../constants'

interface guardianSlice {
  data: {
    relatedPersonFirstName: string
    relatedPersonLastName: string
    relationship: string
    relatedPersonEmail: string
    userId: string
  },
  submitting: boolean
  guardianIsUpdate: boolean
}
const initialStore: guardianSlice = {
  data: {
    relatedPersonFirstName: '',
    relatedPersonLastName: '',
    relationship: '',
    relatedPersonEmail: '',
    userId: '',
  },
  submitting: false,
  guardianIsUpdate: false
}

export const guardianSlice = createSlice({
  name: 'guardian',
  initialState: initialStore,
  reducers: {
    setSubmitting(store, { payload }: { payload: boolean }) {
      store.submitting = payload
    },
    setGuardianInfo(store, { payload }: { payload: any }) {
        store.data = payload
        store.guardianIsUpdate = true
      },

  },
})

const { setSubmitting, setGuardianInfo } = guardianSlice.actions

export const getGuardianInfo = (userId: any) => async (
    dispatch: any,
  ) => {
      dispatch(setSubmitting(true))
    const res = await api.getGuardianInfo(userId)

    if (res.data && res.isOK) {
      dispatch(setSubmitting(false))
      dispatch(setGuardianInfo(res.data))
    }
  }

  export const updateGuardianInfo = (data: any) => async (
    dispatch: any,
  ) => {
      dispatch(setSubmitting(true))
    const res = await api.updateGuardianInfo(data)
  
    if(res?.error){
      dispatch(handleError(res.error))
      dispatch(setSubmitting(false))
    }
  
    if (res.data && res.isOK) {
      dispatch(setSubmitting(false))
      history.push(paths.guardianComplete())
    }
  }

export const submitGuardianInfo = (data: any) => async (
  dispatch: any,
) => {
    dispatch(setSubmitting(true))
  const res = await api.addGuardianInfo(data)

  if(res?.error){
    dispatch(handleError(res.error))
    dispatch(setSubmitting(false))
  }

  if (res.data && res.isOK) {
    dispatch(setSubmitting(false))
    history.push(paths.guardianComplete())
  }
}

export const guardianReducer = guardianSlice.reducer
export const guardianReducerName = guardianSlice.name

interface RootStore {
  [guardianReducerName]: typeof initialStore
}

export const selectSubmitting = ({ guardian }: RootStore) => guardian.submitting

export const selectGuardianInfo = ({ guardian }: RootStore) => guardian.data

export const selectGuardianIsUpdate = ({ guardian }: RootStore) => guardian.guardianIsUpdate

