import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { FormikProps } from 'formik'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { selectLanguageContent } from '../../../../features/translation'
import { FormField } from '../form-field'
import { CustomSelect } from '../../../../ui/atoms/select'
import { translateOptions } from '../../../../libs/helpers'
import { allowedGenders, allowedRelationships } from '../../../../constants'
import { DependentFormValues } from '../../type'

export const DependentForm = (props: {
  formik: FormikProps<DependentFormValues>
}) => {
  const classes = useStyles()
  const { formik } = props
  const i18n = useSelector(selectLanguageContent)

  return (
    <Box className={classes.formContainer}>
      <FormField
        label={i18n.first_name}
        formik={formik}
        name="firstName"
        required
      />
      <FormField
        label={i18n.last_name}
        formik={formik}
        name="lastName"
        required
      />
      <KeyboardDatePicker
        label={i18n.date_of_birth}
        value={formik.values.birthDate}
        format="MM-dd-yyyy"
        onBlur={formik.handleBlur}
        fullWidth
        error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
        helperText={formik.touched.birthDate && formik.errors.birthDate}
        name="birthDate"
        onChange={(e) => formik.setFieldValue('birthDate', e)}
        maxDate={moment().subtract(18, 'years').subtract(1, 'days').toDate()}
        required
      />
      <CustomSelect
        label={i18n.sex}
        options={translateOptions(allowedGenders, i18n)}
        value={formik.values.sexAtBirth}
        fullWidth
        name="sexAtBirth"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.sexAtBirth && Boolean(formik.errors.sexAtBirth)}
        helperText={formik.touched.sexAtBirth ? formik.errors.sexAtBirth : ''}
        required
      />
      <CustomSelect
        label={i18n.policy_holder_relationship}
        options={translateOptions(allowedRelationships, i18n)}
        value={formik.values.relationship}
        fullWidth
        name="relationship"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.relationship && Boolean(formik.errors.relationship)
        }
        helperText={
          formik.touched.relationship ? formik.errors.relationship : ''
        }
        required
      />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'grid',
    gridColumnGap: 36,
    justifyContent: 'space-between',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridRowGap: 24,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}))
