import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TryAgain from '../../../assets/img/dialogs/invalid-code.png'
import { paths } from '../../../pages/paths'
import { resetRegisterForm } from '../../../pages/register/model/registerSlice'
import { AppDialogWrapper } from '../dialog-wrapper'
import { SimpleDialogContent } from '../helpers/simple-dialog-content'
import { resetDialogStore } from '../model'
import { DialogProps } from '../types'

export const InvalidCodeDialog = (props: DialogProps) => {
  const { i18n } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const cancelEligibility = () => {
    dispatch(resetRegisterForm())
    localStorage.removeItem('tenantId')
    history.push(paths.login())
  }

  const tryAgain = () => {
    dispatch(resetDialogStore())
  }

  return (
    <AppDialogWrapper disableClickAway>
      <SimpleDialogContent
        i18n={i18n}
        title={i18n.invalid_access_code}
        body={i18n.invalid_access_code_instruction}
        confirmationLabel={i18n.try_again}
        onConfirm={tryAgain}
        onCancel={cancelEligibility}
        image={TryAgain}
      />
    </AppDialogWrapper>
  )
}
