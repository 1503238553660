import { RouteConfig } from 'react-router-config'
import { ApplicationLayout } from '../features/application'
import { SignUpLayout } from '../features/application/templates/signup/signup-application-layout'
import { FlowsList } from '../features/flowcontrol/flow-list'
import { HSBeInTouch } from '../features/health-service-lite/pages/be-in-touch'
import { HSCanceled } from '../features/health-service-lite/pages/canceled'
import { AddCardPage } from './add-card/page'
import { AddInsuranceInfoPage } from './add-insurance-info/page'
import { AddressInfoPage } from './address-info/page'
import { CheckoutPage } from './checkout/page'
import { CheckoutConsent } from './checkout-consent/page'
import { ConfirmEmailPage } from './confirm-email/page'
import { InsuranceProblemView } from './confirm-insurance/insuranceProblem'
import { InsuranceProviderAdded } from './confirm-insurance/insuranceProviderAdded'
import { InsuranceVerifyingProcess } from './confirm-insurance/InsuranceVerifyingProcess'
import { ConfirmInsurancePage } from './confirm-insurance/page'
import { ConfirmationPage } from './confirmation/page'
import { ContactSupportPage } from './contact-support/page'
import { DashboardPage } from './dashboard/page'
import { DependentListPage } from './dependents/dependents-list'
import { Error404Page } from './error404/page'
import { FacilityPage } from './facility/page'
import { FaqPage } from './faq/page'
import { GetTestedPage } from './get-tested/page'
import { GuardianAgreementPage } from './guardian/guardian-agreementPage'
import { GuardianCompletePage } from './guardian/guardian-completePage'
import { GuardianNotificationPage } from './guardian/guardian-notificationPage'
import { GuardianInfoPage } from './guardian/guardianInfoPage'
import { UploadFailedPage } from './instructions/instructionComponents/uploadFailedPage'
import { UploadProcessingPage } from './instructions/instructionComponents/uploadProcessingPage'
import { UploadSuccessfulPage } from './instructions/instructionComponents/uploadSuccessfulPage'
import { InstructionsPage } from './instructions/page'
import { AddInsuranceProvider } from './insurance/addInsuranceProvider'
import { InsurancePage } from './insurance/page'
import { InviteVerifyPage } from './invite-web-registration/notification-views/inviteVerifyPage'
import { InviteMessagingPage } from './invite-web-registration/notification-views/messagesWithAppBanner'
import { MFASetup } from './login/mfa/chooseMFA'
import { MFAChallengePage } from './login/mfa/mfa'
import { SetupMFAOptionPage } from './login/mfa/setupMFAOption'
import { LoginPage } from './login/page'
import { MemberAccountPage } from './member-account/page'
import { MemberAccountEditProfilePage } from './member-account-edit-profile/page'
import { MyPassportPage } from './my-passport/myPassportPage'
import { MyProfilePage } from './my-profile'
import { OrderDetailPage } from './order-test/orderDetailPage'
import { OrderPlacedPage } from './order-test/orderPlacedPage'
import { OutcomesPage } from './outcomes/outcomesPage'
import { ForgotPasswordPage } from './password-reset/forgotPasswordPage'
import { ResetConfirmationPage } from './password-reset/resetConfirmationPage'
import { ResetPasswordSuccessfulPage } from './password-reset/resetSuccessful'
import { paths } from './paths'
import { AddPaymentCardScreen } from './payment-methods/addPaymentCardScreen'
import { PaymentMethodsPage } from './payment-methods/page'
import { PolicyHolderInfoPage } from './policy-holder-info/page'
import { ProductAssetDetailPage } from './product-asset-details/page'
import { QuestionnaireV2Page } from './questionnaireV2/questionnaireV2Page'
import { AccessCode } from './register/components/accessCode'
import { RegisterStepForm } from './register/page'
import { RegisterTest } from './register-a-test-module/register-test'
import { ResourcesPage } from './resources/page'
import { ResourceDetailsPage } from './resources/resourceDetailsPage'
import { ResourceLinkedPage } from './resources/resourceDirectLink'
import { InvalidQRPage } from './scan-qr/invalidQRPage'
import { ScanQRPage } from './scan-qr/page'
import { ScanIntroductionPage } from './scan-qr/scanIntroductionPage'
import { SchedulePage } from './schedule/pageLegacy'
import { ScheduleAppointmentTimePage } from './schedule-appointment/page'
import { SchedulePickupPage } from './schedule-pickup/page'
import { SettingsPage } from './settings'
import { AddDependentPage } from './settings/tabs/dependents/add-dependent'
import { EditDependentPage } from './settings/tabs/dependents/edit-dependent'
import { DropOffVerifiedView } from './shipping/DropOffVerifiedView'
import { ShippingOptionsPage } from './shipping-options/page'
import { OrderProcessingPage } from './shopify-store/order-processing'
import { ShopifyModule } from './shopify-store/shopify-wrapper'
import { SupportMessageSentPage } from './support-message-sent/page'
import { SymptomCheckerIntroductionPage } from './symptom-checker-introduction/symptomCheckerIntroPage'
import { SymptomCheckerMoreInfoPage } from './symptom-checker-introduction/symptomCheckerMoreInfoPage'
import { SystemMessagesItem } from './system-messages/item'
import { SystemMessagesPage } from './system-messages/page'
import { TelehealthIntakePage } from './telehealth/telehealthIntakePage'
import { TelehealthIntroductionPage } from './telehealth/telehealthIntroPage'
import { TelehealthPage } from './telehealth/telehealthPage'
import { TermsAndCondsPageWapper } from './terms-and-conds/termsWithHeaderBarWrapper'
import { TestDetailsPage } from './test-details/page'
import { TestResultsPage } from './test-results/page'
import { TreatmentPlanPage } from './treatment-plan/page'
import { WelcomePage } from './welcome-page/welcomePage'

export const ROUTES: RouteConfig[] = [
  {
    //Hide all common routes for Email invite signup with 404 page
    // component: ApplicationLayout,
    component: Error404Page,
    path: paths.app.prefix(),
    routes: [
      { exact: true, path: paths.app.dashboard(), component: DashboardPage },
      {
        exact: true,
        path: paths.app.dependents(),
        component: DependentListPage,
      },
      { exact: true, path: paths.app.myPassport(), component: MyPassportPage },
      {
        exact: true,
        path: paths.app.contactSupport(),
        component: ContactSupportPage,
      },
      {
        exact: true,
        path: paths.app.hsCanceled(),
        component: HSCanceled,
      },
      { exact: true, path: paths.app.settings(), component: SettingsPage },
      { exact: true, path: paths.app.addCreditCard(), component: AddCardPage },
      {
        exact: true,
        path: paths.app.addDependent(),
        component: AddDependentPage,
      },
      {
        exact: true,
        path: paths.app.editDependent(),
        component: EditDependentPage,
      },

      {
        exact: true,
        path: paths.app.settingsPaymentMethods(),
        component: PaymentMethodsPage,
      },
      { exact: true, path: paths.app.getTested(), component: GetTestedPage },
      {
        exact: true,
        path: paths.app.productAssetDetails(),
        component: ProductAssetDetailPage,
      },
      {
        exact: true,
        path: paths.app.instructions(),
        component: InstructionsPage,
      },

      { exact: true, path: paths.app.telehealth(), component: TelehealthPage },

      {
        exact: true,
        path: paths.treatmentPlan(),
        component: TreatmentPlanPage,
      },
      { exact: true, path: paths.app.outcome(), component: OutcomesPage },
      { exact: true, path: paths.app.insurance(), component: InsurancePage },
      {
        exact: true,
        path: paths.app.systemMessages(),
        component: SystemMessagesPage,
      },

      {
        exact: true,
        path: paths.app.myProfile(),
        component: MyProfilePage,
      },
      { exact: true, path: paths.app.scanQrCode(), component: ScanQRPage },
    ],
  },
  // {
  //   exact: true,
  //   path: paths.beInTouch(),
  //   component: HSBeInTouch,
  // },
  // {
  //   exact: true,
  //   path: paths.termsAndConds(),
  //   component: TermsAndCondsPageWapper,
  // },
  // {
  //   exact: true,
  //   path: paths.termsAndCondsDetails(),
  //   component: TermsAndCondsPageWapper,
  // },
  // { exact: true, path: paths.faqs(), component: FaqPage },
  // { exact: true, path: paths.faqsDetails(), component: FaqPage },

  // {
  //   exact: true,
  //   path: paths.symptomCheckerIntro(),
  //   component: SymptomCheckerIntroductionPage,
  // },
  // {
  //   exact: true,
  //   path: paths.symptomCheckerIntroById(),
  //   component: SymptomCheckerIntroductionPage,
  // },
  // {
  //   exact: true,
  //   path: paths.telehealthIntro(),
  //   component: TelehealthIntroductionPage,
  // },
  // {
  //   exact: true,
  //   path: paths.telehealthIntake(),
  //   component: TelehealthIntakePage,
  // },
  // { exact: true, path: paths.registerTest(), component: RegisterTest },
  // {
  //   exact: true,
  //   path: paths.orderTestPreview(),
  //   component: OrderDetailPage,
  // },
  // {
  //   exact: true,
  //   path: paths.shopifyStore(),
  //   component: ShopifyModule,
  // },
  // {
  //   exact: true,
  //   path: paths.shopifyProductDetail(),
  //   component: ShopifyModule,
  // },
  // {
  //   exact: true,
  //   path: paths.flowList(),
  //   component: FlowsList,
  // },
  // {
  //   exact: true,
  //   path: paths.systemMessageItem(),
  //   component: SystemMessagesItem,
  // },
  // { exact: true, path: paths.facility(), component: FacilityPage },
  // { exact: true, path: paths.dropOff(), component: FacilityPage },

  // {
  //   exact: true,
  //   path: paths.uploadSuccessful(),
  //   component: UploadSuccessfulPage,
  // },
  // {
  //   exact: true,
  //   path: paths.scanIntroduction(),
  //   component: ScanIntroductionPage,
  // },
  // { exact: true, path: paths.settingsTab(), component: SettingsPage },
  // {
  //   exact: true,
  //   path: paths.settingsTabOption(),
  //   component: SettingsPage,
  // },
  // {
  //   exact: true,
  //   path: paths.uploadProcessing(),
  //   component: UploadProcessingPage,
  // },
  // { exact: true, path: paths.uploadFailed(), component: UploadFailedPage },
  // {
  //   exact: true,
  //   path: paths.shippingOptions(),
  //   component: ShippingOptionsPage,
  // },
  // {
  //   exact: true,
  //   path: paths.confirmAddress(),
  //   component: AddressInfoPage,
  // },
  // { exact: true, path: paths.schedulePickup(), component: SchedulePickupPage },

  // { exact: true, path: paths.schedule(), component: SchedulePage },
  // {
  //   exact: true,
  //   path: paths.scheduleAppointment(),
  //   component: ScheduleAppointmentTimePage,
  // },
  // {
  //   exact: true,
  //   path: paths.symptomCheckerMoreInfo(),
  //   component: SymptomCheckerMoreInfoPage,
  // },
  // {
  //   exact: true,
  //   path: paths.symptomCheckerV2(),
  //   component: QuestionnaireV2Page,
  // },
  // {
  //   exact: true,
  //   path: paths.symptomCheckerById(),
  //   component: QuestionnaireV2Page,
  // },
  // {
  //   exact: true,
  //   path: paths.testResults(),
  //   component: TestResultsPage,
  // },
  // {
  //   exact: true,
  //   path: paths.testResultDetails(),
  //   component: TestDetailsPage,
  // },
  // {
  //   exact: true,
  //   path: paths.testOrderDetails(),
  //   component: TestDetailsPage,
  // },
  // {
  //   exact: true,
  //   path: paths.guardianNotification(),
  //   component: GuardianNotificationPage,
  // },
  // { exact: true, path: paths.guardianInfo(), component: GuardianInfoPage },
  // {
  //   exact: true,
  //   path: paths.guardianAgreement(),
  //   component: GuardianAgreementPage,
  // },
  // {
  //   exact: true,
  //   path: paths.guardianComplete(),
  //   component: GuardianCompletePage,
  // },
  // { exact: true, path: paths.welcome(), component: WelcomePage },

  // { exact: true, path: paths.orderPlaced(), component: OrderPlacedPage },
  // {
  //   exact: true,
  //   path: paths.treatmentPlan(),
  //   component: TreatmentPlanPage,
  // },
  // {
  //   exact: true,
  //   path: paths.policyHolderInfo(),
  //   component: PolicyHolderInfoPage,
  // },
  // {
  //   exact: true,
  //   path: paths.confirmInsurance(),
  //   component: ConfirmInsurancePage,
  // },
  // { exact: true, path: paths.addInsurance(), component: AddInsuranceInfoPage },
  // {
  //   exact: true,
  //   path: paths.insuranceVerifyingProcess(),
  //   component: InsuranceVerifyingProcess,
  // },
  // {
  //   exact: true,
  //   path: paths.dropOffVerified(),
  //   component: DropOffVerifiedView,
  // },
  // {
  //   exact: true,
  //   path: paths.insuranceProblem(),
  //   component: InsuranceProblemView,
  // },
  // {
  //   exact: true,
  //   path: paths.addInsuranceProvider(),
  //   component: AddInsuranceProvider,
  // },
  // {
  //   exact: true,
  //   path: paths.insuranceProviderAdded(),
  //   component: InsuranceProviderAdded,
  // },
  // { exact: true, path: paths.checkout(), component: CheckoutPage },
  // { exact: true, path: paths.informedConsent(), component: CheckoutConsent },
  // { exact: true, path: paths.hippaConsnet(), component: CheckoutConsent },
  // { exact: true, path: paths.memberAccount(), component: MemberAccountPage },
  // {
  //   exact: true,
  //   path: paths.editAccount(),
  //   component: MemberAccountEditProfilePage,
  // },
  // { exact: true, path: paths.paymentMethods(), component: PaymentMethodsPage },
  // {
  //   exact: true,
  //   path: paths.shippingAddress(),
  //   component: AddressInfoPage,
  // },
  // {
  //   exact: true,
  //   path: paths.billingAddress(),
  //   component: AddressInfoPage,
  // },
  // {
  //   exact: true,
  //   path: paths.addPaymentCard(),
  //   component: AddPaymentCardScreen,
  // },
  // { exact: true, path: paths.addCard(), component: AddCardPage },

  // { exact: true, path: paths.invalidQRCode(), component: InvalidQRPage },

  // {
  //   exact: true,
  //   path: paths.productAssetDetails(),
  //   component: ProductAssetDetailPage,
  // },
  // { exact: true, path: paths.testDetails(), component: TestDetailsPage },
  // { exact: true, path: paths.instructions(), component: InstructionsPage },
  // { exact: true, path: paths.confirmation(), component: ConfirmationPage },
  // { exact: true, path: paths.resources(), component: ResourcesPage },
  // {
  //   exact: true,
  //   path: paths.resourceDetails(),
  //   component: ResourceDetailsPage,
  // },
  // {
  //   exact: true,
  //   path: paths.resourcesById(),
  //   component: ResourceLinkedPage,
  // },
  // { exact: true, path: paths.contactSupport(), component: ContactSupportPage },
  // {
  //   exact: true,
  //   path: paths.supportMessageSent(),
  //   component: SupportMessageSentPage,
  // },

  {
    exact: true,
    path: paths.inviteEmailVerification(),
    component: InviteVerifyPage,
  },
  {
    exact: true,
    path: paths.inviteMessaging(),
    component: InviteMessagingPage,
  },
  {
    //Hide all common routes for Email invite signup with 404 page
    component: SignUpLayout,
    // component: Error404Page,
    path: paths.app.empty(),
    routes: [
      { exact: true, path: paths.registerTab(), component: RegisterStepForm },
      { path: '*', component: Error404Page },
      // { exact: true, path: paths.login(), component: LoginPage },
      // { exact: true, path: paths.accessCode(), component: AccessCode },
      // { exact: true, path: paths.confirmEmail(), component: ConfirmEmailPage },

      // { exact: true, path: paths.mfAuth(), component: MFAChallengePage },
      // { exact: true, path: paths.setupMFA(), component: MFASetup },
      // {
      //   exact: true,
      //   path: paths.setupMFAType(),
      //   component: SetupMFAOptionPage,
      // },

      // {
      //   exact: true,
      //   path: paths.forgotPassword(),
      //   component: ForgotPasswordPage,
      // },
      // {
      //   exact: true,
      //   path: paths.resetConfirmation(),
      //   component: ResetConfirmationPage,
      // },
      // {
      //   exact: true,
      //   path: paths.resetSuccessful(),
      //   component: ResetPasswordSuccessfulPage,
      // },
    ],
  },
  { path: '*', component: Error404Page },
]
