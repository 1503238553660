/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  ConsultData,
  ConsultPrescription,
} from '../../features/health-service-lite/types'

const getUserFullName = (user: any) => {
  if (!user) return ''
  return ''.concat(
    user.firstName || '',
    ' ',
    user.middleName || '',
    ' ',
    user.lastName || '',
    ' ',
    user.title || ''
  )
}

enum SoapNotesType {
  'subjective' = 0,
  'objective' = 1,
  'assessment' = 2,
  'plan' = 3,
}
interface SoapNote {
  created: string
  soapType: SoapNotesType
  text: string
  diagnosis: Diagnosis[] | null
}

interface Diagnosis {
  code: string
  name: string
}

interface PharmacyContact {
  MobilePhoneNumber: string | null
  HomePhoneNumber: string | null
  EmailAddress: string | null
}

interface PharmacyAddress {
  LineAddress1: string | null
  LineAddress2: string | null
  City: string | null
  State: string | null
  Zip: string | null
  Country: string | null
}

export interface EncounterPharmacy {
  RcopiaId: string | null
  RcopiaMasterId: string | null
  NCPDPID: string | null
  Name: string | null
  Address: PharmacyAddress | null
  Type: string | null
  ContactDetails: PharmacyContact
  prescriptions: ConsultPrescription[]
}

const formatNotes = (
  soapNotes: SoapNote[],
  type: SoapNotesType,
  forDiagnosis = false
) => {
  if (!soapNotes || typeof type !== 'number') return null

  const note = soapNotes.find((sn) => sn.soapType === type)
  if (!note) return null
  if (forDiagnosis) return note.diagnosis?.map((d) => d.name)
  return note.text
    .replace('Plan:', '')
    .replace('Assessment:', '')
    .replace('Subjective:', '')
    .split('\n-')
}

export const transformFVFTreatment = (data: ConsultData | null) => {
  const providerResponses = {} as any
  if (!data) return
  data.consultResponse?.consult?.provider_answers?.forEach((a) => {
    providerResponses[a.key] = a.value
  })
  return {
    ...data,
    ...providerResponses,
    providerName: data.consultResponse?.details?.provider,
    completed_at: data.consultResponse?.consult?.completed_at,
    prescriptions: data.consultResponse?.consult?.prescriptions,
    shipping_address: data.consultRequest?.data?.shipping_address,
    pharmacy_address: {
      ...data.consultRequest?.data?.shipping_address,
      line1: data.consultRequest?.data?.shipping_address.street_1,
      line2: data.consultRequest?.data?.shipping_address.street_2,
    },
    pharmacy_phone: '6154760115',
    notes_to_patient: data.consultResponse?.consult?.notes_to_patient,
  }
}

export const transformSAFETreatment = (data: any) => {
  if (!data) return
  return {
    ...data,
    // notes: data.notes,
    notes: data.treatmentPlan?.soapNotes
      ? formatNotes(data.treatmentPlan?.soapNotes, SoapNotesType.assessment)
      : '',
    diagnosis: data.treatmentPlan?.soapNotes
      ? formatNotes(
          data.treatmentPlan?.soapNotes,
          SoapNotesType.assessment,
          true
        )
      : '',
    providerName: getUserFullName(data?.provider),
    memberName: getUserFullName(data?.member),
    memberDOB: data?.member.birthDate,
    provider: data?.provider,
    completed_at: data.modified,
    prescriptions: data.treatmentPlan?.prescriptions,
    // prescriptions: [
    //   {
    //     name: 'Paxlovid 150 mg-100 mg (300 mg-100 mg Dose) tablet',
    //     ndc: '00069034530',
    //     refills: 0,
    //     daysSupply: 5,
    //     pharmacyNCPDPId: '4707',
    //     sig: '300 mg nirmatrelvir (two 150 mg tablets) with 100 mg ritonavir (one 100 mg tablet) with all three tablets taken together orally twice daily for 5 days,  ',
    //     quantity: 30,
    //   },
    //   {
    //     name: 'Lagevrio 200 mg capsule',
    //     ndc: '00006505506',
    //     refills: 0,
    //     daysSupply: 5,
    //     pharmacyNCPDPId: '4707',
    //     sig: 'take 4 capsules by mouth twice a day for 5 days',
    //     quantity: 40,
    //   },
    //   {
    //     name: 'Paxlovid 150 mg-100 mg (300 mg-100 mg Dose) tablet',
    //     ndc: '00069034530',
    //     refills: 0,
    //     daysSupply: 5,
    //     pharmacyNCPDPId: '4707',
    //     sig: '300 mg nirmatrelvir (two 150 mg tablets) with 100 mg ritonavir (one 100 mg tablet) with all three tablets taken together orally twice daily for 5 days,  ',
    //     quantity: 30,
    //   },
    //   {
    //     name: 'Losartan 25 mg tablet',
    //     ndc: '00054012322',
    //     refills: 0,
    //     daysSupply: 30,
    //     pharmacyNCPDPId: '4231',
    //     sig: '1 tab PO daily',
    //     quantity: 30,
    //   },
    // ],
    pharmacies: data.treatmentPlan?.pharmacies,
    // pharmacies: [
    //   {
    //     RcopiaId: null,
    //     RcopiaMasterId: null,
    //     NCPDPID: '4707',
    //     Name: 'CVS Pharmacy # 6406',
    //     Address: {
    //       LineAddress1: '3126 DALLAS-HIGH SHOALS HWY.',
    //       LineAddress2: '',
    //       City: 'DALLAS',
    //       State: 'NC',
    //       Zip: '28034',
    //       Country: null,
    //     },
    //     Type: null,
    //     ContactDetails: {
    //       MobilePhoneNumber: '7049228911',
    //       HomePhoneNumber: null,
    //       EmailAddress: null,
    //     },
    //   },
    //   {
    //     RcopiaId: null,
    //     RcopiaMasterId: null,
    //     NCPDPID: '4231',
    //     Name: 'Walgreens #8932',
    //     Address: {
    //       LineAddress1: '1000 DALLAS-Cherryville HWY.',
    //       LineAddress2: '',
    //       City: 'DALLAS',
    //       State: 'NC',
    //       Zip: '28034',
    //       Country: null,
    //     },
    //     Type: null,
    //     ContactDetails: {
    //       MobilePhoneNumber: '7049228911',
    //       HomePhoneNumber: null,
    //       EmailAddress: null,
    //     },
    //   },
    // ],
    pharmacy: {
      name: data.treatmentPlan?.pharmacy?.Name,
      line1: data.treatmentPlan?.pharmacy?.Address?.LineAddress1,
      line2: data.treatmentPlan?.pharmacy?.Address?.LineAddress2,
      city: data.treatmentPlan?.pharmacy?.Address?.City,
      state: data.treatmentPlan?.pharmacy?.Address?.State,
      zip: data.treatmentPlan?.pharmacy?.Address?.Zip,
    },
    pharmacy_phone: data.pharmacy?.ContactDetails?.MobilePhoneNumber,
    visitType: data.callDetails ? data.callDetails[0]?.type : '',
  }
}

export const collectionResultFields = {
  VISIT_SUMMARY_SAFE: {
    fields: [
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'medicationTitle',
            type: 'title',
            plainTextName: 'Medication',
            divider: false,
          },
          {
            id: 'prescriptions',
            type: 'SAFEprescriptionList',
            translationName: '',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'visitSummary',
            type: 'title',
            plainTextName: 'Notes from your Clinician',
          },
          {
            id: 'notes',
            type: 'smallTextArray',
            plainTextName: '',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'diagnosisTitle',
            type: 'title',
            plainTextName: 'Diagnosis',
          },
          {
            id: 'diagnosis',
            type: 'smallTextArray',
            plainTextName: '',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'prescriptionTitle',
            type: 'title',
            plainTextName: 'Prescription Details',
            divider: false,
          },
          {
            id: 'prescriptions',
            type: 'FVFprescriptionList',
            translationName: '',
          },
        ],
      },
      {
        type: 'accordion',
        plainTextName: 'Visit Details',
        fields: [
          {
            id: 'providerName',
            type: 'listText',
            plainTextName: 'Clinician',
          },
          {
            id: 'memberName',
            type: 'listText',
            plainTextName: 'Patient',
          },
          {
            id: 'memberDOB',
            type: 'dateAndTime',
            noTime: true,
            plainTextName: 'Date of birth',
          },
          {
            id: 'completed_at',
            type: 'dateAndTime',
            plainTextName: 'Date & Time',
          },
          {
            id: 'visitType',
            type: 'listText',
            plainTextName: 'Visit Type',
          },
        ],
      },
    ],
  },
  VISIT_SUMMARY_FVF: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'visitSummary',
            type: 'title',
            plainTextName: 'Notes from your Clinician',
          },
          {
            id: 'notes_to_patient',
            type: 'smallText',
            plainTextName: '',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'diagnosisTitle',
            type: 'title',
            plainTextName: 'Test Result',
          },
          {
            id: 'TEST_RESULT',
            type: 'smallText',
            plainTextName: '',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'telehealthSummary',
            type: 'title',
            plainTextName: 'Consultation Summary',
          },
          {
            id: 'providerName',
            type: 'smallText',
            plainTextName: 'Provider',
          },
          {
            id: 'telehealthDate',
            type: 'dateAndTime',
            plainTextName: 'Date',
            inline: true,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'medicationTitle',
            type: 'title',
            plainTextName: 'Medication',
            divider: false,
          },
          {
            id: 'MEDICATION',
            type: 'smallText',
            translationName: '',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'prescriptionTitle',
            type: 'title',
            plainTextName: 'Prescription',
            divider: false,
          },
          {
            id: 'prescriptions',
            type: 'FVFprescriptionList',
            translationName: '',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'referralTitle',
            type: 'title',
            plainTextName: 'Referral',
            divider: false,
          },
          {
            id: 'REFERRAL',
            type: 'smallText',
            translationName: '',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'rationaleTitle',
            type: 'title',
            plainTextName: 'Rationale',
            divider: false,
          },
          {
            id: 'RATIONALE',
            type: 'smallText',
            translationName: '',
          },
        ],
      },
    ],
  },
} as any
