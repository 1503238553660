export const races = [
  {
    label: 'race_american_indian_or_alaskan',
    value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  },
  { label: 'race_asian', value: 'ASIAN' },
  {
    label: 'race_black_or_african_american',
    value: 'BLACK_OR_AFRICAN_AMERICAN',
  },
  {
    label: 'race_hawaiian_ot_pacific',
    value: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  },
  { label: 'race_white', value: 'WHITE' },
  { label: 'race_other', value: 'OTHER' },
  { label: 'race_not_to_specify', value: 'I_PREFER_NOT_TO_SPECIFY' },
]

export const ethnicities = [
  { label: 'ethnicity_hispanic_or_latino', value: 'HISPANIC_OR_LATINO' },
  {
    label: 'ethnicity_not_hispanic_or_latino',
    value: 'NOT_HISPANIC_OR_LATINO',
  },
  { label: 'ethnicity_not_to_specify', value: 'I_PREFER_NOT_TO_SPECIFY' },
]

export const allowedGenders = [
  { label: 'male_gender', value: 'MALE' },
  { label: 'female_gender', value: 'FEMALE' },
  // { label: 'intersex_gender', value: 'INTERSEX' },
  // { label: 'other_gender', value: 'OTHER' },
  // { label: 'gender_not_to_specify', value: 'I_PREFER_NOT_TO_SPECIFY' },
]
export const allowedRelationships = [
  { label: 'parent', value: 'Parent' },
  { label: 'caregiver', value: 'Caregiver' },
  { label: 'legalGuardian', value: 'LegalGuardian' },
  { label: 'powerOfAttorney', value: 'PowerOfAttorney' },
]

export const allowedEmergencyContactRelationships = [
  { label: 'family_relationship', value: 'Family Member' },
  { label: 'friend_relationship', value: 'Friend' },
  { label: 'agent_relationship', value: 'Agent' },
  { label: 'caregiver_relationship', value: 'Caregiver' },
  { label: 'guardian_relationship', value: 'Guardian' },
  { label: 'next_of_kin_relationship', value: 'Next of Kin' },
  { label: 'personal_relationship', value: 'It\'s Personal' },
]
export const allowedPurposes = [
  { label: 'registration_questions_subject', value: 'Registration Questions' },
  { label: 'techniacl_issues_subject', value: 'Technical Issues' },
  { label: 'medical_questions_subject', value: 'Medical Questions' },
  { label: 'feedback_subject', value: 'Provide Feedback' },
  { label: 'other_subject', value: 'Other' },
]

export const USAStateList = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  // { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  // { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  // { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  // { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  // { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  // { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  // { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  // { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
]

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday']

export enum testTypes {
  ORDER = 'order',
  APPOINTMENT = 'appointment',
  DROPOFF = 'drop-off',
  PICKUP = 'pickup',
  SCAN = 'scan',
}

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  TRANSPARENCY: 'transparency',
}

export const ALPHA_REGEX = /^[a-zA-Z]+$/
export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9-]+$/

export const emailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)?$")

export const ADDRESS_TYPES = {
  SHIPPING_ADDRESS: 'shipping-address',
  BILLING_ADDRESS: 'billing-address',
  PICKUP_ADDRESS: 'pickup-address',
}

export enum StepType {
  ELIGIBILITY = 'eligibility',
  SIGN_UP = 'sign-up',
  CONFIRM_EMAIL = 'confirm-email',
  ACC_INFO = 'account-information',
  INSURANCE = 'insurance',
  POLICY_INFO = 'policy-information',
  CREATE_PASSWORD = 'create-password',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  ENTER_PHONE_NUMBER = 'enter-phone',
  SHIPPING_ADDRESS = 'shipping-address',
  SETUP_MFA = 'setup-mfa',
  VERIFY_PHONE_NUMBER = 'verify-phone',
  VERIFY_ADDRESS = 'verify-address',
}

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  UNKNOWN = 'UNKNOWN',
}
