import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { I18n } from '../../../features/translation/types'
import { noInvalidCharacter } from '../../../libs/helpers'
import { FilledTextField } from '../text-field'
import { PasswordVisibility } from './password-visibility'

export const CreatePasswordField = ({
  name,
  label,
  handleChange,
  error,
  value,
  onBlur,
  i18n,
}: {
  label: string
  handleChange: (name: string, value: string) => void
  name: string
  value: string
  error: string
  onBlur: any
  i18n: I18n
}) => {
  const classes = useIconStyles()
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [passwordIllegalCharError, setPasswordIllegalCharError] = useState('')
  let timeout: NodeJS.Timeout

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const legal = noInvalidCharacter(e.target.value)
    if (!legal && e.target.value) {
      if (timeout) clearTimeout(timeout)
      setPasswordIllegalCharError('Character not allowed')
      timeout = setTimeout(() => {
        setPasswordIllegalCharError('')
      }, 3000)
    } else {
      setPasswordIllegalCharError('')
      handleChange(name, e.target.value)
    }
  }

  return (
    <FilledTextField
      label={label ? label : i18n.login_password}
      value={value}
      onChange={onChange}
      type={isShowPassword ? 'text' : 'password'}
      name={name}
      autoComplete="new-password"
      required
      onBlur={onBlur}
      error={passwordIllegalCharError || error}
      helperText={passwordIllegalCharError || error}
      endAdornment={
        <IconButton
          className={classes.iconBtn}
          aria-label="toggle password visibility"
          onClick={() => setIsShowPassword(!isShowPassword)}
        >
          <PasswordVisibility showPassword={isShowPassword} />
        </IconButton>
      }
    />
  )
}

const useIconStyles = makeStyles({
  iconBtn: {
    'width': 12,
    'height': 12,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: 'rgb(0, 60, 114)',
    },
  },
})
