import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allowedGenders, ethnicities, races } from '../../constants'
import { selectLanguageContent } from '../../features/translation'
import { translateOptions } from '../../libs/helpers'
import {
  Button,
  FilledDatePicker,
  FilledTextField,
  MaskedInput,
} from '../../ui'
import { FilledSelect } from '../../ui/atoms/select'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { selectNetworkSettings } from '../network/model'
import { selectProfileData, updateUserData } from '../profile/model'
import { FormSkeletonLoader } from './FormSkeletonLoader'
import { FormValues } from './type'
import validationSchema from './validationSchema'

export const MyProfilePage = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const networkSettings = useSelector(selectNetworkSettings)

  const dispatch = useDispatch()

  const onSubmit = (values: FormValues) => {
    const {
      firstName,
      lastName,
      dob,
      email,
      phone,
      race,
      gender,
      ethnicity,
      zip,
    } = values

    const payload = {
      ...profileData,
      firstName,
      lastName,
      email,
      birthDate: dob?.toISOString(),
      contact: {
        ...profileData.contact,
        mobileCountryCode: '+1',
        mobileNumber: phone,
      },
      race,
      gender,
      ethnicity,
      primaryAddress: {
        ...profileData.primaryAddress,
        zip,
      },
    }
    dispatch(updateUserData(payload))
  }

  const {
    _id,
    firstName,
    lastName,
    primaryAddress,
    birthDate,
    gender,
    race,
    ethnicity,
    email,
    contact,
  } = profileData

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName,
      lastName,
      email,
      phone: contact.mobileNumber,
      dob: birthDate ? new Date(birthDate) : null,
      gender,
      race,
      ethnicity,
      zip: primaryAddress.zip,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema(i18n, networkSettings),
  })

  const renderFirstName = () => (
    <FilledTextField
      label={i18n.first_name}
      value={formik.values.firstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
      helperText={formik.touched.firstName ? formik.errors.firstName : ''}
      name="firstName"
      required
    />
  )

  const renderLastName = () => (
    <FilledTextField
      label={i18n.last_name}
      value={formik.values.lastName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
      helperText={formik.touched.lastName ? formik.errors.lastName : ''}
      name="lastName"
      required
    />
  )

  const renderEmail = () => (
    <FilledTextField
      label={i18n.email}
      value={formik.values.email}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={formik.touched.email ? formik.errors.email : ''}
      name="email"
      type="email"
      disabled
      required
    />
  )

  const renderPhone = () => (
    <MaskedInput
      mask="+1 (999)-999-9999"
      maskChar=""
      variant="filled"
      label={i18n.phone_number}
      value={formik.values.phone}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.phone && Boolean(formik.errors.phone)}
      helperText={formik.touched.phone ? formik.errors.phone : ''}
      inputProps={{
        inputMode: 'numeric',
      }}
      name="phone"
      required
    >
      {(inputProps: any) => <TextField {...inputProps} />}
    </MaskedInput>
  )

  const renderDOB = () =>
    networkSettings?.collectDateOfBirth && (
      <FilledDatePicker
        label={i18n.date_of_birth}
        value={formik.values.dob}
        format="MM-dd-yyyy"
        onBlur={formik.handleBlur}
        fullWidth
        error={formik.touched.dob && Boolean(formik.errors.dob)}
        helperText={formik.touched.dob && formik.errors.dob}
        name="dob"
        onChange={(e) => formik.setFieldValue('dob', e)}
        maxDate={moment().subtract(18, 'years').subtract(1, 'days').toDate()}
        inputProps={{
          inputMode: 'numeric',
        }}
        required={networkSettings.collectDateOfBirthRequired}
      />
    )

  const renderGender = () =>
    networkSettings?.collectSex && (
      <FilledSelect
        label={i18n.sex}
        options={translateOptions(allowedGenders, i18n)}
        value={formik.values.gender}
        fullWidth
        name="gender"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.gender && Boolean(formik.errors.gender)}
        helperText={formik.touched.gender ? formik.errors.gender : ''}
        required={networkSettings.collectSexRequired}
      />
    )

  const renderRace = () =>
    networkSettings?.collectRace && (
      <FilledSelect
        label={i18n.race}
        options={translateOptions(races, i18n)}
        value={formik.values.race}
        fullWidth
        name="race"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.race && Boolean(formik.errors.race)}
        helperText={formik.touched.race ? formik.errors.race : ''}
        required={networkSettings.raceRequired}
      />
    )

  const renderEthnicity = () =>
    networkSettings?.collectEthicity && (
      <FilledSelect
        label={i18n.ethnicity}
        options={translateOptions(ethnicities, i18n)}
        value={formik.values.ethnicity}
        fullWidth
        name="ethnicity"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.ethnicity && Boolean(formik.errors.ethnicity)}
        helperText={formik.touched.ethnicity ? formik.errors.ethnicity : ''}
        required={networkSettings.ehtnicityRequired}
      />
    )

  const renderZip = () => (
    <MaskedInput
      mask="99999"
      maskChar=""
      variant="filled"
      label={i18n.zip_code}
      value={formik.values.zip}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.zip && Boolean(formik.errors.zip)}
      helperText={formik.touched.zip ? formik.errors.zip : ''}
      name="zip"
      inputProps={{
        inputMode: 'numeric',
      }}
      required
    >
      {(inputProps: any) => <TextField {...inputProps} />}
    </MaskedInput>
  )

  const renderForm = () => {
    if (!networkSettings || !_id) return <FormSkeletonLoader />

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.form}>
          {renderFirstName()}
          {renderLastName()}
          {renderEmail()}
          {renderPhone()}
          {renderDOB()}
          {renderGender()}
          {renderRace()}
          {renderEthnicity()}
          {renderZip()}
        </div>
        <Button
          className={classes.button}
          disabled={!formik.isValid}
          isLoading={formik.isSubmitting}
          type="submit"
        >
          {i18n.save_button}
        </Button>
      </form>
    )
  }

  return <PageWrapper title={i18n.my_profile}>{renderForm()}</PageWrapper>
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 32,
    gridRowGap: 24,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  button: {
    width: 92,
    minWidth: 92,
    margin: '32px 0 16px',
    [theme.breakpoints.down('xs')]: {
      margin: '24px 0 8px',
      width: '100%',
    },
  },
}))
