import { ListItem, ListItemText, Theme, useMediaQuery } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../../../features/translation'
import { AccountSelectionOptions } from '../../../../features/user/types/mfa'
import { Select, Typography } from '../../../../ui'
import { paths } from '../../../paths'
import { settingsStyles } from '../../styles'
import { AccountSettingsType, SettingsTabType } from '../../type'
import { accountSettingsMenuItems } from './sections/mapping'

export const AccountTab = () => {
  const classes = settingsStyles()
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()
  const { type, selection } = useParams<{
    type?: AccountSettingsType
    selection: AccountSelectionOptions
  }>()

  const [selected, setSelected] = useState<AccountSettingsType>(
    type || AccountSettingsType.PERSONAL_INFORMATION
  )
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  useEffect(() => {
    if (!type) return
    setSelected(type)
  }, [type])

  const renderContent = () => {
    const Content = accountSettingsMenuItems[selected].content

    if (!Content) return null

    return <Content />
  }

  const getMobileMenuList = () => {
    const menuItems = Object.keys(accountSettingsMenuItems).map((option) => {
      const value =
        accountSettingsMenuItems[option as AccountSettingsType].label_key
      return {
        label: i18n[value],
        value: option,
      }
    })
    return menuItems
  }

  const fullSizeView = () => (
    <div className={classes.settingsList}>
      {Object.keys(accountSettingsMenuItems).map((option) => {
        return (
          <ListItem
            key={`account-${option}`}
            selected={selected === (option as AccountSettingsType)}
            onClick={() =>
              history.push(paths.settingsTab('account', option))
            }
            className={classes.item}
          >
            <ListItemText
              primary={
                i18n[
                  accountSettingsMenuItems[option as AccountSettingsType]
                    .label_key
                ]
              }
            />
          </ListItem>
        )
      })}
    </div>
  )

  const mobileView = () => (
    <div className={classes.settingsList}>
      <Select
        value={selected}
        onChange={(e) =>
          history.push(
            paths.settingsTab(SettingsTabType.ACCOUNT, e.target.value)
          )
        }
        options={getMobileMenuList()}
      />
    </div>
  )

  return (
    <div className={classes.tabWrapper}>
      {isMobile ? mobileView() : fullSizeView()}
      <div className={classes.activeTab}>
        {!selection && (
          <Typography className={classes.title} variant="h2">
            {i18n[accountSettingsMenuItems[selected].label_key]}
          </Typography>
        )}
        {renderContent()}
      </div>
    </div>
  )
}
