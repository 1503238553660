import { Dialog, Link as MUILink } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StepType } from '../../../../constants'
import { addLinkWithinString } from '../../../../features/details-helper/fields'
import { selectLanguageContent } from '../../../../features/translation'
import { cleanPhoneNumber } from '../../../../libs/utils'
import { Button, MaskedInput, TextField, Typography } from '../../../../ui'
import { TextFieldProps } from '../../../../ui/atoms/text-field/text-field'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import {
  getConsents,
  selectConsentsFetched,
} from '../../../terms-and-conds/model/model'
import { TermsAndConditionsPage } from '../../../terms-and-conds/signupPage'
import { getName, scrollTop } from '../../helpers'
import { saveContact, setError } from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'

export const VerifyPhoneNumber = (props: RegisterStepProps) => {
  const { handleNext, formik, tenantId, isInvite } = props
  const classes = onboardingStyles()
  const type = StepType.ENTER_PHONE_NUMBER
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const consentsFetched = useSelector(selectConsentsFetched)
  const [termsOpen, setTermsOpen] = useState(false)

  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  // Override Safari cached load on browser back action
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  const onPhoneChange = (e: React.ChangeEvent<{ value: string }>) => {
    const cleanNumber = cleanPhoneNumber(e.target.value)
    formik.setFieldValue(
      getName('mobileNumber', StepType.ENTER_PHONE_NUMBER),
      cleanNumber
    )
  }

  useEffect(() => {
    dispatch(setError(''))
    if (!consentsFetched) {
      dispatch(getConsents(tenantId))
    }
    document.addEventListener('focusout', scrollTop)
    return () => {
      document.removeEventListener('focusout', scrollTop)
    }
  }, [])

  const onNext = () => {
    sessionStorage.removeItem('codeSent')
    dispatch(saveContact({ ...values, countryCode: '+1' }))
    handleNext()
  }

  const renderPhone = () => (
    <MaskedInput
      mask="(999) 999-9999"
      maskChar=""
      value={values.mobileNumber}
      variant="filled"
      onChange={(e) => onPhoneChange(e)}
      label={i18n.phone_number}
      name={getName('mobileNumber', StepType.ENTER_PHONE_NUMBER)}
      onBlur={formik.handleBlur}
      error={Boolean(touched?.mobileNumber && errors?.mobileNumber)}
      helperText={(touched?.mobileNumber && errors?.mobileNumber) || ''}
      required
    >
      {(inputProps: TextFieldProps) => <TextField {...inputProps} />}
    </MaskedInput>
  )

  return (
    <>
      <form className={classes.content}>
        <Typography className={classes.title} color="primary" variant="h1">
          {i18n.verify_phone_number}
        </Typography>
        <Typography className={classes.description} align="left">
          {i18n.phone_number_disclaimer}
        </Typography>
        {renderPhone()}
        {addLinkWithinString(
          i18n.phone_number_data_rates_warning.replace('\\n\\n', '\n'),
          i18n.terms_ampersand_conditions_link,
          () => setTermsOpen(true),
          classes.dataRatesText,
          classes.termsLink,
          2
        )}
        <div
          className={
            isInvite ? classes.inviteMobileButtonWrapper : classes.buttonWrapper
          }
        >
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={!formik.isValid || !values.mobileNumber}
            onClick={onNext}
          >
            {i18n.continue_button}
          </Button>
        </div>
      </form>
      <Dialog open={termsOpen} fullScreen>
        <div className={classes.dialogContainer}>
          <TermsAndConditionsPage
            onClose={() => setTermsOpen(false)}
            parentTenantId={tenantId}
          />
        </div>
      </Dialog>
    </>
  )
}
