import { Box, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USAStateList } from '../../../../../../constants'
import { selectLanguageContent } from '../../../../../../features/translation'
import {
  Button,
  FilledTextField,
  MaskedInput,
  Typography,
} from '../../../../../../ui'
import { FilledSelect } from '../../../../../../ui/atoms/select'
import { selectNetworkSettings } from '../../../../../network/model'
import { selectProfileData, updateUserData } from '../../../../../profile/model'
import { settingsStyles } from '../../../../styles'
import { AddressFormValues } from '../../../../type'
import { FormSkeletonLoader } from './skeleton-loader'
import validationSchema from './validation-schema'

export const Address = () => {
  const classes = settingsStyles()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const currentNetwork = useSelector(selectNetworkSettings)
  const [edit, setEdit] = useState(false)

  const dispatch = useDispatch()

  const {
    collectAddress,
    collectStreetAddress,
    collectStreetAddressRequired,
    collectState,
    collectStateRequired,
    collectZip,
    collectZipRequired,
  } = currentNetwork || {}

  const onCancel = () => {
    setEdit(false)
    formik.resetForm()
  }

  const onSubmit = async (values: AddressFormValues) => {
    const updatedUser = {
      ...profileData,
      primaryAddress: {
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        zip: values.zip,
      },
    }
    await dispatch(updateUserData(updatedUser, true, () => setEdit(false)))
  }

  const formik = useFormik<AddressFormValues>({
    initialValues: {
      line1: profileData.primaryAddress.line1 || '',
      line2: profileData.primaryAddress.line2 || '',
      city: profileData.primaryAddress.city || '',
      state: profileData.primaryAddress.state || '',
      zip: profileData.primaryAddress.zip || '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema(i18n, currentNetwork),
    onSubmit,
  })

  if (!profileData._id) return <FormSkeletonLoader />

  const renderStreetAddress = () => (
    <>
      {collectAddress && collectStreetAddress && (
        <>
          <FilledTextField
            label={i18n.street_address}
            autoComplete="address-line1"
            name={'line1'}
            type={'text'}
            value={formik.values.line1}
            error={formik.touched?.line1 && Boolean(formik.errors?.line1)}
            noHelperText
            disabled={!edit}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={collectStreetAddressRequired}
          />
          <FilledTextField
            label={i18n.patient_records_apt_or_suit}
            autoComplete="address-line2"
            name={'line2'}
            type={'text'}
            value={formik.values.line2}
            error={formik.touched?.line2 && Boolean(formik.errors?.line2)}
            noHelperText
            disabled={!edit}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FilledTextField
            label={i18n.city}
            autoComplete="address-level2"
            name={'city'}
            type={'text'}
            value={formik.values.city}
            error={formik.touched?.city && Boolean(formik.errors?.city)}
            noHelperText
            disabled={!edit}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={collectStreetAddressRequired}
          />
        </>
      )}
    </>
  )

  const renderZip = () =>
    collectZip && (
      <MaskedInput
        mask="99999"
        maskChar=""
        variant="filled"
        label={i18n.zip_code}
        value={formik.values.zip}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.zip && Boolean(formik.errors.zip)}
        helperText={formik.touched.zip ? formik.errors.zip : ''}
        name="zip"
        disabled={!edit}
        inputProps={{
          inputMode: 'numeric',
        }}
        required={collectZipRequired}
      >
        {(inputProps: any) => <TextField {...inputProps} />}
      </MaskedInput>
    )

  const renderState = () =>
    collectState && (
      <FilledSelect
        label={i18n.state}
        options={USAStateList}
        autoComplete="address-level1"
        value={formik.values.state}
        fullWidth
        variant="filled"
        name={'state'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={!edit}
        error={formik.touched?.state && Boolean(formik.errors?.state)}
        helperText={formik.touched?.state ? formik.errors?.state : ''}
        required={collectStateRequired}
      />
    )

  return (
    <div className={classes.formWrapper}>
      {!edit && (
        <Box className={classes.editBox} onClick={() => setEdit(true)}>
          <Typography color="primary">
            {i18n.edit_personal_information}
          </Typography>
        </Box>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.formContainer}>
          {renderStreetAddress()}
          {renderState()}
          {renderZip()}
        </Box>
        {edit && (
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              onClick={onCancel}
              className={classes.confirmButton}
            >
              {i18n.cancel_button}
            </Button>
            <Button
              className={classes.confirmButton}
              disabled={!formik.isValid}
              isLoading={formik.isSubmitting}
              type="submit"
            >
              {i18n.save_button}
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}
