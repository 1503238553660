import '../../../fonts.css'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Alert from '../../../assets/icons/alert-red.svg'
import { I18n } from '../../../features/translation/types'
import { hexToRGBA } from '../../../libs/helpers'
import { capitalizeString } from '../../../libs/utils'
import { PatientTest } from '../../../types/patientTest'
import { NetworkSettings } from '../../network/type'
import { paths } from '../../paths'
import { SHADOW } from '../constants'

export const TestHistory = ({
  i18n,
  isMobile,
  networkSettings,
  test,
  isLatest = false,
}: {
  i18n: I18n
  networkSettings: NetworkSettings | null
  test: PatientTest
  isLatest: boolean
  isMobile?: boolean
}) => {
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const history = useHistory()
  const [pillBackgound, setPillBackgound] = useState(
    networkSettings?.secondaryColor
      ? hexToRGBA(networkSettings.secondaryColor, 0.2)
      : hexToRGBA('#01C888', 0.2)
  )

  const classes = useStyles({ radius, pillBackgound })
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  useEffect(() => {
    if (test.result === 'POSITIVE') {
      setPillBackgound(hexToRGBA('#E22E2A', 0.2))
    }
  }, [test])

  const outcomeColor = (outcome: string) => {
    switch (outcome) {
      case 'Negative':
      case 'NEGATIVE': {
        return '#009104'
      }
      case 'Positive':
      case 'POSITIVE': {
        return '#E22E2A'
      }
      case 'Invalid':
      case 'INAVLID':
      case 'ERROR':
      default: {
        return '#858585'
      }
    }
  }

  const TestResultList = () => (
    <div
      onClick={(event) => {
        event.stopPropagation()
        history.push(paths.testResults())
      }}
    >
      <MoreHorizIcon />
    </div>
  )

  return (
    <Box
      className={classes.historyWrapper}
      onClick={() => history.push(paths.testResultDetails(test._id))}
    >
      {!isMobile && (
        <Box className={`${classes.careServiceTitleSection} desktop`}>
          <Typography className={`${classes.careServiceTitle} desktop`}>
            {isLatest ? 'Latest Test' : i18n.test_history}
          </Typography>
          <TestResultList />
        </Box>
      )}
      <Box className={classes.history}>
        <Box className={classes.historyIcon}>
          {test.result === 'POSITIVE' ? (
            <img src={Alert} className={classes.alertIcon} />
          ) : (
            <HistoryRoundedIcon className={classes.icon} />
          )}
        </Box>
        <Box className={classes.historySection}>
          {isMobile && (
            <Box className={classes.careServiceTitleSection}>
              <Typography className={classes.careServiceTitle}>
                {isLatest ? 'Latest Test' : i18n.test_history}
              </Typography>
              <TestResultList />
            </Box>
          )}
          <Typography className={classes.testName}>
            {test.details?.orderableTest?.orderableName}
          </Typography>
          <Typography className={classes.testName}>
            {new Date(test.modified).toLocaleDateString('en-GB', options)}{' '}
            {isMobile && (
              <>
                |
                <span
                  style={{
                    color: outcomeColor(test.result),
                  }}
                >
                  {' '}
                  {test.result && capitalizeString(test.result)}
                  {/* {test.metadata?.outcome
                    ? capitalizeString(test.metadata.outcome)
                    : capitalizeString(test.statusDisplayText)} */}
                </span>
              </>
            )}
          </Typography>
          {!isMobile && (
            <Typography className={classes.testName}>
              <span
                style={{
                  color: outcomeColor(test.result),
                }}
              >
                {test.result && capitalizeString(test.result)}
                {/* {test.metadata?.outcome
                  ? capitalizeString(test.metadata.outcome)
                  : capitalizeString(test.statusDisplayText)} */}
              </span>
            </Typography>
          )}
        </Box>
        <Box className={classes.careServiceTreatments}></Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  historyWrapper: {
    'backgroundColor': '#FFF',
    'maxWidth': '528px',
    'width': 'calc(45vw - 130px)',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'borderRadius': ({ radius }: { radius: number; pillBackgound: string }) =>
      radius,
    [theme.breakpoints.down(960)]: {
      width: 'calc(45vw - 8px)',
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: '90vw',
      width: '100%',
    },
  },
  history: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '25px',
    },
  },
  historySection: {
    marginLeft: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  careServiceTitleSection: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '&.desktop': {
      padding: '32px 32px 0px 32px',
      [theme.breakpoints.down(850)]: {
        padding: '24px 24px 0px 16px',
      },
    },
  },
  careServiceTitle: {
    'fontSize': '16px',
    'fontWeight': 600,
    '&.desktop': {
      fontSize: '32px',
    },
  },
  careServiceTreatments: {
    display: 'flex',
    maxWidth: '85vw',
    overflowY: 'scroll',
  },
  treatmentWrapper: {
    marginRight: '8px',
    backgroundColor: '#F3F3F3',
    borderRadius: ({ radius }: { radius: number; pillBackgound: string }) =>
      radius,
  },
  treatmentInner: {
    display: 'flex',
    width: 'max-content',
    padding: '8px',
  },
  treatmentTitle: {
    width: 'fit-content',
    color: '#757575',
  },
  treatmentTypeWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    borderRadius: ({ radius }: { radius: number; pillBackgound: string }) =>
      radius,
    backgroundColor: ({
      pillBackgound,
    }: {
      radius: number
      pillBackgound: string
    }) => pillBackgound,
  },
  treatmentType: {
    fontSize: '12px',
    padding: '0px 6px',
    color: theme.palette.secondary.main,
  },
  historyIcon: {
    color: theme.palette.secondary.main,
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    padding: '16px',
    backgroundColor: ({
      pillBackgound,
    }: {
      radius: number
      pillBackgound: string
    }) => pillBackgound,
  },
  icon: {
    fontSize: '32px',
    [theme.breakpoints.down(600)]: {
      fontSize: '23px',
    },
  },
  alertIcon: {
    height: '32px',
    [theme.breakpoints.down(600)]: {
      height: '23px',
    },
  },
  testName: {
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'fontSize': '14px',
  },
}))
