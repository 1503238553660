import { Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { paths } from '../../pages/paths'
import { Typography } from '../atoms'

export const BackHeader = ({
  title,
  backPath = paths.app.dashboard(),
  className,
  backActionOverride,
}: {
  title: string
  backPath?: string
  className?: string
  backActionOverride?: () => void
}) => {
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  const handleBack = () => {
    if (backActionOverride) {
      backActionOverride()
    } else history.push(backPath)
  }

  return (
    <div className={classes.backHeaderWrapper}>
      <div className={classes.backIconWrapper} onClick={() => handleBack()}>
        <ArrowBackIcon className={classes.pageLink} />
        {!isMobile && (
          <Typography className={classes.pageLink}>
            {i18n.back_button}
          </Typography>
        )}
      </div>
      <Typography className={classes.pageTitle}>{title}</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  backHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: 64,
    borderBottom: 'solid 1px #E0E0E0',
    position: 'fixed',
    zIndex: 1001,
    backgroundColor: '#FFF',
    [theme.breakpoints.down(600)]: {
      alignItems: 'flex-end',
      backgroundColor: theme.palette.primary.main,
    },
  },
  backIconWrapper: {
    display: 'flex',
    cursor: 'pointer',
    position: 'absolute',
    marginLeft: '0px',
    left: 64,
    [theme.breakpoints.down('sm')]: {
      left: 16,
    },
    [theme.breakpoints.down(600)]: {
      left: 8,
      bottom: 8,
    },
  },
  pageLink: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: 4,
    cursor: 'pointer',
    alignSelf: 'center',
    marginTop: 1,
    color: '#505358',
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      color: '#FFF',
    },
  },
  pageTitle: {
    fontSize: '20px',
    [theme.breakpoints.down(600)]: {
      color: '#FFF',
      fontWeight: 500,
      marginBottom: 4,
    },
  },
}))
