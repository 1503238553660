import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SubHeaderGetTested } from '../../features/application'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { ccExpiresFormat, formatCardNumber } from '../../libs/helpers'
import { Button, TextField } from '../../ui'
import { Content, FormWrapper, Wrapper } from '../../ui/templates/app-layout'
import { paths } from '../paths'
import { addPaymentCreditCard, selectIsLoading } from '../payment-methods/model'

export const AddCardPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const addingNewCreditCard = useSelector(selectIsLoading)
  const i18n = useSelector(selectLanguageContent)
  const [cardNumber, setCardNumber] = useState('')
  const [expDate, setExpDate] = useState('')
  const [cvc, setCvc] = useState('')
  const [ownerName, setOwnerName] = useState('')
  const [zipCode, setZipCode] = useState('')

  const dispatch = useDispatch()

  const goBack = () => {
    history.goBack()
  }

  async function addCrediCard() {
    return dispatch(
      addPaymentCreditCard(cardNumber, expDate, cvc, ownerName, zipCode)
    )
  }

  const handleSubmit = async () => {
    if (await addCrediCard()) history.push(paths.paymentMethods(type, id))
  }

  return (
    <Wrapper>
      {addingNewCreditCard ? <ContentLoaderBox /> : null}
      <SubHeaderGetTested
        title={i18n.add_credit_card_screen_title}
        onHandleBack={goBack}
      />
      <Content noValidate>
        <FormWrapper>
          <CardNumber
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            i18n={i18n}
          />
          <DateAndCode>
            <ExpirationDate
              expDate={expDate}
              setExpDate={setExpDate}
              i18n={i18n}
            />
            <CVC cvc={cvc} setCvc={setCvc} i18n={i18n} />
          </DateAndCode>
          <NameOnCard
            ownerName={ownerName}
            setOwnerName={setOwnerName}
            i18n={i18n}
          />
          <ZIP zipCode={zipCode} setZipCode={setZipCode} i18n={i18n} />
        </FormWrapper>
        <Button
          className={classes.submitButton}
          onClick={handleSubmit}
          disabled={!cardNumber || !expDate || !cvc || !ownerName || !zipCode}
        >
          {i18n.save_card_button}
        </Button>
      </Content>
    </Wrapper>
  )
}

const CardNumber = ({
  cardNumber,
  setCardNumber,
  i18n,
}: {
  cardNumber: string
  setCardNumber: any
  i18n: I18n
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(cardNumber)

  const handleChange = (e: any) => {
    const val = e.target.value
    if (val.length <= 19) {
      const formatedVal = formatCardNumber(val)
      setValue(formatedVal)
      setCardNumber(val.length >= 18 && val.length <= 19 ? formatedVal : '')
    }
  }

  return (
    <TextField
      className={classes.inputNumber}
      label={i18n.credit_card_number}
      type="text"
      value={value}
      onChange={(e) => {
        handleChange(e)
      }}
      required
    />
  )
}

const ExpirationDate = ({
  expDate,
  setExpDate,
  i18n,
}: {
  expDate: string
  setExpDate: any
  i18n: I18n
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(expDate)

  const handleChange = (e: any) => {
    const val = e.target.value
    const formatedVal = ccExpiresFormat(val)
    setValue(formatedVal)
    setExpDate(formatedVal.length === 5 ? formatedVal : '')
  }

  return (
    <TextField
      className={classes.inputNumber}
      label={i18n.credit_card_mm_yy}
      type="text"
      value={value}
      onChange={(e) => {
        handleChange(e)
      }}
      required
    />
  )
}

const CVC = ({
  cvc,
  setCvc,
  i18n,
}: {
  cvc: string
  setCvc: any
  i18n: I18n
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(cvc)

  const handleChange = (e: any) => {
    const val = e.target.value
    if (val.length <= 3) {
      setValue(val)
      setCvc(val.length === 3 ? val : '')
    }
  }

  return (
    <TextField
      className={classes.inputNumber}
      label={i18n.credit_card_cvc}
      type="number"
      value={value}
      onChange={(e) => {
        handleChange(e)
      }}
      required
    />
  )
}

const NameOnCard = ({
  ownerName,
  setOwnerName,
  i18n,
}: {
  ownerName: string
  setOwnerName: any
  i18n: I18n
}) => {
  const [value, setValue] = useState(ownerName)
  const handleChange = (e: any) => {
    const val = e.target.value
    setValue(val)
    setOwnerName(val)
  }

  return (
    <TextField
      label={i18n.credit_card_name_on}
      type="text"
      value={value}
      onChange={(e) => {
        handleChange(e)
      }}
      required
    />
  )
}
const ZIP = ({
  zipCode,
  setZipCode,
  i18n,
}: {
  zipCode: string
  setZipCode: any
  i18n: I18n
}) => {
  const classes = useStyles()

  const [value, setValue] = useState(zipCode)
  const handleChange = (e: any) => {
    const val = e.target.value

    if (val.length <= 6) {
      setValue(val)
      setZipCode(val)
    }
  }

  return (
    <TextField
      className={classes.inputNumber}
      label={i18n.credit_card_zip_code}
      type="number"
      value={value}
      onChange={(e) => {
        handleChange(e)
      }}
      required
    />
  )
}

const DateAndCode = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`

const primaryColor = 'rgb(0, 60, 114)'
const primaryColorDisabled = 'rgba(0, 60, 114, 0.7)'
const useStyles = makeStyles({
  submitButton: {
    'margin': '8px',
    'maxHeight': 48,
    'width': '100%',
    'backgroundColor': primaryColor,
    '&.Mui-disabled': {
      borderColor: primaryColorDisabled,
      color: primaryColorDisabled,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      borderColor: primaryColor,
      color: primaryColor,
      backgroundColor: 'transparent',
    },
  },
  inputNumber: {
    '&.MuiInputBase-input::-webkit-outer-spin-button, .MuiInputBase-input::-webkit-inner-spin-button':
      {
        '-webkit-appearance': 'none',
        'margin': 0,
      },
  },
})
