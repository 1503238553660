import '../../fonts.css'
import { Avatar, Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { stripHtml } from 'string-strip-html'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { LastAssessmentData } from '../../features/user/types'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { selectNetworkSettings } from '../network/model'
import { NetworkSettings } from '../network/type'
import {
  selectLatestResult,
  selectProfileData,
  selectUserAvatar,
  selectUserCode,
} from '../profile/model'

export const MyPassportPage = () => {
  const [backgroundColor, setBackgroundColor] = useState('#858585')
  const classes = useStyles({ backgroundColor })
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)

  const userAvatar = useSelector(selectUserAvatar)
  const userCode = useSelector(selectUserCode)
  const lastAssessment = useSelector(selectLatestResult)
  const { fullName } = profileData
  const networkSettings = useSelector(selectNetworkSettings)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (networkSettings?.showHomeScreenBackgroundColor) {
      if (lastAssessment.status === 'CLEARED') {
        setBackgroundColor('#009104')
      } else if (lastAssessment.status === 'NOT_CLEARED') {
        setBackgroundColor('#E22E2A')
      }
    } else {
      setBackgroundColor(networkSettings?.secondaryColor || '#858585')
    }
  }, [lastAssessment])

  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  }

  return (
    <PageWrapper
      className={classes.pageWrapper}
      title={'My Passport'}
      paper={false}
    >
      <Box
        className={classes.passportContainer}
        style={{
          borderRadius: radius,
        }}
      >
        <Box className={classes.passportUserInfo}>
          {networkSettings?.showLastAssessmentDate && (
            <>
              <Typography variant="h1" className={classes.assessmentTitle}>
                {i18n.last_assessment_label}
              </Typography>
              <Typography className={classes.assessmentDate}>
                {new Date(lastAssessment.lastAssessment).toLocaleDateString(
                  'en-us',
                  options
                )}
              </Typography>
            </>
          )}
          <Avatar className={classes.avatar} src={userAvatar} />

          <Typography className={classes.passportName}>{fullName}</Typography>
          {networkSettings?.showQRCode && (
            <img className={classes.code} src={userCode} />
          )}
          {networkSettings?.showClearanceStatusHomeScreen && (
            <Assessment
              i18n={i18n}
              lastAssessment={lastAssessment}
              classes={classes}
              networkSettings={networkSettings}
            />
          )}
        </Box>
      </Box>
    </PageWrapper>
  )
}

const Assessment = ({
  i18n,
  lastAssessment,
  classes,
  networkSettings,
}: {
  i18n: I18n
  lastAssessment: LastAssessmentData
  classes: ClassNameMap
  networkSettings: NetworkSettings | null
}) => {
  const theme = useTheme()

  const message = lastAssessment?.message
    ? stripHtml(lastAssessment.message)
        .result.replace(/([\r|\n]+)/g, ' ')
        .slice(0, 100)
    : ''

  const checkNetworkForColor = () => {
    return networkSettings?.showHomeScreenBackgroundColor
      ? '#FFF'
      : theme.palette.primary.main
  }

  const clearanceFallbackText = () =>
    lastAssessment.status === 'CLEARED'
      ? i18n.cleared_for_work
      : i18n.not_cleared_for_work

  return (
    <Box className={classes.clearance}>
      <Typography
        style={{ color: checkNetworkForColor() }}
        className={classes.clearance}
      >
        {message || clearanceFallbackText()}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxWidth: '90vw',
  },
  passportContainer: {
    height: '700px',
    maxWidth: '342px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginLeft: '72px',
    backgroundColor: ({ backgroundColor }: { backgroundColor: string }) =>
      backgroundColor,
    [theme.breakpoints.up('md')]: {
      margin: '42px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '42px auto',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto',
    },
  },
  avatar: {
    width: 226,
    height: 226,
    marginTop: '32px',
    marginBottom: '24px',
  },
  passportUserInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  passportName: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '30px',
    color: '#FFF',
    marginBottom: '39px',
  },
  code: {
    borderRadius: 8,
    width: 179,
    height: 179,
    marginBottom: '16px',
  },
  assessmentTitle: {
    marginTop: '40px',
    fontSize: 10,
    fontWeight: 400,
    color: '#FFF',
  },
  assessmentDate: {
    fontSize: '1.5rem',
    fontWeight: 600,
    marginTop: '4px',
    color: '#FFF',
  },
  clearance: {
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
}))
