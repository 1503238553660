import MUICheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { CSSProperties } from 'react'

type Color = 'primary' | 'secondary'
const getButtonColor = (theme: Theme, color: Color) => {
  switch (color) {
    case 'secondary':
      return theme.palette.secondary.main
    case 'primary':
    default:
      return theme.palette.primary.main
  }
}

interface CheckboxProps {
  checked?: boolean
  onChange?: (e: React.ChangeEvent) => void
  label?: React.ReactNode
  color?: 'primary' | 'secondary'
  name?: string
  disabled?: boolean
  style?: CSSProperties
}
export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  color = 'primary',
  disabled,
  name,
  style,
}) => {
  const classes = useStyles({ color })
  return (
    <FormControlLabel
      control={
        <MUICheckbox
          id={`${name}-checkbox`}
          disabled={disabled}
          name={name}
          checked={checked}
          disableRipple
          onChange={onChange}
          color={color}
          inputProps={{ 'aria-label': 'checkbox' }}
          classes={{ root: classes.checkmark, checked: classes.checkboxActive }}
        />
      }
      style={style}
      label={label}
    />
  )
}
const useStyles = makeStyles((theme) => ({
  checkmark: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checkboxActive': {
      color: ({ color }: { color: Color }) => getButtonColor(theme, color),
    },
    '&$checkboxActive:hover': {
      backgroundColor: 'transparent',
    },
    // '& .MuiSvgIcon-root': {
    //   color: theme.palette.primary.main,
    // },
    '&$checkboxActive .MuiSvgIcon-root': {
      color: ({ color }: { color: Color }) => getButtonColor(theme, color),
    },
  },
  checkboxActive: {},
}))
