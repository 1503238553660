import React, { ChangeEvent, ReactNode } from 'react'
import { FormikErrors } from 'formik'

export const ReactiveFormField = ({
  Component,
  componentProps,
  ...restProps
}: {
  Component: React.FC
  componentProps: any
  value?: any
  defaultValue?: any
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: ReactNode
  helperText?: string | FormikErrors<any> | false
}) => {
  const { optional } = componentProps

  return <Component {...restProps} {...componentProps} required={!optional} />
}
