import { CircularProgress, CircularProgressProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export const Loading = (props: CircularProgressProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress {...props} />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
})
