import { Box, Dialog, Theme, useMediaQuery } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dialogStyles } from './helpers/dialog-styles'
import { selectDialogType } from './model'

export const AppDialogWrapper = ({
  // type,
  localOpen,
  children,
  disableClickAway,
}: {
  // type: string
  /**
   * If Dialog wrapper and content need to be used in a local component
   */
  localOpen?: boolean
  children: ReactNode
  disableClickAway?: boolean
}) => {
  const classes = dialogStyles()
  const isOpen = Boolean(useSelector(selectDialogType))
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  return (
    <Dialog
      classes={{ paper: isMobile ? undefined : classes.paperRounded }}
      open={localOpen ? localOpen : isOpen}
      fullScreen={isMobile}
    >
      <Box className={classes.modal}>{children}</Box>
    </Dialog>
  )
}
