import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { addDays, differenceInHours, differenceInMinutes } from 'date-fns'
import React from 'react'
import { NetworkSettings } from '../../../pages/network/type'
import { Button, Typography } from '../../../ui'
import { HealthService } from '../../health-service-lite/types'
import { OrderStatusStep } from '../types'

interface StatusSnackbarProps {
  networkSettings: NetworkSettings | null
  healthService: HealthService
  step: OrderStatusStep
}

export const SimpleView = (props: StatusSnackbarProps) => {
  const { networkSettings, healthService, step } = props
  const buttonFlex = step.mobileButtonFlex || 'row'
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles({ radius, buttonFlex })
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(960))

  const getTimeRemaining = () => {
    const expires = addDays(new Date(healthService.createdAt), 1)
    const remainingHours = differenceInHours(expires, new Date())
    const remainingMinutes = differenceInMinutes(expires, new Date())
    return (
      <>
        <Typography align="center" className={classes.hours}>
          {remainingHours > 0 ? remainingHours : remainingMinutes}
        </Typography>
        <Typography align="center" className={classes.remaining}>
          {remainingHours > 0 ? 'Hours Remaining' : 'Minutes Remaining'}
        </Typography>
      </>
    )
  }

  const mobileView = () => (
    <>
      <Box className={classes.content}>
        <Box className={classes.textContainer}>
          <Box className={classes.messageHeaderContainer}>
            {step.headerIcon && (
              <img
                src={step.headerIcon}
                style={{ marginRight: 8 }}
                className={classes.headerIcon}
              />
            )}
            <Typography variant="h2" className={classes.messageHeader}>
              {step.header}
            </Typography>
          </Box>
          <Typography variant="h2" className={classes.messageBody}>
            {step.body}
          </Typography>
        </Box>
        {step.graphic === 'IMAGE' ? (
          <>{step.image()}</>
        ) : (
          <Box
            className={classes.imageContainer}
            style={{ backgroundColor: step.bgColor }}
          >
            <Box className={classes.timeContainer}>{getTimeRemaining()}</Box>
          </Box>
        )}
      </Box>
      {step.actions.length > 0 && (
        <Box className={classes.buttonContainer}>
          {step.actions.map((action) => (
            <React.Fragment key={action.title}>
              {action.variant === 'text' ? (
                <Box
                  onClick={() => action.function()}
                  className={
                    action.smallButton ? classes.smallButton : classes.button
                  }
                >
                  <Typography
                    className={
                      action.smallButton ? classes.smallButton : classes.button
                    }
                  >
                    {action.title}
                  </Typography>
                </Box>
              ) : (
                <Button
                  onClick={() => action.function()}
                  variant={action.variant}
                  disabled={action.disabled}
                  className={
                    action.smallButton ? classes.smallButton : classes.button
                  }
                >
                  {action.title}
                </Button>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </>
  )

  const desktopView = () => (
    <>
      <Box className={classes.content}>
        {step.graphic === 'IMAGE' ? (
          <>{step.image()}</>
        ) : (
          <Box
            className={classes.imageContainer}
            style={{ backgroundColor: step.bgColor }}
          >
            <Box className={classes.timeContainer}>{getTimeRemaining()}</Box>
          </Box>
        )}
        <Box className={classes.textContainer}>
          <Box className={classes.messageHeaderContainer}>
            {step.headerIcon && (
              <img
                src={step.headerIcon}
                style={{ marginRight: 8 }}
                className={classes.headerIcon}
              />
            )}
            <Typography variant="h2" className={classes.messageHeader}>
              {step.header}
            </Typography>
          </Box>
          <Typography variant="h2" className={classes.messageBody}>
            {step.body}
          </Typography>
        </Box>
        {step.actions.length > 0 && (
          <Box className={classes.buttonContainer}>
            {step.actions.map((action) => (
              <Button
                key={action.title}
                onClick={() => action.function()}
                variant={action.variant}
                disabled={action.disabled}
                className={classes.button}
              >
                {action.title}
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </>
  )

  return matches ? mobileView() : desktopView()
}

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.up(600)]: {
      height: 75,
      width: 75,
    },
  },
  hours: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '32px',
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
      lineHeight: '20px',
    },
  },
  remaining: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '14px',
    [theme.breakpoints.down(600)]: {
      fontSize: 10,
      lineHeight: '10px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 110,
    gap: '20px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(960)]: {
      flexDirection: ({ buttonFlex }: { buttonFlex: 'row' | 'column' }) =>
        buttonFlex,
      marginTop: '16px',
      width: '100%',
      height: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
  messageHeaderContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 32,
    [theme.breakpoints.down(1310)]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
    },
  },
  imageContainer: {
    'padding': '12px',
    '-webkit-mask-image':
      '-webkit-radial-gradient(circle, white 100%, black 100%)',
    'borderRadius': ({
      radius,
      buttonFlex,
    }: {
      radius: number
      buttonFlex: 'row' | 'column'
    }) => radius,
    [theme.breakpoints.down(600)]: {
      height: 40,
      width: 40,
    },
  },
  headerIcon: {
    marginTop: '-6px',
    height: '36px',
    [theme.breakpoints.down(600)]: {
      height: '18px',
    },
  },
  button: {
    'margin': '0px auto',
    'minWidth': 231,
    'maxHeight': 41,
    'padding': '8px 20px',
    'fontSize': '16px',
    'fontWeight': 600,
    '& span': {
      whiteSpace: 'noWrap',
    },
    '&.MuiButton-outlined': {
      'color': '#505358',
      'borderColor': '#505358',
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: '#FFF !important',
      },
    },
    '&.MuiButton-contained': {
      backgroundColor: '#505358',
      borderColor: '#505358',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '8px',
      fontSize: '14px',
      fontWeight: 600,
      maxWidth: 'unset',
      maxHeight: '33px',
    },
  },
  smallButton: {
    'maxHeight': '42px',
    'maxWidth': '45%',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      fontWeight: 500,
      maxHeight: '33px',
    },
    '&.MuiButton-outlined': {
      color: '#505358',
      borderColor: '#505358',
    },
    '&.MuiButton-contained': {
      backgroundColor: '#505358',
      borderColor: '#505358',
    },
  },
  linkButton: {},
  messageHeader: {
    width: '95%',
    fontWeight: 600,
    color: '#505358',
    fontSize: '35px',
    marginBottom: '4px',
    [theme.breakpoints.down(1310)]: {
      fontSize: '28px',
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '26px',
    },
    [theme.breakpoints.down(700)]: {
      width: '96%',
      fontSize: '22px',
      marginBottom: 0,
    },
    [theme.breakpoints.down(600)]: {
      width: '96%',
      fontSize: '16px',
    },
  },
  messageBody: {
    width: '95%',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '27px',
    color: '#898989',
    [theme.breakpoints.down(960)]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down(600)]: {
      width: '96%',
      lineHeight: '18px',
      marginBottom: '8px',
      fontSize: '14px',
    },
  },
}))
