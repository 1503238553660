import { Avatar } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserAvatar } from '../../profile/model'
import { telehealthStyles } from './styles'

export const ProviderAvatar = () => {
  const classes = telehealthStyles()
  const userAvatar = useSelector(selectUserAvatar)

  return <Avatar className={classes.ava} src={userAvatar} />
}
