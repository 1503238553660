import styled from '@emotion/styled'
import { ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const DrawerListItem = ({
  path,
  icon,
  label,
  isVisible = true,
  onClick,
}: {
  path?: string
  onClick?: () => void
  icon: JSX.Element
  label: string
  isVisible?: boolean
}) => {
  const history = useHistory()
  const classes = useStyles()
  const handleClick = () => {
    if (onClick) onClick()
    if (path) history.push(path)
  }
  return (
    <ListItemStyled button onClick={handleClick} data-is-visible={isVisible}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <Typography className={classes.label}>{label}</Typography>
    </ListItemStyled>
  )
}

const ListItemStyled = styled(ListItem)`
  padding: 19px 26px;
  box-sizing: border-box;
  &[data-is-visible='false'] {
    display: none;
  }
`
const useStyles = makeStyles({
  label: {
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  icon: {
    color: '#7E7E7E',
  },
})
