import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'

interface VCTranslations {
  translations: {
    eng: string
    validatedLanguages: []
  }
}
interface IntroScreen {
  title: VCTranslations
  description: VCTranslations
  buttonText: VCTranslations
  image: string
}
interface SCIntroSlice {
  isLoading: boolean
  introVerbiage: string
  error: string
  introScreen: IntroScreen | undefined
}
const initialStore: SCIntroSlice = {
  isLoading: false,
  introVerbiage: '',
  error: '',
  introScreen: undefined,
}

export const symptomCheckerIntroductionSlice = createSlice({
  name: 'symptomCheckerIntroduction',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setSCIntro(store, { payload }: { payload: any }) {
      store.introVerbiage = payload
    },
    setVcIntroScreen(store, { payload }: { payload: IntroScreen }) {
      store.introScreen = payload
    },
    setError(store, { payload }: { payload: any }) {
      store.error = payload
    },
    resetIntroStore: () => initialStore,
  },
})

export const { resetIntroStore } = symptomCheckerIntroductionSlice.actions

const { setLoading, setSCIntro, setVcIntroScreen } =
  symptomCheckerIntroductionSlice.actions

export const getIntroScreen =
  (id = '') =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    const data = await api.getVcIntroScreen(id)

    if (data) {
      dispatch(setVcIntroScreen(data))
    }

    dispatch(setLoading(false))
    //   else if (error) {
    //     dispatch(setError("Sorry, we are unable locate a tenant for this user"))
    //     dispatch(setLoading(false))
    //   }
  }

export const getVerbiage = () => async (dispatch: Dispatch, getStore: any) => {
  dispatch(setLoading(true))
  const SCInfoKey = 'VirtualConsultCitation'

  const data = await api.getVerbiage()

  if (data) {
    const SCInfo = data.filter((d: any) => d.key === SCInfoKey)
    if (SCInfo.length > 0) {
      dispatch(setSCIntro(SCInfo[0]?.value))
    } else {
      //Use this if no VirtualConsultCitation set in Admin Verbiage
      dispatch(setSCIntro(fallbackSCMessage))
    }
  }

  dispatch(setLoading(false))
  //   else if (error) {
  //     dispatch(setError("Sorry, we are unable locate a tenant for this user"))
  //     dispatch(setLoading(false))
  //   }
}

export const symptomCheckerIntroductionSliceReducer =
  symptomCheckerIntroductionSlice.reducer
export const symptomCheckerIntroductionReducerName =
  symptomCheckerIntroductionSlice.name
interface RootStore {
  [symptomCheckerIntroductionReducerName]: typeof initialStore
}

export const selectIntroLoading = ({ symptomCheckerIntroduction }: RootStore) =>
  symptomCheckerIntroduction.isLoading

export const selectVcIntroScreen = ({
  symptomCheckerIntroduction,
}: RootStore) => symptomCheckerIntroduction.introScreen

export const selectMoreInfoVerbiage = ({
  symptomCheckerIntroduction,
}: RootStore) => symptomCheckerIntroduction.introVerbiage

const fallbackSCMessage = `<p><span style="font-size:17.3333px;font-weight:bold;color:#0000ff;">What is HealthCheck Virtual Consult? <br></span></p>
<p><span style="color:#000000;font-size:14.6667px;">The HealthCheck virtual consult questionnaire was developed in alignment with CDC guidance for symptoms and other COVID-19 screening and testing factors. <br><br></span><span style="font-weight:bold;font-size:17.3333px;color:#0000ff;">How Does HealthCheck Evaluate my COVID-19 Status?</span><span style="font-size:16.000000000000004px;color:#000000;"> </span></p>
<p><span style="color:#000000;font-size:14.6667px;">HealthCheck offers a virtual consult questionnaire to help make a recommendation on whether a COVID-19 test would be advisable, based on an end user’s responses. The questions asked during the virtual consult rely on guidance from the US Centers for Disease Control and Prevention (CDC). </span></p>
<p><span style="color:#000000;font-size:14.6667px;">The virtual consult begins by asking about COVID-19 symptoms, as identified by CDC guidance. (See </span><span style="font-size:14.6667px;color:#1155cc;text-decoration:underline;">https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html</span><span style="font-size:14.6667px;color:#000000;">.) </span></p>
<p><span style="color:#000000;font-size:14.6667px;">Symptoms are important, but are not the only consideration. Other factors the virtual consult considers are:</span><span style="color:#000000;font-size:16.000000000000004px;"> </span></p>
<ul>
<li><p><span style="color:#000000;font-size:14.6667px;">Having close contact with someone with COVID-19</span><span style="color:#000000;font-size:16.000000000000004px;"> </span></p>
</li>
<li><p><span style="color:#000000;font-size:14.6667px;">Recent travel</span><span style="color:#000000;font-size:16.000000000000004px;"> </span></p>
</li>
<li><p><span style="color:#000000;font-size:9.33333px;"> </span><span style="color:#000000;font-size:14.6667px;">Vaccination status</span><span style="color:#000000;font-size:16.000000000000004px;"> </span></p>
</li>
<li><p><span style="color:#000000;font-size:14.6667px;">Recent recovery from a previous COVID-19 infection</span><span style="color:#000000;font-size:16.000000000000004px;"> </span></p>
</li>
</ul>
<p><span style="color:#000000;font-size:14.6667px;">HealthCheck accounts for these factors and makes a recommendation to the end user about COVID-19 testing and self-quarantine. These recommendations are based on CDC guidance, and may also account for policy choices made by employers who use HealthCheck as part of their COVID-19 return-to-work process.</span></p>
<p><span style="color:#000000;font-size:16.000000000000004px;"> </span><span style="color:#000000;font-size:14.6667px;">For more information, including further links to additional CDC resources such as the definition of “close contact”, see </span><span style="font-size:14.6667px;color:#1155cc;text-decoration:underline;">https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html</span><span style="font-size:14.6667px;color:#000000;">.</span></p>`
