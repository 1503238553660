export interface FlowState {
  flowId: string
  flowExecutionId: string
  name: string
  flowHistory: any
  isFlowExecuting: boolean
  isLoading: boolean
  flowsList: []
  currentModule: {
    module_id: string
    module_type: string
    inputs: {[key:string]:string}
    outputs: {out:string,[key:string]:string}
  }
}

export const flowInitialState: FlowState = {
  flowId: '',
  flowExecutionId: '',
  name: '',
  flowHistory: null,
  isFlowExecuting: false,
  isLoading: false,
  flowsList: [],
  currentModule: {
    module_id: '',
    module_type: '',
    inputs: {},
    outputs: {out:''},
  },
}

export interface FlowAction {
  type: FlowActions
  payload: {
    [key: string]: any
  }
}

export enum FlowActions {
  START_FLOW,
  RESUME_FLOW,
  END_FLOW,
  SET_MODULE,
  SET_OUTPUT,
  SET_LOADING,
  SET_FLOW_IDS,
  SET_FLOWS_LIST
}

export const flowReducer = (state: FlowState, action: FlowAction) => {
  const { type, payload } = action
  const newState: Partial<FlowState> = {}
  switch (type) {
    case FlowActions.START_FLOW:
      newState.isFlowExecuting = true

      break
    case FlowActions.END_FLOW:
      newState.isFlowExecuting = false

      break
    case FlowActions.SET_MODULE:
      newState.currentModule = payload.module as any

      break
    case FlowActions.SET_OUTPUT:
      if (newState.currentModule?.outputs)
        newState.currentModule.outputs = payload.outputs

    break
      case FlowActions.SET_LOADING:
        newState.isLoading = payload.loading

    break
    case FlowActions.RESUME_FLOW:
        newState.currentModule = payload.module
        newState.flowId = payload.flowId
        newState.flowExecutionId = payload.flowExecutionId
        newState.isFlowExecuting = true

    break
    case FlowActions.SET_FLOW_IDS:
        if (payload.flowId)
            newState.flowId = payload.flowId
        if (payload.flowExecutionId)
            newState.flowExecutionId = payload.flowExecutionId

    break
    case FlowActions.SET_FLOWS_LIST:
        newState.flowsList = payload.flowsList

    break
    default:
  }

  return { ...state, ...newState }
}

export const flowActions = {
  [FlowActions.START_FLOW]: () => ({
    type: FlowActions.START_FLOW,
    payload: {},
  }),
  [FlowActions.END_FLOW]: () => ({
    type: FlowActions.END_FLOW,
    payload: {},
  }),
  [FlowActions.SET_MODULE]: (module: FlowState['currentModule']) => ({
    type: FlowActions.SET_MODULE,
    payload: { module },
  }),
  [FlowActions.SET_OUTPUT]: (outputs: any) => ({
    type: FlowActions.SET_OUTPUT,
    payload: { outputs },
  }),
  [FlowActions.SET_LOADING]: (loading: boolean) => ({
    type: FlowActions.SET_LOADING,
    payload: { loading },
  }),
  [FlowActions.RESUME_FLOW]: ({flowId,flowExecutionId,module}:{flowId:string,flowExecutionId:string,module:any}) => ({
    type: FlowActions.RESUME_FLOW,
    payload: {flowId,flowExecutionId,module},
  }),
  [FlowActions.SET_FLOW_IDS]: ({flowId,flowExecutionId}:{flowId:string,flowExecutionId:string}) => ({
    type: FlowActions.RESUME_FLOW,
    payload: {flowId,flowExecutionId},
  }),
  [FlowActions.SET_FLOWS_LIST]: (flowsList:[]) => ({
    type: FlowActions.SET_FLOWS_LIST,
    payload: { flowsList },
  }),
}
