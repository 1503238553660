export const formatExpirationDate = (
  month: number | undefined,
  year: number | undefined
) => {
  if (!month || !year) return ''

  let spacer = ''
  if (month < 10) spacer = '0'
  return `${spacer}${month}-${year}`
}
