import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Verified } from '../../assets/img/circle-check.svg'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Button } from '../../ui'
import { paths } from '../paths'
import {
  resetOutcomeStore,
  selectDiagnosticOutcomeResponseMessage,
} from './model'

export const OutcomesPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const message = useSelector(
    selectDiagnosticOutcomeResponseMessage
  ) as keyof I18n

  const goNext = () => {
    dispatch(resetOutcomeStore())
    history.push(paths.app.dashboard())
  }

  return (
    <Box className={classes.instructionsPageWrapper}>
      <Box className={classes.instructionsCardWrapper}>
        <Box className={classes.testWrapper} style={{ flex: 1 }}>
          <Box className={classes.testingContent}>
            <Verified />
            <Box className={classes.contentContainer}>
              <Box className={classes.optionsWrapper}>
                <Box className={classes.optionWrapper}>
                  {i18n[message] || message}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Button
          onClick={goNext}
          variant="contained"
          className={classes.continueButton}
        >
          {i18n.ok_button}
        </Button>
      </Box>
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((theme) => ({
  unknownResult: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000000',
  },
  positiveResult: {
    fontSize: 20,
    fontWeight: 600,
    color: '#900000',
  },
  negativeResult: {
    fontSize: 20,
    fontWeight: 600,
    color: '#006633',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto',
    minHeight: 200,
  },
  headerTitle: {
    fontSize: 20,
    color: '#003C71',
    fontWeight: 600,
  },
  instructionsPageWrapper: {
    backgroundColor: '#FAFAFA',
    height: '100%',
    [theme.breakpoints.down(576)]: {
      backgroundColor: '#FFF',
    },
  },
  instructionsCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    margin: '32px auto 0px',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    textAlign: 'center',
    [theme.breakpoints.down(576)]: {
      width: '100%',
      padding: '0px',
      minHeight: 'unset',
      margin: '0px auto',
    },
  },
  testWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  testingContent: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(767)]: {
      padding: '0 15px',
    },
  },
  optionsWrapper: {
    width: '100%',
  },
  optionWrapper: {
    'padding': '20px 15px',
    'borderRadius': 10,
    'display': 'flex',
    'marginTop': '25px',
    'justifyContent': SPACE_BETWEEN,
    'margin': '0 auto 20px',
    'maxWidth': 400,
    'color': '#003C71',

    '&:first-child': {
      cursor: 'pointer',
    },
  },
  continueButton: {
    margin: '0 auto',
    minHeight: 45,
    marginBottom: 32,
    padding: '0px 24px',
    minWidth: 'unset',
  },
}))
