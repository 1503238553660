import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { lsClient } from '../../../ls-client'
import { paths } from '../../../pages/paths'
import { Button } from '../../../ui'

type Variant = 'contained' | 'text' | 'outlined'

type SymptomCheckerButtonProps = {
  buttonTitle: string
  buttonAction: any
  disabled: boolean
  variant: Variant
}

export const SymptomCheckerButton = (props: SymptomCheckerButtonProps) => {
  const { buttonTitle, buttonAction, disabled, variant } = props
  const classes = useStyles()
  const history = useHistory()

  const linkPath = (to: string) => {
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        history.push(paths.app.getTested())
        break
      }
      case 'HealthService': {
        lsClient.setUserLS('isHealthService', 'Y')
        history.push(paths.symptomCheckerIntro())
        break
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        //VCB 1 implementation
        //VCB 2 implementation
        history.push(paths.symptomCheckerIntro())
        // history.push(paths.questionnaire())
        break
      }
      case 'Resources': {
        history.push(paths.resources())
        break
      }
      case 'ImportHealthRecords': {
        history.push(paths.resources())
        break
      }
      default:
        return () => paths.resources()
    }
  }

  return (
    <div className={classes.buttonWrapper}>
      <Button
        disabled={disabled}
        className={classes.button}
        onClick={() => linkPath(buttonAction?.value)}
        variant={variant}
      >
        {buttonTitle}
      </Button>
    </div>
  )
}

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: 'center',
    width: '100%',
    margin: '8px auto',
  },
  button: {
    'width': '100%',
    'height': '48px',
    '@media (max-width: 500px)': {},
  },
})
