import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import MUIButton from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { PageWrapper } from '../../../../ui/templates/page-wrapper'
import { selectLanguageContent } from '../../../../features/translation'
import { DependentForm } from '../../components/dependent-form'
import { DependentFormValues, SettingsTabType } from '../../type'
import validationSchema from '../../components/dependent-form/validation-schema'
import { Button, Checkbox, Typography } from '../../../../ui'
import { paths } from '../../../paths'

export const AddDependentPage = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const [parentalConsentAgree, setParentalConsentAgree] = useState<boolean>(
    false
  )
  const history = useHistory()

  const onSubmit = async (values: DependentFormValues) => {}

  const formik = useFormik<DependentFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      birthDate: null,
      sexAtBirth: '',
      relationship: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleBack = () =>
    history.push(paths.settingsTab(SettingsTabType.ACCOUNT))

  return (
    <PageWrapper title={i18n.title_add_dependent} style={{ paddingBottom: 32 }}>
      <form className={classes.wrapper} onSubmit={formik.handleSubmit}>
        <DependentForm formik={formik} />
        <Checkbox
          label={
            <Typography className={classes.termsText}>
              {i18n.agree_terms_label + ' '}
              <a>{i18n.parental_consent}</a>
            </Typography>
          }
          style={{ marginTop: 16 }}
          checked={parentalConsentAgree}
          onChange={() => setParentalConsentAgree(!parentalConsentAgree)}
        />
        <Box display="flex" gridGap="16px" marginTop="20px">
          <Button
            variant="contained"
            style={{ maxWidth: 200 }}
            disabled={!formik.isValid || !parentalConsentAgree}
            isLoading={formik.isSubmitting}
            type="submit"
          >
            {i18n.add_dependent}
          </Button>
          <MUIButton className={classes.cancelButton} onClick={handleBack}>
            <Typography style={{ fontWeight: 600 }}>
              {i18n.cancel_title}
            </Typography>
          </MUIButton>
        </Box>
      </form>
    </PageWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  termsText: {
    'color': '#505358',
    'fontSize': 16,
    'fontFamily': 'Avenir Next Rounded Medium',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '& > a': {
      'color': theme.palette.primary.main,
      'fontWeight': 600,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  cancelButton: {
    textTransform: 'none',
    width: '100%',
    maxWidth: 100,
  },
}))
