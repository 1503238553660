import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { testTypes } from '../../../constants'
import { SymptomCheckerButton } from '../../../features/questionnaire'
import { I18n } from '../../../features/translation/types'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { lsClient } from '../../../ls-client'
import { Typography } from '../../../ui'
import { selectNetworkSettings } from '../../network/model'
import { DashboardButton } from '../../network/type'
import { paths } from '../../paths'
import { SHADOW } from '../constants'

export const DashboardActionItem = (props: {
  activeHealthService: boolean
  button: DashboardButton
  i18n: I18n
  xl?: boolean
}) => {
  const { button, i18n, xl = false, activeHealthService } = props
  const history = useHistory()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles({ radius })

  if (activeHealthService && button.action.value === 'HealthService')
    return <></>

  const linkPath = (to: string) => {
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        history.push(paths.app.getTested())
        break
      }
      case 'HealthService': {
        lsClient.setUserLS('isHealthService', 'Y')
        history.push(paths.symptomCheckerIntro())
        break
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        history.push(paths.symptomCheckerIntro())
        break
      }
      case 'Resources': {
        history.push(paths.resources())
        break
      }
      case 'ImportHealthRecords': {
        history.push(paths.resources())
        break
      }
      case 'OrderTestKit': {
        history.push(paths.selectTestOrder(testTypes.ORDER))
        break
      }
      default:
        return () => paths.resources()
    }
  }

  switch (button.style) {
    //Traditional Button - Contained (0) / Outline (1)
    case 0:
    case 1: {
      const buttonStyle = button.style === 0 ? 'contained' : 'outlined'
      const title = button.title as keyof I18n
      return (
        <SymptomCheckerButton
          key={button.title}
          buttonTitle={i18n[title]}
          buttonAction={button.action}
          disabled={!button.enabled}
          variant={buttonStyle}
        />
      )
    }
    //Button with gradient background
    case 2: {
      let gradientStyle = 'linear-gradient(to right, #00C93C, #00C6DD)'
      if (button.colors.length > 0 && !button.colors.includes(null)) {
        gradientStyle = `linear-gradient(to right, ${button.colors[0]},  ${button.colors[1]})`
      }
      return (
        <Box
          key={button.title}
          className={
            xl
              ? `${classes.gradientButtonContainer} ${classes.xlCard}`
              : activeHealthService ? `${classes.gradientButtonContainer} ${classes.xlCardHidden}` : `${classes.gradientButtonContainer}`
          }
          style={{
            background: gradientStyle,
          }}
          onClick={() => button.enabled && linkPath(button.action.value)}
        >
          <Box className={classes.dashboardButtonCard}>
            <Typography className={classes.dashboardButtonTitle}>
              {button.cardTitle}
            </Typography>
            <Typography className={classes.dashboardButtonBody}>
              <HTMLTextComponent HTMLString={button.cardBody} />
            </Typography>
          </Box>
        </Box>
      )
    }
    //Button with background image
    case 3: {
      return (
        <>
          <Box
            key={button.title}
            className={
              xl
                ? `${classes.imageButtonContainer} ${classes.xlCard}`
                : activeHealthService ? `${classes.imageButtonContainer} ${classes.xlCardHidden}` : `${classes.imageButtonContainer}`
            }
            style={{
              backgroundImage: `url(${button.imageUrl})`,
              borderRadius: radius,
            }}
            onClick={() => linkPath(button.action.value)}
          >
            <Box
              className={`${classes.dashboardButtonCard} ${classes.contrastImage}`}
            >
              <Typography className={classes.dashboardButtonTitle}>
                {button.cardTitle}
              </Typography>
              <Typography className={classes.dashboardButtonBody}>
                <HTMLTextComponent HTMLString={button.cardBody} />
              </Typography>
            </Box>
          </Box>
        </>
      )
    }
    default: {
      return <></>
    }
  }
}

const useStyles = makeStyles((theme) => ({
  parentWrapper: {},
  imageButtonContainer: {
    'height': '20vw',
    'maxHeight': '278px',
    'maxWidth': '678px',
    'backgroundRepeat': 'no-repeat',
    'backgroundSize': 'cover',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'flex-end',
    'cursor': 'pointer',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'borderRadius': ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down('xs')]: {
      margin: '24px 0px',
      width: 'unset',
      minWidth: 'unset',
      height: '264px',
    },
  },
  gradientButtonContainer: {
    'height': 'calc(20vw - 12px)',
    'maxHeight': '278px',
    'maxWidth': '678px',
    'backgroundRepeat': 'no-repeat',
    'objectFit': 'cover',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'flex-end',
    'cursor': 'pointer',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'borderRadius': ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(850)]: {
      justifyContent: 'center',
      margin: '24px 0px',
      width: 'unset',
      minWidth: 'unset',
      height: 'fit-content',
      maxWidth: 'unset',
    },
  },
  xlCard: {
    maxHeight: '580px',
    maxWidth: '580px',
    width: '40vw',
    height: '40vw',
    [theme.breakpoints.down(850)]: {
      width: '100%',
      height: '264px',
      maxWidth: 'unset',
    },
  },
  xlCardHidden: {
    maxWidth: "100%",
  },
  dashboardButtonCard: {
    padding: '6%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderBottomRightRadius: ({ radius }: { radius: number }) => radius,
    borderBottomLeftRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  dashboardButtonTitle: {
    fontSize: '3rem',
    fontWeight: 500,
    color: '#FFF',
    [theme.breakpoints.down(1400)]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      marginBottom: '8px',
    },
  },
  dashboardButtonBody: {
    'fontSize': '24px',
    'color': '#FFF',
    'lineHeight': '32px',
    '& p': {
      margin: '0px',
    },
    [theme.breakpoints.down(1400)]: {
      fontSize: '1.25rem',
      lineHeight: '24px',
    },
    [theme.breakpoints.down(900)]: {
      lineHeight: '24px',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      '& span': {
        fontSize: '14px !important',
        lineHeight: '19px',
      },
    },
  },
  contrastImage: {
    background:
      'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,.8) 100%)',
  },
}))
