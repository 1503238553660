import styled from '@emotion/styled'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export const ContentLoaderBox = () => {
  const classes = useStyles()

  return (
    <Wrapper>
      <CircularProgress className={classes.loader} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(255 255 255 / 80%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const useStyles = makeStyles((_theme) => ({
  loader: {
    width: '80px!important',
    height: '80px!important',
  },
}))
