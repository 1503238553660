import styled from '@emotion/styled'
import {
  Avatar,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectProfileData,
  selectUserAvatar,
} from '../../../pages/profile/model'
import { drawerClosed, drawerOpened } from '../../application/model'
import { selectLanguageContent } from '../../translation'

export const DrawerHeader = ({
  isOpen,
}: {
  isOpen: boolean
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const userAvatar = useSelector(selectUserAvatar)
  const profileData = useSelector(selectProfileData)
  const handleDrawerClose = () => {
    dispatch(drawerClosed())
  }
  const { fullName } = profileData

  const renderControl = () => {
    if (isOpen) {
      return (
        <div className={classes.drawerWrapper}>
          <div className={classes.header}>
            <IconButton
              onClick={handleDrawerClose}
              className={classes.backButton}
            >
              <ArrowBackIcon color="primary" />
            </IconButton>

            <Typography
              variant="h2"
              color="primary"
              className={classes.title}
              align="center"
            >
              {i18n.my_account}
            </Typography>
          </div>

          <div className={classes.userWrapper}>
            <div className={classes.avatarWrapper}>
              <Avatar className={classes.ava} src={userAvatar} />
            </div>
            <Typography variant="h2" className={classes.userObj}>
              {fullName}
            </Typography>
          </div>
        </div>
      )
    }

    return (
      <Button
        className={classes.controlButton}
        color="inherit"
        aria-label="open drawer"
        onClick={() => dispatch(drawerOpened())}
      >
        <MenuIcon className={classes.icon} />
      </Button>
    )
  }

  return (
    <HeaderWrapper className={classes.header}>{renderControl()}</HeaderWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '100vw',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
    marginBottom: '32px',
    [theme.breakpoints.up(600)]: {
      display: 'none',
    },
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  locWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  userObj: {
    fontSize: 20,
    fontWeight: 600,
    color: '#282D37',
    [theme.breakpoints.up(600)]: {
      margin: '8px 24px',
    },
    // [theme.breakpoints.down(600)]: {
    //   fontWeight: 600,
    // },
  },
  avatarWrapper: {
    backgroundColor: '#F3F3F3',
    height: 72,
    width: 72,
    borderRadius: '50%',
    padding: 4,
    margin: '0px 24px 0px 24px',
  },
  ava: {
    height: 72,
    width: 72,
  },
  controlButton: {
    width: '100%',
    maxWidth: 72,
    height: 64,
    alignSelf: 'flex-end',
  },
  icon: {
    color: '#505358',
  },
  backButton: {
    position: 'absolute',
    left: '8px',
    height: 42,
    width: 42,
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  title: {
    margin: '0px auto',
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '1rem',
    },
  },
}))

const HeaderWrapper = styled.div`
  display: flex;
  padding: 0;
`
