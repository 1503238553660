import { FormikProps } from 'formik'
import moment from 'moment'
import { allowedRelationships, USAStateList } from '../../../../../../constants'
import { I18n } from '../../../../../../features/translation/types'
import { translateOptions } from '../../../../../../libs/helpers'
import { FilledDatePicker, FilledTextField } from '../../../../../../ui'
import { FilledSelect } from '../../../../../../ui/atoms/select'
import { MaskedInput } from '../../../../../../ui/atoms/text-field/masked-input'
import { InsuranceProviderCompanyData } from '../../../../../register/model/type'
import { FormAutocomplete } from '../../../../components/form-autocomplete'

const InsuranceInfoFields = ({
  i18n,
  formik,
  providerList,
  providerListLoading,
}: {
  i18n: I18n
  formik: FormikProps<any>
  providerList: InsuranceProviderCompanyData[]
  providerListLoading: boolean
}) => [
  {
    Component: FormAutocomplete,
    props: {
      options: providerList,
      label: i18n.checkout_insuranse_provider,
      name: 'insuranceProviderCompany',
      optionLabelKey: 'name',
      selectByKey: 'payorId',
      onChange: (event: any, value: InsuranceProviderCompanyData) => {
        formik.setFieldValue('insuranceProviderCompany', value)
      },
      loading: providerListLoading,
    },
  },
  {
    Component: FilledTextField,
    props: {
      label: i18n.insurance_plan_name_field_name,
      name: 'planName',
    },
  },
  {
    Component: FilledTextField,
    props: {
      label: i18n.insurance_id_field_name,
      name: 'insuranceId',
    },
  },
  {
    Component: FilledTextField,
    props: {
      label: i18n.insurance_group_number_field_name,
      name: 'groupNumber',
    },
  },
  {
    Component: FilledDatePicker,
    props: {
      label: i18n.insurance_coverage_date_field_name,
      name: 'coverageDate',
      format: 'MM-dd-yyyy',
      minDate: moment().toDate(),
      onChange: (e: any) => formik.setFieldValue('coverageDate', e),
    },
  },
]

const policyHolderFields = ({
  i18n,
  formik,
}: {
  i18n: I18n
  formik: FormikProps<any>
}) => [
  {
    Component: FilledTextField,
    props: {
      label: i18n.first_name,
      name: 'firstName',
    },
  },
  {
    Component: FilledTextField,
    props: {
      label: i18n.last_name,
      name: 'lastName',
    },
  },
  {
    Component: MaskedInput,
    props: {
      mask: '999-99-9999',
      maskChar: '',
      label: i18n.phone_number,
      name: 'phone',
      variant: 'filled',
      inputProps: {
        inputMode: 'numeric',
      },
    },
  },
  {
    Component: FilledDatePicker,
    props: {
      label: i18n.date_of_birth,
      name: 'dob',
      format: 'MM-dd-yyyy',
      maxDate: moment().subtract(18, 'years').subtract(1, 'days').toDate(),
      onChange: (e: any) => formik.setFieldValue('dob', e),
    },
  },
  {
    Component: FilledSelect,
    props: {
      label: i18n.policy_holder_relationship,
      name: 'relationship',
      options: translateOptions(allowedRelationships, i18n),
    },
  },
]

const addressInfoFields = (i18n: I18n) => [
  {
    Component: FilledTextField,
    props: {
      label: i18n.street_address,
      name: 'line1',
    },
  },
  {
    Component: FilledTextField,
    props: {
      label: i18n.policy_holder_unit,
      name: 'line2',
      optional: true,
    },
  },
  {
    Component: FilledTextField,
    props: {
      label: i18n.city,
      name: 'city',
    },
  },
  {
    Component: FilledSelect,
    props: {
      label: i18n.state,
      name: 'state',
      options: USAStateList,
    },
  },
  {
    Component: FilledSelect,
    props: {
      label: i18n.country,
      name: 'country',
      options: USAStateList,
    },
  },
  {
    Component: MaskedInput,
    props: {
      mask: '99999',
      maskChar: '',
      label: i18n.zip_code,
      name: 'zipCode',
      variant: 'filled',
      inputProps: {
        inputMode: 'numeric',
      },
    },
  },
]

export const getInsuranceSections = ({
  i18n,
  formik,
  providerList,
  providerListLoading,
}: {
  i18n: I18n
  formik: FormikProps<any>
  providerList: InsuranceProviderCompanyData[]
  providerListLoading: boolean
}) => [
  {
    title_key: 'insurance_information',
    fields: InsuranceInfoFields({
      i18n,
      formik,
      providerList,
      providerListLoading,
    }),
  },
  {
    title_key: 'policy_holder_information',
    fields: policyHolderFields({ i18n, formik }),
  },
  {
    title_key: 'address_information',
    fields: addressInfoFields(i18n),
  },
]
