import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/hc-logo-no-text.png'
import { selectLanguageContent } from '../../features/translation'
import { Button, Typography } from '../../ui'
import { selectNetworkSettings } from '../network/model'
import { paths } from '../paths'

export const SupportMessageSentPage = (props: any) => {
  const { isDialogWindow, setSupportDialogOpen } = props
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const networkSettings = useSelector(selectNetworkSettings)

  const handleContinue = () => {
    !isDialogWindow
      ? history.push(paths.app.dashboard())
      : setSupportDialogOpen(false)
  }

  return (
    <Wrapper>
      <Content>
        <img
          src={
            networkSettings?.logoRectangleDark
              ? networkSettings.logoRectangleDark
              : undefined
          }
          alt="company logo"
          style={{ width: '130px', margin: '60px 0px 60px 16px' }}
        />
        <ContentInner>
          <HeaderText align="center" variant="h2" component="h1">
            {i18n.thank_you_for_contacting}
          </HeaderText>
          <HeaderText align="center" variant="h3" component="h1">
            {i18n.will_be_in_touch}
          </HeaderText>
          {!isDialogWindow && (
            <>
              <EmailIcon className={classes.icon} color="primary" />
              <Typography className={classes.text} align="center">
                {i18n.for_more_immediate_answers}
                <LinkStyled onClick={() => history.push(paths.faqs())}>
                  {i18n.frequently_asked_questions}
                </LinkStyled>
              </Typography>
            </>
          )}
        </ContentInner>
      </Content>
      <Controls>
        <Button className={classes.btn} onClick={handleContinue}>
          {i18n.continue_button}
        </Button>
      </Controls>
    </Wrapper>
  )
}
const useStyles = makeStyles((theme) => ({
  btn: {
    'width': '100%',
    'minWidth': '540px',
    'height': 48,
    'maxHeight': 50,
    'borderRadius': 8,
    'fontWeight': 600,
    'backgroundColor': '#003C71',
    'color': '#FFF',
    'marginTop': 60,
    'marginBottom': 10,
    'border': '1px solid #003C71',

    '&:hover': {
      backgroundColor: '#FFF',
      color: '#003C71',
      border: '1px solid #003C71',
    },
    [theme.breakpoints.down(600)]: {
      minWidth: '90vw',
    },
  },
  icon: {
    fontSize: '100px',
  },
  text: {
    paddingTop: 20,
    fontSize: '21px',
    color: '#616161',
  },
}))
const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const LinkStyled = styled.div`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const HeaderText = styled(Typography)`
  padding-bottom: 32px;
`
const Wrapper = styled.div`
  flex: 1;
  display: flex;
  margin: 32px auto;
  flex-direction: column;
  max-width: 500px;
  padding: 0 16px;
`
const Content = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
`
const Controls = styled.div`
  display: grid;
  grid-gap: 16px;
  justify-content: center;
`
