import * as Yup from 'yup'
import moment from 'moment'
import { I18n } from '../../../../../../features/translation/types'
import { validateDate } from '../../../../../../libs/helpers'

const insuranceTabValidation = (i18n: I18n) =>
  Yup.object().shape({
    insuranceProviderCompany: Yup.object().nullable().required(i18n.required),
    insuranceId: Yup.string().required(i18n.required),
    groupNumber: Yup.string().required(i18n.required),
    planName: Yup.string().required(i18n.required),
    coverageDate: Yup.date()
      .nullable()
      .typeError(i18n.invalid_date)
      .min(moment().subtract(1, 'days').toDate(), i18n.invalid_date)
      .required(i18n.required),
    firstName: Yup.string().required(i18n.required),
    lastName: Yup.string().required(i18n.required),
    phone: Yup.string()
      .min(11, i18n.invalid_phone_error_message)
      .max(11, i18n.invalid_phone_error_message)
      .required(i18n.required),
    dob: Yup.date()
      .nullable()
      .transform(validateDate)
      .typeError(i18n.invalid_date)
      .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
      .max(
        moment().subtract(18, 'years').subtract(1, 'days').toDate(),
        i18n.to_young_error_message
      )
      .required(i18n.required),
    relationship: Yup.string().required(i18n.required),
    line1: Yup.string().required(i18n.required),
    line2: Yup.string(),
    city: Yup.string().required(i18n.required),
    state: Yup.string().required(i18n.required),
    country: Yup.string().required(i18n.required),
    zipCode: Yup.string()
      .min(5, i18n.invalid_zip_code_error_message)
      .max(5, i18n.invalid_zip_code_error_message)
      .required(i18n.required),
  })

export default insuranceTabValidation
