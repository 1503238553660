import { Box, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { I18n } from '../../../features/translation/types'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { Loading } from '../../../ui'
import { onboardingStyles } from '../../_styles/registrationStyles'
import {
  getHelpCenter,
  selectFAQsList,
  selectFAQsLoading,
} from '../../faq/faqSlice'
import { Faq, TenantFaqType } from '../../faq/types'

export const HelpCenter = (props: {
  tenantId: string
  handleClose?: () => void
}) => {
  const { tenantId, handleClose } = props
  const classes = onboardingStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const isLoading = useSelector(selectFAQsLoading)
  const faqsList = useSelector(selectFAQsList)
  const [selectedId, setSelectedId] = useState('')
  const [faqDetails, setFAQDetails] = useState<Faq>()

  useEffect(() => {
    const payload = {
      tenantId,
      language: 'en',
      TenantFaqType: TenantFaqType.REGISTRATION,
    }
    dispatch(getHelpCenter(payload))
  }, [])

  const getFaqDetail = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return faqsList?.find((f) => f.question === selectedId)
  }

  useEffect(() => {
    if (selectedId) {
      setFAQDetails(getFaqDetail())
    }
  }, [selectedId])

  const noFAQs = () => (
    <Typography color="primary" align="center" className={classes.noContent}>
      {i18n.no_result_found}
    </Typography>
  )

  if (selectedId) {
    return (
      <FAQDetails
        faq={faqDetails}
        close={() => setSelectedId('')}
        i18n={i18n}
      />
    )
  }

  return (
    <Box className={classes.faqList}>
      <div className={`${classes.detailHeader} list`}>
        <ArrowBackIcon
          onClick={handleClose ? () => handleClose() : undefined}
          className={`${classes.detailBackButton} list`}
        />
        <Typography variant="h2" className={`${classes.detailTitle} list`}>
          {i18n.registration_faq_title}
        </Typography>
      </div>
      {isLoading ? (
        <Box className={classes.loading}>
          <Loading />
        </Box>
      ) : (
        <div className={classes.newHelpCenter}>
          {faqsList.length > 0 ? (
            faqsList.map((faq) => (
              <div
                className={classes.listWrapper}
                onClick={() => setSelectedId(faq.question)}
              >
                <Typography variant="h6" className={classes.newHelpListTitle}>
                  {faq.question}
                </Typography>
                <ExpandMoreIcon className={classes.expandIcon} />
              </div>
            ))
          ) : (
            <>{noFAQs()}</>
          )}
        </div>
      )}
    </Box>
  )
}

const FAQDetails = (props: {
  i18n: I18n
  faq: Faq | undefined
  close: () => void
}) => {
  const { i18n, faq, close } = props
  const classes = onboardingStyles()

  return (
    <>
      <div className={classes.detailHeader}>
        <ArrowBackIcon
          color="primary"
          onClick={() => close()}
          className={classes.detailBackButton}
        />
        <Typography variant="h2" className={classes.detailTitle}>
          {i18n.registration_faq_title}
        </Typography>
      </div>
      <div className={classes.faqDetailWrapper}>
        <Typography variant="h2" className={classes.faqDetailTitle}>
          {faq?.question}
        </Typography>
        <Typography className={classes.faqDetailDescription}>
          {faq?.answer ? (
            <HTMLTextComponent HTMLString={faq.answer} />
          ) : (
            <>{i18n.no_data_loaded_error_message}</>
          )}
        </Typography>
      </div>
    </>
  )
}
