import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../../assets/logo-row.png'
import { selectNetworkSettings } from '../../../pages/network/model'
import { paths } from '../../../pages/paths'

export const Logo = () => {
  const networkSettings = useSelector(selectNetworkSettings)
  return (
    <>
      {networkSettings && (
        <Link to={paths.app.dashboard()}>
          <LogoImage
            src={
              networkSettings.logoRectangleDark
                ? networkSettings.logoRectangleDark
                : logo
            }
            alt="health check logo"
          />
        </Link>
      )}
    </>
  )
}

const LogoImage = styled.img`
  max-height: 54px;
  max-width: 180px;
  @media (max-width: 600px) {
    padding-top: 4px;
  }
`
