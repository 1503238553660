import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Unable from '../../../assets/img/dialogs/unable-to-verify.png'
import { paths } from '../../../pages/paths'
import {
  getEligibilityAttempt,
  resetRegisterForm,
  selectEligibilityErrorMessage,
  setEligibilityTry,
} from '../../../pages/register/model/registerSlice'
import { AppDialogWrapper } from '../dialog-wrapper'
import { SimpleDialogContent } from '../helpers/simple-dialog-content'
import { resetDialogStore } from '../model'
import { DialogProps } from '../types'

export const UnableToVerifyUserDialog = (props: DialogProps) => {
  const { i18n } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogDesc, setDialogDesc] = useState('')

  const cancelEligibility = () => {
    dispatch(resetRegisterForm())
    localStorage.removeItem('tenantId')
    history.push(paths.login())
  }

  const tryAgain = () => {
    dispatch(resetDialogStore())
  }

  const message = useSelector(selectEligibilityErrorMessage)
  const eligibilityAttempt = useSelector(getEligibilityAttempt)

  useEffect(() => {
    getErrorText()
  }, [message])

  const getErrorText = () => {
    switch (message) {
      case 'User Already Enrolled':
      case 'Internal error: User Already Enrolled': {
        setDialogTitle(i18n.user_already_enrolled_title)
        break
      }
      case 'User access declined':
      case 'Internal error: User access declined': {
        dispatch(setEligibilityTry(6))
        setDialogTitle(i18n.eligibility_declined)
        break
      }
      case 'Unable to retrieve token':
      case 'User/Token Not Found':
      case 'Internal error: User/Token Not Found':
      default: {
        setDialogTitle(i18n.unable_to_verify_account)
        setDialogDesc(i18n.unable_to_verify_account_instruction)
        break
      }
    }
  }

  return (
    <AppDialogWrapper disableClickAway>
      <SimpleDialogContent
        i18n={i18n}
        title={dialogTitle}
        body={dialogDesc}
        confirmationLabel={i18n.try_again}
        onConfirm={eligibilityAttempt === 6 ? undefined : tryAgain}
        onCancel={cancelEligibility}
        image={Unable}
      />
    </AppDialogWrapper>
  )
}
