import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import Alert from '../../../assets/icons/alert-red.svg'
import TimeExpired from '../../../assets/img/treatment-plan/time_expired.svg'
import { ConsultStatus } from '../../health-service-lite/types'
import { I18n } from '../../translation/types'
import { Graphic, OrderStatusStep, Variant } from '../types'
import { Images } from './statusHelper'

export const statusCards = (
  i18n: I18n,
  classes: ClassNameMap,
  actions: any,
  loadingTest: boolean
) =>
  [
    {
      view: ConsultStatus.safe_created,
      type: 'SIMPLE',
      header: i18n.unassigned_video_status_card_title,
      body: i18n.unassigned_video_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).UpcomingImage,
      mobileButtonFlex: 'column',
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
        {
          title: i18n.telehealth_return_to_waiting_room_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'contained' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.safe_dequeued,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: '',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).UpcomingImage,
      actions: [
        {
          title: i18n.telehealth_return_to_waiting_room_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.steadyMD_in_review,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: '',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConnectingImage,
      actions: [],
    },
    {
      view: ConsultStatus.IN_CALL,
      type: 'SIMPLE',
      header: i18n.in_call_status_card_title,
      body: i18n.in_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConnectingImage,
      actions: [],
    },
    {
      view: ConsultStatus.CLOSED,
      type: 'SIMPLE',
      header: i18n.in_call_status_card_title,
      body: i18n.in_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConnectingImage,
      actions: [],
    },
    {
      view: ConsultStatus.CANCELLED,
      type: 'SIMPLE',
      header: i18n.telehealth_session_cancelled_card_title,
      body: i18n.telehealth_session_cancelled_card_message,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).CancelledImage,
      actions: [],
    },
    {
      view: ConsultStatus.MISSED_CALL,
      type: 'SIMPLE',
      header: i18n.missed_call_status_card_title,
      body: '',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConsultMissedImage,
      actions: [
        {
          title: i18n.telehealth_begin_session_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.safe_treatment_ready,
      type: 'SIMPLE',
      header: i18n.treatment_ready_status_card_title,
      body: i18n.treatment_ready_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).PlanReadyImage,
      actions: [
        {
          title: i18n.treatment_ready_status_card_action,
          function: () => actions.gotoTreatmentPlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.NOT_ASSIGNED,
      type: 'SIMPLE',
      header: i18n.unassigned_phone_status_card_title,
      body: i18n.unassigned_phone_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConsultationImage,
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.consult_submitted,
      type: 'SIMPLE',
      header: i18n.unassigned_phone_status_card_title,
      body: i18n.unassigned_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConsultationImage,
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.consult_scheduled,
      type: 'SIMPLE',
      header: 'Telehealth Consult Scheduled',
      body: 'You can cancel your appointment using the link below.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVConsultScheduledImage,
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.in_progress,
      type: 'SIMPLE',
      headerIcon: Alert,
      header: i18n.default_status_card_title,
      body: i18n.default_status_card_text,
      graphic: 'TIMER' as Graphic,
      bgColor: '#22A2FF',
      image: () => null,
      mobileButtonFlex: 'row',
      actions: [
        {
          title: i18n.cancel_button,
          function: () => actions.endHealthService(),
          variant: 'outlined' as Variant,
          smallButton: true,
          disabled: false,
        },
        {
          title: i18n.continue_button,
          function: () => actions.continueService(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: loadingTest,
        },
      ],
    },
    {
      view: ConsultStatus.created,
      type: 'SIMPLE',
      header: 'Connecting with your care team',
      body: 'Hang tight! The care team is reviewing your information and will call you soon.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConnectingImage,
      actions: [],
    },
    {
      view: ConsultStatus.fv_call_missed,
      type: 'SIMPLE',
      header: 'Oops! Looks like you missed your provider’s phone calls',
      body: 'A care plan cannot be provided without consulting with a provider. If you still wish to receive a telehealth consult, please press below to continue your request',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVCallMissedImage,
      actions: [
        {
          title: i18n.continue_telehealth_request,
          function: () => actions.continueService(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: loadingTest,
        },
      ],
    },
    {
      view: ConsultStatus.call_missed,
      type: 'SIMPLE',
      header: 'Oops! Looks like you missed your provider’s phone calls',
      body: 'A treatment plan cannot be provided without consulting with a provider. If you still feel unwell, you can start a new consultation.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).CallMissedImage,
      actions: [],
    },
    {
      view: ConsultStatus.no_treatment_required,
      type: 'SIMPLE',
      header: 'Your provider has determined that you don’t require treatment',
      body: 'If your symptoms worsen, let us know',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).NoTreatmentImage,
      actions: [],
    },
    {
      view: ConsultStatus.cancelled,
      type: 'SIMPLE',
      header: 'Your prescription was cancelled',
      body: 'This medication is unavailable in your area. Please contact your Primary Care Provider.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).CancelledImage,
      actions: [],
    },
    {
      view: ConsultStatus.treatment_rx_prescribed,
      type: 'SIMPLE',
      header: 'Your care plan is ready to review',
      body: "Click to see your provider's recommendations.",
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVCarePlanImage,
      actions: [
        {
          title: 'Review Care Plan',
          function: () => actions.reviewCarePlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.alt_pharm_loc,
      type: 'SIMPLE',
      header: 'Medication was sent to a different pharmacy',
      body: 'View new pharmacy information in your care plan.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).AltPharmLocImage,
      actions: [
        {
          title: 'Review Care Plan',
          function: () => actions.reviewCarePlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    // {
    //   view: ConsultStatus.completed,
    //   type: 'SIMPLE',
    //   header: 'Your prescription details will be avavilable soon...',
    //   body: "This may take a few minutes - you're almost done!",
    //   graphic: 'IMAGE' as Graphic,
    //   image: Images(classes).AvailableSoonImage,
    //   actions: [],
    // },
    {
      view: ConsultStatus.fv_completed,
      type: 'SIMPLE',
      header: 'Your prescription details will be avavilable soon...',
      body: "This may take a few minutes - you're almost done!",
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVAvailableSoonImage,
      actions: [],
    },
    {
      view: ConsultStatus.for_pickup,
      header: 'Prescription ready! Pick up at:',
      body: 'Walgreens 5504 Balboa Ave, San Diego, CA',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).PickupReadyImage,
      bgColor: '#01C7D7',
      actions: [
        {
          title: 'View Pharmacy Location',
          function: () => actions.openMap(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.for_delivery,
      type: 'PROGRESS',
      header: 'A delivery request has been created!',
      body: 'Your prescription is being assigned to a delivery service.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).DeliveryImage,
      actions: [],
    },
    {
      view: ConsultStatus.healthservice_expired,
      type: 'SIMPLE',
      headerIcon: TimeExpired,
      header: i18n.expired_status_card_title,
      body: i18n.expired_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ExpiredImage,
      actions: [],
    },
  ] as OrderStatusStep[]
