import { paths } from '../../pages/paths'

export interface Feature {
  path: (any?: any) => any
}

export const FEATURES: { [key: string]: Feature } = {
  SymptomCheckerFeature: {
    path: (inputs) => paths.symptomCheckerIntroById(inputs['Questionnaire Id']),
  },
  GetTestedFeature: {
    path: (inputs) => paths.registerTest(),
  },
  OffRampFeature: {
    path: (inputs) => paths.app.dashboard(),
  },
  TelehealthFeature: {
    path: (inputs) => paths.telehealthIntake('confirm-location'),
  },
  ShoppingFeature: {
    path: (inputs) => paths.shopifyStore(inputs['Collection Type']),
  },
}
