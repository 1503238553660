/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Dialog, Link as MUILink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../../../../features/cookies/cookie-helper'
import { selectLanguageContent } from '../../../../../features/translation'
import {
  addMFAPreference,
  forgetMFADevice,
  selectDevice,
  selectUserLoading,
} from '../../../../../features/user/model/userTenantSlice'
import { MFAOptions } from '../../../../../features/user/types/mfa'
import { lsClient } from '../../../../../ls-client'
import { Button, RoundedCheckbox, Typography } from '../../../../../ui'
import { selectNetworkSettings } from '../../../../network/model'
import { paths } from '../../../../paths'
import { selectProfileData } from '../../../../profile/model'
import { TermsAndConditionsPage } from '../../../../terms-and-conds/signupPage'
import { SecuritySettingsType, SettingsTabType } from '../../../type'
import { addLinkWithinString } from '../../../../../features/details-helper/fields'

export const MFA = () => {
  const classes = useStyles()
  const userOption = lsClient.getUserLSByKey('mfa')
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies()
  const profile = useSelector(selectProfileData)
  const loading = useSelector(selectUserLoading)
  const hasRememberedDevice = userHasDeviceCookie(profile.email)
  const currentNetwork = useSelector(selectNetworkSettings)
  const [termsOpen, setTermsOpen] = useState(false)

  const [selected, setSelected] = useState(
    lsClient.getUserLSByKey('mfa') || MFAOptions.undefined
  )

  const addSMSPreference = () => {
    dispatch(
      addMFAPreference(
        '0',
        backToSettings,
        hasRememberedDevice ? forgetDevice : undefined
      )
    )
  }

  const backToSettings = () =>
    history.push(
      paths.settingsTab(SettingsTabType.SECURITY, SecuritySettingsType.MFA)
    )

  const forgetDevice = () => {
    const callback = () => {
      removeCookie(`${btoa(profile.email)}_safe_device`, { path: '/' })
      backToSettings()
    }
    const accessToken = localStorage.getItem('access_token')
    const deviceParams = getSavedDeviceValues(profile.email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: profile.email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  const handleNext = () => {
    if (selected === MFAOptions.SMS) {
      addSMSPreference()
    } else
      history.push(
        paths.settingsTabOption(
          SettingsTabType.SECURITY,
          SecuritySettingsType.MFA,
          selected
        )
      )
  }

  return (
    <div className={classes.mfaWrapper}>
      <Typography>{i18n.mfa_message}</Typography>
      {currentNetwork?.mfaSettings?.enableGoogleAuth && (
        <div
          className={classes.mfAOption}
          onClick={() => setSelected(MFAOptions.TOTP)}
        >
          <Typography style={{ fontWeight: 600 }}>
            {i18n.use_authenticator_application}
          </Typography>
          <RoundedCheckbox
            checked={selected === MFAOptions.TOTP}
            color="primary"
            onChange={() => setSelected(MFAOptions.TOTP)}
          />
        </div>
      )}
      {currentNetwork?.mfaSettings?.enableSMSVerification && (
        <div
          className={classes.mfAOption}
          onClick={() => setSelected(MFAOptions.SMS)}
        >
          <Typography style={{ fontWeight: 600 }}>
            {i18n.send_a_text}
          </Typography>
          <RoundedCheckbox
            checked={selected === MFAOptions.SMS}
            color="primary"
            onChange={() => setSelected(MFAOptions.SMS)}
          />
        </div>
      )}
      {selected === MFAOptions.SMS && selected !== userOption && (
        <>
          {addLinkWithinString(
            i18n.message_data_rates_warning.replaceAll('\\n', '\n'),
            i18n.terms_and_conditions_link,
            () => setTermsOpen(true),
            classes.dataRates,
            classes.termsLink,
            2
          )}
        </>
      )}
      <Button
        onClick={handleNext}
        className={classes.confirmButton}
        disabled={selected === MFAOptions.undefined || selected === userOption}
      >
        {i18n.save_button}
      </Button>
      <Dialog open={termsOpen} fullScreen>
        <div className={classes.dialogContainer}>
          <TermsAndConditionsPage onClose={() => setTermsOpen(false)} />
        </div>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((_theme) => ({
  mfaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 26,
  },
  mfAOption: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'padding': '4px 4px 4px 16px',
    'borderRadius': '16px',
    'width': '100%',
    'boxSizing': 'border-box',
    'boxShadow': '0 2px 9px 0 rgba(0,0,0,.23)',
    'backgroundColor': '#fff',
    '& span:nth-child(2)': {
      fontWeight: 600,
    },
  },
  confirmButton: {
    maxWidth: 'fit-content',
    alignSelf: 'flex-end',
  },
  dataRates: {
    fontSize: 12,
  },
  termsLink: {
    fontSize: 12,
    cursor: 'pointer',
    fontWeight: 600,
  },
  dialogContainer: {
    height: '85vh',
    margin: '32px 0px',
  },
}))
