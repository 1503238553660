import { Box } from '@material-ui/core'
import React, { ReactChild } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { SupportDialog } from '../../../contact-support/supportDialog'
import {
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../../../network/model'

interface InvitationPageWrapperProps {
  children: ReactChild
  supportDialogOpen: boolean
  setSupportDialogOpen: (param: boolean) => void
}
export const InvitationPageWrapper = (props: InvitationPageWrapperProps) => {
  const { children, supportDialogOpen, setSupportDialogOpen } = props
  const classes = onboardingStyles()
  const isLoading = useSelector(selectNetworkSettingsLoading)
  const networkSettings = useSelector(selectNetworkSettings)
  const tenantId = sessionStorage.getItem('tenantId')

  if (isLoading || (tenantId && !networkSettings)) return <Loading />

  const getImage = () => {
    if (networkSettings?.loginPopupImage) {
      return networkSettings.loginPopupImage
    }
    if (networkSettings?.welcomeScreenImage) {
      return networkSettings.welcomeScreenImage
    }
    return undefined
  }

  return (
    <>
      <Box className={classes.welcomeWrapper}>
        <Box className={classes.welcomeContentWrapper} style={{ flex: 1 }}>
          <Box className={classes.welcomeContent}>
            <img
              src={getImage()}
              alt={
                networkSettings?.name
                  ? `${networkSettings?.name} logo`
                  : 'Company logo'
              }
              style={{
                maxHeight: '130px',
                maxWidth: '65vw',
                margin: '52px 0px 70px 0px',
              }}
            />
            {children}
          </Box>
        </Box>
      </Box>
      {/* <SupportDialog
        open={supportDialogOpen}
        setSupportDialogOpen={setSupportDialogOpen}
      /> */}
    </>
  )
}
