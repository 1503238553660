import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../translation'
import { dialogs } from './helpers/dialog-mapper'
import { selectDialogType } from './model'

export const AppDialog = () => {
  const i18n = useSelector(selectLanguageContent)
  const type = useSelector(selectDialogType)

  const dialog = _.find(dialogs(i18n), { type })
  if (!dialog) return null
  return dialog.content
}
