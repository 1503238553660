import { createSlice } from '@reduxjs/toolkit'
import { DialogOptions, DialogType } from './types'

interface DialogSlice {
  type: DialogType | undefined
  options?: DialogOptions
}

const initialStore: DialogSlice = {
  type: undefined,
  options: {},
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialStore,
  reducers: {
    setDialog(
      store,
      { payload }: { payload: { type: DialogType; options?: DialogOptions } }
    ) {
      store.type = payload.type
      store.options = payload.options
    },
    resetDialogStore: () => initialStore,
  },
})

export const { resetDialogStore, setDialog } = dialogSlice.actions

export const selectDialogType = ({ dialog }: RootStore) => dialog.type
export const selectDialogOptions = ({ dialog }: RootStore) => dialog.options

export const dialogSliceReducer = dialogSlice.reducer

export const dialogSliceReducerName = dialogSlice.name

interface RootStore {
  [dialogSliceReducerName]: typeof initialStore
}
