import React from 'react'
// import { TextField, TextFieldProps } from '@material-ui/core'
import { FormikProps } from 'formik'
import {FilledTextField } from '../../../../ui'
import { TextFieldProps } from '../../../../ui/atoms/text-field/text-field'
export const FormField = (
  props: TextFieldProps & {
    name: string
    formik: FormikProps<any>
  }
) => {
  const { formik, name } = props
  return (
    <FilledTextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] ? formik.errors[name]?.toString() : ''}
      {...props}
    />
  )
}
