import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ImageQuality from '../../../assets/img/dialogs/image-poor-quality.png'
import { paths } from '../../../pages/paths'
import { AppDialogWrapper } from '../dialog-wrapper'
import { SimpleDialogContent } from '../helpers/simple-dialog-content'
import { resetDialogStore, selectDialogOptions } from '../model'
import { DialogProps } from '../types'

export const TestErrorDialog = (props: DialogProps) => {
  const options = useSelector(selectDialogOptions)
  const history = useHistory()
  const dispatch = useDispatch()
  const onCancel = () => {
    history.push(paths.app.dashboard())
  }

  const tryAgain = () => {
    history.push(paths.instructions())
    dispatch(resetDialogStore())
  }
  return (
    <AppDialogWrapper disableClickAway>
      <SimpleDialogContent
        {...props}
        {...options}
        image={ImageQuality}
        onCancel={onCancel}
        onConfirm={tryAgain}
      />
    </AppDialogWrapper>
  )
}
