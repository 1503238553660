import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import MUISelect from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

type Color = 'primary' | 'secondary'
interface SelectProps {
  onChange?: (e: React.ChangeEvent<{ name?: string; value: any }>) => void
  value?: string
  label?: string
  options?: { label: string; value: string }[]
  required?: boolean
  error?: string
  onBlur?: (e: React.FocusEvent) => void
  readOnly?: boolean
  disabled?: boolean
  name?: string
  color?: Color
}
export const Select: React.FC<SelectProps> = ({
  onChange,
  value,
  label,
  options = [],
  required = false,
  error,
  onBlur,
  readOnly,
  disabled,
  name,
  color = 'primary',
}) => {
  const classes = useStyles({ color })
  return (
    <FormControl
      required={required}
      error={Boolean(error)}
      aria-readonly={readOnly}
    >
      <InputLabel className={classes.label}>{label}</InputLabel>
      <MUISelect
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        className={classes.select}
        onBlur={onBlur}
        error={Boolean(error)}
        readOnly={readOnly}
        IconComponent={readOnly ? 'div' : undefined}
        disabled={disabled}
      >
        {options.map((gen) => (
          <MenuItem value={gen.value} key={gen.value}>
            {gen.label}
          </MenuItem>
        ))}
      </MUISelect>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: ({ color }: { color: Color }) =>
      color === 'secondary' ? theme.palette.secondary.main : '#798dad',
  },
  select: {
    'marginBottom': '25px',
    '&.MuiInput-underline:before': {
      borderColor: 'rgb(121, 141, 173)',
    },
    '& .MuiInputBase-input': {
      color: ({ color }: { color: Color }) =>
        color === 'secondary' ? theme.palette.secondary.main : '#505358',
    },
  },
}))
