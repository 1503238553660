import { Box, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useSelector } from 'react-redux'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { Timer } from '../../../ui/molecules/timer/timer'
import { testInstructionStyles } from '../../_styles/testInstructionStyle'
import { selectNetworkSettings } from '../../network/model'
import { TimerProps } from '../types'

interface TimerOnlyProps extends TimerProps {
  remainingTime: number
}

export const TimerOnly = (props: TimerOnlyProps) => {
  const { current, timerActive, timerValue, remainingTime, timerContinue } =
    props
  const classes = testInstructionStyles()

  const settings = useSelector(selectNetworkSettings)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))
  const timerStartColor = settings?.primaryColor || '#004777'
  const children = ({ remainingTime }: any) => {
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    let spacer = ''
    if (seconds < 10) {
      spacer = '0'
    }
    return (
      <>
        <Typography
          className={classes.circularTime}
          style={{ color: timerStartColor }}
        >
          {`${minutes}:${spacer}${seconds}`}
        </Typography>
      </>
    )
  }

  if (!(current && timerActive && timerValue && remainingTime)) return null

  return (
    <Box>
      <Box
        className={`${classes.instructionContainer} reduced`}
        id="instructionContainer"
      >
        <Box className={classes.assetBox} id="instructions">
          <Typography className={classes.title}>{current?.title}</Typography>
          <Typography className={classes.body}>
            <HTMLTextComponent HTMLString={current?.body} />
          </Typography>
        </Box>
        {current?.imageURL && (
          <img
            className={classes.img}
            src={current?.imageURL}
            alt="test-instructions"
          />
        )}
        {current?.videoURL && (
          <Box className={classes.videoContainer}>
            <video src={current.videoURL} controls className={classes.video} />
          </Box>
        )}
        <Box className={classes.timerBox}>
          {/* <Timer
            isPlaying={true}
            color={settings?.primaryColor || '#004777'}
            // timeLimit={timerValue}
           /> */}
          <CountdownCircleTimer
            isPlaying={timerActive}
            strokeWidth={14}
            trailStrokeWidth={14}
            size={225}
            strokeLinecap="round"
            rotation="counterclockwise"
            onComplete={() => {
              timerContinue(current?.autoStartTimer)
              return [false, 0]
            }}
            duration={timerValue}
            initialRemainingTime={remainingTime}
            colors={[[`${timerStartColor}`, 1]]}
          >
            {children}
          </CountdownCircleTimer>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    minHeight: '200px',
  },
  videoContainer: {
    marginTop: '20px',
    width: '100%',
  },
  timer: {
    fontSize: 88,
    [theme.breakpoints.down(600)]: {
      fontSize: 48,
    },
  },
  img: {
    maxWidth: '375px',
    [theme.breakpoints.down(320)]: {
      width: '90%',
    },
  },
  linearBox: {
    maxHeight: '60px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  timerBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down(600)]: {
      paddingBottom: 60,
    },
  },
  assetBox: {
    'margin': '16px',
    'width': '100%',
    '& span': {
      textAlign: 'left',
    },
    [theme.breakpoints.down(576)]: {
      'width': '90%',
      'margin': 0,
      '& span': {
        fontSize: '20px !important',
      },
    },
  },
  instructionContainer: {
    'overflowX': 'hidden',
    'margin': '0px auto',
    'width': '504px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'space-evenly',
    [theme.breakpoints.down(576)]: {
      width: '90%',
      justifyContent: 'flex-start',
      height: 'calc(100vh - 275px)',
      margin: '10px auto',
    },
    '&.reduced': {
      justifyContent: 'flex-start',
    },
  },
}))
