/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Link as MUILink, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import AgoraRTC from 'agora-rtc-sdk-ng'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import { setDialog } from '../../features/dialog/model'
import { DialogType } from '../../features/dialog/types'
import { selectLanguageContent } from '../../features/translation'
import { handleError } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { ProctorStatus } from '../../types/ls'
import { InstructionV2 } from '../../types/tests'
import { Button, Loading } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { SupportDialog } from '../contact-support/supportDialog'
import {
  getNetworkSettings,
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../network/model'
import { paths } from '../paths'
import { AudioRequired } from '../proctor/dialogWindows/audioRequiredWarning'
import { MicrophoneRequiredDialog } from '../proctor/dialogWindows/micRequiredDialog'
import {
  createProctorSession,
  getLastSession,
  selectAudioState,
  selectProctorSessionData,
  selectProctorSessionLoading,
  selectProctorStatus,
  selectSessionStatus,
  setForceShowControls,
  setProctorStatus,
  setSessionStartTime,
  trackProctorSession,
} from '../proctor/model'
import { ProctorVideoCall } from '../proctor/proctorVideoCall'
import {
  selectCollectionInstructionsV2,
  selectQR2DKitId,
  selectTestCanSkipInstructions,
  selectTestLoading,
  setActive,
  setCollectionsForContinue,
} from '../register-a-test-module/model'
import { CollectionInstructionsOnly } from './collectionInstructionTypeViews/collectionInstructionOnly'
import { ProctorInstruction } from './collectionInstructionTypeViews/proctorInstruction'
import { ProctorWaitingRoom } from './collectionInstructionTypeViews/proctorWaitingRoomInstruction'
import { Scanner } from './collectionInstructionTypeViews/scanner'
import { TimerOnly } from './collectionInstructionTypeViews/timerOnly'
import { TimerWithInstructions } from './collectionInstructionTypeViews/timerWithInstructions'
import { CancelTestDialog } from './instructionComponents/cancelTestDialog'
import { SessionExpiredDialog } from './instructionComponents/sessionExpired'

const initialCurrentState: InstructionV2 = {
  autoContinue: false,
  autoStartTimer: false,
  body: '',
  buttonTitle: '',
  canContinue: false,
  imageURL: '',
  isFullscreenVideo: false,
  localNotificationMessage: '',
  localNotificationTitle: '',
  navigationTitle: '',
  resumeInstructionHere: false,
  sequenceOrder: 0,
  showBackButton: false,
  subtractTime: false,
  supportButtonTitle: '',
  timeInSeconds: 0,
  title: '',
  tooltips: {},
  type: 'unset',
  videoThumbnailUrl: '',
  videoURL: '',
}

const WAIT_TIMER_START = 'wait_timer_start'
const SCAN_TIMER_START = 'scan_timer_start'
const STEP = 'step'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const InstructionsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const testQRId =
    useSelector(selectQR2DKitId) || lsClient.getUserLSByKey('kitId') || ''
  const testLoading = useSelector(selectTestLoading)
  const settingsLoading = useSelector(selectNetworkSettingsLoading)
  const proctorSessionLoading = useSelector(selectProctorSessionLoading)
  const session = useSelector(selectProctorSessionData)
  const settings = useSelector(selectNetworkSettings)
  const [progress, setProgress] = useState(0)
  const [current, setCurrent] = useState(initialCurrentState)
  const [step, setStep] = useState(0)
  const [timerValue, setTimerValue] = useState(-1)
  const [remainingTime, setRemainingTime] = useState(-1)
  const [cameraActive, setCameraActive] = useState(false)
  const [timerActive, setTimerActive] = useState(false)
  const [cancelTestDialogOpen, setCancelTestDialogOpen] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)
  const [overFlowIndicator, setOverFlowIndicator] = useState(false)
  const [inCall, setInCall] = useState(false)
  const proctorStatus = useSelector(selectProctorStatus)
  const i18n = useSelector(selectLanguageContent)
  const testCanSkipInstructions = useSelector(selectTestCanSkipInstructions)
  const instructionsV2 = useSelector(selectCollectionInstructionsV2)
  const [instructions, setInstructions] = useState(instructionsV2)
  const isAudioOn = useSelector(selectAudioState)
  const sessionStatus = useSelector(selectSessionStatus)
  const [audioWarning, setAudioWarning] = useState(false)
  const [devicePermissionDialog, setDevicePermissionDialog] = useState(false)
  const [canSkipTo, setCanSkipTo] = useState<number | undefined>(undefined)
  const matches = useMediaQuery(theme.breakpoints.down(600))
  const [isNativeDevice, setIsNativeDevice] = useState(false)

  useEffect(() => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsNativeDevice(true)
    }
  }, [])

  const collectionViews = {
    CollectionInstructionOnly: () => (
      <CollectionInstructionsOnly current={current} />
    ),
    ProctorInstruction: () => <ProctorInstruction current={current} />,
    TimerOnly: () => (
      <TimerOnly
        current={current}
        timerActive={timerActive}
        timerValue={timerValue}
        remainingTime={remainingTime}
        timerContinue={timerContinue}
      />
    ),
    TimerWithCollectionInstruction: () => (
      <TimerWithInstructions
        current={current}
        timerActive={timerActive}
        timerValue={timerValue}
        remainingTime={remainingTime}
        timerContinue={timerContinue}
        matches={matches}
      />
    ),
    ProctorWaitingRoomInstruction: () => (
      <ProctorWaitingRoom
        current={current}
        timerActive={timerActive}
        timerValue={timerValue}
        timerContinue={joinTimeExpired}
      />
    ),
    Scanner: () => (
      <Scanner
        timerValue={timerValue}
        timerActive={timerActive}
        setCameraActive={setCameraActive}
        setSessionExpired={setSessionExpired}
        setSupportDialogOpen={setSupportDialogOpen}
        current={current}
        matches={matches}
      />
    ),
    unset: () => <></>,
  }

  useEffect(() => {
    if (
      settings?.allowSkipableCollectionInstructions &&
      !settings.enableProctorFeature &&
      testCanSkipInstructions
    ) {
      const skipStep = instructionsV2.findIndex((i) => i.resumeInstructionHere)
      if (skipStep > 0) setCanSkipTo(skipStep)
    }
  }, [settings])

  /**
   * Check if the Instructions element overflows its container for displaying the 'more' icon
   * as well as reset the scroll to the top of the Instructions container when changing collection steps
   * @returns boolean
   */
  const checkOverflow = () => {
    const element = document.querySelector('#instructions')
    const parent = document.querySelector('#instructionContainer')
    parent ? (parent.scrollTop = 0) : null
    const elemHeight = element?.getBoundingClientRect().height || 0
    const parentHeight =
      element?.parentElement?.getBoundingClientRect().height || 0
    return elemHeight > parentHeight * 0.5
  }

  useEffect(() => {
    if (settings && !settings?.enableProctorFeature) {
      setInstructions(
        instructionsV2.filter(
          (c: any) =>
            !(
              c.type === 'ProctorInstruction' ||
              c.type === 'ProctorWaitingRoomInstruction'
            )
        )
      )
    } else setInstructions(instructionsV2)
  }, [settings, instructionsV2])

  useEffect(() => {
    if (instructions) {
      if (!instructions.length) return
      setCurrent(instructions[step])
      setProgress(((step + 1) / instructions.length) * 100)
    }
  }, [instructions])

  const history = useHistory()

  //Pick up where Member left off if closed window or Nav away
  useEffect(() => {
    if (!settings) {
      dispatch(getNetworkSettings())
    }

    //reset/reload Procotor session if needed
    const isProctorSession = lsClient.getUserLSByKey('proctor') as ProctorStatus
    if (isProctorSession) {
      dispatch(setProctorStatus(isProctorSession))
      dispatch(getLastSession())
    }

    const continueFrom = lsClient.getUserLSByKey(STEP)
    const testId = lsClient.getUserLSByKey('testId')
    const testType = lsClient.getUserLSByKey('testType')

    if (continueFrom && typeof Number(continueFrom) === 'number' && testId) {
      //Refresh Collection Instructions
      ;(async function () {
        let instructionsReload
        if (testType === 'orderableTest') {
          instructionsReload = await api.getLabTestForContinue(testId, 'en')
        } else {
          instructionsReload = await api.getLabTestPanel(testId, 'en')
        }
        if (instructionsReload && !instructionsReload.error) {
          dispatch(
            setCollectionsForContinue(
              testType === 'orderableTest'
                ? instructionsReload
                : instructionsReload.result
            )
          )
        } else if (instructionsReload?.error) {
          // try to get translated error by key from localization file or render received error string
          dispatch(
            handleError(
              null,
              i18n[instructionsReload.error] || instructionsReload.error
            )
          )
        }
      })()
      setStep(Number(continueFrom))
      if (instructions?.length > 0) {
        setCurrent(instructions[continueFrom])
      }
    } else if (!continueFrom && testId) {
      history.push(paths.productAssetDetails())
    }
  }, [])

  useEffect(() => {
    //Check if instructions element overflows it container
    setOverFlowIndicator(checkOverflow())
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (current?.type) {
      collectionInstruction(current?.type)
    }
  }, [current])

  const timerContinue = (auto = false) => {
    if (!auto || current?.type === 'TimerOnly') {
      if (
        !lsClient.getUserLSByKey(SCAN_TIMER_START) &&
        step + 1 === instructions.length - 1
      ) {
        lsClient.setUserLS(SCAN_TIMER_START, moment(new Date()).toString())
      }

      setTimerActive(false)
      lsClient.removeUserKeyLS(WAIT_TIMER_START)
      advanceStep()
    } else if (auto) {
      setSessionExpired(true)
    }
  }

  const requestPermissions = () => {
    AgoraRTC.getDevices()
      .then((devices) => {
        const onSuccess = () => {
          advanceStep()
        }
        requestProctorSession(onSuccess)
      })
      .catch((e) => {
        setDevicePermissionDialog(true)
      })
  }

  const requestProctorSession = (onSuccess?: () => void) => {
    const testId = lsClient.getUserLSByKey('testId')
    const data = {
      orderableTestId: testId,
      patientTestId: testQRId,
      allowSMS: true,
    }
    dispatch(createProctorSession(data, onSuccess))
  }

  const joinProctorCall = () => {
    if (!isAudioOn) {
      dispatch(setForceShowControls(true))
      setAudioWarning(true)
    } else {
      lsClient.removeUserKeyLS('session')
      lsClient.setUserLS('proctor', 'Active')
      dispatch(setProctorStatus('Active'))
      advanceStep()
    }
  }

  //Proctoring - Member does not join in the alloted window
  const joinTimeExpired = () => {
    setTimerActive(false)
    setCurrent(instructions[step - 1])
    setProgress(((step - 1) / instructions.length) * 100)
    setStep(step - 1)
    dispatch(setSessionStartTime(''))
    lsClient.setUserLS(STEP, (step - 1).toString())
    dispatch(setProctorStatus('FailToJoin'))
  }

  const advanceStep = () => {
    if (
      current?.type === 'TimerWithCollectionInstruction' ||
      step === instructions.length - 1
    ) {
      if (current.timeInSeconds > 0) {
        handleScanTimer()
      }
      setCameraActive(true)
    } else if (
      current?.type === 'ProctorInstruction' &&
      !lsClient.getUserLSByKey('proctor')
    ) {
      requestPermissions()
    } else if (
      current?.type === 'ProctorWaitingRoomInstruction' &&
      proctorStatus === 'ProctorJoined' &&
      lsClient.getUserLSByKey('session')
    ) {
      const data = {
        sessionId: session.channelId,
        eventId: 'M_SS',
      }
      dispatch(trackProctorSession(data))
      joinProctorCall()
    } else {
      setCurrent(instructions[step + 1])
      setProgress(((step + 1) / instructions.length) * 100)
      setStep(step + 1)
      lsClient.setUserLS(STEP, (step + 1).toString())
    }
  }

  const handleScanTimer = () => {
    const scanTimerStart = lsClient.getUserLSByKey(SCAN_TIMER_START)
    const waitTimerStart = lsClient.getUserLSByKey(WAIT_TIMER_START)
    //Member happy path
    if (!scanTimerStart && !waitTimerStart) {
      lsClient.setUserLS(SCAN_TIMER_START, moment(new Date()).toString())
      setTimerValue(current.timeInSeconds)
      setRemainingTime(current.timeInSeconds)
      setTimerActive(true)
    }

    //Member has reloaded / refreshed within the Scan Step (there is a value in Local Storage for SCAN_TIMER_START) - calculate the offset timer value
    else if (scanTimerStart && !waitTimerStart) {
      const elapsedScanTime =
        Math.round(
          moment.duration(moment(new Date()).diff(scanTimerStart)).asSeconds()
        ) || 0
      setTimerValue(current.timeInSeconds)
      setRemainingTime(current.timeInSeconds - elapsedScanTime)
      setTimerActive(true)
    }

    //Member has been away long enough that the Wait Timer has expired and they are now into the Scan Timer window
    else {
      //Calculate true Scan Timer Start and set
      const waitTimeOverflow =
        Math.round(
          moment.duration(moment(new Date()).diff(waitTimerStart)).asSeconds()
        ) || 0
      const actualStartTime = moment(new Date()).subtract(
        waitTimeOverflow,
        'seconds'
      )
      lsClient.setUserLS(SCAN_TIMER_START, actualStartTime.toString())
      lsClient.removeUserKeyLS(WAIT_TIMER_START)

      //Member has been away long enough for the Final Scan timer to expire
      if (current.timeInSeconds - waitTimeOverflow < 1) {
        setSessionExpired(true)
      }

      //Member has been away long enough for the Wait Timer to expire, but time would remain on the final Scan Timer
      else {
        setTimerValue(current.timeInSeconds)
        setRemainingTime(current.timeInSeconds - waitTimeOverflow)
        setTimerActive(true)
      }
    }
  }

  const collectionInstruction = (type: string) => {
    switch (type) {
      case 'CollectionInstructionOnly': {
        lsClient.removeUserKeyLS(WAIT_TIMER_START)
        if (step === instructions.length - 1) {
          // lsClient.setUserLS(SCAN_TIMER_START, moment(new Date()).toString())
          handleScanTimer()
        } else setTimerActive(false)
        break
      }

      case 'ProctorInstruction': {
        break
      }

      case 'ProctorWaitingRoomInstruction': {
        if (sessionStatus === 'R') {
          //As it is possible this page can be set while out of browser view, timer sync is set locally in the Proctor Waiting Room child
          setTimerActive(true)
        }
        break
      }

      case 'TimerOnly': {
        const waitTimerStart = lsClient.getUserLSByKey(WAIT_TIMER_START)

        //Member happy path
        if (!waitTimerStart) {
          lsClient.setUserLS(WAIT_TIMER_START, moment(new Date()).toString())
          setRemainingTime(current.timeInSeconds)
          setTimerValue(current.timeInSeconds)
          setTimerActive(true)
        }

        //Member has navigated away after starting the Wait Timer
        else {
          const elapsedTime =
            Math.round(
              moment
                .duration(moment(new Date()).diff(waitTimerStart))
                .asSeconds()
            ) || 0

          //Wait Timer has expired when Member returns
          if (current.timeInSeconds - elapsedTime < 1) {
            advanceStep()
          }

          //Wait Timer has time remaining when Member returns
          else {
            setTimerValue(current.timeInSeconds)
            setRemainingTime(current.timeInSeconds - elapsedTime)
            setTimerActive(true)
          }
        }
        break
      }
      case 'Scanner':
      case 'TimerWithCollectionInstruction': {
        handleScanTimer()
        break
      }
      default: {
        break
      }
    }
  }

  const onSkip = () => {
    if (canSkipTo) {
      setCurrent(instructions[canSkipTo])
      setProgress((canSkipTo / instructions.length) * 100)
      setStep(canSkipTo)
      lsClient.setUserLS(STEP, canSkipTo.toString())
    }
  }

  const prevStep = () => {
    if (
      instructions[step - 1]?.type === 'ProctorWaitingRoomInstruction' ||
      instructions[step - 1]?.type === 'ProctorInstruction'
    ) {
      return
    }
    setTimerActive(false)
    if (step !== 0) {
      setCurrent(instructions[step - 1])
      setProgress(((step - 1) / instructions.length) * 100)
      setStep(step - 1)
      lsClient.setUserLS(STEP, (step - 1).toString())
    } else if (!proctorStatus) {
      lsClient.removeUserKeyLS(STEP)
      dispatch(setActive(3))
      history.push(paths.registerTest())
    }
  }

  const cancelTest = () => {
    setCancelTestDialogOpen(true)
  }

  const resetTest = () => {
    lsClient.setUserLS('step', 1)
    lsClient.removeUserKeyLS('scan_timer_start')
    lsClient.removeUserKeyLS('scan_capture_time')
    lsClient.removeUserKeyLS('wait_timer_start')
  }

  if (testLoading || settingsLoading) return <Loading />

  return (
    <>
      {isNativeDevice && cameraActive ? (
        <Scanner
          timerValue={timerValue}
          timerActive={timerActive}
          setCameraActive={setCameraActive}
          setSessionExpired={setSessionExpired}
          setSupportDialogOpen={setSupportDialogOpen}
          current={current}
          matches={matches}
        />
      ) : (
        <ModuleHeader
          leftContentTitle={
            current?.showBackButton ? i18n.back_button : undefined
          }
          leftContentAction={current?.showBackButton ? prevStep : undefined}
          rightContentTitle={i18n.exit_button}
          rightContentAction={cancelTest}
          color="#505358"
          progress={progress}
        >
          <Box className={classes.instructionsPageWrapper}>
            {proctorStatus === 'Active' && !matches && (
              <Box className={classes.proctorConnectedWrapper}>
                <Typography className={classes.proctorConnected} align="center">
                  You are in a live Proctored video session.
                </Typography>
              </Box>
            )}
            <Box className={classes.instructionsCardWrapper}>
              {proctorStatus === 'Active' && matches && (
                <Box className={classes.proctorConnectedWrapper}>
                  <Typography
                    className={classes.proctorConnected}
                    align="center"
                  >
                    You are in a live Proctored video session.
                  </Typography>
                </Box>
              )}
              <Box className={classes.instructionsWrapper}>
                <Box className={classes.stepImageWrapper}>
                  {!cameraActive ? (
                    <>{collectionViews[current?.type]()}</>
                  ) : (
                    <Box className={classes.cameraWrapper}>
                      <Scanner
                        timerValue={timerValue}
                        timerActive={timerActive}
                        setCameraActive={setCameraActive}
                        setSessionExpired={setSessionExpired}
                        setSupportDialogOpen={setSupportDialogOpen}
                        current={current}
                        matches={matches}
                      />
                    </Box>
                  )}
                </Box>

                {overFlowIndicator &&
                  !(
                    current?.type === 'ProctorWaitingRoomInstruction' ||
                    current?.type === 'ProctorInstruction'
                  ) && <CallReceivedIcon className={classes.moreIconWrapper} />}
                {!!proctorStatus && (
                  <Box className={classes.videoCell}>
                    <ProctorVideoCall
                      setInCall={setInCall}
                      inCall={inCall}
                      nextStep={advanceStep}
                      requestProctorSession={requestProctorSession}
                      matches={matches}
                    />
                  </Box>
                )}
                {!cameraActive && (
                  <Box className={classes.buttonWrapper}>
                    {current?.supportButtonTitle && (
                      <MUILink
                        role="button"
                        color="primary"
                        className={classes.pageLink}
                        onClick={() => setSupportDialogOpen(true)}
                      >
                        {current?.supportButtonTitle}
                      </MUILink>
                    )}
                    <Box className={classes.lgButtonBox}>
                      <Button
                        id="continue"
                        className={
                          current?.showBackButton && !matches
                            ? `${classes.confirmButton}  ${classes.mr1}`
                            : `${classes.confirmButton}  ${classes.center}`
                        }
                        onClick={() => advanceStep()}
                        variant="contained"
                        disabled={
                          (timerActive && current?.type === 'TimerOnly') ||
                          (proctorStatus !== 'ProctorJoined' &&
                            current?.type ===
                              'ProctorWaitingRoomInstruction') ||
                          proctorSessionLoading
                        }
                      >
                        {current?.buttonTitle || `${i18n.continue_button}`}
                      </Button>
                      {canSkipTo && canSkipTo > step && (
                        <MUILink
                          role="button"
                          color="primary"
                          className={classes.pageLink}
                          onClick={() => onSkip()}
                        >
                          {i18n.skip}
                        </MUILink>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <SupportDialog
              open={supportDialogOpen}
              setSupportDialogOpen={setSupportDialogOpen}
            />
            <SessionExpiredDialog
              open={sessionExpired}
              setDialogOpen={setSessionExpired}
            />
            <CancelTestDialog
              current={current}
              open={cancelTestDialogOpen}
              setDialogOpen={setCancelTestDialogOpen}
            />
            <AudioRequired
              open={audioWarning}
              setAudioWarning={setAudioWarning}
            />
            <MicrophoneRequiredDialog
              open={devicePermissionDialog}
              close={setDevicePermissionDialog}
            />
          </Box>
        </ModuleHeader>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  ml1: {
    marginLeft: '48px',
  },
  mr1: {
    marginRight: '48px',
  },
  center: {
    margin: '0px auto',
  },
  instructionsPageWrapper: {
    width: '100%',
    display: 'flex',
  },
  proctorConnected: {
    color: '#FFF',
  },
  proctorConnectedWrapper: {
    width: '100%',
    margin: '-10px 0',
    backgroundColor: '#009104',
    position: 'relative',
    [theme.breakpoints.up(575)]: {
      margin: 0,
      height: '32px',
    },
  },
  videoCell: {
    position: 'absolute',
    right: 20,
    bottom: 150,
    zIndex: 10001,
    [theme.breakpoints.up(900)]: {
      top: 100,
      right: 25,
    },
  },
  pageLink: {
    fontWeight: 600,
    marginBottom: 10,
    cursor: 'pointer',
  },
  cameraWrapper: {
    maxWidth: 540,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(576)]: {
      borderTop: '2px solid #E0E0E0',
    },
  },
  instructionsCardWrapper: {
    minHeight: '600px',
    height: 'fit-content',
    margin: '0px auto',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    textAlign: 'center',
    [theme.breakpoints.down(576)]: {
      width: '100vw',
      padding: '0px',
      minHeight: 'unset',
      margin: '0px auto',
    },
  },
  instructionsWrapper: {
    width: '500px',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down(600)]: {
      width: '90%',
      padding: '0px',
      minHeight: 'unset',
      margin: '0px auto 128px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
    width: '100%',
    maxHeight: '250px',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      margin: '0px auto',
      padding: '20px 0px',
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: '#FFF',
    },
    [theme.breakpoints.down(320)]: {
      padding: '0px 0px 16px',
    },
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 16,
    [theme.breakpoints.down(600)]: {
      width: '90%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  skip: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '48px',
    [theme.breakpoints.down(600)]: {
      marginRight: '8px',
    },
  },
  moreIconWrapper: {
    transform: 'rotate(-45deg)',
    position: 'relative',
    left: 'calc(85vw)',
    bottom: '75px',
    zIndex: 11,
    color: '#58585A',
    opacity: 0.4,
    [theme.breakpoints.up(600)]: {
      display: 'none',
    },
  },
  stepImageWrapper: {
    width: '100%',
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'unset',
    margin: 0,
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: '90%',
      minHeight: '46px',
    },
  },
}))
