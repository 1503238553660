import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectNetworkSettings } from '../../../pages/network/model'
import { FooterConfigurationOptions } from '../../../pages/network/type'
import { selectHasDependents } from '../../../pages/profile/model'
import { selectLanguageContent } from '../../translation'
import { NavigationItems } from './navigation-items'

export const FooterItems = (props: {isDesktop: boolean; light?: boolean}) => {
  const classes = useStyles()
  const { isDesktop, light } = props
  const networkSettings = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)
  const { footerConfiguration } = networkSettings || {}
  const hasDependents = useSelector(selectHasDependents)

  return (
    <div className={classes.iconWrapper}>
      {footerConfiguration &&
        Object.values(footerConfiguration).map(
          (f: FooterConfigurationOptions) => {
            return NavigationItems(i18n, isDesktop, light, hasDependents)[f]()
          }
        )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}))
