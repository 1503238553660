import { Box, Paper, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { CSSProperties, ReactNode } from 'react'
import { BackHeader } from './back-header'

interface PageProps {
  className?: string
  title?: string
  backPath?: string
  showBack?: boolean
  paper?: boolean
  style?: CSSProperties
  backActionOverride?: () => void
  children: ReactNode
}

export const PageWrapper = (props: PageProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  return isMobile ? <MobileLayout {...props} /> : <DesktopLayout {...props} />
}

const DesktopLayout = ({
  className,
  title,
  style,
  children,
  backPath,
  paper = true,
  showBack = true,
  backActionOverride,
}: PageProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.desktopWrapper}>
      {showBack && (
        <BackHeader
          className={classes.header}
          title={title || ''}
          backPath={backPath}
          backActionOverride={backActionOverride}
        />
      )}
      {paper ? (
        <Paper
          elevation={0}
          className={classNames(classes.content, className)}
          style={style}
        >
          {children}
        </Paper>
      ) : (
        <Box
          className={classNames(classes.contentNoBg, className)}
          style={style}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

const MobileLayout = ({
  className,
  title,
  style,
  children,
  backPath,
  paper = true,
  showBack = true,
}: PageProps) => {
  const classes = useStyles()

  return (
    <Box className={classNames(classes.mobileWrapper, className)}>
      {showBack && (
        <BackHeader
          className={classes.header}
          title={title || ''}
          backPath={backPath}
        />
      )}
      <Box className={classes.childWrapper}>{children}</Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  childWrapper: {
    width: '100%',
    marginTop: '16px',
    [theme.breakpoints.down(600)]: {
      marginTop: '8px',
    },
  },
  content: {
    borderRadius: 16,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    marginTop: 24,
    [theme.breakpoints.up('lg')]: {
      width: '95%',
      padding: '32px 40px 16px',
      marginLeft: '32px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '32px 48px 16px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '32px 24px 16px',
    },
  },
  contentNoBg: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '32px',
    },
  },
  header: {
    alignSelf: 'flex-start',
    marginLeft: 75,
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
    },
  },
  desktopWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mobileWrapper: {
    width: '100%',
    padding: '16px 32px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    flex: 1,
  },
}))
