import { makeStyles } from '@material-ui/core/styles'

enum Flex {
  FLEX_START = 'flex-start',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly',
}

const MASK_RADIAL = '-webkit-radial-gradient(circle, white 100%, black 100%)'

export const testInstructionStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    minHeight: '200px',
  },
  videoContainer: {
    marginTop: '20px',
    width: '90%',
    padding: 24,
    border: '1px solid #505358',
    borderRadius: 8,
    backgroundColor: '#E0E0E0',
    [theme.breakpoints.down(600)]: {
      padding: 8,
    },
  },
  imageWrapper: {
    marginTop: 48,
    [theme.breakpoints.down(600)]: {
      marginTop: 32,
    },
  },
  img: {
    maxWidth: '325px',
    [theme.breakpoints.down(320)]: {
      width: '90%',
    },
  },
  pageLink: {
    marginBottom: 10,
    cursor: 'pointer',
  },
  assetBox: {
    'margin': '16px 0px 48px',
    'minWidth': '100%',
    '& span': {
      textAlign: 'left',
    },
    [theme.breakpoints.down(600)]: {
      'width': '90%',
      'margin': 0,
      '& span': {
        fontSize: '20px !important',
      },
    },
  },
  instructionContainer: {
    'overflowX': 'auto',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'gap': 32,
    'marginTop': 32,
    'minHeight': '70vh',
    [theme.breakpoints.down(600)]: {
      minHeight: 'unset',
      flexDirection: 'column-reverse',
      justifyContent: Flex.FLEX_START,
      margin: '10px auto',
    },
    '&.reduced': {
      flexDirection: 'column',
      justifyContent: Flex.FLEX_START,
    },
  },
  title: {
    color: '#282D37',
    fontSize: 36,
    fontWeight: 600,
    lineHeight: '130%',
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  body: {
    'fontSize': 16,
    'color': '#757575',
    'fontWeight': 500,
    'letterSpacing': '0.1px',
    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  cameraLoading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularTime: {
    fontSize: 60,
    fontWeight: 500,
  },
  timer: {
    fontSize: 88,
    [theme.breakpoints.down(600)]: {
      fontSize: 44,
    },
  },
  timerBox: {
    display: 'flex',
    width: '100%',
    marginBottom: 60,
    justifyContent: 'center',
    [theme.breakpoints.down(600)]: {
      margin: '24px 0px',
    },
  },
  linearBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '78px 0px 16px',
    zIndex: 1,
    [theme.breakpoints.down(600)]: {
      maxHeight: '60px',
      margin: '24px 0px',
    },
  },
  mt1: {
    marginTop: '24px',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 16,
    [theme.breakpoints.down(600)]: {
      gap: 0,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  guides: {
    'position': 'absolute',
    'height': '495px',
    'zIndex': 101,
    '&.landscape': {
      height: 'unset',
      width: '495px',
    },
    [theme.breakpoints.down(600)]: {
      height: '80vh',
    },
  },
  landscapeGuides: {
    'position': 'absolute',
    'zIndex': 101,
    'width': '475px',
    '&.rotate': {
      transform: 'rotate(90deg)',
      width: '595px',
    },
    '&.nativeDevice': {
      width: '595px',
    },
    [theme.breakpoints.down(600)]: {
      'height': '300px',
      'width': 'unset',
      '&.rotate': {
        transform: 'rotate(90deg)',
        height: 'unset',
        width: '595px',
      },
    },
  },
  dialogImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  webcamContainer: {
    'width': 500,
    'height': 500,
    'borderRadius': 16,
    '-webkit-mask-image': MASK_RADIAL,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '&.landscape': {
      height: 325,
      width: 600,
    },
    '&.nativeDevice': {
      height: '100vh',
      width: '100vw',
      marginTop: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.down(600)]: {
      height: '100vh',
      width: '100vw',
      marginTop: 0,
      borderRadius: 0,
    },
  },
  imageCaptureDialog: {
    padding: 24,
    position: 'relative',
    [theme.breakpoints.down(600)]: {
      width: '70vw',
    },
  },
  imageDialogContent: {
    width: 500,
    [theme.breakpoints.down(600)]: {
      width: '100%',
      marginTop: 0,
    },
  },
  cameraPage: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'width': '100%',
    '&.nativeDevice': {
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
    },
    [theme.breakpoints.down(600)]: {},
  },
  mobileScannerTimerBox: {
    padding: '5px 10px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    minWidth: 80,
    marginLeft: 380,
    marginTop: 10,
    position: 'absolute',
    top: 25,
    [theme.breakpoints.down(600)]: {
      right: 20,
      marginTop: 0,
      minWidth: 50,
    },
  },
  desktopTimerBox: {
    padding: '5px 10px',
    marginTop: 24,
  },
  capturedImage: {
    width: 500,
    [theme.breakpoints.down(600)]: {
      'height': '400px',
      'width': 'unset',
      '&.landscape': {
        transform: 'rotate(90deg)',
        width: '400px',
        height: 'unset',
        padding: '100px 0px',
      },
    },
  },
  cameraText: {
    margin: '16px',
    fontSize: '24px',
    color: '#505358',
  },
  buttonContainer: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'marginTop': '16px',
    'gap': 16,
    '&.nativeCamera': {
      width: 'fit-content',
      position: 'fixed',
      bottom: 5,
      right: 5,
    },
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      bottom: 5,
    },
  },
  buttonWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'marginBottom': 32,
    'width': '100%',
    'maxHeight': '250px',
    'zIndex': 10,
    '&.noBottomMargin': {
      marginBottom: 0,
    },
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'unset',
    padding: '10px 24px',
    margin: 0,
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      marginTop: 10,
      marginBottom: 10,
      width: '90%',
      minHeight: '46px',
    },
  },
  loadrunner: {
    'position': 'fixed',
    'bottom': '1px',
    'left': '1px',
    'boxShadow': 'none',
    'border': 'none',
    'backgroundColor': 'transparent',
    'maxHeight': '1px !important',
    'maxWidth': '1px !important',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))
