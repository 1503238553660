import { I18n } from '../translation/types'

export interface DialogWrapperProps {
  open: boolean
  dialogType: string
  closeDialog: (prop1: boolean) => void
}
export interface DialogProps {
  i18n: I18n
}

export enum AttemptLimitErrorType {
  PASSWORD_RESET = 'PASSWORD_RESET',
  MFA = 'MFA',
  ELIGIBILITY = 'ELIGIBILITY',
}

export interface DialogOptions {
  id?: string
  attemptLimitType?: AttemptLimitErrorType
  title?: string
  body?: string
  image?: string
  onConfirm?: () => void
  confirmationLabel?: string
  onCancel?: () => void
  cancelLabel?: string
}

export interface DialogContent extends DialogProps {
  title?: string
  body?: string
  image?: string
  onConfirm?: () => void
  confirmationLabel?: string
  onCancel?: () => void
  cancelLabel?: string
}

export enum DialogType {
  LOGOUT = 'LOGOUT',
  QUESTIONNAIRE_INTRO = 'QUESTIONNAIRE_INTRO',
  TEST_PROCESSING_ERROR = 'TEST_PROCESSING_ERROR',
  // ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
  INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE',
  UNABLE_TO_VERIFY_USER = 'UNABLE_TO_VERIFY_USER',
  ACCESS_LIMIT = 'ACCESS_LIMIT',
}
