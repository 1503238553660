import axios from 'axios'
// import { NextQuestionPayload } from '../features/questionnaire/types'
// import { FullUser, FullRegisterData } from '../features/user/types'
import { Span } from 'opentracing'
import {
  ResumeQuestionnairePayload,
  StartQuestionnairePayload,
} from '../features/questionnaire/types'
import { CommonResponse } from '../features/user/types'
// import { authClient, doLogout } from '../libs/auth-client'
import { isArrayBuffer, isBase64, logout } from '../libs/helpers'
import { createResourcesLink } from '../libs/resources'
import { lsClient } from '../ls-client'
import { FAQQueryModel } from '../pages/faq/types'
import {
  CheckAvailabilityPayload,
  SchedulePickUpRequest,
  ShortAddress,
} from '../pages/shipping/types'
import httpClient from './httpClient'
import { tracer } from './tracer'
import { ContactUsForm } from './types'

const Buffer = require('buffer').Buffer

const identityTenant = process.env.REACT_APP_IDENTITY_TENANT_NAME
const realm = process.env.REACT_APP_AUTH0_REALM
const ZENDESK_TOKEN = process.env.REACT_APP_ZENDESK_TOKEN || ''
const ZENDESK_DOMAIN = process.env.REACT_APP_ZENDESK_DOMAIN || ''

//VCB 1
const PREFIX_VCB1_URL = '/questionnaire-rules/v1/exec'

const PREFIX_QUESTIONNAIRE_URL = `/virtual-consult/v1`
const PREFIX_HEALTH_SERVICE_URL = `/health-service/v1`
const PREFIX_PROVIDER_URL = '/core/v1/provider'
const PREFIX_TELEHEALTH_URL = '/telehealth/v1'
const PREFIX_COMMUNICATION_URL = '/communication/v1'
const PREFIX_ADMIN_URL = '/admin/v1'
const PREFIX_MEMBER_URL = '/core/v1/member'
const PREFIX_LAB_ORDER_URL = '/lab-order/v1'
const PREFIX_IDENTITY_URL = '/identity/v1'
const PREFIX_LOCALIZATION_URL = '/core/v1/localization'
const PREFIX_CORE = '/core/v1'
const PREFIX_DIAGNOSTIC_BUILDER = '/diagnostic-builder/v1'
const PREFIX_INSURANCE = '/insurance/v1'
const PREFIX_PAYMENT = '/payment/v2'
const PREFIX_RESOURCES = 'resources/v1'
const PREFIX_SHIPPING = '/shipping/v1'
const PREFIX_PATIENT_RECORD = '/patient-record/v1'

const ACCOUNT_ID = process.env.REACT_APP_DEFAULT_ACCOUNT_ID || ''
const COVID_QUESTIONNAIRE = process.env.REACT_APP_DEFAULT_QUESTIONNAIRE

const getTenant = () => {
  return lsClient.getUserLSByKey('tenantId') || localStorage.getItem('tenantId')
}

const getAccount = () => {
  const signupAccount = sessionStorage.getItem('accountId')
  return signupAccount ? signupAccount : ACCOUNT_ID
}
export interface ApiResponse<T> {
  code: number
  data: T | null
  message: string
  success: boolean
}

const API = {
  // Self Onboarding Endpoints

  getDefaultSignupTenant() {
    const accountId = process.env.REACT_APP_DEFAULT_ACCOUNT_ID || ''
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/accounts/default/signup/tenant/${accountId}`
    )
  },

  submitAccessCode(accessCode: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/verify/access/code/${accessCode}`
    )
  },

  getDependents(memberId: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/user/dependents/${memberId}?skip=0&pageSize=10&direction=DESC`,
      {}
    )
  },

  inviteDependent(data: { email: string; dependentId: string }) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/user/dependent/invite`,
      data
    )
  },

  eligibilityVerification(data: {
    tenantId: string
    lastName: string
    dateOfBirth: string
    lastFourSSN: string
  }) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/eligibility/verification`,
      data
    )
  },

  verifyAddress(data: {
    address1: string | undefined
    address2: string
    city: string
    state: string
    zip: string
  }) {
    return doHttpCall('POST', `${PREFIX_SHIPPING}/usps/validateaddress`, data)
  },

  onboardingComplete(data: {
    tenantId: string
    email: string
    is3rdParty: boolean
    isDependent: boolean
  }) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/user/onboarding/complete`,
      data
    )
  },

  getProfile() {
    return doHttpCall('GET', `${PREFIX_IDENTITY_URL}/users/info`, {})
  },

  getUserPermission() {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/users/${tenant}/permission`
    )
  },

  updateProfile(data: any) {
    return doHttpCall('PUT', `${PREFIX_IDENTITY_URL}/users/update`, data)
  },

  updateProfileContact(data: any) {
    return doHttpCall(
      'PUT',
      `${PREFIX_IDENTITY_URL}/users/update/contact`,
      data
    )
  },

  getGuardianInfo(userId: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/userrelationship/getdetails/${userId}`,
      {}
    )
  },

  updateGuardianInfo(data: any) {
    return doHttpCall(
      'PUT',
      `${PREFIX_IDENTITY_URL}/userrelationship/update`,
      data
    )
  },

  addGuardianInfo(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/userrelationship/create`,
      data
    )
  },

  sendFeedback(body: { body: string; subject: string }) {
    const tenant = getTenant()
    return doHttpCall('POST', `${PREFIX_ADMIN_URL}/feedback/${tenant}`, body)
  },

  async submitEmailVerification(data: any) {
    return doHttpCall(
      'PUT',
      `${PREFIX_IDENTITY_URL}/users/invite/by/email/confirm`,
      data,
      {},
      false
    )
  },

  async sendNewInviteEmail(userData: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/reinvite/by/email`,
      userData
    )
  },

  async requestPhoneVerificationV2(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/phone/sendverificationcode`,
      data
    )
  },

  async submitPhoneVerificationV2(data: any) {
    return doHttpCall('POST', `${PREFIX_IDENTITY_URL}/phone/verifycode`, data)
  },

  async requestPhoneVerificationCode(data: any) {
    data.tenantId = getTenant()
    return doHttpCall('POST', `${PREFIX_CORE}/phone/sendverificationcode`, data)
  },

  async submitPhoneVerificationCode(data: any) {
    data.tenantId = getTenant()
    return doHttpCall('POST', `${PREFIX_CORE}/phone/verifycode`, data)
  },

  async registerNewUser({ password, ...userData }: any) {
    // todo refactor api, create api function for passing query
    const user: any = {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      userPermissionOption: 5,
      tenantId: identityTenant,
      password,
      dateOfBirth: new Date('2000-00-01'),
      sex: userData.gender,
    }
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/create`,
      user,
      {},
      false
    )
  },

  async submitInviteUserRegistration(userData: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/create?autoLogin=true`,
      userData,
      {},
      false
    )
  },

  sendAppDownloadEmail() {
    return doHttpCall('GET', `${PREFIX_IDENTITY_URL}/users/welcome`)
  },

  getMemberByTicketId({ ticketId }: { ticketId: string }) {
    return doHttpCall('GET', `/workers/v1/member/by/ticketId/${ticketId}`)
  },

  async login(data: { email: string; password: string; accountId?: string }) {
    if (!data.accountId) data.accountId = ACCOUNT_ID
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/login`,
      data,
      {},
      false
    )
  },

  // MFA endpoints //

  async loginUserWithRememberedDevice(data: {
    accessToken: string
    deviceGroupKey: string
    passwordVerifier: string
    deviceKey: string
    username: string
    password: string
    accountId?: string
  }) {
    if (!data.accountId) data.accountId = ACCOUNT_ID
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/mfa/AuthDevice`,
      data
    )
  },

  async mfaChallenge(data: any, challengeType: string) {
    if (!data.accountId) data.accountId = ACCOUNT_ID
    const URL =
      challengeType === 'SMS_MFA'
        ? `${PREFIX_IDENTITY_URL}/users/sms/challenge`
        : `${PREFIX_IDENTITY_URL}/users/totpmfa/RespondToTOTPChallenge`

    return doHttpCall('POST', URL, data)
  },

  async getMFATOTPAppId(tokens?: { accessToken: string; idToken: string }) {
    const sessionId = tokens?.accessToken
      ? tokens.accessToken
      : localStorage.getItem('access_token')
    const tenant = getTenant()
    const account = getAccount()
    let headers = {}
    if (tokens) {
      headers = {
        'Authorization': `Bearer ${tokens.idToken}`,
        'id_Token': tokens.idToken,
        'X-SF-ACCOUNT': account,
        'X-SF-TENANT': tenant,
      }
    }
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/totpmfa/GetSecretForAppMFA`,
      { sessionId },
      headers,
      Boolean(!tokens)
    )
  },

  async verifyTOTP(
    data: { sessionId: string; mfaCode: string, username: string, tenantId?: string, accountId?: string },
    tokens?: { accessToken: string; idToken: string }
  ) {
    const tenant = getTenant()
    const account = getAccount()
    data.tenantId = tenant
    data.accountId = account
    let headers = {}
    if (tokens) {
      headers = {
        'Authorization': `Bearer ${tokens.idToken}`,
        'id_Token': tokens.idToken,
        'X-SF-ACCOUNT': account,
        'X-SF-TENANT': tenant,
      }
    }
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/totpmfa/VerifyTOTP`,
      data,
      headers,
      Boolean(!tokens)
    )
  },

  async setupMFAChoice(
    data: { sessionId: string },
    type: string,
    tokens?: { accessToken: string; idToken: string }
  ) {
    const tenant = getTenant()
    const account = getAccount()
    let headers = {}
    if (tokens) {
      headers = {
        'Authorization': `Bearer ${tokens.idToken}`,
        'id_Token': tokens.idToken,
        'X-SF-ACCOUNT': account,
        'X-SF-TENANT': tenant,
      }
    }
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/setup/smsmfa?type=${type}`,
      data,
      headers,
      Boolean(!tokens)
    )
  },

  async rememberMFADevice(data: {
    accessToken: string
    deviceKey: string
    deviceGroupKey: string
    username: string
  }) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/mfa/UpdateDeviceStatus`,
      data
    )
  },

  async confirmMFADevice(data: {
    accessToken: string
    deviceKey: string
    deviceGroupKey: string
    username: string
    accountId?: string
  }) {
    if (!data.accountId) data.accountId = ACCOUNT_ID
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/mfa/ConfirmDevice`,
      data
    )
  },

  async forgetMFADevice(data: {
    accessToken: string
    deviceKey: string
    deviceGroupKey: string
    username: string
  }) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/mfa/ForgetDevice`,
      data
    )
  },

  // END MFA Endpoints //

  getLanguageSettings(language: string, tenant: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_LOCALIZATION_URL}/settings/${tenant}/${language}`
    )
  },

  getLanguageFile(language: string, version: string, tenant: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_LOCALIZATION_URL}/json/${tenant}/${language}/${version}`
    )
  },

  getConsent<T>(accountId: string, email: string) {
    const tenant = getTenant()
    return doHttpCall<T>(
      'GET',
      `${PREFIX_ADMIN_URL}/user/consent/${tenant}/pending?direction=DESC&limit=999&skip=0&sort=consented`,
      {}
    )
  },

  //VCB 1 implementation for use until VCB2 integration is ready

  startQuestionnaireV1() {
    const EN_FORM_ID = '5f1c3c672fec9272e58e962d'
    const ES_FORM_ID = '612f50d362dc5dc4c3936bd9'
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_VCB1_URL}/start/${EN_FORM_ID}?tenant=${tenant}`,
      {}
    )
  },

  resumeQuestionnaireV1(questionnaireId: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_VCB1_URL}/get/byId/${questionnaireId}`,
      {}
    )
  },

  nextQuestionV1(answer: any, questionId: string) {
    const language = 'en'
    return doHttpCall(
      'POST',
      `${PREFIX_VCB1_URL}/next/${questionId}/${language}`,
      { ...answer }
    )
  },

  previousQuestionV1(questionId: string) {
    const language = 'en'
    return doHttpCall('POST', `${PREFIX_VCB1_URL}/back/${questionId}`, {})
  },

  //VCB 2 implementaion

  getLatestResult() {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_ADMIN_URL}/laboratory-test/clearance-status/${tenant}/own`,
      {}
    )
  },

  getVcIntroScreen(id = '') {
    let tenant = getTenant()
    //
    if (id) {
      tenant = '_platform'
    }
    return doHttpCall(
      'GET',
      `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/configuration/intro`,
      { id: id ? id : undefined }
    )
  },

  startQuestionnaireV2(
    sendToQueue: boolean,
    healthServiceId?: string,
    questionnaireId?: string
  ) {
    const tenant = getTenant()
    let URL = `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/questionnaires/start?sendToQueue=${sendToQueue}`

    if (questionnaireId) {
      URL += `&questionnaire=${questionnaireId}`
    }
    return doHttpCall('POST', URL, { healthServiceId })
  },

  startQuestionnaire({
    email,
    accountId,
    questionnaireID,
  }: StartQuestionnairePayload) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/questionnaires/start?questionnaire=${COVID_QUESTIONNAIRE}`,
      {}
    )
  },

  resumeQuestionnaireV2(executionId: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/questionnaires/${executionId}/node`,
      {}
    )
  },

  nextQuestionV2(thisAnswer: any, executionId: string) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/questionnaires/${executionId}/answer`,
      {
        answer: thisAnswer,
      }
    )
  },

  previousQuestionV2(executionId: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/questionnaires/${executionId}/back`,
      {}
    )
  },

  getQuestionnaireResult({ executionId, accountId, email }: any) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_QUESTIONNAIRE_URL}/${tenant}/questionnaires/${executionId}/result`,
      {}
    )
  },

  // Health Service lite endpoints

  getHealthServiceList(userId: string, status: string, userEmail: string) {
    const values = {
      userId,
      status,
    }

    return doHttpCall(
      'GET',
      // `${PREFIX_HEALTH_SERVICE_URL}?userId=${userId}&status=${status}`,
      `${PREFIX_HEALTH_SERVICE_URL}?userId=${userId}&limit=1`,

      {}
    )
  },

  getNextStep(serviceId: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_HEALTH_SERVICE_URL}/${tenant}/${serviceId}`,
      {}
    )
  },

  createHealthService(params: any) {
    return doHttpCall('POST', `${PREFIX_HEALTH_SERVICE_URL}/`, params)
  },

  addHS(serviceId: string, params: any, type: string) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_HEALTH_SERVICE_URL}/${serviceId}/${type}`,
      params
    )
  },

  completeHealthService(serviceId: string, params: any) {
    const tenant = getTenant()
    return doHttpCall(
      'PUT',
      `${PREFIX_HEALTH_SERVICE_URL}/${serviceId}/status`,
      params
    )
  },

  getPrescriptionById(
    npi: string,
    healthServiceId: string,
    prescriptionId: string
  ) {
    return doHttpCall(
      'GET',
      `${PREFIX_PATIENT_RECORD}/prescription/${npi}/${healthServiceId}/${prescriptionId}`,
      {}
    )
  },

  // FirstViewFinancial
  createFirstViewFinancialConsult(data: any) {
    // const tenantId = getTenant()
    data.tenantId = getTenant()
    return doHttpCall('POST', `${PREFIX_CORE}/consults/create`, { data })
  },

  getFirstViewFinancialConsult(serviceId: string) {
    return doHttpCall('GET', `${PREFIX_CORE}/consults/${serviceId}`, {})
  },

  getTreatmentPlan(consultId: string) {
    return doHttpCall('GET', `${PREFIX_CORE}/user/treatment/${consultId}`, {})
  },

  confirmTreatmentPlan(data: any, consultId: string) {
    return doHttpCall('POST', `${PREFIX_CORE}/consults/${consultId}/accept`, {
      data,
    })
  },

  declineTreatmentPlan(data: any, consultId: string) {
    return doHttpCall('POST', `${PREFIX_CORE}/consults/${consultId}/cancel`, {
      data,
    })
  },

  cancelFVFConsult(data: any, consultId: string) {
    return doHttpCall('POST', `${PREFIX_CORE}/consults/${consultId}/pause`, {
      data,
    })
  },

  resumeFVFConsult(data: any, consultId: string) {
    return doHttpCall('POST', `${PREFIX_CORE}/consults/${consultId}/resume`, {
      data,
    })
  },

  // End FirstViewFinancial

  // Encounter record

  createEncounterRecord(data: any) {
    return doHttpCall('POST', `${PREFIX_CORE}/encounter/create`, {
      data,
    })
  },

  getEncounterRecord(id: string) {
    return doHttpCall('GET', `${PREFIX_CORE}/encounter/${id}`, {})
  },

  updateEncounterRecord(data: any) {
    return doHttpCall('PUT', `${PREFIX_CORE}/encounter/update`, {
      data,
    })
  },

  // End Encounter record

  getTerms<T>() {
    const tenant = getTenant()
    return doHttpCall<T>(
      'GET',
      `${PREFIX_ADMIN_URL}/verbiage/${tenant}/rendered`,
      {}
    )
  },

  getTenantTerms(language: string, tenantId?: string) {
    const tenant = tenantId ? tenantId : getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/users/consent/terms/${tenant}/${language}`,
      {}
    )
  },

  getPendingTerms(language: string, userEmail: string, tenantId?: string) {
    const tenant = tenantId ? tenantId : getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/users/consent/pending/${tenant}/${language}?userEmail=${userEmail}`,
      {}
    )
  },

  getTenantTermByKey(language: string, tenantId: string, verbiageKey: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_IDENTITY_URL}/users/consent/terms/${tenantId}/${language}`,
      { verbiageKey }
    )
  },

  agreeTerm(pendingTerms: any) {
    return doHttpCall(
      'POST_DATA',
      `${PREFIX_IDENTITY_URL}/users/consents`,
      pendingTerms
    )
  },

  async requestResetPassword(data: any) {
    data.tenantId = identityTenant
    if (!data.accountId) data.accountId = ACCOUNT_ID
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/password/reset`,
      data
    )
  },

  async confirmResetPassword(data: any) {
    data.tenantId = identityTenant
    if (!data.accountId) data.accountId = ACCOUNT_ID

    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/password/reset/confirm`,
      data
    )
  },

  // async createSteadyMDTelehealthSession(healthServiceId: string) {
  //   return doHttpCall(
  //     'GET',
  //     `${PREFIX_TELEHEALTH_URL}/steadymd/createsession/${healthServiceId}`,
  //     {}
  //   )
  // },

  //PROVIDER TELEHEALTH

  async createProviderTelehealthSession(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_TELEHEALTH_URL}/member/sessions/create/provider`,
      data
    )
  },

  async getTelehealthSession(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/memberandprovider/sessions/${id}`,
      {}
    )
  },

  async getLastTelehealthSession() {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/member/sessions/latestsession`,
      {}
    )
  },

  async getTelehealthSessionStatus(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/memberandprovider/sessions/status/${id}`,
      {}
    )
  },

  async regenerateTelehealthToken(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/memberandprovider/regeneratetoken/${id}`,
      {}
    )
  },

  async trackTelehealthSession(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_TELEHEALTH_URL}/provider/tracksession`,
      {
        data,
      }
    )
  },

  async cancelTelehealthSession(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_TELEHEALTH_URL}/member/sessions/cancel`,
      data
    )
  },

  async endTelehealthCall(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_TELEHEALTH_URL}/memberandprovider/sessions/end`,
      data
    )
  },

  async updateDirectCall(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_TELEHEALTH_URL}/memberandprovider/directcall/update`,
      data
    )
  },

  getSAFETreatmentPlan(consultId: string) {
    return doHttpCall('GET', `${PREFIX_CORE}/user/treatment/${consultId}`, {})
  },

  //PROCTORING
  async createProctorSession(data: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_TELEHEALTH_URL}/proctor/createsession`,
      {
        data,
      }
    )
  },

  async getProctorSession(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/proctor/session/${id}`,
      {}
    )
  },

  async getLastSession() {
    return doHttpCall('GET', `${PREFIX_TELEHEALTH_URL}/proctor/lastsession`, {})
  },

  async getSessionStatus(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/proctor/sessionstatus/${id}`,
      {}
    )
  },

  async regenerateProctorToken(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_TELEHEALTH_URL}/proctor/regenerateToken/${id}`,
      {}
    )
  },

  async trackProctorSession(data: any) {
    return doHttpCall('POST', `${PREFIX_TELEHEALTH_URL}/tracksession`, {
      data,
    })
  },

  async cancelProctorSession(data: any) {
    return doHttpCall('POST', `${PREFIX_TELEHEALTH_URL}/cancelsession`, {
      data,
    })
  },

  async getUserInfo() {
    return doHttpCall('GET', `${PREFIX_IDENTITY_URL}/users/info`, {})
  },

  async getPicture(data = { accountId: '', email: '' }) {
    return doHttpCall<CommonResponse>(
      'GET',
      `${PREFIX_MEMBER_URL}/getuserimage`,
      data
    )
  },

  async getTestImage(id: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_RESOURCES}/content/rapidtest/image/${id}`,
      {}
    )
  },

  async updatePicture(data: File) {
    return doHttpCall('POST_PHOTO', `${PREFIX_MEMBER_URL}/uploaduserpic`, data)
  },

  async getDashboard() {
    return doHttpCall('GET', `${PREFIX_PROVIDER_URL}/app/dashboard`, {})
  },

  async getOrderStatus() {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/latestorderstatus/${tenant}`
    )
  },

  async getLatestOrderDetails() {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/getlatestorderdetails/${tenant}`
    )
  },

  async getOrderDetailsById(id: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/getorderdetails/${id}`
    )
  },

  async downloadLabReportPDF(testId: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET_PDF',
      `${PREFIX_ADMIN_URL}/laboratory-test/${testId}/results`
    )
  },



  async getVc() {
    return doHttpCall('GET', `${PREFIX_PROVIDER_URL}/app/virtual/consult`, {})
  },

  async getDa() {
    return doHttpCall(
      'GET',
      `${PREFIX_PROVIDER_URL}/app/diagnostic/assessment`,
      {}
    )
  },

  async getTC() {
    return doHttpCall(
      'GET',
      `${PREFIX_PROVIDER_URL}/app/telehealth/consult`,
      {}
    )
  },

  async createEncounter(data: any) {
    return doHttpCall('POST', `${PREFIX_PROVIDER_URL}/app/create/encounter`, {
      data,
    })
  },

  async sendSupportMessage(data: any) {
    const tenant = getTenant()
    return doHttpCall('POST', `${PREFIX_ADMIN_URL}/feedback/${tenant}`, {
      data,
    })
  },

  async getSettings() {
    return doHttpCall('GET', `${PREFIX_PROVIDER_URL}/appconfiguration`, {})
  },

  async getVerbiage() {
    const tenant = getTenant()
    const language = 'en'

    return doHttpCall(
      'GET',
      `${PREFIX_ADMIN_URL}/verbiagedetail/${ACCOUNT_ID}/${tenant}/${language}`,
      {}
    )
  },

  getFaqsByLanguage(language = 'en') {
    const tenant = getTenant()
    return doHttpCall('GET', `${PREFIX_RESOURCES}/content/faq/${tenant}`, {
      language,
    })
  },

  getHelpCenter(data: FAQQueryModel) {
    return doHttpCall(
      'GET',
      `${PREFIX_RESOURCES}/content/faqs/type`,
      data,
      {},
      false
    )
  },

  checkEmailForExisting(email: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_ADMIN_URL}/user/exists?accountId=${realm}&id=${email}`
    )
  },

  contactUs(data: ContactUsForm) {
    const tenant = getTenant()
    return doHttpCall('POST', `${PREFIX_PROVIDER_URL}/app/contact/us`, {
      data: { ...data, tenantId: tenant },
    })
  },

  getPassportStatus(userUUID: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_MEMBER_URL}/passport/status/${tenant}/${userUUID}`,
      {}
    )
  },

  getUserCode({ email, accountId }: any) {
    return doHttpCall<File>(
      'GET_PHOTO',
      `${PREFIX_ADMIN_URL}/user/code`,
      {
        email,
        params: { width: 150 },
      }
      // {
      //   userId: `${accountId}/${email}`,
      // }
    )
  },

  getUsersAvailableTenants() {
    return doHttpCall('GET', `${PREFIX_IDENTITY_URL}/tenants/selectable`)
  },

  getSystemMessages({ skip, limit }: { skip: number; limit: number }) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_COMMUNICATION_URL}/safemessenger/getconversations/${tenant}`,
      {
        pageNumber: skip,
        pageSize: limit,
        sortMessagesAscending: false,
      }
    )
  },

  updateAccount(data: any) {
    return doHttpCall('PUT', `${PREFIX_ADMIN_URL}/user`, data)
  },

  getTestOrderResults<T>({ page }: { page: number }) {
    const tenant = getTenant()
    return doHttpCall<T>(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/getorders/${tenant}`,
      {
        pageNumber: page,
        pageSize: 20,
      }
    )
  },

  getTestResults<T>({
    page,
    pageSize,
    sort,
    direction,
  }: {
    page: number
    pageSize: number
    sort: string
    direction: string
  }) {
    const tenant = getTenant()
    return doHttpCall<T>('GET', `${PREFIX_LAB_ORDER_URL}/result/getresults`, {
      pageNumber: page,
      pageSize,
      sort,
      direction,
    })
  },

  getOrderResults(page: { pageNumber: number; pageSize: number }) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/getorders/${tenant}`,
      page
    )
  },

  getTestResultItem(itemId: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/getorderdetails/${itemId}`,
      {}
    )
  },

  async getResourcesList<T>() {
    const resourcesLink = createResourcesLink()
    if (!resourcesLink) return null as never
    return getContentHttpCall<T>(resourcesLink.basic)
  },

  getVideoResourcesList<T = any>(language = 'en', query: string) {
    const tenant = getTenant() || ''
    return doHttpCall<ApiResponse<T>>(
      'GET',
      `${PREFIX_RESOURCES}/content/allvideos`,
      {
        q: query,
        language: language,
        tenantID: tenant,
      }
    )
  },

  getArticleResourcesList<T = any>(language = 'en', query: string) {
    const tenant = getTenant() || ''
    return doHttpCall<ApiResponse<T>>(
      'GET',
      `${PREFIX_RESOURCES}/content/articles`,
      {
        q: query,
        language: language,
        tenantID: tenant,
      }
    )
  },

  getInstructionsByQrTestType<T = any>(testType: string) {
    const tenant = getTenant()
    return doHttpCall<ApiResponse<T>>(
      'POST',
      `${PREFIX_LAB_ORDER_URL}/scanqrcode/scanqrcode/${tenant}`,
      {
        code: testType,
      }
    )
  },

  getFamily<T>({ userEmail }: { userEmail: string }) {
    const tenant = getTenant()
    return doHttpCall<ApiResponse<T>>(
      'GET',
      `${PREFIX_MEMBER_URL}/users/dependents`,
      {}
      // {
      //   userId: `${tenant}/${userEmail}`,
      // }
    )
  },
  createChild<ChildData, Response>({
    childData,
    parentEmail,
  }: {
    childData: ChildData
    parentEmail: string
  }) {
    return doHttpCall<ApiResponse<Response>>(
      'POST',
      `${PREFIX_MEMBER_URL}/users/dependents`,
      childData
      // { userId: `${tenant}/${parentEmail}` }
    )
  },
  deleteChild({
    parentEmail,
    childEmail,
  }: {
    parentEmail: string
    childEmail: string
  }) {
    const tenant = getTenant()
    return doHttpCall<ApiResponse<any>>(
      'DELETE',
      `${PREFIX_MEMBER_URL}/users/dependents/${childEmail}`,
      { userId: `${tenant}/${parentEmail}` }
    )
  },
  async refreshIdToken() {
    return doHttpCall('GET', `${PREFIX_IDENTITY_URL}/users/refresh`)
  },

  ////Test Kit Flow////

  async getScanCode(language: string, params: any) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_LAB_ORDER_URL}/scancode/${tenant}/${language}`,
      params
    )
  },

  async getUploadURL(data: any) {
    // const tenant = getTenant()
    data.tenantId = getTenant()
    return doHttpCall('POST', `${PREFIX_MEMBER_URL}/createuploadurl`, data)
  },

  async submitPatientTest(params: any) {
    return doHttpCall('POST', `${PREFIX_MEMBER_URL}/submitpatienttest`, params)
  },

  async getDiagnosticResult(id: string, elapsedTime: number) {
    return doHttpCall(
      'GET',
      `${PREFIX_MEMBER_URL}/getdiagnosticresultsformember/${id}/${elapsedTime}`
    )
  },

  async getLabTestForContinue(labTestOrderableId: string, language: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_DIAGNOSTIC_BUILDER}/LabTestOrderable/${labTestOrderableId}/${language}`
    )
  },

  async getLabTestOrderable(testkitID: string, language: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_DIAGNOSTIC_BUILDER}/LabTestOrderable/${testkitID}/${language}`
    )
  },

  async getLabTestPanel(testkitID: string, language: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_DIAGNOSTIC_BUILDER}/LabTestPanel/${testkitID}`
    )
  },

  async getInsuranceInfoForTenant() {
    const tenant = getTenant()
    return doHttpCall('GET', `${PREFIX_INSURANCE}/insurance/info/${tenant}`)
  },

  async getInsuranceProviderRequest() {
    const tenant = getTenant()

    return doHttpCall('GET', `${PREFIX_INSURANCE}/payor/${tenant}`)
  },

  async getInsuranceProvidersRequest(q: string) {
    return doHttpCall(
      'GET',
      `${PREFIX_PROVIDER_URL}/admin/providers/directory?q=${q}&skip=0&limit=20`
    )
  },

  async updateInsuranceDataRequest(params: any) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `${PREFIX_INSURANCE}/insurance/set/${tenant}`,
      params
    )
  },

  async getPaymentCreditCardsRequest() {
    return doHttpCall('GET', `${PREFIX_PAYMENT}/process/payment/creditCards`)
  },

  async createCustomerRequest() {
    return doHttpCall('GET', `/payment/v2/process/Payment/createCustomer`)
  },

  async getPaymentTokenRequest(params: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_PAYMENT}/process/Payment/getToken`,
      params
    )
  },

  async createCustomerWithCard(params: any) {
    return doHttpCall(
      'POST',
      `${PREFIX_PAYMENT}/process/Payment/createCustomerWithCard`,
      params
    )
  },

  async addPaymentCreditCard(stripeToken: string) {
    return doHttpCall(
      'POST',
      `${PREFIX_PAYMENT}/process/Payment/addCard/${stripeToken}`
    )
  },

  async deletePaymentMethodRequest(id: string) {
    return doHttpCall(
      'DELETE',
      `${PREFIX_PAYMENT}/process/Payment/deleteCard/${id}`
    )
  },

  async getTestsForTenant(params: {
    returnOnlyFacilityTest: boolean
    returnOnlyTestKits: boolean
  }) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `${PREFIX_DIAGNOSTIC_BUILDER}/tenantorderable/getorderables/${tenant}`,
      params
    )
  },
  async sendOrderRequest(params: any) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `${PREFIX_LAB_ORDER_URL}/order/create/${tenant}`,
      params
    )
  },
  async getOrderingProviderByTenant() {
    const tenant = getTenant()

    return doHttpCall(
      'GET',
      `${PREFIX_LAB_ORDER_URL}/order/getorderingproviderbytenant/${tenant}`
    )
  },

  async getFacilitiesRequest(payload: any) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `/quest-scheduler/v1/facility/search/${tenant}`,
      payload
    )
  },

  async getFedExOfficesRequest(payload: any) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `shipping/v1/dropoff/nearestlocations/${tenant}`,
      payload
    )
  },

  async confirmDropOffRequest(payload: any) {
    const tenant = getTenant()
    return doHttpCall('POST', `shipping/v1/dropoff/confirm/${tenant}`, payload)
  },

  async getAvailableQuestSlotsRequest(payload: any) {
    const tenant = getTenant()
    return doHttpCall(
      'POST',
      `/quest-scheduler/v1/Appointment/FindAvailableAppointment/${tenant}`,
      payload
    )
  },

  async getNetworkSettings(tenantId?: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_RESOURCES}/content/network/for/tenant/${
        tenantId ? tenantId : tenant
      }`
    )
  },

  async getLegalNotesRequest(language: string) {
    const tenant = getTenant()
    return doHttpCall(
      'GET',
      `${PREFIX_ADMIN_URL}/verbiage/${tenant}/legal/${language}`
    )
  },

  // async getVerbiage(key: string, language: string) {
  //      const tenant = getTenant()
  //   return doHttpCall(
  //     'GET',
  //     `${PREFIX_ADMIN_URL}/verbiage/${tenant}/byKey/${key}/dto/${language}`
  //   )
  // },

  async applyCouponRequest(payload: { couponCode: string }) {
    return doHttpCall(
      'POST',
      `${PREFIX_PAYMENT}/process/Payment/validatecouponcode`,
      payload
    )
  },

  async bookAppointmentRequest(payload: {
    date: any
    time: any
    siteCode: any
    isCovidAntibodyTest: boolean
  }) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `quest-scheduler/v1/Appointment/Schedule/${tenant}`,
      payload
    )
  },

  async getAppointmentDataRequest(appointmentId: string) {
    const tenant = getTenant()

    return doHttpCall(
      'GET',
      `/appointment/appointmentdetails/${tenant}/${appointmentId}`
    )
  },

  async getInsuranceResponsibilityRequest(payload: any) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `${PREFIX_INSURANCE}/Insurance/checkestimator/${tenant}`,
      payload
    )
  },

  async adminDirectSupport(payload: any) {
    const tenant = getTenant()

    return doHttpCall('POST', `${PREFIX_ADMIN_URL}/support/${tenant}`, payload)
  },

  async submitZendeskSupport(payload: string, email: string) {
    const encodedUserAuth = Buffer.from(
      `${email}/token:${ZENDESK_TOKEN}`
    ).toString('base64')
    const auth = `Basic ${encodedUserAuth}`

    return doHttpCall(
      'ZENDESK_POST',
      `https://${ZENDESK_DOMAIN}.zendesk.com/api/v2/requests.json`,
      payload,
      {
        Authorization: auth,
      },
      false
    )
  },

  async getStreetAddress(latitude: number, longitude: number) {
    return doHttpCall(
      'GET',
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude}%2C${longitude}&sensor=false&key=AIzaSyA81t09urAbNO7LQOxAjM4qlHSkBRgywX4`,
      {},
      {},
      false
    )
  },

  async validatePickUpAddressRequest(payload: ShortAddress) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `${PREFIX_SHIPPING}/validateaddress/${tenant}`,
      payload
    )
  },

  async checkPickUpAvailabilityRequest(payload: CheckAvailabilityPayload) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `${PREFIX_SHIPPING}/pickup/availability/${tenant}`,
      payload
    )
  },

  async schedulePickUpRequest(payload: SchedulePickUpRequest) {
    const tenant = getTenant()

    return doHttpCall(
      'POST',
      `${PREFIX_SHIPPING}/pickup/schedule/${tenant}`,
      payload
    )
  },

  async registerNewMemberRequest(
    payload: {
      firstName: string
      lastName: string
      email: string
      password: string
      userPermissionOption: number
      verbiageKeys?: string[]
    },
    tenantId: string | null
  ) {
    const data = {
      ...payload,
      tenantId,
    }

    return doHttpCall('POST', `${PREFIX_IDENTITY_URL}/users/create`, data)
  },

  async sendEmailVerificationCode(email: string) {
    const data = {
      email,
      tenantId: identityTenant,
    }

    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/registrationverificationrequest`,
      data
    )
  },

  async verifyEmailCodeRequest(payload: { email: string; pin: string }) {
    const data = {
      ...payload,
      tenantId: identityTenant,
    }
    return doHttpCall(
      'PUT',
      `${PREFIX_IDENTITY_URL}/users/registrationverificationrequest`,
      data
    )
  },

  async registrationEmailRequest(data: {
    email: string
    firstName: string
    lastName: string
    tenantId: string
    accountId: string
  }) {
    return doHttpCall(
      'POST',
      `${PREFIX_IDENTITY_URL}/users/verificationrequest`,
      data
    )
  },

  async verifyRegistrationEmailCode(data: {
    email: string
    pin: string
    tenantId: string
    accountId: string
  }) {
    return doHttpCall(
      'PUT',
      `${PREFIX_IDENTITY_URL}/users/verificationrequest`,
      data
    )
  },
}

export default API

function createResponse<T>(
  result: T,
  error?: any,
  span?: Span
): { result: T; error: any } {
  if (span) handleResponseSpan(result, span, error)
  return { result, error }
}

function createRawResponse<T>(result: T, error?: T | any, span?: Span): T {
  if (span) handleResponseSpan(result, span, error)
  return result
}

function handleResponseSpan(result: any, span: Span, error?: any): void {
  let spanPayload = result?.data || {}
  if (isBase64(spanPayload as string) || isArrayBuffer(spanPayload)) {
    spanPayload = { data: 'Big size content' }
  }

  span.log({
    event: 'response_payload',
    response_body: !error ? JSON.stringify(spanPayload) : { error },
  })
  span.finish()
}

// eslint-disable-next-line sonarjs/cognitive-complexity
async function doHttpCall<T = any>(
  method: any,
  url: string,
  payload: any = {},
  extraHeaders?: Record<string, string>,
  requireTokens = true
): Promise<T> {
  const span = tracer.startSpan('api_http_call')

  span.setTag('http.method', method)
  span.setTag('http.url', url)
  span.setTag('span.kind', 'client')

  try {
    let result = null
    let headers: any = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json',
    }

    const idToken = localStorage.getItem('id_token')
    const tenant = getTenant()
    const account = getAccount()

    if (requireTokens && idToken) {
      headers.Authorization = `Bearer ${idToken}`
      headers.id_Token = idToken
      headers['X-SF-ACCOUNT'] = account
      headers['X-SF-TENANT'] = tenant
    }
    if (extraHeaders) {
      headers = {
        ...headers,
        ...extraHeaders,
      }
    }

    // tracer.inject(span, 'http_headers', headers)

    if (method === 'POST') {
      result = await httpClient.post(url, { ...payload }, { headers })
    } else if (method === 'POST_DATA') {
      result = await httpClient.post(url, payload, { headers })
    } else if (method === 'PUT') {
      result = await httpClient.put(url, { ...payload }, { headers })
    } else if (method === 'GET') {
      result = await httpClient.get(url, { headers, params: payload })
    } else if (method === 'DELETE') {
      result = await httpClient.delete(url, { headers, params: payload })
    } else if (method === 'GET_PHOTO') {
      const { accountId, email, params } = payload
      let query = ''
      if (accountId || email) {
        query = `?username=${[accountId, email].filter(Boolean).join('/')}`
        if (params) {
          for (const i in params) {
            query = `${query}&${i}=${params[i]}`
          }
        }
      }
      result = await httpClient.get(`${url}${query}`, {
        headers,
        responseType: 'arraybuffer',
      })
    } else if (method === 'POST_PHOTO') {
      const formData = new FormData()
      formData.append('image', payload)
      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      })
    } else if (method === 'ZENDESK_POST') {
      headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
      }
      if (extraHeaders) {
        headers = {
          ...headers,
          ...extraHeaders,
        }
      }
      result = await axios.post(url, payload, { headers })
    } else if (method === 'GET_PDF') {
      result = await httpClient.get(url, {
        headers: {
          ...headers,
          accept: 'application/pdf',
        },
        // params,
        responseType: 'blob',
      })
    }
    if (result && result.data) {
      return createRawResponse<T>(result.data, null, span)
    }

    throw new Error('Data not found')
  } catch (error: any) {
    span.setTag('error', true)
    span.log({
      'event': 'error',
      'error.object': error.message,
    })
    span.finish()

    if (
      error.message === 'Network Error' ||
      (error && error.response && error.response.status === 401)
    ) {
      logout()
    }
    return <any>{
      error:
        error?.response?.data?.code ||
        error?.response?.data?.errorMessage ||
        error?.response?.data?.message ||
        error?.response?.data?.title ||
        error?.message ||
        'Unexpected Error',
    }
    // throw createResponse<null>(null, error, span)
  }
}

async function getContentHttpCall<T>(url: any) {
  const span = tracer.startSpan('getContent_api_http_call')

  span.setTag('http.method', 'GET')
  span.setTag('http.url', url)
  span.setTag('span.kind', 'client')

  let result = null
  const headers = {
    'Content-Type': 'text/plain',
  }

  try {
    result = await axios.get(url, { headers })
    if (!result) {
      return createResponse<null>(null, { message: 'Data not found' }, span)
    }

    return createResponse<T>(result.data, null, span)
  } catch (error: any) {
    //error.message === 'Network Error' ||
    if (error && error.response && error.response.status === 401) {
      logout()
    }
    return createResponse<null>(
      null,
      error?.response?.data?.errorMessage ||
        error?.response?.data?.message ||
        error?.response?.data?.title ||
        error?.message ||
        'Unexpected Error',
      span
    )
  }
}
