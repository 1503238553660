import { Dialog, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { Button } from '../../../ui'

export const ProctorSessionCompleteDialog = (props: any) => {
  const { open, endSession, matches } = props
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)

  return (
    <Dialog open={open}>
      <Box className={classes.articleContainer}>
        <Box>
          <Typography className={classes.complete} align="center">
            Proctor has left the session
          </Typography>
          <Typography
            className={classes.completeInstructions}
            variant="h6"
            align="center"
          >
            Now that the SAFE Health representative has left, it is important
            that you complete the remaining steps as directed.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.controls}>
        {matches ? (
          <Box className={classes.ok} onClick={endSession}>
            <Typography align="center" className={classes.dialogActionText}>
              {i18n.ok_button}
            </Typography>
          </Box>
        ) : (
          <Button
            id="ok"
            className={classes.confirmButton}
            onClick={endSession}
            variant="contained"
          >
            {i18n.ok_button}
          </Button>
        )}
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  dialogActionText: {
    color: '#007AFF',
    fontSize: '16px',
    fontFamily: 'Montserrat Bold',
    [theme.breakpoints.up(1000)]: {
      fontFamily: 'Avenir Next Rounded Regular, serif',
    },
  },
  ok: {
    textAlign: 'center',
    width: '100%',
    padding: '11px 0',
    cursor: 'pointer',
    color: '#007AFF',
  },
  controls: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up(1000)]: {
      marginTop: '12px',
      marginBottom: '44px',
    },
  },
  complete: {
    padding: '4px 0px',
    fontWeight: 800,
    fontSize: '17px',
    color: '#000',
    fontFamily: 'Montserrat Medium',
    [theme.breakpoints.up(1000)]: {
      fontFamily: 'Avenir Next Rounded Regular, serif',
      fontWeight: 400,
      marginTop: '39px',
      fontSize: '30px',
      padding: 0,
    },
  },
  completeInstructions: {
    margin: '0 13px',
    fontSize: '13px',
    color: '#000',
    fontFamily: 'Montserrat Regular',
    [theme.breakpoints.up(1000)]: {
      fontFamily: 'Avenir Next Rounded Regular',
      color: '#505358',
      margin: '24px 48px',
      fontSize: '18px',
      textAlign: 'left',
    },
  },
  articleContainer: {
    width: '280px',
    display: 'flex',
    marginTop: '19px',
    paddingBottom: '19px',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid black',
    [theme.breakpoints.up(1000)]: {
      width: '459px',
      border: 'none',
      margin: 0,
      padding: 0,
    },
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '0px 24px',
    width: '100%',
    height: '46px',
  },
}))
