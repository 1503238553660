import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Button, Typography } from '../../../../ui'
import { getButtonVariant } from '../../../../ui/atoms/button/button'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../../network/model'
import { DashboardCard } from '../../../network/type'

export const SimpleButton = (props: {
  activeHealthService: boolean
  button: DashboardCard
  linkPath: string
  xl?: boolean
}) => {
  const { button, linkPath } = props
  const history = useHistory()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = dashboardCardStyles({ radius })

  if (!button.enabled) return null
  return (
    <div className={classes.largeCardWrapper}>
      <Button
        style={{
          backgroundColor: button.buttonColor ? button.buttonColor : 'unset',
          borderColor: button.buttonColor ? button.buttonColor : 'unset',
          color: button.buttonLabelColor ? button.buttonLabelColor : 'unset',
        }}
        onClick={() => linkPath && history.push(linkPath)}
        className={classes.simpleButton}
        disabled={!button.buttonEnabled}
        variant={getButtonVariant(button.buttonStyle)}
      >
        {button.buttonLabel}
      </Button>
    </div>
  )
}
