import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSystemMessages,
  selectIsLoading,
  SystemMessagesList,
} from '../../features/system-messages'
import { selectLanguageContent } from '../../features/translation'
import { Loading } from '../../ui'
import { PageWrapper } from '../../ui/templates/page-wrapper'

export const SystemMessagesPage = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)

  useEffect(() => {
    dispatch(getSystemMessages())
  }, [])
  const isLoading = useSelector(selectIsLoading)

  if (isLoading) return <Loading />

  return (
    <PageWrapper
      className={classes.pageWrapper}
      title={i18n.messenger_screen_title}
    >
      <Box className={classes.messageWrapper}>
        <SystemMessagesWrapper>
          <SystemMessagesList />
        </SystemMessagesWrapper>
      </Box>
    </PageWrapper>
  )
}

const SystemMessagesWrapper = styled.div`
  background-color: inherit;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`
const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxWidth: '90vw',
  },
  header: {
    padding: 0,
    borderBottom: '1px solid #D7D7D7',
  },
  messageWrapper: {
    // maxWidth: 540,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down(500)]: {
      padding: '0px',
    },
  },
}))
