import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from '@material-ui/lab'
// import { TextField } from '@material-ui/core'
import React from 'react'
// import { TextFieldProps } from '@material-ui/core/TextField'
import { FilledTextField } from '../../../../ui'
import { TextFieldProps } from '../../../../ui/atoms/text-field/text-field'

export const FormAutocomplete = (
  props: {
    label?: string
    optionLabelKey: string
    selectByKey: string
    name: string
    value: any
    required?: boolean
    helperText: string | undefined
  } & AutocompleteProps<any, any, any, any> &
    TextFieldProps
) => {
  const {
    name,
    value,
    label,
    selectByKey,
    optionLabelKey,
    required,
    error,
    helperText,
  } = props

  return (
    <MuiAutocomplete
      {...props}
      getOptionSelected={(option: any, value: any) =>
        option[selectByKey] === value[selectByKey]
      }
      getOptionLabel={(option: any) => option[optionLabelKey] || ''}
      renderInput={(params: any) => (
        <FilledTextField
          {...params}
          label={label}
          name={name}
          value={value}
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
    />
  )
}
