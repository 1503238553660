import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { stripHtml } from 'string-strip-html'
import AlertGrey from '../../../../assets/icons/alert-grey.svg'
import Alert from '../../../../assets/icons/alert-red.svg'
import { selectLanguageContent } from '../../../../features/translation'
import { I18n } from '../../../../features/translation/types'
import { LastAssessmentData } from '../../../../features/user/types'
import { Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../../network/model'
import { DashboardCard, NetworkSettings } from '../../../network/type'
import { paths } from '../../../paths'
import {
  selectLatestResult,
  selectProfileData,
  selectUserCode,
} from '../../../profile/model'
import { SHADOW } from '../../constants'

export const PassportCard = (props: {
  button: DashboardCard
  linkPath: string
}) => {
  const history = useHistory()
  const profileData = useSelector(selectProfileData)
  const [gradient, setGradient] = useState({ start: '#858585', end: '#B0B0C9' })
  const { accountId, email, fullName, firstName, _id: userId } = profileData
  // const userdata = lsClient.getUserLS()
  // const tenantId = userdata?.tenantId
  const userCode = useSelector(selectUserCode)
  const lastAssessment = useSelector(selectLatestResult)
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles({ radius })
  const i18n = useSelector(selectLanguageContent)

  const goToPassport = () => {
    history.push(paths.app.myPassport())
  }

  useEffect(() => {
    if (networkSettings?.showHomeScreenBackgroundColor) {
      if (lastAssessment.status === 'CLEARED') {
        setGradient({ start: '#00C906', end: '#00E778' })
      } else if (lastAssessment.status === 'NOT_CLEARED') {
        setGradient({ start: '#FF5D17', end: '#FFAF14' })
      }
    }
  }, [lastAssessment])

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return (
    <>
      <Box
        onClick={() => goToPassport()}
        className={classes.passportContainer}
        style={{
          borderRadius: radius,
        }}
      >
        <Box className={classes.passport}>
          <Box className={classes.passportSection}>
            <Box className={classes.passportUserInfo}>
              <div>
                <Typography className={classes.passportTitle}>
                  My Passport
                </Typography>
                <Typography className={classes.passportName}>
                  {fullName}
                </Typography>
              </div>
              {networkSettings?.showLastAssessmentDate && (
                <div>
                  <Typography className={classes.assessmentTitle}>
                    {i18n.last_assessment_label}:
                  </Typography>
                  <Typography className={classes.assessmentDate}>
                    {lastAssessment.lastAssessment
                      ? new Date(
                          lastAssessment.lastAssessment
                        ).toLocaleDateString('en-GB', options)
                      : 'No assessment found'}
                  </Typography>
                </div>
              )}
              {networkSettings?.showClearanceStatusHomeScreen && (
                <Assessment
                  i18n={i18n}
                  lastAssessment={lastAssessment}
                  networkSettings={networkSettings}
                />
              )}
            </Box>
          </Box>
          {networkSettings?.showQRCode && (
            <Box
              className={classes.qrSection}
              style={{
                borderTopRightRadius: radius,
                borderBottomRightRadius: radius,
                background: `linear-gradient(to right, ${gradient.start} 0%, ${gradient.end}) 100%`,
              }}
            >
              <img className={classes.code} src={userCode} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const Assessment = ({
  i18n,
  networkSettings,
  lastAssessment,
}: {
  i18n: I18n
  lastAssessment: LastAssessmentData
  networkSettings: NetworkSettings
}) => {
  const classes = useStyles()

  const message = lastAssessment?.message
    ? stripHtml(lastAssessment.message)
        .result.replace(/([\r|\n]+)/g, ' ')
        .slice(0, 100)
    : ''

  const checkColorConfig = (color: string) => {
    return networkSettings.showHomeScreenBackgroundColor ? color : '#757575'
  }

  switch (lastAssessment.status) {
    case 'CLEARED': {
      return (
        <Box
          style={{ color: checkColorConfig('#009104') }}
          className={classes.clearance}
        >
          <CheckCircleIcon />
          <Typography
            style={{ color: checkColorConfig('#009104'), marginLeft: '5px' }}
            className={classes.assessmentText}
          >
            {message || i18n.cleared_for_work}
          </Typography>
        </Box>
      )
    }
    case 'NOT_CLEARED': {
      return (
        <Box
          style={{ color: checkColorConfig('#E22E2A') }}
          className={classes.clearance}
        >
          <img src={Alert} className={classes.headerIcon} />
          <Typography
            className={classes.assessmentText}
            style={{ color: checkColorConfig('#E22E2A'), marginLeft: '5px' }}
          >
            {message || i18n.not_cleared_for_work}
          </Typography>
        </Box>
      )
    }
    case 'NOT_CLEARED_TAKE_CONSULT':
    case 'UNKNOWN': {
      return (
        <Box style={{ color: '#757575' }} className={classes.clearance}>
          <img src={AlertGrey} className={classes.headerIcon} />
          <Typography
            className={classes.assessmentText}
            style={{ color: '#757575', marginLeft: '5px' }}
          >
            {message || i18n.not_cleared_for_work}
          </Typography>
        </Box>
      )
    }
    default: {
      return <></>
    }
  }
}

const useStyles = makeStyles((theme) => ({
  passportContainer: {
    'height': 'calc(20vw - 12px)',
    'maxHeight': '278px',
    'maxWidth': '528px',
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'space-between',
    'cursor': 'pointer',
    'backgroundColor': '#FFF',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    [theme.breakpoints.down(960)]: {
      maxWidth: '45vw',
      justifyContent: 'flex-start',
      width: '100%',
      height: 'unset',
      marginTop: '0px',
    },
    [theme.breakpoints.down(850)]: {
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: '90vw',
    },
  },
  passport: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  passportSection: {
    width: '100%',
    display: 'flex',
  },
  passportUserInfo: {
    margin: '8% 0px 8% 10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1400)]: {
      margin: '6% 0px 6% 10%',
    },
    [theme.breakpoints.down(900)]: {
      margin: '3% 0px 3% 10%',
    },
    [theme.breakpoints.down(600)]: {
      margin: '16px 0px 16px 16px',
    },
  },
  passportCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  passportTitle: {
    fontSize: '2rem',
    fontWeight: 500,
    color: '#505358',
    marginBottom: 24,
    [theme.breakpoints.down(1400)]: {
      marginBottom: 12,
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: '1.4rem',
      marginBottom: 4,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '1.5rem',
      marginBottom: 8,
    },
  },
  passportName: {
    'fontSize': 20,
    'fontWeight': 500,
    'lineHeight': '22px',
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'marginBottom': 16,
    [theme.breakpoints.down(1200)]: {
      fontSize: 16,
      marginBottom: 8,
    },
    [theme.breakpoints.down(900)]: {
      marginBottom: 0,
    },
  },
  assessmentText: {
    'fontSize': 20,
    'fontWeight': 500,
    'lineHeight': '22px',
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down(1100)]: {
      fontSize: 14,
      minWidth: '18ch',
    },
  },
  code: {
    borderRadius: 8,
    margin: '12% 12%',
    width: '90%',
    maxWidth: '216px',
    minWidth: '150px',
    [theme.breakpoints.down(1200)]: {
      width: '85%',
      minWidth: 'unset',
    },
    [theme.breakpoints.down(1000)]: {
      width: '80%',
      minWidth: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      width: 120,
      height: 120,
    },
  },
  headerIcon: {
    marginTop: '-2px',
    height: '26px',
    [theme.breakpoints.down(1100)]: {
      height: '20px',
    },
    [theme.breakpoints.down(600)]: {
      height: '18px',
    },
  },
  assessmentTitle: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginTop: '4px',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
  assessmentDate: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
    [theme.breakpoints.down(1000)]: {
      fontSize: 14,
      marginBottom: 4,
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: 8,
    },
  },
  clearance: {
    display: 'flex',
    alignItems: 'center',
  },
  qrSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    [theme.breakpoints.down(850)]: {
      width: '60%',
    },
  },
}))
