import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { testTypes } from '../../../../constants'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { lsClient } from '../../../../ls-client'
import { Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../../network/model'
import { CarouselItem, DashboardCard } from '../../../network/type'
import { paths } from '../../../paths'

export const CarouselCard = (props: {
  activeHealthService: boolean
  button: DashboardCard
  linkPath: string
  xl: boolean
}) => {
  const { button, xl } = props
  const history = useHistory()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = dashboardCardStyles({ radius })

  const getClick = (button: CarouselItem | undefined) => {
    if (!button) return null
    switch (button.itemButtonAction.value) {
      case 'TakeaTest':
      case 'GetTested': {
        return history.push(paths.app.getTested())
      }
      case 'HealthService': {
        lsClient.setUserLS('isHealthService', 'Y')
        return history.push(paths.symptomCheckerIntro())
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        return history.push(paths.symptomCheckerIntro())
      }
      case 'Resources': {
        if (button.link?.itemResourceId) {
          return history.push(
            paths.resourcesById(button.link.itemResourceId)
          )
        }
        return history.push(paths.resources())
      }
      // case 'ImportHealthRecords': {
      //   return paths.resources()
      // }
      case 'TestHistory': {
        return history.push(paths.testResults())
      }
      case 'PurchaseHistory':
      case 'CarePlans': {
        return history.push(paths.app.getTested())
      }
      case 'OrderTestKit': {
        return history.push(paths.selectTestOrder(testTypes.ORDER))
      }
      default:
        return ''
    }
  }

  return (
    <Box className={classes.largeCardWrapper}>
      <div className={classes.largeCard}>
        <Typography className={classes.topTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        {button.carouselItems?.[0] && xl && (
          <>
            <Box
              className={classes.carouselImageContainer}
              style={{
                cursor: 'pointer',
                backgroundImage: `url(${button.carouselItems?.[0].itemImageUrl})`,
              }}
              onClick={() => getClick(button.carouselItems?.[0])}
            />
            <Typography
              className={classes.dashboardButtonBody}
              style={{ marginTop: 16, fontWeight: 600 }}
            >
              <HTMLTextComponent
                HTMLString={button.carouselItems?.[0].itemTitle}
              />
            </Typography>
          </>
        )}
        <Box className={classes.secondaryItems}>
          {button.carouselItems &&
            button.carouselItems.length > 0 &&
            button.carouselItems.map((item, i) => (
              <React.Fragment key={`${item.itemButtonAction?._id}-${i}`}>
                {i === 0 && xl ? null : (
                  <Box className={classes.itemContainer}>
                    <Box
                      className={classes.smallImageContainer}
                      style={{
                        backgroundImage: `url(${item.itemImageUrl})`,
                      }}
                      onClick={() => getClick(item)}
                    />
                    <Typography className={classes.secondaryText}>
                      <HTMLTextComponent HTMLString={item.itemTitle} />
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            ))}
        </Box>
      </div>
    </Box>
  )
}
