import {
  Box,
  Drawer as MUIDrawer,
  List,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { appVersion } from '../../libs/utils'
import { Typography } from '../atoms'

interface DrawerProps {
  isOpen: boolean
  isDesktop: boolean
  onDrawerClose: () => void
  anchor?: 'left' | 'right'
  drawerHeader: React.ReactNode
  drawerItems: { DrawerItem: React.FunctionComponent; id: number }[]
  hideWhenClosed?: boolean
}
function getIsEventKeyboard(
  event: React.MouseEvent | React.KeyboardEvent
): event is React.KeyboardEvent {
  return event.type === 'keydown'
}

export const Drawer = ({
  isOpen,
  anchor = 'left',
  onDrawerClose,
  drawerHeader,
  drawerItems,
  isDesktop,
  hideWhenClosed,
}: DrawerProps) => {
  const classes = useStyles()


  const handleDrawerClose = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (getIsEventKeyboard(e) && (e.key === 'Tab' || e.key === 'Shift')) {
      return
    }
    onDrawerClose()
  }

  const renderFooter = () => {
    if (!isOpen) return null

    return (
      <Typography className={classes.footerText}>
        Powered by Safe Health Systems
      </Typography>
    )
  }

  const getDrawerStyles = () => {
    if (!isDesktop) return

    return `${classes.drawer} ${
      isOpen ? classes.drawerOpen : classes.drawerClose
    }`
  }

  return (
    <MUIDrawer
      anchor={anchor}
      elevation={1}
      open={isDesktop ? true : isOpen}
      onClose={handleDrawerClose}
      variant={isDesktop ? 'permanent' : 'temporary'}
      className={getDrawerStyles()}
      classes={{
        paper: getDrawerStyles(),
      }}
    >
      <Box className={classes.content}>
        <div role="presentation" onKeyDown={handleDrawerClose}>
          {drawerHeader}
          <List style={{ padding: 0 }} onClick={handleDrawerClose}>
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            {drawerItems.map(({ DrawerItem, id }) => (
              <DrawerItem key={id} />
            ))}
          </List>
        </div>
        <Box className={classes.footer}>
          {renderFooter()}
          <Typography className={classes.versionText}>
            {appVersion()}
          </Typography>
        </Box>
      </Box>
    </MUIDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 32,
  },
  footerText: {
    alignSelf: 'center',
    fontSize: 14,
    color: '#7E7E7E',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: '1s',
    }),
  },
  versionText: {
    alignSelf: 'center',
    fontSize: 11,
    color: '#7E7E7E',
    whiteSpace: 'nowrap',
  },
  drawer: {
    display: 'flex',
    top: '0',
    overflowX: 'hidden',
  },
  drawerOpen: {
    width: 241,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9),
  },
}))
