import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { handleError } from '../../libs/helpers'

interface facilitySlice {
  isLoading: boolean
  isMoreLoading: boolean
  facilitiesList: any[]
}

interface IFedExParams {
  logisticsPartner: string,
  longitude?: string,
  latitude?: string,
  orderId: string,
  address?: Record<string, string>
}

const initialStore: facilitySlice = {
  isLoading: true,
  isMoreLoading: false,
  facilitiesList: [],
}

export const facilitySlice = createSlice({
  name: 'facility',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setMoreLoading(store, { payload }: { payload: boolean }) {
      store.isMoreLoading = payload
    },
    setFacilitiesList(store, { payload }: { payload: any }) {
      store.facilitiesList = payload
    },
    setMoreFacilities(store, { payload }: { payload: any }) {
      store.facilitiesList = [...store.facilitiesList, ...payload]
    },
  },
})

const {
  setLoading,
  setFacilitiesList,
  setMoreFacilities,
  setMoreLoading,
} = facilitySlice.actions

export const getFacilities = (
  { term = '', lat = 0, lng = 0, pageNumber = 0, pageSize = 10 },
  loadMore = false,
) => async (dispatch: any, getStore: any) => {

  const { languageContent } = getStore().translation

  if (!loadMore) {
    dispatch(setLoading(true))
  } else {
    dispatch(setMoreLoading(true))
  }

  // const state = getState().profile.data.primaryAddress.state
  const { success, data, error } = await api.getFacilitiesRequest({
    pageSize,
    pageNumber,
    state: 'CA',
    longitude: lng,
    latitude: lat,
    term,
  })

  if (error) {
    // try to get translated error by key from localization file or render received error string
    dispatch(handleError(null, languageContent[error] || error))
    dispatch(setMoreLoading(false))
    return dispatch(setLoading(false))
  }

  if (success && data) {
    if (!loadMore) {
      dispatch(setFacilitiesList(data))
      dispatch(setLoading(false))
    } else {
      dispatch(setMoreLoading(false))
      dispatch(setMoreFacilities(data))
    }
  }
}

export const getFedExOffices = (
  { term = '', lat = 0, lng = 0, orderId = '' },
) => async (dispatch: any, getStore: any) => {

  const { languageContent } = getStore().translation
  const userAddress = getStore().profile.data.primaryAddress

  dispatch(setLoading(true))

  // const params: IFedExParams = {
  //   logisticsPartner: 'Fedex',
  //   // longitude: lng.toString(),
  //   // latitude: lat.toString(),
  //   orderId: '3BN8sQDx7UqqfL',
  //   // phoneNumber: "string",
  //   address: {
  //     addressLine: userAddress?.line1 || '',
  //     city: userAddress?.city || '',
  //     stateOrProvinceCode: userAddress?.state || '',
  //     postalCode: userAddress?.zip || '',
  //     countryCode: 'US',
  //   },
  // }

  const params: IFedExParams = {
    logisticsPartner: 'Fedex',
    // longitude: '42.384923',
    // latitude: '-83.503604',
    orderId: '3BN8sQDx7UqqfL',
    // phoneNumber: "string",
    address: {
      addressLine: '46501 Commerce Center Dr',
      city: 'Plymouth',
      stateOrProvinceCode: 'MI',
      postalCode: '48170',
      countryCode: 'US',
    },
  }

  const { success, data, error } = await api.getFedExOfficesRequest(params)

  if (error) {
    // try to get translated error by key from localization file or render received error string
    dispatch(handleError(null, languageContent[error] || error))
    return dispatch(setLoading(false))
  }

  if (success && data) {
    const updatedData = data.map((item: any) => {
        return {
          name: item?.contact?.companyName || 'FedEx Office',
          address1: item?.address?.streetLines || '',
          city: item?.address?.city || '',
          state: item?.address?.stateOrProvinceCode || '',
          zip: item?.address?.postalCode || '',
          latitudeLongitudeDistance: item?.distance?.value || '',
          latitude: item?.address?.latitude || '',
          longitude: item?.address?.longitude || '',
          phone: item?.contact?.phoneNumber || '',
          website: '',
          ...item,
        }

    })
    dispatch(setFacilitiesList(updatedData))
    dispatch(setLoading(false))
  }
}

export const facilityReducer = facilitySlice.reducer

export const facilityReducerName = facilitySlice.name

interface RootStore {
  [facilityReducerName]: typeof initialStore
}

export const selectLoading = ({ facility }: RootStore) => facility.isLoading
export const selectMoreLoading = ({ facility }: RootStore) =>
  facility.isMoreLoading

export const selectFacilitiesList = ({ facility }: RootStore) =>
  facility.facilitiesList
