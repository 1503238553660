import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { NOTIFICATION_TYPES, VerificationStatus } from '../../constants'
import { showNotification } from '../../features/notifications/model'
import { I18n } from '../../features/translation/types'
import { AssessmentState, LastAssessmentData } from '../../features/user/types'
import {
  convertArrayBufferToBlobUrl,
  convertBase64ToBlobUrl,
} from '../../libs/files'
import { handleError } from '../../libs/helpers'
import { ProfileData, UserContact } from './type'

interface CreateSlice {
  data: ProfileData
  avatar: string
  userCode: string
  lastAssessment: LastAssessmentData
  isLoading: boolean
  latestResult: any
}
const initialStore: CreateSlice = {
  avatar: '',
  userCode: '',
  latestResult: {},
  lastAssessment: {
    enumStatusID: 0,
    lastAssessment: '',
    message: '',
    status: '',
    questionnaireExpired: '',
  },
  isLoading: false,
  data: {
    accountId: '',
    billingAddress: {
      line1: '',
      city: '',
      state: '',
      zip: '',
    },
    biometricsEnabled: false,
    birthDate: null,
    complianceStatus: AssessmentState.UNKNOWN,
    contact: {
      faxCode: undefined,
      faxNumber: undefined,
      homeCountryCode: undefined,
      homeNumber: undefined,
      workCountryCode: undefined,
      workNumber: undefined,
      mobileCountryCode: undefined,
      mobileNumberverified: false,
      mobileNumber: undefined,
    },
    created: '',
    creator: '',
    email: '',
    firstName: '',
    fullName: '',
    gender: null,
    hasDependants: false,
    identityId: '',
    infectionTestDates: [],
    infectionTestResults: null,
    isGuardianMode: false,
    isIdentityVerified: false,
    lastLoginDateTime: '',
    lastName: '',
    lastVaccineRecordResults: null,
    memberOverrideDates: null,
    metadata: { _PERMISSION: 'DEFAULT' },
    modified: '',
    modifier: '',
    nationalHealthId: '',
    notificationOnRegistrationSent: false,
    primaryAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
    },
    race: '',
    rootAdminAccount: false,
    settings: {
      acceptEmailNotification: false,
      acceptMessageNotification: false,
      acceptSMSNotification: false,
      badgeSharingEnabled: false,
      biometricEnabled: false,
      firstTimeLogin: false,
      verificationStatus: VerificationStatus.UNKNOWN,
    },
    currentLocation: null,
    status: '',
    superhero: false,
    tenantId: '',
    vaccineStatus: [],
    _id: '',
  },
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialStore,
  reducers: {
    setProfileData(store, { payload }: { payload: ProfileData }) {
      store.data = {
        ...payload,
        fullName: `${payload.firstName} ${payload.lastName}`,
      }
    },
    setProfilePicture(store, { payload }: { payload: string }) {
      store.avatar = payload
    },
    setContactInfo(store, { payload }: { payload: UserContact }) {
      store.data.contact = payload
    },
    setUserCode(store, { payload }: { payload: string }) {
      store.userCode = payload
    },
    setUserLastAssessment(store, { payload }: { payload: LastAssessmentData }) {
      store.lastAssessment = payload
    },
    setLatestResult(store, { payload }: { payload: any }) {
      store.latestResult = payload
    },
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    resetProfileStore: () => initialStore,
  },
})

const {
  setProfilePicture,
  setUserCode,
  setUserLastAssessment,
  setLoading,
  setLatestResult,
} = profileSlice.actions
export const { setProfileData, setContactInfo, resetProfileStore } =
  profileSlice.actions

export const getProfileData =
  (forVerification = false, callback?: (data: ProfileData) => void) =>
  async (dispatch: any) => {
    const { isOK, data } = await api.getProfile()

    if (isOK && data) {
      if (callback) {
        callback(data)
      }
      dispatch(setProfileData(data))
    }
  }

export const getUserDependents = () => async (dispatch: any, getStore: any) => {
  const userId = getStore().profile.data._id
  const { isOK, data } = await api.getDependents(userId)

  if (isOK && data) {
    dispatch(setProfileData(data))
  }
}

export const getUserPicture =
  (payload: { accountId: string; email: string }) => async (dispatch: any) => {
    const { data, isOK, errorMessage } = await api.getPicture(payload)
    if (isOK && data) {
      dispatch(setProfilePicture(convertBase64ToBlobUrl(data)))
    }
  }

export const updateUserAvatar = (file: File) => async (dispatch: any) => {
  const { data, isOK, errorMessage } = await api.updatePicture(file)
  if (isOK) {
    dispatch(setProfilePicture(convertArrayBufferToBlobUrl(file)))
  }
}

export const getUserCode =
  (email: string, accountId: string) =>
  async (dispatch: any, getStore: any) => {
    // dispatch(setLoading(true))

    const file = await api.getUserCode({ email, accountId })

    if (file) {
      const image = convertArrayBufferToBlobUrl(file, 'image/png')
      dispatch(setUserCode(image))
    } else {
      // dispatch(setError("Sorry, no QR available"))
      dispatch(handleError({ message: 'Sorry, no QR available' }))

      // dispatch(setLoading(false))
    }
  }

const getLatestResult =
  (userId: string) => async (dispatch: any, getStore: any) => {
    const result = await api.getLatestResult()
    if (result) {
      dispatch(setLatestResult(result))
    } else {
      dispatch(handleError({ message: 'Unable to get latest result' }))
    }
  }

export const updateUserData =
  (updatedUser: any, withNotification = true, callback?: () => void) =>
  async (dispatch: any) => {
    dispatch(setLoading(true))
    const { isOK, data, error } = await api.updateProfile(updatedUser)

    if (error || !isOK) {
      dispatch(setLoading(false))
      dispatch(handleError({ message: error }))
    }

    if (data && isOK) {
      dispatch(setLoading(false))
      if (withNotification) {
        dispatch(
          showNotification(
            'User data successfully updated',
            NOTIFICATION_TYPES.SUCCESS
          )
        )
      }
      dispatch(setProfileData(data))
      if (callback) {
        callback()
      }
      return true
    }
    return false
  }

export const updateUserContact =
  (updatedUserContact: any, withNotification = true, callback?: () => void) =>
  async (dispatch: any, getStore: any) => {
    const i18n = getStore().translation.languageContent as I18n
    dispatch(setLoading(true))
    const { isOK, data, error } = await api.updateProfileContact(
      updatedUserContact
    )

    if (error || !isOK) {
      dispatch(setLoading(false))
      dispatch(handleError({ message: error }))
    }

    if (data && isOK) {
      dispatch(setLoading(false))
      if (withNotification) {
        dispatch(
          showNotification(i18n.changes_saved, NOTIFICATION_TYPES.SUCCESS)
        )
      }
      dispatch(setContactInfo(updatedUserContact.contact))
      if (callback) {
        callback()
      }
      return true
    }
    return false
  }

export const profileReducer = profileSlice.reducer
export const profileReducerName = profileSlice.name

interface RootStore {
  [profileReducerName]: typeof initialStore
}

export const selectProfileData = ({ profile }: RootStore) => profile.data
export const selectUserAvatar = ({ profile }: RootStore) => profile.avatar
export const selectIsUserVerified = ({ profile }: RootStore) => {
  const currentStatus = profile.data?.settings?.verificationStatus
  return (
    currentStatus === VerificationStatus.UNKNOWN ||
    currentStatus === VerificationStatus.VERIFIED
  )
}

export const selectNationalHealthId = ({ profile }: RootStore) =>
  profile.data.nationalHealthId

export const selectUserCode = ({ profile }: RootStore) => profile.userCode

export const selectLastAssessment = ({ profile }: RootStore) =>
  profile.lastAssessment

export const selectProfileIsLoading = ({ profile }: RootStore) =>
  profile.isLoading

export const selectLatestResult = ({ profile }: RootStore) =>
  profile.latestResult

export const selectHasDependents = ({ profile }: RootStore) =>
  profile.data.hasDependants
