import { FormHelperText, FormLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { TextField } from '../../atoms'
import { Autocomplete } from '../../molecules'
import { Option } from '../../molecules/autocomplete/autocomplete'

/* eslint-disable @typescript-eslint/ban-ts-comment */
const months = [
  { label: 'Jan', value: 0 },
  { label: 'Feb', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Apr', value: 3 },
  { label: 'May', value: 4 },
  { label: 'Jun', value: 5 },
  { label: 'Jul', value: 6 },
  { label: 'Aug', value: 7 },
  { label: 'Sep', value: 8 },
  { label: 'Oct', value: 9 },
  { label: 'Nov', value: 10 },
  { label: 'Dec', value: 11 },
]
interface NewDatePickerProps {
  value?: Date | null
  onChange?: (newDate: Date | null) => void
  onBlur?: (e: React.FocusEvent) => void
  label?: string
  required?: boolean
  helperText?: string
}
export const NewDatePicker: React.FC<NewDatePickerProps> = ({
  value,
  onChange,
  label,
  onBlur,
  required,
  helperText,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [year, setYear] = React.useState<number | null>(
    value ? value.getFullYear() : null
  )
  const [month, setMonth] = React.useState<number | null>(
    value ? value.getMonth() : null
  )
  const [day, setDay] = React.useState<number | null>(
    value ? value.getDate() : null
  )

  const yearRef = React.useRef<HTMLInputElement>(null)
  const monthRef = React.useRef<HTMLInputElement>(null)
  const dayRef = React.useRef<HTMLInputElement>(null)

  const classes = useStyles()

  const i18n = useSelector(selectLanguageContent)

  const getMonth = () => months.find((m) => m.value === month)

  React.useEffect(() => {
    if (value) {
      setYear(value.getFullYear())
      setMonth(value.getMonth())
      setDay(value.getDate())
    }
  }, [value])

  const handleChangeResponse = ({
    d,
    m,
    y,
  }: {
    d: null | number
    m: null | number
    y: null | number
  }) => {
    const isDateTypesCorrect =
      typeof d === 'number' && typeof m === 'number' && typeof y === 'number'
    const isYearDayLengthCorrect = `${y}`.length === 4 && `${d}`.length > 0
    const isDateValid = isDateTypesCorrect && isYearDayLengthCorrect

    if (!isDateValid) {
      if (onChange) onChange(null)
      setYear(y)
      setMonth(m)
      setDay(d)
      return
    }
    if (typeof y !== 'number') return
    if (typeof m !== 'number') return
    if (typeof d !== 'number') return
    let correctD = d
    const dateCandidate = new Date(y, m, d)
    if (dateCandidate.getMonth() !== m) {
      correctD = d - dateCandidate.getDate()
    }

    if (onChange) onChange(new Date(y, m, correctD))
    setYear(y)
    setMonth(m)
    setDay(correctD)
  }

  const handleMonthChange = (_: any, monthOption?: Option) => {
    const m = monthOption ? monthOption.value : null

    handleChangeResponse({ d: day, m, y: year })
  }

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const y = e.target.valueAsNumber
    if (Number.isNaN(y)) {
      setYear(null)
      if (onChange) onChange(null)
      return
    }
    if (`${y}`.length < 4) {
      setYear(y)
      if (onChange) onChange(null)
      return
    }
    if (`${y}`.length > 4) return

    handleChangeResponse({ d: day, m: month, y })
    setYear(y)
  }

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const d = e.target.valueAsNumber
    if (Number.isNaN(d)) {
      setDay(null)
      if (onChange) onChange(null)
      return
    }
    if (`${d}`.length > 2) return

    let correctD = d
    if (d > 31) correctD = 31
    handleChangeResponse({ d: correctD, m: month, y: year })
  }

  const preventExponent = (e: React.KeyboardEvent) => {
    if (e.key === 'e') e.preventDefault()
  }

  const getIsNewFocusInsideDateComponent = () => {
    if (!yearRef.current) return
    if (!monthRef.current) return
    if (!dayRef.current) return

    if (yearRef.current.contains(document.activeElement)) return true
    if (monthRef.current.contains(document.activeElement)) return true
    if (dayRef.current.contains(document.activeElement)) return true
    return false
  }
  const handleBlur = (e: React.FocusEvent) => {
    if (!onBlur) return

    setTimeout(() => {
      const isFocusInside = getIsNewFocusInsideDateComponent()
      if (!isFocusInside) onBlur(e)
    }, 50)
  }

  return (
    <div>
      <FormLabel
        required={required}
        error={Boolean(helperText)}
        className={classes.label}
      >
        {label}
      </FormLabel>
      <div className={classes.inputWrapper}>
        <Autocomplete
          options={months}
          // @ts-ignore
          value={getMonth() || null}
          // @ts-ignore
          onChange={handleMonthChange}
          label={i18n.month}
          onBlur={handleBlur}
          ref={monthRef}
        />
        <TextField
          type="number"
          label={i18n.day}
          value={day || ''}
          onKeyDown={preventExponent}
          onChange={handleDayChange}
          min={1}
          max={31}
          onBlur={handleBlur}
          ref={dayRef}
        />
        <TextField
          type="number"
          label={i18n.year}
          value={year || ''}
          onChange={handleYearChange}
          onBlur={handleBlur}
          onKeyDown={preventExponent}
          ref={yearRef}
        />
      </div>
      <FormHelperText error={Boolean(helperText)}>{helperText}</FormHelperText>
    </div>
  )
}

const useStyles = makeStyles({
  label: {
    fontSize: '10px',
    color: '#505358',
  },
  inputWrapper: {
    marginTop: 4,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '1rem',
  },
})
