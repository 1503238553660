import styled from '@emotion/styled'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from '../../../pages/paths'

export const Header = () => {
  const history = useHistory()
  const { id: messageId } = useParams<{ id: string }>()

  const handleBack = () => {
    history.push(
      !messageId ? paths.app.dashboard() : paths.app.systemMessages()
    )
  }

  return (
    <HeaderStyled>
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6" align={'center'}>
        Secure Messenger
      </Typography>
    </HeaderStyled>
  )
}
const HeaderStyled = styled.div`
  border-bottom: 1px solid #efefef;
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  align-items: center;
  color: rgb(0, 60, 114);
`
