import { Dialog, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { clearTestKitFlow } from '../../../libs/helpers'
import { InstructionV2 } from '../../../types/tests'
import { Button } from '../../../ui'
import { paths } from '../../paths'
import {
  cancelProctorSession,
  selectProctorSessionData,
  selectProctorStatus,
} from '../../proctor/model'
import { DialogProps } from '../types'

interface CancelDialogProps extends DialogProps {
  current: InstructionV2 | undefined
}

export const CancelTestDialog = (props: CancelDialogProps) => {
  const { open, setDialogOpen, current } = props
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const session = useSelector(selectProctorSessionData)
  const proctorStatus = useSelector(selectProctorStatus)
  const i18n = useSelector(selectLanguageContent)

  const endTest = () => {
    if (!!proctorStatus) {
      dispatch(cancelProctorSession(session?.channelId, false))
    }
    clearTestKitFlow()
    setDialogOpen(false)
    history.push(paths.app.getTested())
  }

  return (
    <Dialog open={open}>
      <Box className={classes.schedulePageHolder}>
        <Box className={classes.scheduleWrapper}>
          <Box className={classes.headerWrapper}>
            <Typography
              variant="h4"
              color="primary"
              className={classes.title}
              align="left"
            >
              {proctorStatus
                ? current?.type === 'ProctorWaitingRoomInstruction'
                  ? 'Leave Waiting Room'
                  : 'Leave Proctored Session?'
                : 'Would you like to end the session?'}
            </Typography>
            <Typography variant="body1" className={classes.body}>
              {proctorStatus
                ? current?.type === 'ProctorWaitingRoomInstruction'
                  ? 'If you leave, this session will be cancelled. Do not open your test kit until you are with a Proctor.'
                  : 'If you leave this session, your test kit will be invalid and you will be taken to your home screen.'
                : 'If you end this session, you will be sent back to the Testing Options screen and your test will be invalidated.'}
            </Typography>
          </Box>
          <Box className={classes.lgButtonBox}>
            <Button
              onClick={endTest}
              className={classes.confirmButton}
              variant="outlined"
            >
              {proctorStatus
                ? current?.type === 'ProctorWaitingRoomInstruction'
                  ? 'Leave'
                  : 'End Session'
                : 'End Session'}
            </Button>
            <Button
              onClick={() => setDialogOpen(false)}
              className={classes.confirmButton}
            >
              {current?.type === 'ProctorWaitingRoomInstruction'
                ? 'Stay'
                : 'Continue'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '30px',
    marginBottom: '28px',
    width: '100%',
  },
  body: {
    color: '#7b7c7d',
    fontSize: '18px',
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(576)]: {
      flexDirection: 'column',
    },
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '10px 24px',
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      margin: '14px auto',
      minWidth: '250px',
    },
  },
  schedulePageHolder: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: '8px',
    width: 571,
    height: 279,
    [theme.breakpoints.down(560)]: {
      padding: '0px',
      width: 'unset',
      height: 'unset',
    },
  },
  scheduleWrapper: {
    width: '100%',
    margin: '40px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.down(500)]: {
      margin: '24px',
    },
  },
}))
