import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'

interface SupportSlice {
  isLoading: boolean
}
const initialStore: SupportSlice = {
  isLoading: false,
}

export const supportSlice = createSlice({
  name: 'support',
  initialState: initialStore,
  reducers: {
    startLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
  },
})
const { startLoading } = supportSlice.actions

export const sendMessage =
  (
    email: string,
    userName: string,
    purpose: string,
    message: string,
    meta: string
  ) =>
  async (dispatch: Dispatch, getStore: any) => {
    if (!email) return false
    const zendeskTicket = JSON.stringify({
      request: {
        subject: purpose,
        comment: { body: message + meta },
        requester: { name: userName, email },
      },
    })
    const { request } = await api.submitZendeskSupport(zendeskTicket, email)
    return request
  }

export const adminDirectSupport =
  (
    email: string,
    userName: string,
    subject: string,
    message: string,
    meta: string
  ) =>
  async (dispatch: Dispatch, getStore: any) => {
    if (!email) return false
    const params = {
      body: message + meta,
      subject,
    }
    const { request } = await api.adminDirectSupport(params)
    return request
  }
