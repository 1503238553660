import styled from '@emotion/styled'
import { Avatar, Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectProfileData,
  selectUserAvatar,
} from '../../../pages/profile/model'
import { drawerOpened } from '../model'

export const AvatarAndFullName = ({
  light,
  forHeader,
}: {
  light?: boolean
  forHeader?: boolean
}) => {
  const classes = useStyles()
  const userAvatar = useSelector(selectUserAvatar)
  const { fullName } = useSelector(selectProfileData)
  const dispatch = useDispatch()
  const handleDrawerOpen = () => {
    dispatch(drawerOpened())
  }

  return (
    <div>
      <Button onClick={handleDrawerOpen} color="inherit">
        <AvatarAndName>
          <Avatar className={classes.ava} src={userAvatar} />
          {!forHeader && (
            <Typography
              className={light ? classes.fullNameWhite : classes.fullName}
            >
              {fullName}
            </Typography>
          )}
        </AvatarAndName>
      </Button>
    </div>
  )
}

const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
`

const useStyles = makeStyles((theme) => ({
  ava: {
    width: 24,
    height: 24,
  },
  fullName: {
    color: 'black',
    fontSize: 14,
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  fullNameWhite: {
    color: '#FFF',
    fontSize: 14,
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
}))
