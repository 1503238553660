import Box from '@material-ui/core/Box'
import { Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../features/translation'
import { Typography } from '../../ui'
import { onboardingStyles } from '../_styles/registrationStyles'
import { HelpCenter } from '../register/components/helpCenter'

export const SignUpFaq = (props: { onClose: (prop1: boolean) => void }) => {
  const { onClose } = props
  const i18n = useSelector(selectLanguageContent)
  const classes = onboardingStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  const tenantId = localStorage.getItem('tenantId') || ''

  return (
    <Box className={classes.stepContent} >
      <div className={classes.backIconWrapper} onClick={() => onClose(false)}>
        <ArrowBackIcon className={classes.pageLink} />
        {!isMobile && (
          <Typography className={classes.pageLink}>
            {i18n.back_button}
          </Typography>
        )}
      </div>
      <div className={classes.helpCenter}>
        <HelpCenter tenantId={tenantId} />
      </div>
    </Box>
  )
}
