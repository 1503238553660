import { Dialog } from '@material-ui/core'
import React, { useState } from 'react'
import { SupportMessageSentPage } from '../support-message-sent/page'
import { ContactSupportPage } from './page'

export const SupportDialog = (props: {
  open: boolean
  setSupportDialogOpen: any
}) => {
  const { open, setSupportDialogOpen } = props
  const [messageSent, setMessageSent] = useState(false)

  return (
    <Dialog open={open} fullScreen>
      {!messageSent ? (
        <ContactSupportPage
          setSupportDialogOpen={setSupportDialogOpen}
          setMessageSent={setMessageSent}
          isDialogWindow
        />
      ) : (
        <SupportMessageSentPage
          setSupportDialogOpen={setSupportDialogOpen}
          isDialogWindow
        />
      )}
    </Dialog>
  )
}
