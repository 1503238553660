import { Button, ButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export const ActionButton = ({ className, ...props }: ButtonProps) => {
  const classes = useStyles()
  return <Button {...props} className={`${classes.button} ${className}`} />
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
  },
}))
