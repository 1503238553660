import { Box } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Button, Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { DashboardCard } from '../../../network/type'

export const TransparentCard = (props: {
  activeHealthService: boolean
  button: DashboardCard
  linkPath: string
  xl?: boolean
}) => {
  const { button, linkPath } = props
  const history = useHistory()
  const classes = dashboardCardStyles({ radius: 0 })
  return (
    <Box className={classes.transparentCard}>
      {button.cardImageUrl && (
        <Box className={classes.transparentImageContainer}>
          <img src={button.cardImageUrl} className={classes.image} />
        </Box>
      )}
      <Box className={classes.transparentButtonCard}>
        <Typography className={classes.dashboardButtonTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        <Typography className={classes.dashboardButtonBody}>
          <HTMLTextComponent HTMLString={button.cardBody} />
        </Typography>
        {button.buttonAction && (
          <div className={classes.buttonWrapper}>
            <Button
              style={{
                backgroundColor: button.buttonColor
                  ? button.buttonColor
                  : 'unset',
                borderColor: button.buttonColor ? button.buttonColor : 'unset',
                color: button.buttonLabelColor
                  ? button.buttonLabelColor
                  : 'unset',
              }}
              onClick={() => linkPath && history.push(linkPath)}
              className={classes.confirmButton}
              disabled={!button.buttonEnabled}
              variant="contained"
            >
              {button.buttonLabel}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  )
}
