import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import {
  applicationReducer,
  applicationReducerName,
} from '../features/application'
import {
  dialogSliceReducer,
  dialogSliceReducerName,
} from '../features/dialog/model'
import {
  encounterServiceReducer,
  encounterServiceReducerName,
} from '../features/encounter/model'
import {
  healthServiceLiteReducer,
  healthServiceLiteReducerName,
} from '../features/health-service-lite/model'
import {
  notificationReducer,
  notificationReducerName,
} from '../features/notifications/model'
import {
  orderStatusSnackbarReducer,
  orderStatusSnackbarReducerName,
} from '../features/order-status-snackbar/model'
import {
  pushNotificationReducer,
  pushNotificationReducerName,
} from '../features/pusher-notifications/model'
import {
  shopifyReducer,
  shopifyReducerName,
} from '../features/shopify/model/shopifyStoreSlice'
import { systemMessagesSlice } from '../features/system-messages'
import { termsReducer, termsReducerName } from '../features/terms'
import {
  testResultsReducer,
  testResultsReducerName,
} from '../features/test-results'
import {
  translationReducer,
  translationReducerName,
} from '../features/translation'
import {
  userTenantReducerName,
  userTenantSliceReducer,
} from '../features/user/model/userTenantSlice'
import { checkoutReducer, checkoutReducerName } from '../pages/checkout/model'
import {
  dashboardPageReducer,
  dashboardPageReducerName,
} from '../pages/dashboard/model'
import {
  dependentsReducer,
  dependentsReducerName,
} from '../pages/dependents/model/dependentsSlice'
import { facilityReducer, facilityReducerName } from '../pages/facility/model'
import { faqReducer, faqReducerName } from '../pages/faq/faqSlice'
import { guardianReducer, guardianReducerName } from '../pages/guardian/model'
import {
  uploadKitScanReducer,
  uploadKitScanReducerName,
} from '../pages/instructions/model'
import {
  insuranceReducer,
  insuranceReducerName,
} from '../pages/insurance/model'
import {
  inviteRegistrationReducer,
  inviteRegistrationReducerName,
} from '../pages/invite-web-registration/model/inviteRegistrationSlice'
import { networkReducer, networkReducerName } from '../pages/network/model'
import {
  selectTestKitReducer,
  selectTestKitReducerName,
} from '../pages/order-test/model'
import {
  outcomeResultReducer,
  outcomeResultReducerName,
} from '../pages/outcomes/model'
import {
  resetPasswordReducer,
  resetPasswordReducerName,
} from '../pages/password-reset/passwordResetSlice'
import {
  paymentMethodsReducer,
  paymentMethodsReducerName,
} from '../pages/payment-methods/model'
import { proctorReducer, proctorReducerName } from '../pages/proctor/model'
import { profileReducer, profileReducerName } from '../pages/profile/model'
import {
  questionnaireV2Reducer,
  questionnaireV2ReducerName,
} from '../pages/questionnaireV2/questionnaireV2Slice'
import {
  registrationReducer,
  registrationReducerName,
} from '../pages/register/model/registerSlice'
import {
  testSliceReducer,
  testSliceReducerName,
} from '../pages/register-a-test-module/model'
import {
  resourcesReducer,
  resourcesReducerName,
} from '../pages/resources/resourcesSlice'
import {
  scanOrderCodeReducerName,
  scanOrderCodeSliceReducer,
} from '../pages/scan-qr/model'
import {
  scheduleAppointmentReducer,
  scheduleAppointmentReducerName,
} from '../pages/schedule-appointment/model'
import {
  shippingLocationReducer,
  shippingLocationReducerName,
} from '../pages/shipping/shippingTestSlice'
import {
  symptomCheckerIntroductionReducerName,
  symptomCheckerIntroductionSliceReducer,
} from '../pages/symptom-checker-introduction/symptomCheckerIntroSlice'
import {
  telehealthReducer,
  telehealthReducerName,
} from '../pages/telehealth/model'
import {
  tenantReducerName,
  tenantSliceReducer,
} from '../pages/tenant-selection/tenantSlice'
import {
  termsAndCondsPageReducer,
  termsAndCondsPageReducerName,
} from '../pages/terms-and-conds/model/model'
import {
  testDetailsReducer,
  testDetailsReducerName,
} from '../pages/test-details/model'
import {
  testResultsV2Reducer,
  testResultsV2ReducerName,
} from '../pages/test-results/model/model'
import { welcomeReducer, welcomeReducerName } from '../pages/welcome-page/model'

const registerConfig = {
  key: 'register',
  storage,
}

export const index = combineReducers({
  [pushNotificationReducerName]: pushNotificationReducer,
  [testResultsReducerName]: testResultsReducer,
  [testResultsV2ReducerName]: testResultsV2Reducer,
  [testSliceReducerName]: testSliceReducer,
  systemMessages: systemMessagesSlice.reducer,
  [applicationReducerName]: applicationReducer,
  [termsReducerName]: termsReducer,
  [dialogSliceReducerName]: dialogSliceReducer,
  [translationReducerName]: translationReducer,
  [termsAndCondsPageReducerName]: termsAndCondsPageReducer,
  [guardianReducerName]: guardianReducer,
  [welcomeReducerName]: welcomeReducer,
  [healthServiceLiteReducerName]: healthServiceLiteReducer,
  [dashboardPageReducerName]: dashboardPageReducer,
  [dependentsReducerName]: dependentsReducer,
  [scanOrderCodeReducerName]: scanOrderCodeSliceReducer,
  [uploadKitScanReducerName]: uploadKitScanReducer,
  [encounterServiceReducerName]: encounterServiceReducer,
  [outcomeResultReducerName]: outcomeResultReducer,
  [selectTestKitReducerName]: selectTestKitReducer,
  [checkoutReducerName]: checkoutReducer,
  [facilityReducerName]: facilityReducer,
  [paymentMethodsReducerName]: paymentMethodsReducer,
  [proctorReducerName]: proctorReducer,
  [profileReducerName]: profileReducer,
  [scheduleAppointmentReducerName]: scheduleAppointmentReducer,
  [networkReducerName]: networkReducer,
  [insuranceReducerName]: insuranceReducer,
  [notificationReducerName]: notificationReducer,
  [orderStatusSnackbarReducerName]: orderStatusSnackbarReducer,
  [resourcesReducerName]: resourcesReducer,
  [faqReducerName]: faqReducer,
  [testDetailsReducerName]: testDetailsReducer,
  [tenantReducerName]: tenantSliceReducer,
  [telehealthReducerName]: telehealthReducer,
  [userTenantReducerName]: userTenantSliceReducer,
  [resetPasswordReducerName]: resetPasswordReducer,
  [questionnaireV2ReducerName]: questionnaireV2Reducer,
  [symptomCheckerIntroductionReducerName]:
    symptomCheckerIntroductionSliceReducer,
  [inviteRegistrationReducerName]: inviteRegistrationReducer,
  [shippingLocationReducerName]: shippingLocationReducer,
  [shopifyReducerName]: shopifyReducer,

  [registrationReducerName]: persistReducer(
    registerConfig,
    registrationReducer
  ),

  // [selectAppointmentTestKitReducerName]: selectAppointmentTestKitReducer,
})

const rootReducer = (state: RootStateOrAny, action: Action) => {
  if (action.type === `${userTenantReducerName}/doLogout`) {
    state = undefined
  }
  return index(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
})

export const persistor = persistStore(store)
