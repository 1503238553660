import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../../network/model'
import { DashboardCard } from '../../../network/type'
import { SHADOW } from '../../constants'

export const GradientCard = (props: {
  activeHealthService: boolean
  button: DashboardCard
  linkPath: string
  xl?: boolean
}) => {
  const { button, xl = false, activeHealthService, linkPath } = props
  const history = useHistory()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles({ radius })

  let gradientStyle = 'linear-gradient(to right, #00C93C, #00C6DD)'
  if (
    button.cardGradientColor.length > 0 &&
    !button.cardGradientColor.includes(null)
  ) {
    gradientStyle = `linear-gradient(to right, ${button.cardGradientColor[0]},  ${button.cardGradientColor[1]})`
  }
  return (
    <Box
      key={button.cardTitle}
      className={
        xl
          ? `${classes.gradientButtonContainer} ${classes.xlCard}`
          : activeHealthService
          ? `${classes.gradientButtonContainer} ${classes.xlCardHidden}`
          : `${classes.gradientButtonContainer}`
      }
      style={{
        background: gradientStyle,
      }}
      onClick={() => linkPath && history.push(linkPath)}
    >
      <Box className={classes.dashboardButtonCard}>
        <Typography className={classes.dashboardButtonTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        {button.cardBody && (
          <Typography className={classes.dashboardButtonBody}>
            <HTMLTextComponent HTMLString={button.cardBody} />
          </Typography>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  parentWrapper: {},
  gradientButtonContainer: {
    'height': 'calc(20vw - 12px)',
    'maxHeight': '221px',
    'maxWidth': '528px',
    'backgroundRepeat': 'no-repeat',
    'objectFit': 'cover',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'flex-end',
    'cursor': 'pointer',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'borderRadius': ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(960)]: {
      maxWidth: '45vw',
      justifyContent: 'center',
      width: 'unset',
      minWidth: 'unset',
      height: 'fit-content',
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: '90vw',
    },
  },
  xlCard: {
    maxHeight: '580px',
    maxWidth: '580px',
    width: '40vw',
    height: '40vw',
    [theme.breakpoints.down(850)]: {
      width: '100%',
      height: '264px',
      maxWidth: 'unset',
    },
  },
  xlCardHidden: {
    maxWidth: '100%',
  },
  dashboardButtonCard: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderBottomRightRadius: ({ radius }: { radius: number }) => radius,
    borderBottomLeftRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  dashboardButtonTitle: {
    fontSize: '3rem',
    fontWeight: 500,
    color: '#FFF',
    [theme.breakpoints.down(1400)]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      marginBottom: '8px',
    },
  },
  dashboardButtonBody: {
    'fontSize': '24px',
    'color': '#FFF',
    'lineHeight': '32px',
    '& p': {
      margin: '0px',
    },
    [theme.breakpoints.down(1400)]: {
      fontSize: '1.25rem',
      lineHeight: '24px',
    },
    [theme.breakpoints.down(900)]: {
      lineHeight: '24px',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      '& span': {
        fontSize: '14px !important',
        lineHeight: '19px',
      },
    },
  },
  contrastImage: {
    background:
      'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,.8) 100%)',
  },
}))
