import styled from '@emotion/styled'
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import SearchIcon from '@material-ui/icons/Search'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { testTypes } from '../../../constants'
import { getLegalNotes } from '../../../pages/checkout/model'
import { getInsuranceInfoForOrder } from '../../../pages/insurance/model'
import { getNetworkSettings } from '../../../pages/network/model'
import {
  getTestKitDetails,
  selectTestKitDetails,
} from '../../../pages/order-test/model'
import { getCreditCards } from '../../../pages/payment-methods/model'
import { getProfileData } from '../../../pages/profile/model'
import { Typography } from '../../../ui'
import { selectLanguageContent } from '../../translation'

export const SubHeaderGetTested = ({
  title,
  onHandleBack,
  className = '',
  withSearchBar = false,
  searchValue,
  onChangeSearch,
  fullWidth = false,
  backIcon = (
    <KeyboardBackspaceIcon
      color="primary"
      style={withSearchBar ? { color: 'white' } : {}}
    />
  ),
}: {
  title: string
  onHandleBack?: any
  className?: any
  withSearchBar?: boolean
  searchValue?: string
  onChangeSearch?: any
  fullWidth?: boolean
  backIcon?: JSX.Element
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { type: testingType, id } = useParams<{
    type: string
    id: string
  }>()
  const i18n = useSelector(selectLanguageContent)
  const productData = useSelector(selectTestKitDetails)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfileData())
    dispatch(getLegalNotes())
    dispatch(getNetworkSettings())
    dispatch(getInsuranceInfoForOrder())
    dispatch(getCreditCards())
  }, [id])

  useEffect(() => {
    if (
      id &&
      !productData &&
      (testingType === testTypes.ORDER || testingType === testTypes.APPOINTMENT)
    ) {
      dispatch(getTestKitDetails(id))
    }
  }, [id])

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value
    onChangeSearch(value)
  }
  const goBack = () => {
    onHandleBack ? onHandleBack() : history.goBack()
  }

  return (
    <SubBackHeaderWrapper
      className={`${
        withSearchBar
          ? `${classes.withSearchBarStyles} ${className}`
          : className
      } ${fullWidth ? classes.fullWidth : ''}`}
    >
      <Grid className={classes.headerTop}>
        <IconButton onClick={goBack}>{backIcon}</IconButton>
        <Typography
          color={withSearchBar ? 'initial' : 'primary'}
          variant="h6"
          component="h2"
        >
          {title}
        </Typography>
      </Grid>

      {withSearchBar ? (
        <Grid style={{ width: '100%', marginTop: 20 }}>
          <TextField
            id="outlined-basic"
            className={classes.searchField}
            variant="outlined"
            placeholder={i18n.search_placeholder}
            value={searchValue}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}
    </SubBackHeaderWrapper>
  )
}

const SubBackHeaderWrapper = styled.div`
  border-bottom: 1px solid #efefef;
  max-width: 540px;
  width: 100%;
  box-sizing: border-box;
`

const useStyles = makeStyles((theme) => ({
  headerTop: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateColumns: '48px 1fr 48px',
  },
  withSearchBarStyles: {
    backgroundColor: '#003C72',
    color: 'white',
    padding: '0 16px 16px',
  },
  fullWidth: {
    maxWidth: '100%!important',
    padding: '0 32px!important',
    border: 'none!important',
  },
  searchField: {
    'width': '100%',
    'background': 'white',
    'borderRadius': '5px',
    'outline': 'none',
    '& input': {
      padding: 7,
    },
  },
}))
