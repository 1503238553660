import { makeStyles } from '@material-ui/core/styles'

export const SHADOW = '0px 8px 24px rgba(0,60,113,0.05)'

export const dashboardCardStyles = makeStyles((theme) => ({
  largeCardWrapper: {
    'maxWidth': '528px',
    'width': 'calc(45vw - 130px)',
    'backgroundColor': '#FFF',
    'borderRadius': ({ radius }: { radius: number }) => radius,
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    [theme.breakpoints.down(1200)]: {
      width: 'calc(45vw - 8px)',
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: '90vw',
      width: '100%',
    },
    '&.xl': {
      width: '90vw',
    },
  },
  largeCard: {
    margin: '0px 32px 32px',
    [theme.breakpoints.down(600)]: {
      padding: '16px',
      margin: 0,
    },
  },
  imageContainer: {
    maxHeight: '528px',
    height: 'calc(35vw + 32px)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    borderTopRightRadius: ({ radius }: { radius: number }) => radius,
    borderTopLeftRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(1200)]: {
      height: '50vw',
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: '90vw',
      height: '90vw',
      margin: '0px auto',
      maxHeight: 'unset',
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: '90vw',
      width: 'unset',
      minWidth: 'unset',
      height: '381px',
    },
  },
  medImageContainer: {
    height: 'calc(22vw + 32px)',
    maxHeight: '281px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderTopRightRadius: ({ radius }: { radius: number }) => radius,
    borderTopLeftRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(700)]: {
      height: 'calc(45vw + 32px)',
      width: 'unset',
      minWidth: 'unset',
    },
    [theme.breakpoints.down(500)]: {
      height: '200px',
    },
  },
  transparentCard: {
    maxWidth: '528px',
    [theme.breakpoints.down(700)]: {
      maxWidth: '90vw',
      marginTop: 24,
    },
  },
  bgImageButtonContainer: {
    'height': 'calc(50vw + 32px)',
    'maxHeight': '528px',
    'maxWidth': '528px',
    'width': 'calc(45vw - 130px)',
    'backgroundRepeat': 'no-repeat',
    'backgroundSize': 'cover',
    'backgroundPosition': '50%',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'flex-end',
    'cursor': 'pointer',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'borderRadius': ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(1200)]: {
      width: 'calc(45vw - 8px)',
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: '90vw',
      width: '100%',
    },
    [theme.breakpoints.down(500)]: {
      width: 'unset',
      minWidth: 'unset',
      height: '264px',
    },
  },
  bgImageCard: {
    padding: '6%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderBottomRightRadius: ({ radius }: { radius: number }) => radius,
    borderBottomLeftRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  transparentImageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    [theme.breakpoints.down(700)]: {
      width: 'unset',
      minWidth: 'unset',
    },
  },
  carouselImageContainer: {
    maxWidth: '528px',
    maxHeight: '261px',
    height: 'calc(20vw)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(700)]: {
      height: 'calc(55vw + 32px)',
    },
    [theme.breakpoints.down(500)]: {
      width: 'unset',
      minWidth: 'unset',
      height: '229px',
    },
  },
  smallImageContainer: {
    height: '124px',
    width: '124px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: ({ radius }: { radius: number }) => radius,
  },
  image: {
    width: '180px',
  },
  xlCard: {
    maxHeight: '580px',
    maxWidth: '580px',
    width: '40vw',
    height: '40vw',
    [theme.breakpoints.down(850)]: {
      width: '100%',
      height: '264px',
      maxWidth: 'unset',
    },
  },
  contrastImage: {
    background:
      'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,.8) 100%)',
  },
  transparentButtonCard: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    gap: 16,
    [theme.breakpoints.down('xs')]: {},
  },
  itemContainer: {
    marginRight: '16px',
    width: '124px',
  },
  cardHeader: {
    'wordWrap': 'break-word',
    'fontSize': '20px',
    'fontWeight': 600,
    'marginTop': 10,
    '& p': {
      color: '#505358',
      [theme.breakpoints.down(700)]: {
        margin: '0px !important',
      },
    },
    '& span': {
      fontSize: '32px !important',
      [theme.breakpoints.down(960)]: {
        fontSize: '28px !important',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '20px !important',
      },
    },
  },
  secondaryItems: {
    width: 'calc(100% + 32px)',
    marginTop: '16px',
    display: 'flex',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 16px)',
    },
  },
  secondaryText: {
    '& span': {
      fontWeight: 600,
      fontSize: '14px !important',
    },
  },
  dashboardButtonCard: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    gap: 16,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  topTitle: {
    'fontSize': '20px',
    'fontWeight': 600,
    'margin': '32px 0px',
    'wordWrap': 'break-word',
    '& p': {
      color: '#505358',
      [theme.breakpoints.down('xs')]: {
        margin: '0px !important',
      },
    },
    '& span': {
      fontSize: '32px !important',
      [theme.breakpoints.down(960)]: {
        fontSize: '28px !important',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '20px !important',
      },
    },
    [theme.breakpoints.down(700)]: {
      margin: '16px 0px',
    },
  },
  dashboardButtonTitle: {
    'fontWeight': 600,
    'wordWrap': 'break-word',
    '& p': {
      margin: '0px !important',
      color: '#505358',
    },
    '& span': {
      fontSize: '32px !important',
      [theme.breakpoints.down(960)]: {
        fontSize: '28px !important',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '20px !important',
      },
    },
  },
  dashboardButtonBody: {
    'fontSize': '20px',
    'lineHeight': '32px',
    'fontWeight': 500,
    'wordWrap': 'break-word',
    '& p': {
      wordWrap: 'break-word',
      margin: '0px',
      color: '#757575',
    },
    '& span': {
      wordWrap: 'break-word',
      fontSize: '20px !important',
      [theme.breakpoints.down(960)]: {
        lineHeight: '20px',
        fontSize: '16px !important',
      },
      [theme.breakpoints.down(600)]: {
        lineHeight: '18px',
        fontSize: '14px !important',
      },
    },
    [theme.breakpoints.down(1400)]: {
      fontSize: '1.25rem',
      lineHeight: '24px',
    },
    [theme.breakpoints.down(960)]: {
      lineHeight: '24px',
      fontSize: '1rem',
    },
    [theme.breakpoints.down(700)]: {
      '& span': {
        fontSize: '14px !important',
        lineHeight: '19px',
      },
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  },
  confirmButton: {
    width: '100%',
    height: '36px',
    [theme.breakpoints.up(600)]: {
      height: 'unset',
      maxWidth: 'fit-content',
    },
  },
  simpleButtonWrapper: {
    maxWidth: '50vw',
  },
  simpleButton: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
    },
  },
}))
