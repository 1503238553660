/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { CollectionProps } from '../types'

export const ProctorInstruction = (props: CollectionProps) => {
  const { current } = props
  const classes = useStyles()

  return (
    <>
      <Box>
        <Box
          className={`${classes.instructionContainer}`}
          id="instructionContainer"
        >
          <Box className={classes.assetBox} id="instructions">
            {current?.imageURL && (
              <img
                className={classes.img}
                src={current?.imageURL}
                alt="test-instructions"
              />
            )}
            {current?.videoURL && (
              <Box className={classes.videoContainer}>
                <video
                  src={current.videoURL}
                  controls
                  className={classes.video}
                />
              </Box>
            )}
            {current?.body && <HTMLTextComponent HTMLString={current.body} />}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    minHeight: '200px',
  },
  videoContainer: {
    marginTop: '20px',
    width: '100%',
  },

  img: {
    maxWidth: '325px',
    [theme.breakpoints.down(320)]: {
      width: '90%',
    },
  },
  assetBox: {
    'margin': '16px',
    'width': '100%',
    '& span': {
      textAlign: 'left',
    },
    [theme.breakpoints.down(576)]: {
      'width': '90%',
      'margin': 0,
      '& span': {
        fontSize: '20px !important',
      },
    },
  },
  instructionContainer: {
    'overflowX': 'hidden',
    'margin': '0px auto',
    'width': '504px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'space-evenly',
    [theme.breakpoints.down(576)]: {
      width: '90%',
      justifyContent: 'flex-start',
      height: 'calc(100vh - 275px)',
      margin: '10px auto',
    },
    '&.reduced': {
      justifyContent: 'flex-start',
    },
  },
}))
