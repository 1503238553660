import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import React from 'react'
import { I18n } from '../../../features/translation/types'
import {
  isValidPasswordDigit,
  isValidPasswordLength,
  isValidPasswordLower,
  isValidPasswordSpecial,
  isValidPasswordUpper,
  noInvalidCharacter,
} from '../../../libs/helpers'
import { Typography } from '../typography'
interface PasswordValidationProps {
  i18n: I18n
  value: string
  columns?: number
}

enum PasswordRules {
  VALID_DIGIT = 'VALID_DIGIT',
  VALID_LENGTH = 'VALID_LENGTH',
  VALID_SPECIAL = 'VALID_SPECIAL',
  VALID_UPPER = 'VALID_UPPER',
  VALID_LOWER = 'VALID_LOWER',
}

interface Validator {
  rule: PasswordRules
  label: string
  isValid: boolean
}

export const PasswordValidation = (props: PasswordValidationProps) => {
  const { i18n, value, columns = 2 } = props
  const classes = useValidRuleStyles()

  const passwordValidationChecks: Validator[] = [
    {
      rule: PasswordRules.VALID_LENGTH,
      label: i18n.at_least_characters_label,
      isValid: isValidPasswordLength(value),
    },
    {
      rule: PasswordRules.VALID_UPPER,
      label: i18n.upper_letter_label,
      isValid: isValidPasswordUpper(value),
    },
    {
      rule: PasswordRules.VALID_LOWER,
      label: i18n.lowercase_letter_label,
      isValid: isValidPasswordLower(value),
    },
    {
      rule: PasswordRules.VALID_DIGIT,
      label: i18n.number_label,
      isValid: isValidPasswordDigit(value),
    },
    {
      rule: PasswordRules.VALID_SPECIAL,
      label: i18n.special_character_label,
      isValid: isValidPasswordSpecial(value),
    },
  ]

  let index = 0

  const generatedColumns = [] as React.ReactNode[][]
  for (let i = 0; i < columns; i++) {
    generatedColumns.push([] as React.ReactNode[])
  }

  passwordValidationChecks?.forEach((test, i) => {
    const item = () => (
      <ValidationRule
        key={test.label}
        isValid={test.isValid}
        label={test.label}
      />
    )
    generatedColumns[index]?.push(item())
    if (index === columns - 1) {
      index = 0
    } else index++
  })

  return (
    <Box className={classes.passwordSection}>
      {generatedColumns.map((col, i) => (
        <div key={`column-${i}`}>{col}</div>
      ))}
    </Box>
  )
}

const ValidationRule = ({
  isValid,
  label,
}: {
  isValid: boolean
  label: string
}) => {
  const classes = useValidRuleStyles()

  return (
    <Box className={classes.passwordInformation}>
      <Box component="span">
        {isValid ? (
          <CheckIcon className={classes.checkIcon} />
        ) : (
          <CheckIcon className={`${classes.checkIcon} ${classes.greyIcon}`} />
        )}
      </Box>
      <Typography className={classes.passInfoText}>{label}</Typography>
    </Box>
  )
}

const useValidRuleStyles = makeStyles((theme) => ({
  passwordSection: {
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'space-between',
    '@media (max-width: 375px)': {
      marginTop: 0,
    },
  },
  passwordInformation: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    [theme.breakpoints.down(450)]: {
      width: '100%',
    },
  },
  checkIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    color: theme.palette.success.main,
  },
  passInfoText: {
    fontSize: 12,
    letterSpacing: 0.2,
    color: '#757575',
  },
  greyIcon: {
    color: '#E0E0E0 !important',
  },
}))
