import { createSlice } from '@reduxjs/toolkit'
import { Dispatch, Store } from 'redux'
import api from '../../api'
import { addHS } from '../../features/health-service-lite/model'
import { HealthServiceType } from '../../features/health-service-lite/types'
import { objectToArray, objectToSortedArray } from '../../libs/utils'
import { lsClient } from '../../ls-client'
import {
  InstructionV2,
  ProductAssetDetail,
  RegisterTestDetails,
  SelfAssessmentInstruction,
} from '../../types/tests'

interface ScanOrderCodeSlice {
  isLoading: boolean
  fullTest: any
  testId: string
  registerTestDetails: RegisterTestDetails | undefined
  productAssetDetail: ProductAssetDetail | undefined
  collectionInstructionsV2: InstructionV2[]
  isSelfAssessment: boolean
  selfAssessmentInstructions: SelfAssessmentInstruction[]
  surveySettings: any
  scanImageDetails: any
  disclaimerDetail: any
  cassetteNumber: string
  elapsedTimeOnSubmission: number
  QR2DKitId: string
  error: string
  allowSkipCollectionInstructionsV2: boolean
}
const initialStore: ScanOrderCodeSlice = {
  testId: '',
  isLoading: false,
  fullTest: {},
  registerTestDetails: undefined,
  productAssetDetail: undefined,
  collectionInstructionsV2: [],
  isSelfAssessment: false,
  selfAssessmentInstructions: [],
  surveySettings: {},
  scanImageDetails: undefined,
  disclaimerDetail: {},
  cassetteNumber: '',
  elapsedTimeOnSubmission: 0,
  QR2DKitId: '',
  error: '',
  allowSkipCollectionInstructionsV2: false,
}

export const scanOrderCodeSlice = createSlice({
  name: 'scanOrderCode',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setCassetteNumber(store, { payload }: { payload: any }) {
      store.cassetteNumber = payload
    },
    setElapsedTimeOnSubmission(store, { payload }: { payload: any }) {
      store.elapsedTimeOnSubmission = payload
    },
    setLabtest(store, { payload }: { payload: any }) {
      store.testId = payload.orderableTest._id
      store.fullTest = payload.orderableTest
      store.productAssetDetail = payload.orderableTest.productAssetDetail || {}
      store.collectionInstructionsV2 =
        payload.orderableTest.collectionInstructionV2 || []
      store.isSelfAssessment = payload.orderableTest.isSelfAssessmentMode
      store.selfAssessmentInstructions = objectToSortedArray(
        payload.orderableTest?.selfAssessmentInstructions,
        ['negativeInstructions', 'positiveInstructions', 'invalidInstructions']
      )
      store.registerTestDetails = payload.orderableTest.registerTestDetails
      store.surveySettings = payload.orderableTest.surveySettings || {}
      store.scanImageDetails = payload.orderableTest.scanImageDetails || {}
      store.disclaimerDetail = payload.orderableTest.disclaimerDetail || {}
      store.QR2DKitId = payload.id
      store.allowSkipCollectionInstructionsV2 =
        payload.orderableTest.allowSkipCollectionInstructionsV2
    },
    setCollectionsForContinue(store, { payload }: { payload: any }) {
      store.fullTest = payload
      store.testId = payload._id
      store.collectionInstructionsV2 = payload.collectionInstructionV2
      store.selfAssessmentInstructions = objectToSortedArray(
        payload.selfAssessmentInstructions,
        ['negativeInstructions', 'positiveInstructions', 'invalidInstructions']
      )
      store.isSelfAssessment = payload.isSelfAssessmentMode
      store.scanImageDetails = payload.scanImageDetails
      store.productAssetDetail = payload.productAssetDetail || {}
      store.allowSkipCollectionInstructionsV2 =
        payload.allowSkipCollectionInstructionsV2
    },
    setRegisterTestDetails(store, { payload }: { payload: any }) {
      store.registerTestDetails = payload.registerTestDetails
    },
    setError(store, { payload }: { payload: any }) {
      store.error = payload
    },
    resetTestStore: () => initialStore,
  },
})
export const {
  setLoading,
  setLabtest,
  setCollectionsForContinue,
  setCassetteNumber,
  setElapsedTimeOnSubmission,
  setRegisterTestDetails,
  resetTestStore,
  setError,
} = scanOrderCodeSlice.actions

export const getScanCode =
  (
    language: string,
    code: string,
    format: string,
    healthServiceId?: string,
    onSuccess?: () => void
  ) =>
  async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))
    const params = {
      data: code,
      dataFormat: format,
      markTestAsUsed: true,
    }
    const { data, error } = await api.getScanCode(language, params)
    if (data) {
      dispatch(setLoading(false))
      if (data.orderableTest) {
        lsClient.setUserLS('testId', data.orderableTest._id)
        lsClient.setUserLS('skuId', data.orderableTest.skuId)
      } else if (data.orderablePanel) {
        // lsClient.setUserLS('panelId', data.orderablePanel._id)
        lsClient.setUserLS('kitId', data.id)
        lsClient.setUserLS('skuId', data.orderablePanel.skuId)
      }
      dispatch(setLabtest(data))
      if (healthServiceId) {
        const params = {
          id: data.orderableTest._id,
          type: 'Screening',
        }
        dispatch(addHS(healthServiceId, params, 'connectedTests'))
      }
      if (onSuccess) onSuccess()
    } else if (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const getLabTestDetails =
  (testId: string, callback?: () => void) =>
  async (dispatch: Dispatch, getStore: any) => {
    dispatch(setLoading(true))

    const language = getStore().translation.selectedLanguage

    const testDetails = await api.getLabTestOrderable(testId, language)

    if (testDetails) {
      dispatch(setRegisterTestDetails(testDetails))
      if (callback) {
        callback()
      }
    }
    // else if (error) {
    //   dispatch(setError(error))
    //   dispatch(setLoading(false))
    // }
    dispatch(setLoading(false))
  }

export const scanOrderCodeSliceReducer = scanOrderCodeSlice.reducer
export const scanOrderCodeReducerName = scanOrderCodeSlice.name
interface RootStore {
  [scanOrderCodeReducerName]: typeof initialStore
}

export const selectTestLoading = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.isLoading

export const selectFullOrderableTest = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.fullTest

export const selectIsRapidTest = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.fullTest?.testFormat?.isRapidTest

export const selectProductAssetDetail = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.productAssetDetail

export const selectRegisterDetails = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.registerTestDetails

export const selectProductAssetDetailLoading = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.isLoading

export const selectTestCanSkipInstructions = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.allowSkipCollectionInstructionsV2

export const selectCassetteNumber = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.cassetteNumber

export const selectQR2DKitId = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.QR2DKitId

export const selectElapsedTimeOnSubmission = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.elapsedTimeOnSubmission

export const selectTestId = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.testId

export const selectIsSelfAssessmentMode = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.isSelfAssessment

export const selectCollectionInstructionsV2 = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.collectionInstructionsV2

export const selectSelfAssessmentInstructions = ({
  scanOrderCode,
}: RootStore) => scanOrderCode.selfAssessmentInstructions

export const selectSurveySettings = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.surveySettings

export const selectScanImageDetails = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.scanImageDetails

export const selectDisclaimerDetail = ({ scanOrderCode }: RootStore) =>
  scanOrderCode.disclaimerDetail

export const selectError = ({ scanOrderCode }: RootStore) => scanOrderCode.error
