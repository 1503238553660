import { Box } from '@material-ui/core'
import {
  GridCellParams,
  GridColumns,
  GridRowData,
  GridSortItem,
} from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Visacard } from '../../assets/img/visa-card.svg'
import {
  deletePaymentMethod,
  getCreditCards,
  selectCreditCards,
  selectIsCardSubmitting,
  selectIsLoading,
} from '../../pages/payment-methods/model'
import { CreditCard } from '../../pages/payment-methods/types'
import { formatExpirationDate } from '../../pages/settings/helpers'
import { MenuOption } from '../../pages/settings/type'
import { ActionCell, CustomTable, Typography } from '../../ui'
import { selectLanguageContent } from '../translation'
import { I18n } from '../translation/types'

interface PaginationState {
  page: number
  rowsPerPage: number
  sort: GridSortItem[]
}

export const CreditCardsTable = () => {
  const i18n = useSelector(selectLanguageContent)
  const isCardSubmitting = useSelector(selectIsCardSubmitting)
  const creditCards: CreditCard[] = useSelector(selectCreditCards)
  const creditCardsLoading = useSelector(selectIsLoading)
  const dispatch = useDispatch()

  const initialState: PaginationState = {
    page: 0,
    rowsPerPage: 10,
    sort: [
      {
        field: 'expires',
        sort: 'desc',
      },
    ],
  }

  useEffect(() => {
    if (creditCards.length === 0) dispatch(getCreditCards())
  }, [])

  const handleEditCard = (card: CreditCard) => {
    if (!card) return
  }

  const handleDeleteCard = async (card: CreditCard) => {
    if (!card) return
    await dispatch(deletePaymentMethod(card.id))
  }

  const handleTableUpdate = async () => {}

  const menuItems: MenuOption[] = [
    {
      label: i18n.edit_button,
      onClick: handleEditCard,
    },
    {
      label: i18n.delete_button,
      onClick: handleDeleteCard,
      isLoading: isCardSubmitting,
    },
  ]

  const creditCardsColumns: GridColumns = [
    {
      field: 'card_name',
      headerName: i18n.card,
      flex: 0.55,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <CardName i18n={i18n} card={row as CreditCard} />
      ),
    },
    {
      field: 'name',
      flex: 0.35,
      headerName: i18n.credit_card_name_on,
      sortable: false,
    },
    {
      field: 'expires',
      headerName: i18n.expires_on,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }: GridCellParams) =>
        formatExpirationDate(row.expirationMonth, row.expirationYear),
    },
    {
      field: 'action',
      headerName: i18n.action,
      flex: 0.1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <ActionCell data={row} menuItems={menuItems} />
      ),
    },
  ]

  return (
    <CustomTable
      columns={creditCardsColumns}
      rows={creditCards as GridRowData[]}
      loading={creditCardsLoading}
      rowsPerPageOptions={[10, 25, 100]}
      total={15}
      initialRowsPerPage={initialState.rowsPerPage}
      initialSort={initialState.sort}
      onTableUpdate={handleTableUpdate}
      hideFooter
    />
  )
}

const CardName = ({ card, i18n }: { card: CreditCard; i18n: I18n }) => {
  const getCardIcon = () => {
    switch (card?.brand) {
      case 'Visa':
        return <Visacard />
      case 'Mastercard':
        return <Visacard />
      default:
        return null
    }
  }

  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      {getCardIcon()}
      <Typography>
        <b>{card?.brand}</b>
        {` ${i18n.ending_in} ${card?.last4}`}
      </Typography>
    </Box>
  )
}
