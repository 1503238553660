import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addHS } from '../../features/health-service-lite/model'
import { HealthServiceType } from '../../features/health-service-lite/types'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { Button, TextField, Typography } from '../../ui'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { paths } from '../paths'
import { getScanCode, setCassetteNumber, setError } from './model'

const scanType = 'GS1DataMatrix2Dbarcode'
const QRCode = 'QRCode'

const ENVIRONMENT = process.env.REACT_APP_ENV === 'development'

export const ScanQRPage = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [data, setData] = useState('')
  const i18n = useSelector(selectLanguageContent)
  const [SB, setSB] = useState({ S: '', B: '', D: '' })
  const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
  const goBack = () => {
    history.push(paths.app.getTested())
  }

  const resetScanFlow = () => {
    lsClient.removeUserKeyLS('scan_timer_start')
    lsClient.removeUserKeyLS('wait_timer_start')
    lsClient.removeUserKeyLS('scan_capture_time')
    lsClient.removeUserKeyLS('step')
    lsClient.removeUserKeyLS('testId')
    // lsClient.removeUserKeyLS('kitId')
  }

  const handleResult = (result: any) => {
    dispatch(setError(''))
    if (result.format === 5) {
      const success = () => {
        history.push(paths.productAssetDetails())
      }
      resetScanFlow()
      lsClient.setUserLS('code', result.text)
      dispatch(setCassetteNumber(result.text))
      dispatch(
        getScanCode('en', result.text, scanType, healthServiceId, success)
      )
    } else {
      history.push(paths.invalidQRCode())
      return
    }
    setData(result.text)
    history.push(paths.productAssetDetails())
  }

  const loadrunner = () => {
    if (!ENVIRONMENT) return
    try {
      const result = `\u001d01${SB.D}10${SB.B}\u001d112012011722061921${SB.S}`
      lsClient.setUserLS('code', result)
      dispatch(setCassetteNumber(result))
      dispatch(getScanCode('en', result, scanType))
      setData(result)
    } finally {
      history.push(paths.productAssetDetails())
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    target: 'S' | 'B' | 'D'
  ) => {
    if (!ENVIRONMENT) return
    const val = e.target.value
    setSB({ ...SB, [target]: val })
  }

  return (
    <PageWrapper
      style={{ maxWidth: 600, padding: 32 }}
      showBack={false}
      className={classes.wrapper}
    >
      <Box className={classes.cameraContent}>
        <Box className={classes.blackBox}>
          <div className={classes.cameraCenter}>
            {!data && (
              <BarcodeScannerComponent
                height={750}
                width={750}
                onUpdate={(error, result: any) => {
                  if (result) handleResult(result)
                }}
              />
            )}
          </div>
        </Box>
        <Typography variant="h2" className={classes.cameraExplanation}>
          {i18n.camera_scaning_hint}
        </Typography>
        <Button variant="outlined" style={{ maxWidth: 120 }} onClick={goBack}>
          {i18n.cancel_button}
        </Button>
      </Box>
      {ENVIRONMENT && (
        <>
          <TextField
            id="loadrunnerD"
            className={classes.loadrunner}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, 'D')
            }
          />
          <TextField
            id="loadrunnerS"
            className={classes.loadrunner}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, 'S')
            }
          />
          <TextField
            id="loadrunnerB"
            className={classes.loadrunner}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, 'B')
            }
          />
          <Button
            id="loadrunnerQR"
            className={classes.loadrunner}
            onClick={loadrunner}
          />
        </>
      )}
    </PageWrapper>
  )
}

const MASK_RADIAL = '-webkit-radial-gradient(circle, white 100%, black 100%)'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    margin: '0px auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
      overflow: 'none',
    },
  },
  cameraCenter: {
    position: 'absolute',
    top: 30,
    right: 'calc(30vw - 100px)',
    [theme.breakpoints.down(1000)]: {
      top: 30,
      right: 20,
      maxWidth: '120vw',
      overflow: 'hidden',
    },
    [theme.breakpoints.down(600)]: {
      top: 0,
    },
    [theme.breakpoints.down(281)]: {
      right: 0,
    },
  },
  blackBox: {
    'borderRadius': '16px',
    '-webkit-mask-image': MASK_RADIAL,
    'width': '425px',
    'height': '550px',
    'backgroundColor': '#000',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      height: '60vh',
    },
  },
  scanQrWrapper: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: 540,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  headerTitle: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
    backgroundColor: '#FFF',
  },
  backIconWrapper: {
    transform: 'rotate(45deg)',
    position: 'absolute',
    left: 15,
    cursor: 'pointer',
  },
  backIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  cameraScreen: {
    width: '100%',
    display: 'block',
  },
  cameraExplanation: {
    textAlign: 'center',
    margin: '24px 0',
  },
  cameraContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  },
  button: {
    margin: 15,
  },
  loadrunner: {
    'position': 'fixed',
    'bottom': '1px',
    'left': '1px',
    'boxShadow': 'none',
    'border': 'none',
    'backgroundColor': 'transparent',
    'maxHeight': '1px !important',
    'maxWidth': '1px !important',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))
