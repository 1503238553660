import { Theme, useMediaQuery } from '@material-ui/core'
import React from 'react'
import ContentLoader from 'react-content-loader'

export const FormSkeletonLoader = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const renderDesktop = () => (
    <>
      {/* Fields */}
      <rect x="0" y="0" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="52%" y="0" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="0" y="72" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="52%" y="72" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="0" y="144" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="52%" y="144" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="0" y="220" rx="3" ry="3" width="calc(48%)" height="50" />
      <rect x="52%" y="220" rx="3" ry="3" width="calc(48%)" height="50" />

      {/*Button*/}
      <rect x="0" y="302" rx="8" ry="8" width="92" height="46" />
    </>
  )

  const renderMobile = () => (
    <>
      {/* Fields */}
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="72" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="144" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="220" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="292" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="364" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="436" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="508" rx="3" ry="3" width="100%" height="50" />

      {/*Button*/}
      <rect x="0" y="590" rx="8" ry="8" width="92" height="46" />
    </>
  )

  return (
    <ContentLoader
      height={!matches ? 350 : 644}
      width="100%"
      speed={0.8}
      viewBox="0 0 100% 100%"
    >
      {!matches ? renderDesktop() : renderMobile()}
    </ContentLoader>
  )
}
