import React from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import MUIButton from '@material-ui/core/Button'
import { useHistory, useParams } from 'react-router-dom'
import { PageWrapper } from '../../../../ui/templates/page-wrapper'
import { selectLanguageContent } from '../../../../features/translation'
import { DependentForm } from '../../components/dependent-form'
import { DependentFormValues, SettingsTabType } from '../../type'
import validationSchema from '../../components/dependent-form/validation-schema'
import { Button, Typography } from '../../../../ui'
import { paths } from '../../../paths'

export const EditDependentPage = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const { dependentId } = useParams<{ dependentId?: string }>()
  const history = useHistory()

  const onSubmit = async (values: DependentFormValues) => {}

  const formik = useFormik<DependentFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      birthDate: null,
      sexAtBirth: '',
      relationship: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleBack = () =>
    history.push(paths.settingsTab(SettingsTabType.ACCOUNT))

  return (
    <PageWrapper title={i18n.edit_dependent} style={{ paddingBottom: 32 }}>
      <form className={classes.wrapper} onSubmit={formik.handleSubmit}>
        <DependentForm formik={formik} />
        <Box display="flex" gridGap="16px" marginTop="32px">
          <Button
            variant="contained"
            style={{ maxWidth: 92 }}
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting}
            type="submit"
          >
            {i18n.save_button}
          </Button>
          <MUIButton className={classes.cancelButton} onClick={handleBack}>
            <Typography style={{ fontWeight: 600 }}>
              {i18n.cancel_title}
            </Typography>
          </MUIButton>
        </Box>
      </form>
    </PageWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cancelButton: {
    textTransform: 'none',
    width: '100%',
    maxWidth: 100,
  },
}))
