import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../../../../../features/translation'
import { Typography } from '../../../../../../ui'
import { paths } from '../../../../../paths'
import { CreditCardsTable } from '../../../../../../features/tables/credit-cards-table'
import { ActionButton } from '../../../../../../ui/atoms/button'

export const PaymentMethods = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()

  const handleAddCreditCard = () => history.push(paths.app.addCreditCard())

  return (
    <Box marginBottom="48px">
      <Box className={classes.header}>
        <Typography variant="h2">{i18n.credit_cards_screen_title}</Typography>
        <ActionButton onClick={handleAddCreditCard}>
          {i18n.checkout_add_card_text}
        </ActionButton>
      </Box>
      <Box marginTop="16px">
        <CreditCardsTable />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
  },
}))
