import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import healthCheckRow from '../../../../assets/logo-row.png'
import {
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../../../../pages/network/model'
import { getSignupType } from '../../../../pages/register/model/registerSlice'
import { OnboardingType } from '../../../../pages/register/model/type'

export const SignupHeader = () => {
  const classes = useStyles()
  const networkSettings = useSelector(selectNetworkSettings)
  const networkLoading = useSelector(selectNetworkSettingsLoading)

  const signupType = useSelector(getSignupType)
  const isInvite = Boolean(
    signupType === OnboardingType.DEPENDENT ||
      signupType === OnboardingType.INVITE
  )
  const networkImage = () => {
    return networkSettings?.logoRectangleDark
      ? networkSettings?.logoRectangleDark
      : undefined
  }

  if (isInvite) return <Box style={{ minHeight: 100 }} />

  return (
    <>
      <Box className={classes.appBar}>
        {!networkSettings && networkLoading ? (
          <Box style={{ minHeight: 135 }} />
        ) : (
          <IconButton
            className={classes.iconButton}
            edge="start"
            color="inherit"
            aria-label="home"
            disableRipple
          >
            <img height={39} src={networkImage()} alt="logo" />
          </IconButton>
        )}
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 135,
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(767)]: {
      height: 103,
    },
  },
  iconButton: {
    'padding': 48,
    'borderRadius': 0,
    [theme.breakpoints.down(767)]: {
      padding: 32,
    },
    [theme.breakpoints.down(300)]: {
      marginLeft: 0,
      padding: 24,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
