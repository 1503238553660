import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

export const DisabledBox = () => {
  const classes = useStyles()

  return <Box className={classes.disabled} />
}

const useStyles = makeStyles((_theme) => ({
  disabled: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(255 255 255 / 70%)',
    zIndex: 1111,
  },
}))
