import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Typography } from '../../../ui'
import { resetDialogStore } from '../model'
import { DialogContent } from '../types'
import { dialogStyles } from './dialog-styles'

export const SimpleDialogContent = (props: DialogContent) => {
  const {
    i18n,
    title,
    body,
    onConfirm,
    confirmationLabel,
    onCancel,
    cancelLabel,
    image,
  } = props
  const classes = dialogStyles()

  const dispatch = useDispatch()
  const onClose = () => {
    if (onCancel) onCancel()
    dispatch(resetDialogStore())
  }
  return (
    <div className={classes.actionModalWrapper}>
      <Box className={classes.contentWrapper}>
        <div className={classes.assetWrapper}>
          {image && <img src={image} alt={title} className={classes.img} />}
          <Box className={classes.assetBox}>
            {title && <Title>{title}</Title>}
            {body && <Body>{body}</Body>}
          </Box>
          <div className={classes.buttonWrapper}>
            {onConfirm && (
              <Button
                onClick={() => (onConfirm ? onConfirm() : null)}
                className={classes.confirmButton}
                variant="contained"
              >
                {confirmationLabel || i18n.confirm_button}
              </Button>
            )}
            <Button
              onClick={onClose}
              className={classes.confirmButton}
              variant="text"
            >
              {cancelLabel || i18n.cancel_button}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  )
}

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  color: #282d37;
  width: 100%;
  text-align: inherit;
`

const Body = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  color: #757575;
  width: 100%;
  text-align: inherit;
  margin: 10px 0px;
`
