import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link } from 'react-router-dom'
import logoImage from '../../../assets/logo-row.png'
import { paths } from '../../../pages/paths'

export const Logo: React.FC<{ white?: boolean }> = ({ white = false }) => {
  const classes = useStyles()
  return (
    <Link to={paths.app.dashboard()}>
      <img className={classes.logo} alt={'Safe'} src={logoImage} />
    </Link>
  )
}

const useStyles = makeStyles({
  logo: {
    maxHeight: 40,
  },
})
