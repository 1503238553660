import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

interface RowProps {
  gap?: string
}
export const Row: React.FC<RowProps> = ({ children, gap = '1rem' }) => {
  const classes = useStyles({ gap })
  return <div className={classes.root}>{children}</div>
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gap: ({ gap }: RowProps) => gap,
  },
})
