import { Box, Typography } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useSelector } from 'react-redux'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { testInstructionStyles } from '../../_styles/testInstructionStyle'
import { selectNetworkSettings } from '../../network/model'
import { TimerProps } from '../types'

interface TimerWithInstructionsProps extends TimerProps {
  remainingTime: number
  matches: boolean
}

export const TimerWithInstructions = (props: TimerWithInstructionsProps) => {
  const {
    current,
    timerActive,
    timerValue,
    remainingTime,
    timerContinue,
    matches,
  } = props

  const classes = testInstructionStyles()
  const settings = useSelector(selectNetworkSettings)
  const children = ({ remainingTime }: any) => {
    let timerColor = settings?.primaryColor || '#505358'
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    if (minutes < 2 && minutes > 1) {
      timerColor = '#FAC133'
    } else if (minutes <= 1) {
      timerColor = '#FB646A'
    }
    let spacer = ''
    if (seconds < 10) {
      spacer = '0'
    }
    return (
      <>
        <Typography
          className={classes.timer}
          style={{ color: `${timerColor}` }}
        >
          {`${minutes}:${spacer}${seconds}`}
        </Typography>
      </>
    )
  }

  if (!(current && timerActive && timerValue && remainingTime)) return null

  return (
    <Box>
      <Box
        className={`${classes.instructionContainer} 'reduced'}`}
        id="instructionContainer"
      >
        {!matches && (
          <Timer
            current={current}
            className={classes.linearBox}
            timerActive={timerActive}
            timerValue={timerValue}
            remainingTime={remainingTime}
            timerContinue={timerContinue}
          >
            {children}
          </Timer>
        )}
        {current?.imageURL && (
          <img
            className={classes.img}
            src={current?.imageURL}
            alt="test-instructions"
          />
        )}
        {current?.videoURL && (
          <Box className={classes.videoContainer}>
            <video src={current.videoURL} controls className={classes.video} />
          </Box>
        )}
        <Box className={classes.assetBox} id="instructions">
          <Typography className={classes.title}>{current?.title}</Typography>
          <Typography className={classes.body}>
            <HTMLTextComponent HTMLString={current?.body} />
          </Typography>
          {matches && (
            <Timer
              current={current}
              className={classes.linearBox}
              timerActive={timerActive}
              timerValue={timerValue}
              remainingTime={remainingTime}
              timerContinue={timerContinue}
            >
              {children}
            </Timer>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const Timer = (props: {
  current: any
  className: string
  children: ReactNode
  timerActive: boolean
  timerValue: number
  remainingTime: number
  timerContinue: (prop1: boolean) => void
}) => (
  <Box className={props.className}>
    {props.timerActive && (
      <CountdownCircleTimer
        isPlaying={props.timerActive}
        strokeWidth={0}
        trailStrokeWidth={0}
        size={50}
        strokeLinecap="round"
        rotation="counterclockwise"
        onComplete={() => {
          props.timerContinue(props.current?.autoStartTimer)
          return [false, 0]
        }}
        duration={props.timerValue}
        initialRemainingTime={props.remainingTime}
        colors={[['#FB646A', 0.1]]}
      >
        {props.children}
      </CountdownCircleTimer>
    )}
  </Box>
)
