import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import React from 'react'

export const DateSend = ({
  dateSend,
  separator = '',
}: {
  dateSend: string
  separator?: string
}) => {
  const getLastMessageDate = () => {
    const date = new Date(dateSend)
    if (isToday(date)) {
      return format(date, 'h:mm a')
    }
    if (isYesterday(date)) {
      return `${format(date, 'ccc')} ${separator}${format(date, 'hh:mm a')}`
    }
    return `${format(date, 'LL/dd/yy')}  ${separator}${format(date, 'h:mm a')}`
  }
  return (
    <div>
      <LastMessageAndIcon>
        <LastMessage>{getLastMessageDate()}</LastMessage>
      </LastMessageAndIcon>
    </div>
  )
}
const LastMessage = styled(Typography)`
  font-family: Avenir Next Rounded Medium;
  color: #545454;
  font-size: 12px;
  white-space: nowrap;
`
const LastMessageAndIcon = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
`
