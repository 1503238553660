/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box } from '@material-ui/core'
import React from 'react'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { Typography } from '../../../ui'
import { testInstructionStyles } from '../../_styles/testInstructionStyle'
import { CollectionProps } from '../types'

export const CollectionInstructionsOnly = (props: CollectionProps) => {
  const { current } = props
  const classes = testInstructionStyles()

  return (
    <Box className={classes.instructionContainer} id="instructionContainer">
      {current?.imageURL && (
        <div className={classes.imageWrapper}>
          <img
            className={classes.img}
            src={current.imageURL}
            alt={`Test Instructions ${current.title}`}
          />
        </div>
      )}
      {current?.videoURL && (
        <Box className={classes.videoContainer}>
          <video src={current.videoURL} controls className={classes.video} />
        </Box>
      )}
      <Box className={classes.assetBox} id="instructions">
        <Typography className={classes.title}>{current?.title}</Typography>
        <Typography className={classes.body}>
          {current?.body && <HTMLTextComponent HTMLString={current.body} />}
        </Typography>
      </Box>
    </Box>
  )
}
