import { EncounterPharmacy } from "../../pages/treatment-plan/helper"
import { ConsultPrescription } from "../health-service-lite/types"

export const formatPharmacyData = (data: any) => {
  if (!data) return
  return {
    pharmacy: {
      name: data.Name,
      line1: data.Address?.LineAddress1,
      line2: data.Address?.LineAddress2,
      city: data.Address?.City,
      state: data.Address?.State,
      zip: data.Address?.Zip,
    },
    pharmacy_phone: data.ContactDetails?.MobilePhoneNumber,
  }
}

export const joinPharmaciesAndPrescript = (
  pharm: EncounterPharmacy[],
  prescript: ConsultPrescription[]
): EncounterPharmacy[] | null => {
  const pharmacies: EncounterPharmacy[] = []
  let successfulMatch = false
  if (!pharm || !prescript) return null

  pharm?.forEach((p: EncounterPharmacy) => {
    pharmacies.push({ ...p, prescriptions: [] })
  })

  if (pharmacies.length === 0) return null
  prescript?.forEach((t: ConsultPrescription) => {
    const pharm = pharmacies.findIndex((p) => p.NCPDPID === t.pharmacyNCPDPId)
    if (pharm === -1) return null
    successfulMatch = true
    pharmacies[pharm].prescriptions.push(t)
  })

  return successfulMatch ? pharmacies : null
}
