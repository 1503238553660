import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getEncounterRecord } from '../../features/encounter/model'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { paths } from '../paths'
import { telehealthStyles } from './components/styles'
import { TelehealthVideoCall } from './components/telehealthVideoCall'
import {
  getLastSession,
  selectMemberInSession,
  selectSessionId,
  TelehealthPages,
} from './model'
import {
  BeInTouchPage,
  CompletePage,
  RateYourExperiencePage,
  WaitingRoomPage,
} from './steps'

export const TelehealthPage = () => {
  const classes = telehealthStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const sessionId = useSelector(selectSessionId)
  const history = useHistory()
  const matches = useMediaQuery(theme.breakpoints.down(600))
  const memberInSession = useSelector(selectMemberInSession)
  const { type } = useParams<{
    type: TelehealthPages
  }>()

  useEffect(() => {
    const hasActiveSession = lsClient.getUserLSByKey('telehealthSessionId')
    if (hasActiveSession && hasActiveSession !== sessionId) {
      dispatch(getLastSession())
    }
  }, [])

  useEffect(() => {
    if (type === 'session' || type === 'rate-your-experience') {
      getEncounter()
    }
  }, [type])

  const getEncounter = () => {
    const encounterId = lsClient.getUserLSByKey('healthServiceId')
    dispatch(getEncounterRecord(encounterId))
  }

  const steps = [
    {
      label: 'Request Consult',
      type: 'waiting-room',
      content: (
        <WaitingRoomPage
          handleNext={() =>
            history.push(paths.app.telehealth(TelehealthPages.SESSION))
          }
        />
      ),
    },
    {
      label: 'Session',
      type: 'session',
      content: <TelehealthVideoCall type={type} />,
    },
    {
      label: 'Rate Your Experience',
      type: 'rate-your-experience',
      content: (
        <RateYourExperiencePage
          handleNext={() => {
            lsClient.setUserLS('ratedProvider', 'yes')
            history.push(paths.app.telehealth(TelehealthPages.COMPLETE))
          }}
        />
      ),
    },
    {
      label: 'Be in touch',
      type: 'we-will-be-in-touch',
      content: (
        <BeInTouchPage
          handleNext={() => {
            history.push(paths.app.dashboard())
          }}
        />
      ),
    },
    {
      label: 'Complete',
      type: 'complete',
      content: (
        <CompletePage
          handleNext={() => history.push(paths.app.dashboard())}
          matches={matches}
        />
      ),
    },
  ]

  const renderStepContent = () => {
    const step = _.find(steps, { type })
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.content}>
          {step?.content || steps[0].content}
        </Box>
      </Box>
    )
  }

  return (
    <>
      {!memberInSession ? (
        <PageWrapper
          title={'Telehealth Consultation'}
          showBack={false}
          className={
            type === TelehealthPages.COMPLETE ? classes.completeBg : undefined
          }
        >
          <Box className={classes.telehealthWrapper}>{renderStepContent()}</Box>
        </PageWrapper>
      ) : (
        <Box className={classes.videoSession}>{renderStepContent()}</Box>
      )}
    </>
  )
}
