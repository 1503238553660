import { Box, Dialog } from '@material-ui/core'
import MUICheckbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import CloseIcon from '@material-ui/icons/Close'
import createDOMPurify from 'dompurify'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as MoreInfo } from '../../../assets/icons/moreinfo.svg'
import { selectLanguageContent } from '../../../features/translation'
import { I18n } from '../../../features/translation/types'
import { SelfAssessmentInstruction, TestResult } from '../../../types/tests'
import { Button, Typography } from '../../../ui'
import { Controls } from '../../../ui/templates/app-layout'
import { selectSelfAssessmentInstructions } from '../../register-a-test-module/model'
import { selectLoading } from '../model'
import { CancelTestDialog } from './cancelTestDialog'

const DOMPurify = createDOMPurify(window)

type SelfAssessmentInstructionDialog = SelfAssessmentInstruction | undefined

interface SelfReportingDialogProps {
  open: boolean
  setSelfAssessment: any
  selfAssessment: TestResult
  submitScan: () => void
  closeSelfAssessment: () => void
}

export const SelfReportingDialog = (props: SelfReportingDialogProps) => {
  const {
    open,
    setSelfAssessment,
    selfAssessment,
    submitScan,
    closeSelfAssessment,
  } = props
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const [infoDialogType, setInfoDialogType] =
    useState<SelfAssessmentInstructionDialog>(undefined)
  const [iAgree, setIAgree] = useState(false)
  const [cancelTestDialogOpen, setCancelTestDialogOpen] = useState(false)
  const isloading = useSelector(selectLoading)
  const selfAssessmentInstructions = useSelector(
    selectSelfAssessmentInstructions
  )

  return (
    <Dialog open={open} fullScreen>
      <Box className={classes.headerWrapper}>
        <Box onClick={closeSelfAssessment} className={classes.backIconWrapper}>
          <CallReceivedIcon color="primary" className={classes.backIcon} />
        </Box>
        <Typography
          color="primary"
          className={classes.headerTitle}
          variant="h1"
        >
          {i18n.self_reporting}
        </Typography>
        <Box
          onClick={() => setCancelTestDialogOpen(true)}
          className={classes.closeIconWrapper}
        >
          <CloseIcon color="primary" className={classes.backIcon} />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.assetBox}>{i18n.select_yout_test_cassette}</Box>
        <Box className={classes.resultsContainer}>
          {selfAssessmentInstructions.map((inst, i) => (
            <Box className={classes.resultsColumn} key={`${i}-${inst.title}`}>
              <Box
                className={
                  selfAssessment === inst.title
                    ? `${classes.results} selected`
                    : `${classes.results}`
                }
                onClick={() => setSelfAssessment(inst.title)}
              >
                <Box className={classes.resultsImages}>
                  <img
                    className={classes.img}
                    src={inst.imageURL}
                    alt={i18n.negative_text_info_title}
                  />
                </Box>
                <Typography className={classes.resultText}>
                  {inst.title}
                </Typography>
              </Box>
              <MoreInfo
                className={classes.infoButton}
                onClick={() => setInfoDialogType(inst)}
              />
            </Box>
          ))}
        </Box>
        <Controls>
          <Box className={classes.checkbox}>
            <MUICheckbox
              name="attestCorrect"
              onChange={() => setIAgree(!iAgree)}
              checked={iAgree}
              classes={{
                root: classes.checkmark,
                checked: classes.checkboxActive,
              }}
            />
            <Typography className={classes.checkboxLabel}>
              {i18n.i_agree_self_assessment_select_result}
            </Typography>
          </Box>
          <Button
            onClick={submitScan}
            className={classes.confirmButton}
            disabled={!iAgree || selfAssessment === 'unset' || isloading}
          >
            {i18n.preview_confirm_result}
          </Button>
        </Controls>
      </Box>
      <InfoDialog
        type={infoDialogType}
        setInfoDialogType={setInfoDialogType}
        i18n={i18n}
      />
      <CancelTestDialog
        current={undefined}
        open={cancelTestDialogOpen}
        setDialogOpen={setCancelTestDialogOpen}
      />
    </Dialog>
  )
}

const InfoDialog = (props: {
  type: SelfAssessmentInstructionDialog
  setInfoDialogType: any
  i18n: I18n
}) => {
  const { type, setInfoDialogType, i18n } = props
  const classes = useStyles()

  return (
    <Dialog open={type !== undefined}>
      <Box className={classes.infoDialog}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title} align="left">
            {type?.title}
          </Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => setInfoDialogType(undefined)}
          />
        </Box>
        <Typography className={classes.body} align="left">
          {type && (
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(type.body),
              }}
            />
          )}
        </Typography>
      </Box>
    </Dialog>
  )
}

const FONT_REGULAR = 'Avenir Next Rounded Regular'

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
    width: '100%',
    borderBottom: '1px solid #efefef',
  },
  backIconWrapper: {
    transform: 'rotate(45deg)',
    position: 'absolute',
    left: 15,
    cursor: 'pointer',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  closeIconWrapper: {
    position: 'absolute',
    right: 15,
    cursor: 'pointer',
  },
  header: {},
  content: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'space-between',
    },
  },
  resultsContainer: {
    marginBottom: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resultsColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  results: {
    'margin': '5px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': '200px',
    'height': '300px',
    'border': '1px solid #C4C4C4',
    'borderRadius': '8px',
    '&:hover': {
      border: '1px solid #003C71',
      backgroundColor: '#DEF5FF',
      boxShadow: '0 4px 4px 0 rgba(0,0,0,.25)',
    },
    '&.selected': {
      border: '1px solid #003C71',
      backgroundColor: '#DEF5FF',
    },
    [theme.breakpoints.down(600)]: {
      'width': '112px',
      'height': '187px',
    }
  },
  resultsImages: {},
  resultText: {
    marginTop: '10px',
    fontSize: '14px',
    color: '#505358',
    fontFamily: FONT_REGULAR,
    textAlign: 'center',
  },
  infoButton: {
    marginTop: '10px',
    height: '24px',
    width: '24px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkboxLabel: {
    marginTop: '9px',
    fontSize: '16px',
    color: '#505358',
    fontFamily: FONT_REGULAR,
  },
  checkmark: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checkboxActive': {
      color: 'rgb(0, 60, 114)',
    },
    '&$checkboxActive:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: '#54565B',
    },
    '&$checkboxActive .MuiSvgIcon-root': {
      color: 'rgb(0, 60, 114)',
    },
  },
  checkboxActive: {},
  confirmButton: {
    marginTop: 15,
    marginBottom: 35,
  },
  assetBox: {
    marginTop: '42px',
    fontSize: 24,
    color: '#505358',
    fontFamily: FONT_REGULAR,
    lineHeight: '36px',
    margin: 20,
    textAlign: 'center',
  },
  img: {
    margin: '4px',
    height: '150px',
    [theme.breakpoints.down(600)]: {
      margin: '2px',
      height: '92px',
    },
  },
  pageLink: {
    marginLeft: 4,
    cursor: 'pointer',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '24px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '36px',
    fontFamily: 'Avenir Next Rounded Medium',
    color: '#505358',
    marginLeft: '32px',
  },
  body: {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: FONT_REGULAR,
    color: '#505358',
    margin: '16px 32px',
  },
  infoDialog: {
    minHeight: '450px',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      minHeight: '450px',
      minWidth: '80vw',
    },
  },
  backIcon: {
    color: '#505358',
    cursor: 'pointer',
  },
  closeIcon: {
    marginRight: '32px',
    color: '#505358',
    cursor: 'pointer',
  },
}))
