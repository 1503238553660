import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core/TextField'
import React from 'react'
import { ErrorField } from '..'
import { TextField } from './text-field'

const InputMask = require('react-input-mask')

type MaskedInputProps = {
  maskChar: string | null
  mask: string
  variant: 'standard' | 'outlined' | 'filled'
  helperText?: string
  error: boolean | undefined
  skipTest?: boolean
} & TextFieldProps

export const MaskedInput = (props: MaskedInputProps) => {
  const { variant, error, helperText, disabled, skipTest } = props
  const classes = useStyles()

  const beforeMaskedValueChange = (newState: {
    value: string
    selection: {
      start: number | null
      end: number | null
    } | null
  }) => {
    let { value } = newState
    let selection = newState.selection
    let cursorPosition = selection ? selection.start : null

    const regex = new RegExp(/[^0-9]$/)

    if (!skipTest && regex.test(value)) {
      if (cursorPosition === value.length) {
        cursorPosition--
        selection = { start: cursorPosition, end: cursorPosition }
      }
      value = value.trim().slice(0, -1)
    }

    return {
      value,
      selection,
    }
  }

  const getClasses = () => {
    let filledClass = `${classes.fieldContainer}`
    if (error) {
      filledClass += ` ${classes.inError}}`
    }
    if (disabled) {
      filledClass += ` ${classes.disabled}`
    }

    return filledClass
  }

  return (
    <>
      {variant === 'filled' ? (
        <Box className={classes.fieldWrapper}>
          <Box className={getClasses()}>
            <InputMask
              beforeMaskedValueChange={beforeMaskedValueChange}
              {...props}
            >
              {(inputProps: any) => (
                <TextField
                  disabled={props.disabled}
                  {...inputProps}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  noHelperText
                />
              )}
            </InputMask>
          </Box>
          {error && helperText && <ErrorField error={helperText} />}
        </Box>
      ) : (
        <InputMask beforeMaskedValueChange={beforeMaskedValueChange} {...props}>
          {(inputProps: any) => (
            <TextField
              disabled={props.disabled}
              {...inputProps}
              inputProps={{
                inputMode: 'numeric',
              }}
              noHelperText
            />
          )}
        </InputMask>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    padding: '1px',
    width: 'calc(100% - 4px)',
    border: '1px solid #79747E',
    borderRadius: '4px',
  },
  inError: {
    border: '1px solid',
    borderColor: theme.palette.error.main,
  },
  disabled: {
    borderStyle: 'dashed !important',
    borderColor: '#79747E !important',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: '12px',
  },
}))
