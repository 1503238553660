import { Link as MUILink, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../../features/translation'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { NetworkSettings } from '../../../network/type'

interface SupportProps {
  setSupportDialogOpen: (param: boolean) => void
  currentNetwork: NetworkSettings | null
}

export const ContactSupportFooter = (props: SupportProps) => {
  const { setSupportDialogOpen, currentNetwork } = props
  const classes = onboardingStyles()

  const i18n = useSelector(selectLanguageContent)
  let address = currentNetwork?.addressOne
  if (currentNetwork?.addressTwo) {
    address += ` ${currentNetwork.addressTwo}`
  }
  let cityState = currentNetwork?.city
  if (currentNetwork?.state) {
    cityState
      ? (cityState += `, ${currentNetwork.state}`)
      : (cityState = currentNetwork.state)
  }
  if (currentNetwork?.zip) {
    cityState += ` ${currentNetwork.zip}`
  }

  return (
    <div className={classes.buttonWrapper}>
      {/* <MUILink
        role="button"
        color="primary"
        className={classes.pageLink}
        underline="always"
        onClick={() => setSupportDialogOpen(true)}
      >
        {i18n.contact_support}
      </MUILink> */}
      <Typography className={classes.contactCompany}>
        {currentNetwork?.name}
      </Typography>
      <Typography className={classes.contactAddress}>{address}</Typography>
      <Typography className={classes.contactAddress}>{cityState}</Typography>
    </div>
  )
}
