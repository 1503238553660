import React from 'react'
import ContentLoader from 'react-content-loader'

export const TableSkeletonLoader = () => (
  <ContentLoader height={275} width="100%" speed={0.8} viewBox="0 0 100% 100%">
    <rect x="0" y="40" rx="20" ry="20" width="100%" height="30" />
    <rect x="0" y="90" rx="20" ry="20" width="100%" height="30" />
    <rect x="0" y="140" rx="20" ry="20" width="100%" height="30" />
    <rect x="0" y="190" rx="20" ry="20" width="100%" height="30" />
    <rect x="0" y="240" rx="20" ry="20" width="100%" height="30" />
  </ContentLoader>
)
