import { Dialog, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { clearTestKitFlow } from '../../../libs/helpers'
import { Button } from '../../../ui'
import { paths } from '../../paths'
import { TimeExpiredWithImageDialogProps } from '../types'

export const TimeExpiredWithImageDialog = (
  props: TimeExpiredWithImageDialogProps
) => {
  const { open, handleSubmitScan, setTimeExpiredWithStoredImage, image } = props
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)

  const endTest = () => {
    clearTestKitFlow()
    setTimeExpiredWithStoredImage(false)
    history.push(paths.app.dashboard())
  }

  return (
    <Dialog open={open}>
      <Box className={classes.schedulePageHolder}>
        <Box className={classes.scheduleWrapper}>
          <Box>
            <img src={image} alt="test-history" className={classes.img} />
          </Box>
          <Box className={classes.headerWrapper}>
            <Typography
              variant="h4"
              color="primary"
              style={{ marginBottom: 16 }}
            >
              {i18n.expiration_title}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: '#7b7c7d', marginBottom: 20 }}
            >
              Your scan timer has expired, but you have captured an image before
              the expiration. You may use this image, but you cannot take
              another at this point.
            </Typography>
            <Typography
              variant="body1"
              style={{ color: '#7b7c7d', marginBottom: 20 }}
            >
              If you choose to end your test, this test kit will be invalid.
            </Typography>
            <Typography
              variant="body1"
              style={{ color: '#7b7c7d', marginBottom: 20 }}
            >
              Please do not refresh your browser before completing your
              submission. If you do, your image will be lost.
            </Typography>
          </Box>
          <Button onClick={endTest} className={classes.confirmButton}>
            {i18n.end_test_action}
          </Button>
          <Button onClick={handleSubmitScan} className={classes.confirmButton}>
            Use Image
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  img: {
    height: 200,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  confirmButton: {
    minWidth: 250,
    marginTop: 10,
    marginBottom: 10,
  },
  schedulePageHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    width: 300,
    height: 750,
    padding: 20,
    [theme.breakpoints.down(500)]: {
      padding: 0,
    },
  },
  scheduleWrapper: {
    margin: '0 auto',
    display: 'flex',
    padding: '0 15px',
    maxWidth: 350,
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}))
