import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Typography } from '../../index'

export const ErrorField = (props: {
  error: true | React.ReactChild | React.ReactFragment | React.ReactPortal
}) => {
  const { error } = props
  const classes = useStyles()

  return (
    <Typography variant="caption" className={classes.error}>
      {error}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginLeft: '12px',
  },
}))
