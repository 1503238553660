import axios from 'axios'
import { lsClient } from '../../ls-client'

export const FLOW_START = ''

export class FlowApi {
  flowApiUrl: string
  flowBuilderApiUrl: string
  flowId: null | string = null
  executionId: null | string = null
  currentModule: null | string = null

  constructor(flowApiUrl: string, flowBuilderApiUrl: string) {
    this.flowApiUrl = flowApiUrl
    this.flowBuilderApiUrl = flowBuilderApiUrl
  }

  setFlowId(flowId: string) {
    this.flowId = flowId
  }

  setExecutionId(executionId: string) {
    this.executionId = executionId
  }

  setCurrentModule(currentModule: string) {
    this.currentModule = currentModule
  }

  getTenant() {
    return (
      lsClient.getUserLSByKey('tenantId') || localStorage.getItem('tenantId')
    )
  }

  async startFlow(flowId: string) {
    this.setFlowId(flowId)
    const response: any = await axios.post(
      `${this.flowApiUrl}/start/${this.flowId}`,
      null,
      {
        headers: {
          'userId': 'super.builder',
          'x-sf-tenant': this.getTenant(),
          'Content-Type': 'application/json',
        },
      }
    )

    const { program_execution_id, start_module } = response.data
    this.setExecutionId(program_execution_id)
    this.setCurrentModule(start_module.module_id)

    return { start_module, flowExecutionId: program_execution_id, flowId }
  }

  async next(output: any) {
    const response = await axios.post(
      `${this.flowApiUrl}/next/${this.flowId}`,
      {
        program_execution_id: this.executionId,
        module_execution: {
          module_id: this.currentModule,
          result: {
            status: 'SUCCESS',
            output: {
              out: output,
            },
          },
        },
      },
      {
        headers: {
          'userId': 'super.builder',
          'x-sf-tenant': this.getTenant(),
          'Content-Type': 'application/json',
        },
      }
    )
    if (response.data) {
      this.setCurrentModule(response.data.module_id)
      return response.data
    }
  }

  async getFlows() {
    const response = await axios.get(`${this.flowBuilderApiUrl}/workflows`)
    if (response.data) {
      return response.data
    }
  }
}
