import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { lsClient } from '../../ls-client'
import { setAppointmentId } from '../checkout/model'
import { handleError } from '../../libs/helpers'

interface ScheduleAppointmentSlice {
  isLoading: boolean
  error: string
  availableSlots: []
  appointmentData: any
  isLoadingBook: boolean
}

const initialStore: ScheduleAppointmentSlice = {
  isLoading: false,
  error: '',
  availableSlots: [],
  appointmentData: null,
  isLoadingBook: false,
}

export const scheduleAppointmentSlice = createSlice({
  name: 'scheduleAppointment',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setLoadingBook(store, { payload }: { payload: boolean }) {
      store.isLoadingBook = payload
    },
    setAvailableSlots(store, { payload }: { payload: any }) {
      store.availableSlots = payload.availableDates[0].slots
    },
    setError(store, { payload }: { payload: string }) {
      store.error = payload
    },
    setAppointmentData(store, { payload }: { payload: any }) {
      store.appointmentData = payload
    },
  },
})

// export const {  } = selectTestKitSlice.actions

const {
  setLoading,
  setAvailableSlots,
  setError,
  setAppointmentData,
  setLoadingBook,
} = scheduleAppointmentSlice.actions

export const getAvailableQuestSlots = (siteCode: string, date: any) => async (
  dispatch: any, getStore: any,
) => {

  const { languageContent } = getStore().translation
  dispatch(setError(''))
  dispatch(setLoading(true))

  const params = {
    siteCode,
    toDate: date,
    fromDate: date,
  }

  const { data, success, error } = await api.getAvailableQuestSlotsRequest(
    params,
  )

  if (error) {
    dispatch(setLoading(false))
    dispatch(setError(error))
    return dispatch(handleError(null, languageContent[error] || error))
  }

  if (success && data) {
    dispatch(setAvailableSlots(data))
    dispatch(setLoading(false))
  }
}

export const bookAppointment = (payload: {
  date: any
  time: any
  siteCode: string
  isCovidAntibodyTest: boolean
}) => async (dispatch: any, getStore: any) => {

  const { languageContent } = getStore().translation
  dispatch(setLoadingBook(true))

  // const res = {
  //   nationalHealthId: 'b6fa5c2c813140dc883ebe3294c9a460',
  //   confirmationId: 'JAW31499',
  //   appointmentStart: '2021-10-05T14:10:00-07:00',
  //   appointmentEnd: '2021-10-05T14:20:00-07:00',
  //   locationCode: 'F4I',
  //   locationId: '7537',
  //   locationName: 'Agoura Hills Canwood - Employer Drug Testing Not Offered',
  //   timeZone: 'America/Los_Angeles',
  //   status: 'Succeeded',
  //   cancelDateTime: '0001-01-01T00:00:00',
  //   _id: '615c03648f6bb9b7ab616e64',
  //   created: '2021-10-05T07:48:52.1309964Z',
  //   creator: 'bojan.mise@gmail.com',
  //   modified: '2021-10-05T07:48:52.1309964Z',
  //   modifier: 'bojan.mise@gmail.com',
  // }

  const res = await api.bookAppointmentRequest(payload)
  dispatch(setLoadingBook(false))

  if (res?.error) {
    // try to get translated error by key from localization file or render received error string
    dispatch(handleError(null, languageContent[res?.error] || res?.error))
    return false
  }

  if (res?.success) {
    dispatch(setAppointmentData(res))
    dispatch(setAppointmentId(res.confirmationId))
    lsClient.checkout.setSelectedAppointmentDataLS(res.data._id, payload.date, payload.time)
    return true

  } else {
    dispatch(handleError(res))
  }

  return false

  //   dispatch(setAppointmentData(res))
  //   dispatch(setAppointmentId(res.confirmationId))
  //   lsClient.checkout.setSelectedAppointmentDataLS(res.confirmationId, payload.date, payload.time)
  // return true;
}

export const getAppointmentData = (appointmentId: string) => async (dispatch: any) => {
  const res = await api.getAppointmentDataRequest(appointmentId)
}

export const scheduleAppointmentReducer = scheduleAppointmentSlice.reducer

export const scheduleAppointmentReducerName = scheduleAppointmentSlice.name

interface RootStore {
  [scheduleAppointmentReducerName]: typeof initialStore
}

export const selectIsLoading = ({ scheduleAppointment }: RootStore) => scheduleAppointment.isLoading

export const selectScheduleAppointmentError = ({ scheduleAppointment }: RootStore) => scheduleAppointment.error

export const selectAvailableSlots = ({ scheduleAppointment }: RootStore) =>
  scheduleAppointment.availableSlots

export const selectAppointmentData = ({ scheduleAppointment }: RootStore) =>
  scheduleAppointment.appointmentData


export const selectIsLoadingBook = ({ scheduleAppointment }: RootStore) => scheduleAppointment.isLoadingBook
