import { Box, Theme, useMediaQuery } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { isValid } from 'date-fns'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  allowedGenders,
  ethnicities,
  races,
  StepType,
} from '../../../../constants'
import { selectLanguageContent } from '../../../../features/translation'
import { translateOptions } from '../../../../libs/helpers'
import { Button, FilledDatePicker, FilledSelect } from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { selectNetworkSettings } from '../../../network/model'
import { NetworkSettings } from '../../../network/type'
import { getName } from '../../helpers'
import { saveAccountInfo } from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'

export const AccountInformation = (props: RegisterStepProps) => {
  const { handleNext, formik, isInvite } = props
  const classes = onboardingStyles()
  const type = StepType.ACC_INFO
  const i18n = useSelector(selectLanguageContent)
  const currentNetwork = useSelector(selectNetworkSettings)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  const dispatch = useDispatch()

  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  const goNext = () => {
    dispatch(saveAccountInfo(values))
    handleNext()
  }

  useEffect(() => {
    sessionStorage.removeItem('codeSent')
  }, [])

  const {
    collectDateOfBirth,
    collectDateOfBirthRequired,
    collectSex,
    collectSexRequired,
    collectRace,
    raceRequired,
    collectEthicity,
    ehtnicityRequired,
  } = (currentNetwork as NetworkSettings) || {}

  const handleDateChange = (e: MaterialUiPickersDate) => {
    if (!isValid(e)) {
      formik.setFieldValue(getName('dob', type), '')
    } else
      formik.setFieldValue(
        getName('dob', type),
        moment(e).format('MM/DD/YYYY') || ''
      )
  }

  const renderDOB = () =>
    collectDateOfBirth && (
      <FilledDatePicker
        label={i18n.date_of_birth}
        value={
          values.dob
            ? new Date(
                moment(values.dob.toString().split('Z')[0]).format('MM/DD/YYYY')
              )
            : null
        }
        format="MM/dd/yyyy"
        onBlur={formik.handleBlur}
        autoComplete="bday"
        fullWidth
        error={touched?.dob && Boolean(errors?.dob)}
        helperText={touched?.dob && errors?.dob}
        name={getName('dob', type)}
        onChange={(e) => handleDateChange(e)}
        required={collectDateOfBirthRequired}
        disabled={values.hasPrefillDOB}
        maxDate={moment().subtract(18, 'years').subtract(1, 'days').toDate()}
      />
    )

  const renderSexAtBirth = () =>
    collectSex && (
      <FilledSelect
        label={i18n.sex}
        options={translateOptions(allowedGenders, i18n)}
        value={values?.sexAtBirth}
        fullWidth
        name={getName('sexAtBirth', type)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.sexAtBirth && Boolean(errors?.sexAtBirth)}
        helperText={touched?.sexAtBirth ? errors?.sexAtBirth : ''}
        required={collectSexRequired}
      />
    )

  const renderRace = () =>
    collectRace && (
      <FilledSelect
        label={i18n.race}
        options={translateOptions(races, i18n)}
        value={values.race}
        fullWidth
        name={getName('race', type)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.race && Boolean(errors?.race)}
        helperText={touched?.race ? errors?.race : ''}
        required={raceRequired}
      />
    )

  const renderEthicity = () =>
    collectEthicity && (
      <FilledSelect
        label={i18n.ethnicity}
        options={translateOptions(ethnicities, i18n)}
        value={values.ethnicity}
        fullWidth
        name={getName('ethnicity', type)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.ethnicity && Boolean(errors?.ethnicity)}
        helperText={touched?.ethnicity ? errors?.ethnicity : ''}
        required={ehtnicityRequired}
      />
    )

  return (
    <form className={classes.content}>
      <Typography className={classes.title} color="primary" variant="h1">
        {i18n.enter_account_info_header}
      </Typography>
      <Typography className={classes.body} variant="body1" align="left">
        {i18n.enter_account_info_instruction}
      </Typography>
      {/* {isMobile ? (
        <>
          {renderDOB()}
          {renderSexAtBirth()}
        </>
      ) : (
      )} */}
      <Box className={classes.twoFieldsOnlyDesktop}>
        {renderDOB()}
        {renderSexAtBirth()}
      </Box>
      {renderRace()}
      {renderEthicity()}
      <div
        className={
          isInvite ? classes.inviteMobileButtonWrapper : classes.buttonWrapper
        }
      >
        <Button
          className={classes.actionBtn}
          onClick={goNext}
          disabled={!formik.isValid}
          isLoading={formik.isSubmitting}
        >
          {i18n.continue_button}
        </Button>
      </div>
    </form>
  )
}
