import styled from '@emotion/styled'
import { Button, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectNetworkSettings } from '../../../../pages/network/model'
import { paths } from '../../../../pages/paths'
import { getSignupType } from '../../../../pages/register/model/registerSlice'
import { OnboardingType } from '../../../../pages/register/model/type'
import { clearDocTerm } from '../../../terms/model/main'
import { SignupTerms } from './footer-components'
import { RegisterFAQs } from './footer-components/registerFAQs'

export type FooterVerbiageKey = 'TermsOfService' | 'PrivacyPolicy' | ''

export const SignupFooter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [verbiageKey, setVerbiageKey] = useState<FooterVerbiageKey>('')
  const [verbiageOpen, setVerbiageOpen] = useState(false)
  const networkSettings = useSelector(selectNetworkSettings)
  const [openRegistrationFAQ, setOpenRegistrationFAQ] = useState(false)
  const signupType = useSelector(getSignupType)
  const isInvite = Boolean(
    signupType === OnboardingType.DEPENDENT ||
      signupType === OnboardingType.INVITE
  )

  const loc = window.location.pathname
  const isSignUp = loc.includes('register')
  const openTerms = (key: FooterVerbiageKey) => {
    setVerbiageKey(key)
    setVerbiageOpen(true)
  }

  const closeTerms = () => {
    setVerbiageKey('')
    dispatch(clearDocTerm())
    setVerbiageOpen(false)
  }

  if (isInvite) return null

  return (
    <>
      <Toolbar className={classes.toolBar} disableGutters={true}>
        <Typography className={classes.copyring}>
          Powered by Safe Health Systems
        </Typography>
        <RightContentWrapper className={classes.bottomContent}>
          <Button
            aria-label="Terms of Use"
            onClick={() => openTerms('TermsOfService')}
          >
            <Typography className={classes.bottomContentItem}>
              Terms of Use
            </Typography>
          </Button>
          <Button
            aria-label="Privacy Policy"
            onClick={() => openTerms('PrivacyPolicy')}
          >
            <Typography className={classes.bottomContentItem}>
              Privacy Policy
            </Typography>
          </Button>
          {isSignUp && networkSettings?.enableRegistrationFAQ && (
            <Button
              aria-label="Registration frequently asked questions"
              onClick={() => setOpenRegistrationFAQ(true)}
            >
              <Typography className={classes.bottomContentItem}>FAQ</Typography>
            </Button>
          )}
          {!isInvite && (
            <a
              href="mailto:support@safehealth.me"
              style={{ textDecoration: 'none' }}
            >
              <Typography className={classes.bottomContentItem}>
                Contact Us
              </Typography>
            </a>
          )}
        </RightContentWrapper>
      </Toolbar>
      <SignupTerms
        isOpen={verbiageOpen}
        verbiageKey={verbiageKey}
        closeDialog={closeTerms}
      />
      <RegisterFAQs
        isOpen={openRegistrationFAQ}
        closeDialog={() => setOpenRegistrationFAQ(false)}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  toolBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
    padding: '32px 0',
  },
  copyring: {
    fontSize: 16,
    lineHeight: '22px',
    color: '#7E7E7E',
  },
  bottomContent: {
    display: 'flex',
    padding: '0 5px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    marginTop: 8,
    width: '100%',
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      gap: 25,
    },
  },
  bottomContentItem: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '22px',
    color: '#7E7E7E',
    textAlign: 'center',
    [theme.breakpoints.down(300)]: {
      fontSize: '.8rem',
    },
  },
  bottomToolbar: {
    marginBottom: '1rem',
  },
}))

const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
`
