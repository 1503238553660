import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import isToday from 'date-fns/isToday'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '../../../ui'
import { ResultAssessmentMessage } from '../atoms'
import { selectLanguageContent } from '../../translation/'
import { selectLatestResult } from '../model/userTenantSlice'

export const AssessmentRecommendationMessage = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const latestResult = useSelector(selectLatestResult)

  return (
    <BtnWrapper>
      {isToday(new Date(latestResult?.lastAssessment)) ? (
        <ResultAssessmentMessage />
      ) : (
        <Typography variant="subtitle2" align={'center'} className={classes.symptomText}>
           {i18n.not_cleared_for_work}<br/>
            Please take the Virtual Consult!
        </Typography>
      )}
    </BtnWrapper>
  )
}
const useStyles = makeStyles((theme) => ({
  pageLink: {
    width: "250px",
    marginBottom: 10,
    cursor: 'pointer',
  },
  stop: {
    color: '#ff6200',
    width: '1.25em',
    height: '1.25em',
  },
  btnbase: {
    padding: '15px 8px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content',
    gridGap: '8px',
    boxShadow: '1px 1px 8px #828282',
    borderRadius: '8px',
    color: '#4e4e4e',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    [theme.breakpoints.up(1023)]: {
        display: 'none',
    },
    [theme.breakpoints.down(359)]: {
      padding: '9px 8px',
    },
  },
  symptomText: {
    fontFamily: 'Avenir Next Rounded Regular',
    margin: '16px 0',
    fontSize: 14,
    color: '#505358 !important',
    lineHeight: '19.12px',
    letterSpacing: 0.5,
  },
}))
const BtnWrapper = styled.div`
  padding: 0 10px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`
