/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import testResultsImage from '../../assets/img/test-results-main.svg'
import { selectLanguageContent } from '../../features/translation'
import { useStyles } from '../../ui/templates/app-layout'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { selectTestKitDetails } from '../order-test/model'
import { paths } from '../paths'
import { selectInsurance } from './model'

export const InsurancePage = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const i18n = useSelector(selectLanguageContent)
  const testData = useSelector(selectTestKitDetails)
  const insuranceData = useSelector(selectInsurance)

  const insurance = () => {
    if (!insuranceData) {
      history.push(paths.addInsuranceProvider(type, id))
    } else {
      history.push(paths.confirmInsurance(type, id, 'details'))
    }
  }

  const checkout = () => {
    history.push(paths.checkout(type, id))
  }

  return (
    <PageWrapper
      className={childClasses.card}
      backPath={paths.orderTestPreview(type, id)}
    >
      <Box className={childClasses.content}>
        <img
          src={testResultsImage}
          alt={i18n.insurance_title}
          className={childClasses.testResultsImage}
        />
        <Box className={[classes.infoText, childClasses.textCenter].join(' ')}>
          {i18n.insuranse_provider_pay_label}
        </Box>
        <Box className={classes.optionsWrapper}>
          <Box onClick={insurance} className={classes.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {i18n.use_my_insurance_label}
              </Typography>
              <Typography variant="subtitle2" className={classes.testLab}>
                {i18n.have_your_plan_label}
              </Typography>
            </Box>
            <Box className={classes.testPrice}>
              <Typography color="primary">{i18n.varies_label}</Typography>
              <ChevronRightIcon color="primary" />
            </Box>
          </Box>
          <Box onClick={checkout} className={classes.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {i18n.pay_with_credit_card_label}
              </Typography>
              <Typography variant="subtitle2" className={classes.testLab}>
                {i18n.pay_retail_price_label}
              </Typography>
            </Box>
            <Box className={classes.testPrice}>
              <Typography color="primary">
                {testData?.price ? `$${testData?.price}` : null}
              </Typography>
              <ChevronRightIcon color="primary" />
            </Box>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  )
}

const childStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    justifyContent: 'space-between',
    height: '100%',
    padding: '25px 48px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: 24,
    },
  },
  testLab: {
    color: '#7B7C7D',
  },
  testResultsImage: {
    width: 150,
    margin: '0px auto',
  },
  textCenter: {
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '600px',
  },
}))
