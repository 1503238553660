import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../../api'
import { defaultLanguage } from '../default'
import { lsClient } from '../../../ls-client'

const defaultLang = lsClient.getUserLSByKey('lang') || 'en'

export const initialStore = {
  selectedLanguage: defaultLang,
  languageContent: defaultLanguage,
}

const translation = createSlice({
  name: 'translation',
  initialState: initialStore,
  reducers: {
    setLanguage(store, { payload }) {
      store.selectedLanguage = payload
    },
    setLanguageContent(store, { payload }) {
      store.languageContent = { ...payload }
    },
  },
})

const { actions } = translation
export const { setLanguage } = actions

export const translationReducer = translation.reducer
export const translationReducerName = translation.name

export const getLanguageFile = (lang: string, tenant: string) => async (dispatch: Dispatch) => {
  let langFile = defaultLanguage
  try {
    const { data } = await api.getLanguageSettings(lang, tenant)
    langFile = await api.getLanguageFile(lang, data.version, tenant)
    dispatch(actions.setLanguageContent({ ...defaultLanguage, ...langFile }))
    dispatch(actions.setLanguage(lang))
    lsClient.setUserLS('lang', lang)
  } catch {
    // Use default language in event of API error
    lsClient.setUserLS('lang', defaultLang)
    dispatch(actions.setLanguageContent({ ...defaultLanguage }))
    console.error('Error retrieving language file')
  }
}
