import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

interface ColProps {
  gap?: string
  justify?: string
  flex?: number
  alignContent?: 'space-between'
  maxWidth?: number
  width?: number | string
  padding?: string
  as?: 'div' | 'form'
  onSubmit?: (e: React.FormEvent) => void
  box?: 'border-box' | 'initial'
}

export const Col: React.FC<ColProps> = ({
  children,
  gap = '1rem',
  justify,
  flex,
  width,
  alignContent,
  maxWidth,
  padding,
  as: Component = 'div',
  onSubmit,
  box = 'initial',
}) => {
  const classes = useStyles({
    gap,
    justify,
    flex,
    width,
    alignContent,
    maxWidth,
    padding,
    box,
  })
  return (
    <Component className={classes.root} onSubmit={onSubmit} id="pageWrapper">
      {children}
    </Component>
  )
}
const useStyles = makeStyles({
  root: {
    display: 'grid',
    gap: ({ gap }: ColProps) => gap,
    justifyContent: ({ justify }: ColProps) => justify,
    alignItems: 'center',
    flex: ({ flex }: ColProps) => flex,
    width: ({ width }: ColProps) => width,
    maxWidth: ({ maxWidth }: ColProps) => maxWidth,
    alignContent: ({ alignContent }: ColProps) => alignContent,
    padding: ({ padding }: ColProps) => padding,
    boxSizing: ({ box }: ColProps) => box,
  },
})
