import * as Yup from 'yup'
import moment from 'moment'
import { I18n } from '../../../../features/translation/types'
import { validateDate } from '../../../../libs/helpers'

const dependentFormValidation = (i18n: I18n) =>
  Yup.object().shape({
    firstName: Yup.string().required(i18n.required),
    lastName: Yup.string().required(i18n.required),
    birthDate: Yup.date()
      .nullable()
      .transform(validateDate)
      .typeError(i18n.invalid_date)
      .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
      .max(
        moment().subtract(18, 'years').subtract(1, 'days').toDate(),
        i18n.to_young_error_message
      )
      .required(i18n.required),
    sexAtBirth: Yup.string().required(i18n.required),
    relationship: Yup.string().required(i18n.required),
  })

export default dependentFormValidation
