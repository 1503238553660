import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedCreditCard, setSelectedCreditCard } from '../checkout/model'
import { paths } from '../paths'
import { SubHeaderGetTested } from '../../features/application'
import { Loading } from '../../ui'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import {
  FormWrapper,
  Wrapper,
  Content,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'
import {
  deletePaymentMethod, getCreditCards,
  selectCreditCards,
  selectIsLoading,
} from './model'
import { CreditCard } from './types'

export const PaymentMethodsPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()

  const isDeleting = useSelector(selectIsLoading)
  const paymentMethods: CreditCard[] = useSelector(selectCreditCards)
  const selectedPaymentMethod: CreditCard = useSelector(selectSelectedCreditCard)
  const i18n = useSelector(selectLanguageContent)

  const [selectedMethod, setSelectedMethod] = useState(
    selectedPaymentMethod?.id
  )
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await dispatch(getCreditCards())
      setLoading(false)
    })()
  }, [])

  const goAddCard = () => {
    history.push(paths.addCard(type, id))
  }

  const handleSetSelectedMethod = () => {
    if (selectedMethod) {
      const paymentMethodData = paymentMethods.find(
        (method: CreditCard) => method?.id === selectedMethod
      )
      dispatch(setSelectedCreditCard(paymentMethodData || null))
    }
    if (window.location.href.includes("settings")) {
      history.push(paths.app.settings())
    } else history.push(paths.checkout(type, id))
  }

  const handleDelete = (paymentMethodId: string) => {
    dispatch(deletePaymentMethod(paymentMethodId))
  }

  if (loading) return <Loading />

  return (
    <Wrapper>
      {isDeleting ? <ContentLoaderBox /> : null}
      <SubHeaderGetTested
        title={i18n.credit_cards_screen_title}
        onHandleBack={handleSetSelectedMethod}
      />
      <OptionWrapper>
        <Typography style={{ color: 'black', paddingLeft: '1em', }}>
          {i18n.payment_method_settings}
        </Typography>
        <IconButton
          style={{
            color: primaryColor,
            fontSize: '16px',
            fontWeight: 600,
          }}
          onClick={goAddCard}
        >
          {i18n.add_credit_card_screen_title}
        </IconButton>
      </OptionWrapper>
      <Content noValidate>
        <FormWrapper>
          {paymentMethods?.length
            ? paymentMethods.map((item: any) => (
                <Box className={classes.optionsWrapper}>
                  <Option
                    id={item.id}
                    item={item}
                    handleDelete={handleDelete}
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                    i18n={i18n}
                  />
                </Box>
              ))
            : null}
        </FormWrapper>
        {/* <Button
          className={classes.submitButton}
          onClick={handleSetSelectedMethod}
          disabled={!selectedMethod}
        >
          Continue
        </Button> */}
      </Content>
    </Wrapper>
  )
}

const Option = (props: any) => {
  const classes = useStyles()
  const { id, item, selectedMethod, setSelectedMethod, handleDelete, i18n } = props

  return (
    <OptionWrapper
      key={id}
      className={`${classes.paymentMethod} ${
        selectedMethod === id ? classes.selected : ''
      }`}
      onClick={() => setSelectedMethod(id)}
    >
      <Box className={classes.option}>
        {item.brand || i18n.credit_card}
        {item.last4 ? `...${item.last4}` : ''}
      </Box>
      <Box className={classes.rightChevron} style={{ color: 'black' }}>
        <IconButton
          style={{
            color: primaryColor,
            fontSize: '16px',
            fontWeight: 600,
          }}
          onClick={() => handleDelete(item.id)}
        >
          {i18n.delete_button}
        </IconButton>
        {/*<ChevronRightIcon />*/}
      </Box>
    </OptionWrapper>
  )
}

const OptionWrapper = styled.div`
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
`

const primaryColor = 'rgb(0, 60, 114)'
const primaryColorDisabled = 'rgba(0, 60, 114, 0.7)'
const useStyles = makeStyles({
  submitButton: {
    'margin': '8px',
    'maxHeight': 48,
    'maxWidth': 350,
    'width': '100%',
    'backgroundColor': primaryColor,
    '&.Mui-disabled': {
      backgroundColor: primaryColorDisabled,
    },
    '&:hover': {
      borderColor: primaryColor,
      color: primaryColor,
      backgroundColor: 'transparent',
    },
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rightChevron: {
    display: 'flex',
    alignSelf: 'center',
    color: '#003C72',
    fontSize: 16,
    fontFamily: 'Avenir Next Rounded Regular',
    lineHeight: '22px',
    fontWeight: 900,
  },
  optionsWrapper: {
    width: '100%',
  },
  paymentMethod: {
    cursor: 'pointer',
  },
  selected: {
    background: 'rgb(188 188 188 / 70%)',
    borderRadius: '5px',
    padding: '10px!important',
  },
})
