import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { testTypes } from '../../../constants'
import { createHealthService } from '../../../features/health-service-lite/model'
import { I18n } from '../../../features/translation/types'
import { lsClient } from '../../../ls-client'
import { selectNetworkSettings } from '../../network/model'
import { DashboardCard } from '../../network/type'
import { paths } from '../../paths'
import { selectProfileData } from '../../profile/model'
import {
  BackgroundImageCard,
  BenefitCard,
  CarouselCard,
  GradientCard,
  PassportCard,
  SimpleButton,
  StandardLargeCard,
  StandardMediumCard,
  TestHistoryCard,
  TransparentCard,
} from './CardsV2'

export const DashboardCardItem = (props: {
  activeHealthService: boolean
  button: DashboardCard
  i18n: I18n
  xl?: boolean
}) => {
  const { button, i18n, xl = false, activeHealthService } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const networkSettings = useSelector(selectNetworkSettings)
  const tenantId = localStorage.getItem('tenantId')

  if (activeHealthService && button.buttonAction?.value === 'HealthService')
    return <></>

  const beginHealthService = (type: 'HealthService' | 'FlowControl') => {
    const params = {
      tenantId,
      accountId: user.accountId,
      UserId: user._id,
      Description: 'Starting Health Service',
    }
    const onCompletion = (healthServiceId: string) => {
      lsClient.setUserLS('healthServiceId', healthServiceId)
      if (type === 'HealthService') {
        history.push(paths.symptomCheckerIntro())
      } else history.push(paths.flowList())
    }
    dispatch(createHealthService(params, onCompletion))
  }

  const buttonAction = (to : string) => {
    if (!to) return null
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        history.push(paths.app.getTested())
        break
      }
      case 'HealthService': {
        beginHealthService('HealthService')
        break
      }
      case 'FlowControl': {
        beginHealthService('FlowControl')
        break
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        history.push(paths.symptomCheckerIntro())
        break
      }
      case 'Resources': {
        history.push(paths.resources())
        break
      }
      case 'ImportHealthRecords': {
        history.push(paths.resources())
        break
      }
      case 'OrderTestKit': {
        history.push(paths.selectTestOrder(testTypes.ORDER))
        break
      }
      default:
        return null
    }
  }

  const linkPath = (to: string | undefined) => {
    if (!to) return ''
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        return paths.app.getTested()
      }
      case 'HealthService': {
        lsClient.setUserLS('isHealthService', 'Y')
        return paths.symptomCheckerIntro()
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        return paths.symptomCheckerIntro()
      }
      case 'Resources': {
        return paths.resources()
      }
      case 'ImportHealthRecords': {
        return paths.resources()
      }
      case 'OrderTestKit': {
        return paths.selectTestOrder(testTypes.ORDER)
      }
      default:
        return ''
    }
  }
  switch (button.style) {
    //Traditional Button - Contained (0) / Outline (1)
    case 0:
    case 1: {
      const buttonStyle = button.style === 0 ? 'contained' : 'outlined'
      const title = button.cardTitle as keyof I18n
      return (
        <SimpleButton
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Button with gradient background
    case 2: {
      return (
        <GradientCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Button with background image
    case 3: {
      return (
        <BackgroundImageCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Large Button with image
    case 4: {
      return (
        <StandardLargeCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
          buttonAction={buttonAction}
        />
      )
    }
    //Medium Button with image
    case 5: {
      return (
        <StandardMediumCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Standard Carousel Card
    case 6: {
      return (
        <CarouselCard
          activeHealthService={activeHealthService}
          button={button}
          xl={false}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Large Carousel Card
    case 7: {
      return (
        <CarouselCard
          activeHealthService={activeHealthService}
          button={button}
          xl={true}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Transparent Card
    case 8: {
      return (
        <TransparentCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Benefit Card
    case 9: {
      return (
        <BenefitCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Passport Card
    case 10: {
      return (
        <PassportCard
          button={button}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Test History Card
    case 11: {
      return <TestHistoryCard />
    }
    default: {
      return <></>
    }
  }
}
