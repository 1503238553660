export const calculateStatus = ({
  page,
  rowsPerPage,
  count,
}: {
  page: number
  rowsPerPage: number
  count: number
}) => {
  const from = page * rowsPerPage + 1
  const to = from + rowsPerPage - 1

  return `${from}-${to > count ? count : to} of ${count}`
}
