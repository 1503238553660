import { Box, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormikProps, useFormik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../../../../features/translation'
import { I18nKey } from '../../../../../../features/translation/types'
import { Typography } from '../../../../../../ui'
import { ActionButton } from '../../../../../../ui/atoms/button'
import {
  getInsuranceProviderCompanies,
  selectInsuranceProvidersCompanies,
  selectInsuranceProvidersCompaniesLoading,
} from '../../../../../insurance/model'
import { ReactiveFormField } from '../../../../components/form-field/reactive-form-field'
import { InsuranceTabFormValues } from '../../../../type'
import { getInsuranceSections } from './mapping'
import validationSchema from './validation-schema'

export const MyInsurance = () => {
  const i18n = useSelector(selectLanguageContent)
  const providerList = useSelector(selectInsuranceProvidersCompanies)
  const providerListLoading = useSelector(
    selectInsuranceProvidersCompaniesLoading
  )
  const [activeSection, setActiveSection] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!providerList.length) dispatch(getInsuranceProviderCompanies())
  }, [])

  const onSubmit = async (values: InsuranceTabFormValues) => {} // todo

  const formik = useFormik<InsuranceTabFormValues>({
    initialValues: {
      insuranceProviderCompany: null,
      insuranceId: '',
      groupNumber: '',
      planName: '',
      coverageDate: null,
      notPolicyHolder: false,
      firstName: '',
      lastName: '',
      phone: '',
      dob: null,
      relationship: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleChangeActiveSection = (sectionIndex: number) => {
    setActiveSection(sectionIndex)
  }

  const handleUpdateValue = (e: ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name as keyof InsuranceTabFormValues
    formik.handleBlur(e)
    formik.validateField(targetName)

    if (!formik.errors[targetName]) console.log(formik.values) // todo: send updates to server
  }

  const sections = getInsuranceSections({
    i18n,
    formik,
    providerList,
    providerListLoading,
  })

  return (
    <Box style={{ marginBottom: 16 }}>
      {sections.map(({ title_key, fields }, index) => {
        return (
          <FormSection
            key={title_key}
            title_key={title_key as I18nKey}
            fields={fields}
            disabled={activeSection !== index}
            sectionIndex={index}
            onSectionChange={handleChangeActiveSection}
            formik={formik}
            onBlur={handleUpdateValue}
          />
        )
      })}
    </Box>
  )
}

const FormSection = ({
  title_key,
  fields,
  disabled,
  sectionIndex,
  onSectionChange,
  formik,
  onBlur,
}: {
  title_key: I18nKey
  fields: { Component: React.FC<any>; props: any }[]
  disabled?: boolean
  sectionIndex: number
  formik: FormikProps<InsuranceTabFormValues>
  onSectionChange: (index: number) => void
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const title = i18n[title_key]

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const renderEditButton = () => (
    <ActionButton
      className={classes.editButton}
      onClick={() => onSectionChange(sectionIndex)}
      disabled={!disabled || !formik.isValid}
    >
      {`${i18n.edit_button} ${title}`}
    </ActionButton>
  )

  return (
    <Box className={classes.contentWrapper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Typography variant="h2" style={{ lineHeight: '36px' }}>
          {title}
        </Typography> */}
        {!isMobile && renderEditButton()}
      </Box>
      <Box className={classes.formContainer}>
        {fields.map(({ Component, props }) => {
          const fieldName = props.name as keyof InsuranceTabFormValues
          return (
            <ReactiveFormField
              key={props.name}
              Component={Component}
              componentProps={props}
              value={formik.values[fieldName]}
              helperText={
                !disabled &&
                formik.touched[fieldName] &&
                formik.errors[fieldName]
              }
              error={
                !disabled &&
                formik.touched[fieldName] &&
                formik.errors[fieldName]
              }
              onChange={formik.handleChange}
              onBlur={onBlur}
              disabled={disabled}
            />
          )
        })}
      </Box>
      {isMobile && renderEditButton()}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    margin: '32px 0 7px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.down('md')]: {
      margin: '24px 0 7px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
    },
  },
  editButton: {
    maxWidth: 'max-content',
    height: 46,
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'center',
      marginTop: 24,
    },
  },
  formContainer: {
    display: 'grid',
    gridColumnGap: 36,
    justifyContent: 'space-between',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridRowGap: 24,
    marginTop: 15,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}))
