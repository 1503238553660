import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import WhiteCheckMark from '../../../assets/animations/WhiteCheckMark.json'
import { paths } from '../../../pages/paths'
import { Button } from '../../../ui'
import { selectLanguageContent } from '../../translation'

export const HSCanceled = () => {
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)

  const goHome = () => {
    history.push(paths.app.dashboard())
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.placedText}>
          <Box className={classes.loadingContent}>
            <Lottie animationData={WhiteCheckMark} />
          </Box>
          <Typography className={classes.title}>
            {i18n.health_service_canceled_title}
          </Typography>
          <Typography align="center" className={classes.processText}>
            {i18n.health_service_canceled_text}
          </Typography>
        </Box>
        <div className={classes.buttonContainer}>
          <Button onClick={goHome} className={classes.loadingButton}>
            {i18n.return_home}
          </Button>
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '16px',
    width: '100%',
    height: '80vh',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    height: '200px',
    width: '200px',
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: '2rem',
    color: '#FFF',
  },
  processText: {
    maxWidth: '300px',
    fontSize: 16,
    color: '#FFF',
  },
  buttonContainer: {
    width: '100%',
  },
  loadingButton: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 48,
    fontWeight: 600,
    marginBottom: 24,
    marginLeft: 15,
    marginRight: 15,
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
}))
