/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from 'agora-rtc-react'
import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lsClient } from '../../ls-client'
import { NetworkInterruptionWarningDialog } from './dialogWindows/networkInterruptionDialog'
import { ProctorSessionCompleteDialog } from './dialogWindows/proctorSessionComplete'
import {
  cancelProctorSession,
  getSessionStatus,
  resetProctorStore,
  selectMemberInSession,
  selectProctorSessionData,
  selectProctorSessionLoading,
  selectProctorStatus,
  selectSessionId,
  selectSessionStartTime,
  selectSessionStatus,
  selectShowControls,
  setAudio,
  setMemberInSession,
  setProctorStatus,
} from './model'

const appId = process.env.REACT_APP_AGORA_APP_ID || ''

const config: ClientConfig = {
  mode: 'rtc',
  codec: 'h264',
}

const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()

export const ProctorVideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
  inCall: boolean
  nextStep: () => void
  requestProctorSession: () => void
  matches: boolean
}) => {
  const { setInCall, inCall, nextStep, requestProctorSession, matches } = props
  const isLoading = useSelector(selectProctorSessionLoading)
  const session = useSelector(selectProctorSessionData)
  const sessionStatus = useSelector(selectSessionStatus)
  const joinedClient = useSelector(selectMemberInSession)
  const sessionId = useSelector(selectSessionId)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [checking, setChecking] = useState(1)
  const proctorStatus = useSelector(selectProctorStatus)
  const proctorSessionStartTime = useSelector(selectSessionStartTime)
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])
  const [showControls, setShowControls] = useState(!matches)
  const client = useClient()
  const { ready, tracks } = useMicrophoneAndCameraTracks()
  const [networkQualityFailure, setNetworkQualityFailure] = useState(false)
  const [networkInterruption, setNetworkInterruption] = useState(false)

  const leaveChannel = async () => {
    await closeAgoraSession()
    dispatch(setMemberInSession(false))
    setInCall(false)
    dispatch(resetProctorStore())
    lsClient.removeUserKeyLS('proctor')
    dispatch(setProctorStatus(null))
  }

  const cancelSession = async () => {
    await closeAgoraSession()
    dispatch(setMemberInSession(false))
    setInCall(false)
    dispatch(cancelProctorSession(session?.channelId, true))
    lsClient.removeUserKeyLS('proctor')
    // clearTestKitFlow()
    dispatch(setProctorStatus(null))
    // history.push(paths.app.dashboard())
  }

  const closeAgoraSession = async () => {
    await client.leave()
    client.removeAllListeners()

    if (tracks) {
      tracks[0].close()
      tracks[1].close()
    }
  }

  const returnMemberToWaiting = async () => {
    await client.leave()
    client.removeAllListeners()
    dispatch(cancelProctorSession(session?.channelId, true))
    dispatch(setMemberInSession(false))
    setInCall(false)
  }

  useEffect(() => {
    //Watch for 'FailToJoin' - reset Agora and return Member to line
    if (proctorStatus === 'FailToJoin') {
      returnMemberToWaiting()
    }
  }, [proctorStatus])

  //Checking sessions status for triggering join and complete
  useEffect(() => {
    if ((sessionStatus === 'W' || sessionStatus === 'R') && proctorStatus) {
      //Flip to Proctor Joined on first instance of "R"
      if (sessionStatus === 'R' && proctorStatus === 'Waiting') {
        lsClient.setUserLS('session', proctorSessionStartTime)
        lsClient.setUserLS('proctor', 'ProctorJoined')
        dispatch(setProctorStatus('ProctorJoined'))
        nextStep()
      }

      //Poll session api to monitor changes made by the Proctor
      if (sessionId && !!!networkQualityFailure) {
        const timeout = setInterval(() => {
          dispatch(getSessionStatus(sessionId))
          setChecking(checking + 1)
        }, 10000)
        return () => {
          clearInterval(timeout)
        }
      }
    } else if (sessionStatus === 'C') {
      lsClient.removeUserKeyLS('proctor')
      dispatch(setProctorStatus('Complete'))
    } else if (
      sessionStatus === 'V' &&
      !(proctorStatus === 'Waiting' || proctorStatus === 'ProctorJoined')
    ) {
      lsClient.removeUserKeyLS('proctor')
      dispatch(setProctorStatus('Invalid'))
    }
  }, [sessionStatus, checking, sessionId, networkQualityFailure])

  const debounce = (func: () => void) => {
    let timeout: NodeJS.Timeout
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        func()
      }, 500)
    }
  }

  //Checks session status if Proctor state change detected by Agora SDK.
  const checkSessionStatus = () => {
    if ((session?.status === 'W' || session?.status === 'R') && !isLoading) {
      dispatch(getSessionStatus(sessionId))
    } else if (session?.status === 'C') {
      dispatch(setProctorStatus('Complete'))
    } else if (session?.status === 'V') {
      dispatch(setProctorStatus('Invalid'))
    }
  }

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name: string) => {
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType)
        setNetworkQualityFailure(false)
        setNetworkInterruption(false)
        checkSessionStatus()
        if (mediaType === 'video') {
          setUsers((prevUsers) => {
            return [...prevUsers, user]
          })
        }
        if (mediaType === 'audio') {
          user.audioTrack?.play()
        }
      })

      client.on('user-unpublished', (user, type) => {
        if (type === 'audio') {
          user.audioTrack?.stop()
        }
        if (type === 'video') {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid)
          })
        }
      })

      // client.on("media-reconnect-start", (state) => {
      //   console.log("reconnecting", state);

      // });

      // client.on("network-quality", (state) => {
      //   console.log("network-quality", state);
      //   if (state.uplinkNetworkQuality === 6) {
      //     setNetworkQualityFailure('Member')
      //   } else if (state.downlinkNetworkQuality === 6) {
      //     setNetworkQualityFailure('Proctor')
      //   }
      // });

      client.on('connection-state-change', (curState, revState, reason) => {
        if (curState === 'DISCONNECTED' && reason === 'LEAVE') {
          console.log('Call Ended')
        }
      })

      client.on('user-left', (user, reason) => {
        if (reason === 'ServerTimeOut') {
          setNetworkInterruption(true)
        }
        debounce(() => checkSessionStatus())
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid)
        })
      })

      if (!joinedClient && session) {
        dispatch(setMemberInSession(true))
        await client.join(
          session?.rtcAppId ? session?.rtcAppId : appId,
          session?.channelId,
          session?.sessionAccessToken,
          session?.memberAccessId
        )
      }

      if (tracks && !inCall && proctorStatus === 'Active') {
        await client.publish([tracks[0], tracks[1]])
        console.log('JOIN')
        setInCall(true)
      }
    }

    if (session && ready && tracks) {
      console.log('init ready')
      if (session.channelId) {
        init(session.channelId)
      }
    }
  }, [session, client, ready, tracks, proctorStatus])

  return (
    <>
      <Box>
        {ready && tracks && (
          <>
            {matches && (
              <Typography
                className={showControls ? classes.moreHidden : classes.more}
                align="center"
              >
                Tap for more
              </Typography>
            )}
            <Box
              className={
                showControls
                  ? classes.controlsContainer
                  : classes.controlsContainerHidden
              }
            >
              <AgoraControls tracks={tracks} />
            </Box>
          </>
        )}
        {joinedClient && tracks && (
          <Videos
            users={users}
            tracks={tracks}
            setShowControls={setShowControls}
            showControls={showControls}
            matches={matches}
          />
        )}
      </Box>
      <ProctorSessionCompleteDialog
        open={
          proctorStatus === 'Complete' ||
          (proctorStatus === 'Invalid' && inCall)
        }
        endSession={leaveChannel}
        matches={matches}
      />
      <NetworkInterruptionWarningDialog
        setNetworkQualityFailure={setNetworkQualityFailure}
        networkQualityFailure={networkQualityFailure}
        setNetworkInterruption={setNetworkInterruption}
        open={networkInterruption}
        endSession={cancelSession}
      />
    </>
  )
}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[]
  setShowControls: React.Dispatch<React.SetStateAction<boolean>>
  showControls: boolean
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack]
  matches: boolean
}) => {
  const { users, tracks, setShowControls, showControls, matches } = props
  const classes = useStyles()
  const forceShowControls = useSelector(selectShowControls)
  const usersInSession = [] as string[]

  useEffect(() => {
    if (forceShowControls) {
      setShowControls(forceShowControls)
    }
  }, [forceShowControls])

  return (
    <div>
      <div id="videos">
        {matches ? (
          <Box
            className={classes.videoMask}
            onClick={() => setShowControls(!showControls)}
          >
            <AgoraVideoPlayer
              className={classes.videoCell}
              videoTrack={tracks[1]}
            />
          </Box>
        ) : (
          <Box className={classes.videoMask}>
            <AgoraVideoPlayer
              className={classes.videoCell}
              videoTrack={tracks[1]}
            />
          </Box>
        )}
        {users.length > 0 &&
          users.map((user) => {
            if (
              user.videoTrack &&
              !usersInSession.includes(user.uid.toString())
            ) {
              // setProctorInSession(true)
              usersInSession.push(user.uid.toString())
              return (
                <AgoraVideoPlayer
                  className={classes.proctorVideoCell}
                  videoTrack={user.videoTrack}
                  key={user.uid}
                />
              )
            }
            return null
          })}
      </div>
    </div>
  )
}

const AgoraControls = (props: { tracks: any }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { tracks } = props
  const [trackState, setTrackState] = useState({ video: true, audio: true })

  const mute = async (type: 'audio' | 'video') => {
    if (type === 'audio') {
      dispatch(setAudio(!trackState.audio))
      await tracks[0].setEnabled(!trackState.audio)
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio }
      })
    }
  }

  return (
    <Box className={classes.videoControls}>
      {trackState.audio ? (
        <MicIcon className={classes.icons} onClick={() => mute('audio')} />
      ) : (
        <MicOffIcon className={classes.icons} onClick={() => mute('audio')} />
      )}
    </Box>
  )
}

const EASE = 'all .5s ease-in-out'
const MASK_RADIAL = '-webkit-radial-gradient(circle, white 100%, black 100%)'

const useStyles = makeStyles((theme) => ({
  more: {
    'fontSize': '14px',
    'color': '#505358',
    'transition': EASE,
    'opacity': 1,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
  },
  moreHidden: {
    'fontSize': '14px',
    'color': '#505358',
    'transition': EASE,
    'opacity': 0,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
  },
  controlsContainer: {
    'position': 'absolute',
    'left': 27,
    'transform': 'translate(0,-57px)',
    'transition': EASE,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
    [theme.breakpoints.up(576)]: {
      left: 38,
    },
    [theme.breakpoints.up(1000)]: {
      left: 16,
      top: 126,
      transform: 'translate(0,0px)',
      zIndex: 1,
    },
  },
  controlsContainerHidden: {
    'position': 'absolute',
    'left': 27,
    'transform': 'translate(0, 3px)',
    'transition': EASE,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
    [theme.breakpoints.up(576)]: {
      left: 38,
    },
    [theme.breakpoints.up(1000)]: {
      left: 75,
    },
  },
  icons: {
    margin: 2,
    borderRadius: '100%',
    fontSize: '24px',
    padding: 10,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  videoControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  video: {
    width: '100%',
    minHeight: '200px',
  },
  videoContainer: {
    marginTop: '20px',
    width: '100%',
  },
  proctorVideoCell: {
    display: 'none',
  },
  videoMask: {
    'width': '100px',
    'height': '100px',
    'borderRadius': '100px',
    '-webkit-mask-image': MASK_RADIAL,
    [theme.breakpoints.up(576)]: {
      'borderRadius': '125px',
      '-webkit-mask-image': MASK_RADIAL,
      'width': '125px',
      'height': '125px',
    },
    [theme.breakpoints.up(1000)]: {
      'borderRadius': '8px',

      '-webkit-mask-image': MASK_RADIAL,
      'width': '300px',
      'height': '187px',
    },
  },
  videoCell: {
    marginTop: 1,
    width: '100px',
    height: '100px',
    zIndex: 100,
    [theme.breakpoints.up(576)]: {
      width: '125px',
      height: '125px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '300px',
      height: '187px',
    },
  },
  window: {
    borderRadius: '50px',
  },
  complete: {
    fontWeight: 800,
    fontSize: '17px',
    fontFamily: 'Avenir Next Rounded Regular',
  },
  completeInstructions: {
    margin: '0 13px',
    fontSize: '13px',
  },
  pageLink: {
    margin: 11,
    cursor: 'pointer',
    color: '#007AFF',
  },
  audioWarningContainer: {
    width: '270px',
    display: 'flex',
    marginTop: '19px',
    paddingBottom: '19px',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },
}))
