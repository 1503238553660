import { Box, Dialog } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg'
import { StepType } from '../../../../constants'
import { addLinkWithinString } from '../../../../features/details-helper/fields'
import { selectLanguageContent } from '../../../../features/translation'
import {
  Button,
  Checkbox,
  CreatePasswordField,
  PasswordValidation,
  TextField,
  Typography,
} from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { sendAppDownloadEmail } from '../../../invite-web-registration/model/inviteRegistrationSlice'
import { selectNetworkSettings } from '../../../network/model'
import {
  getConsents,
  selectConsentError,
  selectConsentsAgreed,
  selectConsentsFetched,
  setConsentsAgreed,
} from '../../../terms-and-conds/model/model'
import { TermsAndConditionsPage } from '../../../terms-and-conds/signupPage'
import { HelpCenter } from '../../components/helpCenter'
import { getName, scrollTop } from '../../helpers'
import {
  resetError,
  selectRegisterError,
  selectRegistrationLoading,
  setError,
  submitUserRegistration,
} from '../../model/registerSlice'
import { RegisterStepProps, UserCreate } from '../../model/type'

export const CreatePassword = (props: RegisterStepProps) => {
  const { handleNext, formik, accountId, tenantId, isInvite } = props
  const classes = onboardingStyles()
  const type = StepType.CREATE_PASSWORD
  const isLoading = useSelector(selectRegistrationLoading)
  const dispatch = useDispatch()
  const error = useSelector(selectRegisterError)
  const currentNetwork = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)
  const [termsOpen, setTermsOpen] = useState(false)
  const consentsFetched = useSelector(selectConsentsFetched)
  const agreeToTerms = useSelector(selectConsentsAgreed)
  const consentError = useSelector(selectConsentError)
  const [showHelp, setShowHelp] = useState(false)
  const [showError, setShowError] = useState(false)
  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  // Override Safari cached load on browser back action
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  useEffect(() => {
    document.addEventListener('focusout', scrollTop)
    return () => {
      document.removeEventListener('focusout', scrollTop)
    }
  }, [])

  const handleChange = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  useEffect(() => {
    if (error && error !== 'User is already registered') {
      setShowError(true)
    }
  }, [error])

  const closeErrorDialog = () => {
    dispatch(setError(''))
    setShowError(false)
  }

  useEffect(() => {
    dispatch(setError(''))
    if (!consentsFetched && tenantId) {
      dispatch(getConsents(tenantId))
    }
  }, [tenantId])

  const registerUser = () => {
    const data: UserCreate = {
      ...formik.values[StepType.SIGN_UP],
      password: values.password,
      isEmailVerified: true,
      tenantId,
      accountId,
      permission: 5,
      is3rdParty: currentNetwork?.benefitSettings?.employerBenefit || false,
      isDependent: Boolean(formik.values[StepType.SIGN_UP].dependentId),
      externalId: formik.values[StepType.SIGN_UP].dependentId || '',
    }
    if (currentNetwork?.benefitSettings?.employerBenefit) {
      data.lastFourSSN = formik.values[StepType.ELIGIBILITY].lastFourSSN
      data.dateOfBirth = formik.values[StepType.ACC_INFO].dob
    }

    const inviteCallback = () => {
      dispatch(sendAppDownloadEmail())
      handleNext()
    }

    dispatch(
      submitUserRegistration(data, isInvite ? inviteCallback : handleNext)
    )
  }

  return (
    <>
      <form className={classes.content}>
        <Typography className={classes.title} color="primary" variant="h1">
          {i18n.create_a_password_header}
        </Typography>
        <Typography className={classes.body} variant="body1" align="left">
          {i18n.create_a_password_instruction}
        </Typography>
        <Box style={{ display: 'none' }}>
          <TextField
            label={'username'}
            name="username"
            autoComplete="username"
            type={'email'}
            value={formik.values[StepType.SIGN_UP].email}
          />
        </Box>
        <CreatePasswordField
          label={i18n.login_password}
          name={getName('password', StepType.CREATE_PASSWORD)}
          value={values.password}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          i18n={i18n}
          error={(touched?.password && errors?.password) || ''}
        />
        <CreatePasswordField
          label={i18n.reenter_password}
          name={getName('confirmPassword', StepType.CREATE_PASSWORD)}
          value={values.confirmPassword}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          i18n={i18n}
          error={(touched?.confirmPassword && errors?.confirmPassword) || ''}
        />
        <PasswordValidation
          i18n={i18n}
          value={formik.values[StepType.CREATE_PASSWORD].password}
        />
        {consentError ? (
          <>
            <Box
              className={classes.termsCheckboxContainer}
              style={{ opacity: 0.1 }}
            >
              <Checkbox disabled label={''} />
              <Typography className={classes.terms}>
                {i18n.agree_terms_label} {i18n.terms_and_conditions_link}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.termsCheckboxContainer}>
              <Checkbox
                label={''}
                checked={agreeToTerms}
                onChange={() => dispatch(setConsentsAgreed(!agreeToTerms))}
              />
              {addLinkWithinString(
                i18n.terms_and_conditions_agreement,
                i18n.terms_and_conditions_link,
                () => setTermsOpen(true),
                classes.terms,
                classes.termsLink
              )}
            </Box>
          </>
        )}
        {error === 'User is already registered' && (
          <Typography style={{ color: '#505358', fontWeight: 500 }}>{i18n.already_have_account_message}</Typography>
        ) }
        <div
          className={
            isInvite ? classes.inviteMobileButtonWrapper : classes.buttonWrapper
          }
        >
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={!formik.isValid || !agreeToTerms || isLoading}
            onClick={registerUser}
          >
            {i18n.continue_button}
          </Button>
        </div>
      </form>
      <Dialog open={termsOpen} fullScreen>
        <div className={classes.dialogContainer}>
          <TermsAndConditionsPage
            onClose={() => setTermsOpen(false)}
            parentTenantId={tenantId}
          />
        </div>
      </Dialog>
      <Dialog open={showError} fullScreen>
        <div className={classes.helpContent}>
          {showHelp ? (
            <HelpCenter
              tenantId={tenantId}
              handleClose={() => setShowHelp(false)}
            />
          ) : (
            <>
              <div
                className={`${classes.warningWrapper} fullsceen`}
                style={{ marginBottom: 16 }}
              >
                <WarningIcon className={classes.warningIcon} />
              </div>
              <HelpOutlineIcon
                className={classes.helpCenterIcon}
                onClick={() => setShowHelp(true)}
              />
              <div style={{ width: '90%' }}>
                <Typography className={classes.regErrorTitle} align="center">
                  {i18n.operation_not_successful}
                </Typography>
                <Typography className={classes.regErrorBody} align="center">
                  {i18n.signup_request_error_message}
                </Typography>
              </div>
              <div
                className={
                  isInvite
                    ? classes.inviteMobileButtonWrapper
                    : classes.buttonWrapper
                }
              >
                <Button
                  className={classes.actionBtn}
                  onClick={closeErrorDialog}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  {i18n.try_again}
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}
