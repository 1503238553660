import { I18n } from '../../translation/types'
import {
  InvalidCodeDialog,
  LogoutDialog,
  TestErrorDialog,
  UnableToVerifyUserDialog,
} from '../dialog-content'
import { DialogType } from '../types'

export const dialogs = (i18n: I18n) => [
  {
    type: DialogType.LOGOUT,
    content: <LogoutDialog i18n={i18n} />,
  },
  {
    type: DialogType.INVALID_ACCESS_CODE,
    content: <InvalidCodeDialog i18n={i18n} />,
  },
  {
    type: DialogType.UNABLE_TO_VERIFY_USER,
    content: <UnableToVerifyUserDialog i18n={i18n} />,
  },
  {
    type: DialogType.TEST_PROCESSING_ERROR,
    content: <TestErrorDialog i18n={i18n} />,
  },
]
