import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export const PrimaryTemplate: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    padding: '3rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}))
