import styled from '@emotion/styled'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { allowedPurposes } from '../../constants'
import { SubBackHeader } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { translateOptions } from '../../libs/helpers'
import { getCurrentUser } from '../../libs/utils'
import { lsClient } from '../../ls-client'
import { Button, RenderAlert, Select, TextField } from '../../ui'
import { Wrapper } from '../../ui/templates/app-layout'
import { selectUser } from '../invite-web-registration/model/inviteRegistrationSlice'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import { adminDirectSupport, sendMessage } from './model'

const APP_VERSION = process.env.REACT_APP_VERSION

export const ContactSupportPage = (props: any) => {
  const { isDialogWindow, setMessageSent, setSupportDialogOpen } = props
  const classes = useStyles()
  const { fullName: userName } = useSelector(selectProfileData)
  const userEmail = getCurrentUser()
  const userParams = useSelector(selectUser)
  const [purpose, setPurpose] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const networkSettings = useSelector(selectNetworkSettings)
  const [error, setError] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const tenant = lsClient.getUserLSByKey('tenantId') || userParams?.tenantId
  const lang = lsClient.getUserLSByKey('lang') || 'en'

  useEffect(() => {
    if (!networkSettings && tenant) {
      dispatch(getNetworkSettings(tenant))
    }
  }, [tenant])

  const getOS = () => {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.includes(platform)) {
      os = 'Mac OS'
    } else if (iosPlatforms.includes(platform)) {
      os = 'iOS'
    } else if (windowsPlatforms.includes(platform)) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (/Linux/.test(platform)) {
      os = 'Linux'
    }

    return os
  }

  const browserName = (function (agent) {
    switch (true) {
      case agent.includes('edge'):
        return 'MS Edge'
      case agent.includes('edg/'):
        return 'Edge ( chromium based)'
      case agent.includes('opr'):
        return 'Opera'
      case agent.includes('chrome'):
        return 'Chrome'
      case agent.includes('trident'):
        return 'MS IE'
      case agent.includes('firefox'):
        return 'Mozilla Firefox'
      case agent.includes('safari'):
        return 'Safari'
      default:
        return 'other'
    }
  })(window.navigator.userAgent.toLowerCase())

  const invitedUserFullName = userParams?.firstName
    ? `${userParams.firstName} ${userParams.lastName}`
    : undefined

  const submitZendesk = async (content: string) => {
    setLoading(true)
    try {
      const res = (await dispatch(
        sendMessage(
          userParams?.email || userEmail,
          invitedUserFullName || userName,
          purpose,
          message,
          content
        )
      )) as unknown
      setLoading(false)

      if (res && !isDialogWindow) {
        history.push(paths.supportMessageSent())
      } else if (res && isDialogWindow) {
        setMessageSent(true)
      } else {
        setError(true)
      }
    } catch {
      setError(true)
    }
  }

  const adminSettingSupport = async (content: string) => {
    setLoading(true)
    try {
      const res = (await dispatch(
        adminDirectSupport(
          userParams?.email || userEmail,
          invitedUserFullName || userName,
          purpose,
          message,
          content
        )
      )) as unknown
      setLoading(false)

      if (res && !isDialogWindow) {
        history.push(paths.supportMessageSent())
      } else if (res && isDialogWindow) {
        setMessageSent(true)
      } else {
        setError(true)
      }
    } catch {
      setError(true)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const meta = `\nApp version: ${APP_VERSION}\nSystem version: Web app - ${getOS()} - ${browserName}\nTenant: ${tenant}\nName: ${
      userName || invitedUserFullName
    }\nEmail: ${userEmail || userParams?.email}\nLanguage: ${lang}`
    networkSettings?.enableZendesk
      ? submitZendesk(meta)
      : adminSettingSupport(meta)
  }

  const handleChangePurpose = (value: string) => {
    setPurpose(value)
  }
  const handleChangeMessage = (value: string) => {
    setMessage(value)
  }

  return (
    <Wrapper>
      {!isDialogWindow ? (
        <SubBackHeader title={i18n.feedback_screen_title} />
      ) : (
        <Box className={classes.headerWrapper}>
          <Box className={classes.headerContainer}>
            <Box
              onClick={() => setSupportDialogOpen(false)}
              className={classes.backIconWrapper}
            >
              <CloseIcon color="primary" className={classes.backIcon} />
            </Box>
            <Typography color="primary" variant="h6">
              {i18n.feedback_screen_title}
            </Typography>
            <Box className={classes.callSupportWrapper}>
              {networkSettings?.supportPhone && (
                <a href={`tel:${networkSettings.supportPhone}`}>
                  <HeadsetMicOutlinedIcon
                    color="primary"
                    className={classes.backIcon}
                  />
                </a>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.content}>
        <Form
          handleChangePurpose={handleChangePurpose}
          handleChangeMessage={handleChangeMessage}
          i18n={i18n}
        />
        <Button
          disabled={isLoading || !purpose || !message}
          className={classes.btn}
          onClick={(e) => handleSubmit(e)}
        >
          {i18n.feedback_send}
        </Button>

        <RenderAlert
          open={error}
          onClose={() => setError(false)}
          type="error"
          text={i18n.something_went_wrong_message}
        />
      </Box>
    </Wrapper>
  )
}
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '600px',
  },
  btn: {
    maxHeight: 50,
    marginTop: 10,
    marginBottom: 30,
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
    width: '100%',
    borderBottom: '1px solid #D7D7D7',
  },
  backIconWrapper: {
    cursor: 'pointer',
    marginLeft: '15px',
  },
  instructionsTitle: {
    fontSize: 18,
    marginTop: 15,
  },
  backIcon: {
    marginTop: 5,
    cursor: 'pointer',
  },
  formWrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridGap: '16px',
    maxWidth: '600px',
  },
  callSupportWrapper: {
    marginRight: '15px',
    minWidth: '24px',
  },
}))

const CircularProgressStyled = styled(CircularProgress)`
  position: absolute;
`

const Form = ({
  handleChangePurpose,
  handleChangeMessage,
  i18n,
}: {
  handleChangePurpose: any
  handleChangeMessage: any
  i18n: I18n
}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [purpose, setPurpose] = useState('')
  const onChangePurpose = (e: any) => {
    const selectedPurpose = _.find(allowedPurposes, ['value', e.target.value])
    // eslint-disable-next-line sonarjs/no-identical-expressions
    setPurpose(selectedPurpose ? selectedPurpose.value : '')
    handleChangePurpose(selectedPurpose ? selectedPurpose.value : '')
  }

  const onChangeMessage = (e: any) => {
    const text = e.target.value
    setValue(text)
    handleChangeMessage(text)
  }

  return (
    <Box className={classes.formWrapper}>
      <Select
        options={translateOptions(allowedPurposes, i18n)}
        onChange={onChangePurpose}
        required
        value={purpose}
        label={i18n.select_purpose}
      />
      <Box>
        <TextField
          variant="outlined"
          label={i18n.menu_option_message_support}
          required
          type="textarea"
          InputLabelProps={{ shrink: true }}
          onChange={onChangeMessage}
          placeholder={i18n.feedback_placeholder}
          isMultiline={true}
          rows={15}
          value={value}
        />
      </Box>
    </Box>
  )
}
