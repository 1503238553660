import { Box, Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { Loading } from '../../../ui'

export const NetworkInterruptionWarningDialog = (props: any) => {
  const {
    open,
    setNetworkQualityFailure,
    networkQualityFailure,
    endSession,
    setNetworkInterruption,
  } = props
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    if (open) {
      const timeout = setInterval(() => {
        if (counter <= 15 && !networkQualityFailure) {
          setCounter(counter + 1)
        } else if (!networkQualityFailure) {
          setCounter(0)
          setNetworkQualityFailure(true)
        }
      }, 1000)
      return () => {
        clearInterval(timeout)
      }
    }
    setCounter(0)
  }, [counter, networkQualityFailure, open])

  return (
    <Dialog open={open}>
      {!networkQualityFailure ? (
        <>
          <Box className={classes.articleContainer}>
            <Loading />
            <Box className={classes.complete}>
              <Typography className={classes.complete} align="center">
                Network interruption detected.
              </Typography>
              <Typography
                className={classes.completeInstructions}
                variant="h6"
                align="center"
              >
                We are attempting to reconnect you to your Proctor.
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.articleContainer}>
            <Box className={classes.complete}>
              <Typography className={classes.complete} align="center">
                Network connection failed.
              </Typography>
              <Typography
                className={classes.completeInstructions}
                variant="h6"
                align="center"
              >
                Your Proctor call has ended. It is important that you complete
                the remaining steps as directed.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.controls}>
            <Box
              className={classes.ok}
              onClick={() => {
                endSession()
                setNetworkInterruption(false)
              }}
            >
              <Typography align="center" className={classes.dialogActionText}>
                {i18n.ok_button}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  )
}

const useStyles = makeStyles((_theme) => ({
  dialogActionText: {
    color: '#007AFF',
    fontSize: '16px',
    fontFamily: 'Montserrat Bold',
  },
  ok: {
    textAlign: 'center',
    width: '100%',
    padding: '11px 0',
    cursor: 'pointer',
    color: '#007AFF',
  },
  controls: {
    display: 'flex',
  },
  complete: {
    padding: 4,
    fontWeight: 800,
    fontSize: '17px',
    color: '#000',
    fontFamily: 'Montserrat Medium',
  },
  completeInstructions: {
    margin: '0 13px',
    fontSize: '13px',
    color: '#000',
    fontFamily: 'Montserrat Regular',
  },
  articleContainer: {
    width: '280px',
    display: 'flex',
    marginTop: '19px',
    paddingBottom: '19px',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },
}))
